import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleLine,
 RiMapPinFill,
 RiStore3Fill,
 RiGiftFill,

 RiFileChartLine
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";

import DetailView from "../../../components/DetailView";


function Orders(props) {
  const {
    token, account,
    setModalPre,
    searchQuery,
    accountInfo,
    subview_data,
    setViewPre
  } = props;
  const [ orders, setOrders ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);
  const [ loaded, setLoaded ] = useState(false);

  const [ modal, setModal ] = useState();
  const [ subViewDataLocal, setSubViewDataLocal ] = useState(subview_data);


  const setDetailsPre = (mod) => {

    const timeout = (modal) ? 300 : 0;
    if(modal){
      setModal(modalOld=>{
        return {
          ...modalOld,
          hide: true
        }
      });
    }
    setTimeout( () => {
        setModal(mod);
    }, timeout)

  }

  useEffect( () => {
    setSubViewDataLocal(subview_data);
  }, [subview_data])

  const readdCart = (order) => {

    axios.post( config.api.url + 'account/orders/readd', {
      hash: token,
      order_id: order.id,
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){

        setModalPre({
          title: 'Yippy.',
          subtitle: response,
          content: (
            <>
              <div className="modalContentOptions NoBorder">
                <a onClick={
                  () => {
                    setViewPre('winkelmandje');
                    setModalPre();
                  }
                } className="modalContentOptionsButton C2a">Winkelmandje</a>
                <a onClick={
                  () => {

                    setModalPre();
                  }
                } className="modalContentOptionsButton">Sluiten</a>
              </div>
            </>
          )
        })
        accountInfo();

      }else{
        setModalPre({
          title: 'Oops.',
          subtitle: response
        })
      }

    })
    .catch(function (error) {

    });

  }


  const cancelOrder = () => {

    setDetailsPre();

    if(modal && modal.id > 0){
      setLoading(true);
      axios.post( config.api.url + 'account/orders/cancel', {
        hash: token,
        order_id: modal.id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);


        if(success){

          setModalPre({
            title: 'Bestelling geannuleerd.',
            subtitle: response
          })
          fetchOrders();

        }else{
          setModalPre({
            title: 'Oops.',
            subtitle: response
          })
        }

      })
      .catch(function (error) {
        setLoading(false);
      });
    }

  }


  const toFixedNoRound = (num, precision = 1) => {
    const factor = Math.pow(10, precision);
    return ((Math.floor(num * factor) / factor)).toFixed(2);
  }

  const modalTotals = () => {

    if(!modal) return false;


    let subtotals = (modal.totals_amount);
    let ob = toFixedNoRound((subtotals / 110) * 10, 2);
    ob = 0;
    let totals = toFixedNoRound(subtotals * 1, 2);

    if(modal.delivery_type == 'DELIVERY'){
      totals = toFixedNoRound(parseFloat(totals) + parseFloat(modal.delivery.fee));
    }
    subtotals = toFixedNoRound(subtotals - ob, 2);
    let totals_no_discount = toFixedNoRound(parseFloat(totals));

    if(modal.discount_amount > 0){
      totals -= parseFloat(modal.discount_amount);

      totals = toFixedNoRound(totals);
    }

    return (
      <div className="modalSideFinalWrapper">
        <div className="modalSideFinal">
          <div className="modalSideFinalPre">{ 'Subtotaal' }</div>
          <div className="modalSideFinalAmount">SRD { subtotals }</div>
        </div>
        {/* <div className={`modalSideFinal ${ (parseInt(modal.discount_amount) == 0) ? 'BorderBottom' : ''}`}>
          <div className="modalSideFinalPre">BTW (10%)</div>
          <div className="modalSideFinalAmount">SRD { ob }</div>
        </div> */}
        { (modal.delivery_type == 'DELIVERY') &&
        <div className="modalSideFinal">
          <div className="modalSideFinalPre">Levering</div>
          <div className="modalSideFinalAmount" style={ (modal.delivery.fee == 0) ? { color: '#47bb3c', fontSize: 16 } : { }}>{ (modal.delivery.fee == 0) ? (<b>GRATIS</b>) : (<>SRD <b> { toFixedNoRound(modal.delivery.fee) }</b></>) }</div>
        </div>
        }
        { (modal.discount_amount > 0) &&
        <div className="modalSideFinal BorderBottom">
          <div className="modalSideFinalPre">Subtotaal zonder korting</div>
          <div className="modalSideFinalAmount">SRD { totals_no_discount }</div>
        </div>
        }
        { (modal.discount_amount > 0) &&
        <div className="modalSideFinal">
          <div className={`modalSideFinalPre`}>Korting { (modal.discount) ? ('('+modal.discount.amount+')') : ''}
              <div className="OrderV2OptionsPreOptionsError" style={{
                backgroundColor: '#3a62c6',
                fontSize: 9,
                paddingBottom: 1,
                marginBottom: -1
              }}>
                <RiGiftFill />
              </div>
            </div>
          <div className="modalSideFinalAmount" style={{ color: '#d63636' }}>SRD { modal.discount_amount }</div>
        </div>
        }
        <div className="modalSideFinal">
          <div className="modalSideFinalPre">{ 'Totaal' }</div>
          <div className="modalSideFinalAmount">SRD { totals }</div>
        </div>
      </div>
    )
  }
  const modalView = () => {

    return (
        <div className={`ModalSide`}>
          <div className="ModalSideHeader">
            <div className="ModalSideHeaderTitle">Bestelling { 'bekijken' }</div>

            <a onClick={
              () => {
                setDetailsPre()
              }
            } className="ModalSideHeaderClose">
              <RiCloseLine />
            </a>
          </div>

          <div className="ModalSideContent">

            { (modal && modal.account) &&
            <div className="modalAccountView">

              <div className="modalAccountDetailsWrapper">
                <div className="modalAccountAvatar" style={{ backgroundImage: `url(${ (modal.account?.avatar) })` }} />
                <div className="modalAccountDetails">
                  <div className="modalAccountDetailsName">{ modal.account.name }</div>
                  <div className="modalAccountDetailsPhone">{ modal.account.phone }</div>
                </div>
              </div>

              <div className="modalAccountDetailsOptions">
                  <div className="ModalSideContentTwin Single">
                    <div className="ModalSideContentInput">
                      <div className="ModalSideContentInputPre">Status</div>
                      <div className="ModalSideContentInputTextWrapper">
                        <div className="ModalSideContentInputText Filled" style={{ color: modal.status_color }}>{ modal.status }</div>
                      </div>
                    </div>
                  </div>
              </div>

            </div>
            }

            { (modal) &&
            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Levering/Pick-up</div>
                <div className="ModalSideContentInputTextNormal Padded DeliveryType">{ modal.delivery_type }</div>
              </div>
            </div>
            }


            <div className="modalDeliveryView">
            { (modal && modal.delivery) &&
            ( modal.delivery_type == 'DELIVERY'
            ?
              <div className="modalDelivery">
                <RiMapPinFill style={{ marginTop: 3 }} />
                <div className="modalDeliveryDetails">
                  <div className="modalDeliveryText">{ modal.delivery.address }</div>
                  <div className="modalDeliveryAddress">{ modal.delivery.area }</div>
                </div>
              </div>
            :
            <div className="modalDelivery">
              <RiStore3Fill style={{ marginTop: 3 }} />
              <div className="modalDeliveryDetails">
                <div className="modalDeliveryText">{ modal.delivery.name }</div>
                <div className="modalDeliveryAddress">{ modal.delivery.address }</div>
              </div>
            </div>
            )
            }

            { (modal && !modal.cancel_request && (!['CANCELED', 'COMPLETED', 'ONTHEWAY'].includes(modal.status_raw))) &&
              <div className="modalCancelViewWrapper">
                <div className="modalCancelView">
                  <div className="modalCancelTitle">Van gedachte veranderd?</div>
                  <div onClick={
                    () => {
                      setModalPre({
                        title: 'Bestelling annuleren.',
                        subtitle: 'Stuur een aanvraag om jouw bestelling te annuleren. DA Drogisterij maakt contact op met jou voor verdere afhandeling.',
                        content: (
                          <>
                            <div className="modalContentOptions NoBorder">
                              <a onClick={
                                () => {

                                  cancelOrder();
                                  setModalPre();
                                }
                              } className="modalContentOptionsButton Delete">Aanvragen</a>
                              <a onClick={
                                () => {

                                  setModalPre();
                                }
                              } className="modalContentOptionsButton">Annuleren</a>
                            </div>
                          </>
                        )
                      })
                    }
                  } className="modalCancelButton">Bestelling annuleren</div>
                </div>
              </div>
              }
            </div>

            { (modal && modal.cancel_request) &&
            <div className="modalErrorView">
              <div className="modalError">
                <div className="OrderV2OptionsPreOptionsError">!</div>
                <div className="modalErrorText">Je hebt aangevraagd om deze bestelling te annuleren. DA Drogist maakt contact op met jou voor verder afhandeling.</div>
              </div>
            </div>
            }

            <div key={ JSON.stringify(modal) }>
              <div className="modalCouponTitle">Item(s)</div>

              { (modal && modal.items) &&
                (modal.items).map( (cart_item, cart_index) => {

                  const { qty, product } = cart_item;
                  const finalPrice = (parseInt(qty) * parseFloat(product.price)).toFixed(2);

                  return (
                    <div className="modalCouponItem NoBackground End" key={'coup_item_' + cart_index}>
                      <div className="modalCouponItemImageWrapper">
                        <div className="modalCouponItemImage" style={{ backgroundImage: `url(${ (product.image) })` }}/>
                      </div>
                      <div className="modalCouponItemDetailsWrapper">
                        <div className="modalCouponItemDetails">
                          <div className="modalCouponItemDetailsName">{ product.title }</div>
                          <div className="modalCouponItemDetailsSubWrapper">
                            <div className="modalCouponItemDetailsSub" style={{ backgroundColor: (qty > 1) ? '#4bd63e' : 'rgba(255,255,255,.3)' }}>
                              <div className="modalCouponItemDetailsSubText">{ qty + 'x' }</div>
                            </div>
                            <div className="modalCouponItemDetailsSubItemText">{ 'SRD ' + (product.price) }</div>
                          </div>
                        </div>
                        <div className="modalCouponItemSub">
                          <div className="modalCouponItemSubPrice"></div>
                          <div className="modalCouponItemSubPriceNew">SRD { (finalPrice) }</div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              { (modal) && modalTotals() }

            </div>

          </div>

          <div className="modalContentOptions LightBorder">
            <a onClick={
              () => {

                setDetailsPre();
              }
            } className="modalContentOptionsButton">Sluiten</a>
          </div>

        </div>
    )
  }

  const fetchOrders = (pageFinal = page) => {

    setLoading(true);
    axios.post( config.api.url + 'account/orders', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      setTimeout( () => {
        setLoading(false);
      }, 1);

      if(success){
        const orders = data.orders;
        const stats = data.stats;

        if(!loaded){
          setLoaded(true);
        }

        if(orders){
          setOrders(orders);
        }

        if(subViewDataLocal.includes('order/')){
          const orderID = subViewDataLocal.replace('order/', '');

          if(orderID > 0){
            const orderFound = (orders).find((or, ind) => or.id == orderID);

            if(orderFound){

              setDetailsPre(orderFound);

              // SET URL
              window.history.pushState({"z":"z"}, null, config.homepage + 'account/bestellingen');
              setSubViewDataLocal();


            }

          }
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/bestellingen/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchOrders(page);
  }

  const emailsPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(orders.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/bestellingen/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/bestellingen/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/bestellingen/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }

  useEffect( () => {


    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchOrders(page_parsed);
    }else{
      fetchOrders();
    }

  }, []);


  useEffect( () => {

    if(!loading && loaded){
      // controller.abort();
      fetchOrders();
    }

  }, [searchQuery]);


  const orders_items = (orders).map( (order, index) => {
    const { account, discount, cancel_request, items, timestamp, final_amount, status, status_color } = order;


    let products_items = [];
    for(let pi = 0; pi < items.length; pi++){
      const item = items[pi];

      for(let piq = 0; piq < item.qty; piq++){
        products_items.push(item);
      }
    }
    const startRotate = (products_items.length == 1) ? 0 : (-10);
    const rotatePer = (20 / products_items.length);


    const firstItem = (products_items.length > 0) ? products_items[0] : null;


    return (
      <div className="OrderV2Wrapper" key={'order_' + order.id}>

        <div className="OrderV2Items">
          { (firstItem) &&
          <div className="OrderV2ItemsBackground" style={{ backgroundImage: `url('${ firstItem.product.image }')` }} />
          }

          { (products_items).map( (item, index) => {
            const rotate = (startRotate + (index * rotatePer)) + 'deg';
            return (
              <img key={'order_' + order.id + '_item_' + index} className="OrderV2ItemsItem" style={{ zIndex: (10 + index), rotate: rotate }} src={ item.product.image } />
            )
          })
          }
        </div>

        <div className="OrderV2Options">
          <div className="OrderV2OptionsPre">
            <div className="OrderV2OptionsPreOrderNo">{ order.order_no }</div>
            <div className="OrderV2OptionsPreOrderDate">{ timestamp?.date }</div>
          </div>
          <div className="OrderV2OptionsPreOptions">
            <div className="OrderV2OptionsPreOptionsStatusView">
              <div className="OrderV2OptionsPreOptionsStatus" style={{ color: status_color }}>{ status }</div>
              { (discount) &&
              <div className="OrderV2OptionsPreOptionsError" style={{
                backgroundColor: '#3a62c6',
                fontSize: 9,
                paddingBottom: 1,
                marginRight: -5
              }}>
                <RiGiftFill />
              </div>
              }
              { (cancel_request) &&
              <div className="OrderV2OptionsPreOptionsError">!</div>
              }
            </div>
            <div className="OrderV2OptionsPreOptionsPriceView">
              <div className="OrderV2OptionsPreOptionsPricePre">SRD</div>
              <div className="OrderV2OptionsPreOptionsPrice">{ final_amount }</div>
            </div>
            <div className="OrderV2OptionsPreOptionsButton Cancel" onClick={
              () => {
                setModalPre({
                  title: 'Item(s) opnieuw in jouw winkelmandje plaatsen.',
                  subtitle: 'Plaats de item(s) van deze bestelling opnieuw in jouw winkelmandje, zodat jij opnieuw kan bestellen.',
                  content: (
                    <>
                      <div className="modalContentOptions NoBorder">
                        <a onClick={
                          () => {

                            readdCart(order);
                            setModalPre();
                          }
                        } className="modalContentOptionsButton C2a">Toevoegen</a>
                        <a onClick={
                          () => {

                            setModalPre();
                          }
                        } className="modalContentOptionsButton">Annuleren</a>
                      </div>
                    </>
                  )
                })
              }
            }>Opnieuw bestellen</div>
            <div className="OrderV2OptionsPreOptionsButton" onClick={
              () => {
                setDetailsPre(order);
              }
            }>Bekijken</div>
          </div>
        </div>

      </div>
    )

    // return (
    //   <div className="OrderItem Aligned" key={'order_item_' + order.id}>
    //     <div className="OrderItemDetailsText ID">{ order.order_no }</div>
    //     <div className="OrderItemDetailsText Amount">
    //       <div>{ order.items_count } { (order.items_count == 1) ? 'product' : 'producten' }</div>
    //       <div style={{ marginTop: 5, fontWeight: 'bold' }}>{ final_amount }</div>
    //     </div>
    //     <div className="OrderItemDetailsText EmailFixed">
    //       <div style={{ fontSize: 12 }}>{ order.delivery_type }</div>
    //       <div style={{ marginTop: 5, fontWeight: 'bold' }}>{ (order.delivery_type == 'DELIVERY') ? order.delivery.address : order.delivery.name }</div>
    //     </div>
    //     <div className="OrderItemDetailsText Status Bold" style={{ color: status_color }}>{ status }</div>
    //     <div className="OrderItemDetailsText Date">{ timestamp?.date }</div>
    //
    //     <div className="OrderItemOptions Big">
    //       <a onClick={
    //         () => {
    //
    //           setDetailsPre(order);
    //
    //         }
    //       } className="OrderItemOptionsButton Edit">
    //         <RiFileShield2Line style={{ fontSize: 14 }} />
    //         <span className="OrderItemOptionsButtonText">{ 'Bekijken' }</span>
    //       </a>
    //     </div>
    //   </div>
    // )
  })


  return (
    <div className={`SettingsContentContent List ${ (modal) ? 'Modal' : ''}`}>

      <div className={`settingsContentReal Flexed`}>

        {/*
        <div className="SettingsContentNavigation">
          <div className="SettingsContentNavigationItem ID">ID</div>
          <div className="SettingsContentNavigationItem Amount">Item(s)</div>
          <div className="SettingsContentNavigationItem EmailFixed">Afhaal</div>
          <div className="SettingsContentNavigationItem Status">Status</div>
          <div className="SettingsContentNavigationItem Date">Datum</div>
          <div className="SettingsContentNavigationItem OptionsBig"></div>
        </div>
        */}

        <div className="GlobSettingsScroll">

          { (loading)
          ?
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
         :
         (

           (orders_items.length > 0)
            ?
            orders_items
            :
            <div className="SettingsMessageText">{ (loading) ? '' : 'Geen bestellingen.' }</div>

         )
         }
        </div>

        { emailsPagination() }

      </div>

      <DetailView modal={modal} width={600} setDetailsPre={setDetailsPre}>
      { modalView() }
      </DetailView>

    </div>
  )
}

export default Orders;
