import '../../styles/Login.css';
import config from '../../constants/config';
import {
  RiArrowRightLine,
  RiFacebookFill
} from "react-icons/ri";
import {
  FcGoogle
} from "react-icons/fc";
import React, { useState, useRef, useEffect } from 'react';

import loadingAnimation from '../../animations/loading_circle.json';
import Lottie from "lottie-react";
import axios from 'axios';
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { useLogin } from 'react-facebook';

import jwt_decode from "jwt-decode";


function Login(props) {
  const {
    addNotification,
    setAccountPre,
    setViewPre,
    subview,
    viewData,
    setModalPre,
    loginGoogleFull,
    getUserInfo
  } = props;
  const { login, status, isLoading, error} = useLogin();
  const [ response, setResponse ] = useState('');
  const [ resetPasswordToken, setResetPasswordToken ] = useState('');
  const [ loginView, setLoginView ] = useState('login');
  const [ loading, setLoading ] = useState(false);
  const [ acceptDisclaimer, setAcceptDisclaimer ] = useState(true);

  const [ verifyResponse, setVerifyResponse ] = useState();

  const inputEmail  = useRef('');
  const inputPassword  = useRef('');

  const inputFirstName  = useRef();
  const inputLastName  = useRef();
  const inputPasswordRetype  = useRef();

   const setLoginViewPre = (loginV) => {
     let url = loginV;
     switch(loginV){
       case "login":
         url = 'inloggen';
       break;
       case "signup":
         url = 'registreren';
       break;
       case "forgot":
         url = 'vergeten';
       break;
       case "verify_email":
         url = 'verifieren';
       break;
     }

     window.history.pushState({"z":"z"}, null, config.homepage + 'account/' + url);
     setLoginView(loginV);
   }


  const setResponsePre = (response) => {

    setResponse(response);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setTimeout( () => {
      setResponse('');
    }, 5000);
  }

  const verifyAccount = (token) => {

    if(token != ''){
      setLoading(true);
      axios.post( config.api.url + 'auth/account/verify', {
        token: token
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key,
            'session': props.sessionHash
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setVerifyResponse({
          title: (success) ? 'Yippy.' : 'Oops.',
          subtitle: response,
          account: (data && data.account) ? data.account : null,
          token: (data && data.token) ? data.token : null
        })

        setLoading(false);
      })
      .catch(function (error) {

        setLoading(false);
      });
    }

  }

  const authPasswordSet = () => {
    const password = (inputPassword.current?.value).trim();
    const password_retype = (inputPasswordRetype.current?.value).trim();

    if(resetPasswordToken != ''){
      if(password != password_retype){
        setResponsePre('Jouw ingevoerde wachtwoorden komen niet overeen.');
      }else{
        setLoading(true);
        axios.post( config.api.url + 'auth/password/set', {
          token: resetPasswordToken,
          password: password
        }, {
            crossdomain: true,
            headers: {
              'api': config.api.key,
              'session': props.sessionHash
            }
        })
        .then((json) => {
          const { success, response, data } = json.data;


          if(success){
            const account = data.account;
            const token = data.token;


            if(account && token != ''){

              // if(viewData && viewData.previous){
              //   setViewPre(viewData.previous);
              // }else{
              // }

              // window.history.pushState({"z":"z"}, null, config.homepage + 'account');
              setViewPre('home');
              setAccountPre(account, token);
            }

            setTimeout( () => {
              setModalPre({
                title: 'Welkom terug!',
                subtitle: response
              })
            }, 150);

          }else{
            setResponsePre(response)
          }

          setLoading(false);
        })
        .catch(function (error) {

          setLoading(false);
        });
      }
    }else{
      setResponsePre('De reset token is niet geldig. Vraag nogmaals aan om jouw wachtwoorde te wijzigen.');
    }
  }

  const authLogin = () => {
    const email = inputEmail.current?.value;
    const password = inputPassword.current?.value;


    if(!acceptDisclaimer){
      setResponsePre('Om verder te gaan, moet je eerst akkoord gaan met onze voorwaarden.');

    }else{

      setLoading(true);
      axios.post( config.api.url + 'auth/login', {
        email: email,
        password: password
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key,
            'session': props.sessionHash
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        if(success){
          const account = data.account;
          const token = data.token;


          if(account && token != ''){

            if(viewData && viewData.previous){
              setViewPre(viewData.previous);
            }
            setAccountPre(account, token);
          }

        }else{
          setResponsePre(response)
        }

        setLoading(false);
      })
      .catch(function (error) {

        setLoading(false);
      });

    }


  }

  const authForgot = () => {
    const email = inputEmail.current?.value;

    if(email != ''){
      setLoading(true);
      axios.post( config.api.url + 'auth/forgot', {
        email: email
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key,
            'session': props.sessionHash
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        console.log(json.data);

        if(success){

          setModalPre();
          setLoginViewPre('login');
          setTimeout( () => {
            setModalPre({
              title: 'Bijna daar!',
              subtitle: response
            })
          }, 150);

        }else{
          setResponsePre(response)
        }

        setLoading(false);
      })
      .catch(function (error) {

        setLoading(false);
      });
    }


  }

  const authSignup = () => {
    const first_name = inputFirstName.current?.value;
    const last_name = inputLastName.current?.value;

    const email = inputEmail.current?.value;

    const password = (inputPassword.current?.value).trim();
    const password_retype = (inputPasswordRetype.current?.value).trim();

    if(!acceptDisclaimer){
      setModalPre({
        title: 'Oops.',
        subtitle: 'Om verder te gaan, moet je eerst akkoord gaan met onze voorwaarden.'
      })
    }else{
      if(password != password_retype){
        setResponsePre('Jouw ingevoerde wachtwoorden komen niet overeen.');
      }else{

        setLoading(true);
        axios.post( config.api.url + 'auth/signup', {
          email: email,
          password: password,
          first_name: first_name,
          last_name: last_name
        }, {
            crossdomain: true,
            headers: {
              'api': config.api.key,
              'session': props.sessionHash
            }
        })
        .then((json) => {
          const { success, response, data } = json.data;


          if(success){
            const account = (data) ? data.account : null;
            const token = (data) ? data.token : null;

            if(account && token != ''){

              // SET MODAL
              setModalPre({
                title: 'Hmm...',
                subtitle: response
              })
              if(viewData && viewData.previous){
                setViewPre(viewData.previous);
              }
              setAccountPre(account, token);
            }else{

              setModalPre();
              setLoginViewPre('login');
              setTimeout( () => {
                setModalPre({
                  title: 'Yippy!',
                  subtitle: response
                })
              }, 150);
            }

          }else{
            setResponsePre(response)
          }

          setLoading(false);
        })
        .catch(function (error) {

          setLoading(false);
        });
      }
    }


  }


  useEffect( () => {

     // Get subview
     let subtext = subview;
     if(/^wachtwoord\//.test(subview)){
       subtext = 'wachtwoord';
     }else if(/^verifieren\//.test(subview)){
       subtext = 'verifieren';
     }


    switch(subtext){
      case "registreren":
        setAcceptDisclaimer(false);
        setLoginViewPre('signup');
      break;
      case "inloggen":
        setAcceptDisclaimer(true);
        setLoginViewPre('login');
      break;
      case "vergeten":
        setLoginViewPre('forgot');
      break;
      case "wachtwoord":
        const subviews = (subview + '').split('/');
        const val = (subviews.length > 1) ? subviews[subviews.length - 1] : '';

         if(loginView != 'set_password' && val != ''){
           setLoginView('set_password');
           setResetPasswordToken(val);
         }
      break;
      case "verifieren":
        const subviews_v = (subview + '').split('/');
        const val_v = (subviews_v.length > 1) ? subviews_v[subviews_v.length - 1] : '';

         if(val_v != ''){
           setLoginViewPre('verify_email');
           verifyAccount(val_v);
         }
      break;
    }


  }, [subview])


  const responseView = () => {

    let responseData;
    if(response != ''){

      responseData = (
        <div className={`LoginResponse`}>
          <div className="LoginResponseDot" />
          <div className="LoginResponseDetails">
            <div className="LoginResponseDetailsText">{ response }</div>
          </div>
        </div>
      )
    }

    return (
      <div className={`LoginResponseWrapper ${ (response != '') ? 'Show' : '' }`}>
        { responseData }
      </div>
    );
  }


  const loginGoogle = useGoogleLogin({
    onSuccess: async credentialResponse => {
      getUserInfo(credentialResponse);
    },
    auto_select: true
  });

  async function loginFacebook () {
    try {
      const response = await login({
        scope: 'email',
      });

      console.log(response);
    } catch (error) {
      console.log(error.message);
    }
  }

  const contentView = () => {


    switch(loginView){

      case "verify_email":
        console.log(verifyResponse);
        return (
          <div className="LoginForm">

            <div className="LoginFormHeader">
              <div className="LoginFormHeaderTitle">E-mail adres verifiëren.</div>
            </div>

            <div className="LoginFormItems">

              <div className="LoginFormVerifyWrapper">

                { (loading)
                ?
                <div className="LoginLoading Inline">
                  <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
                </div>
                :
                ( (verifyResponse)
                  ?
                  (
                    <div className="LoginFormVerify">
                      <div className="LoginFormVerifyTitle">{ verifyResponse.title }</div>
                      <div className="LoginFormVerifySubtitle">{ verifyResponse.subtitle }</div>
                    </div>
                  )
                  :
                  (<></>)
                )
                }

              </div>

              <div className="LoginFormOptions">
                <a onClick={
                  () => {

                    setViewPre('home');
                  }
                } className="LoginFormOptionsButton Left Forgot">
                  <div className="LoginFormOptionsButtonText">Sluiten</div>
                  <RiArrowRightLine />
                </a>
                <a onClick={
                  () => {

                    if(verifyResponse && verifyResponse.account && verifyResponse.token){

                      if(viewData && viewData.previous){
                        setViewPre(viewData.previous);
                      }
                      setAccountPre(verifyResponse.account, verifyResponse.token);
                    }

                  }
                } className="LoginFormOptionsButton AccentHover">
                  <div className="LoginFormOptionsButtonText">Inloggen</div>
                  <RiArrowRightLine />
                </a>
              </div>

            </div>

          </div>
        )
      break;

      case "set_password":
        return (
          <div className="LoginForm">

            <div className="LoginFormHeader">
              <div className="LoginFormHeaderTitle">Wachtwoord aanmaken.</div>
            </div>

            <div className="LoginFormItems">
              <div className="LoginFormItem">
                <div className="LoginFormItemPre">Wachtwoord</div>
                <div className="LoginFormItemInputView">
                  <input
                    ref={inputPassword}
                    className="LoginFormItemInput"
                    type={'password'}
                    key={'input_signup_password'}
                    placeholder={''}
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                        switch(key){
                          case "Enter":
                            if(!loading){
                              authPasswordSet();
                            }
                          break;
                        }
                      }
                    }
                  />
                </div>
              </div>

              <div className="LoginFormItem">
                <div className="LoginFormItemPre">Wachtwoord verifiëren</div>
                <div className="LoginFormItemInputView">
                  <input
                    ref={inputPasswordRetype}
                    className="LoginFormItemInput"
                    type={'password'}
                    key={'input_signup_password_ver'}
                    placeholder={''}
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                        switch(key){
                          case "Enter":
                            if(!loading){
                              authPasswordSet();
                            }
                          break;
                        }
                      }
                    }
                  />
                </div>
              </div>


              <div className="LoginFormOptions">
                <a onClick={
                  () => {
                    setAcceptDisclaimer(true);
                    setLoginViewPre('login')
                  }
                } className="LoginFormOptionsButton Left Forgot">
                  <div className="LoginFormOptionsButtonText">Annuleren</div>
                  <RiArrowRightLine />
                </a>
                <a onClick={
                  () => {
                    authPasswordSet();
                  }
                } className="LoginFormOptionsButton AccentHover">
                  <div className="LoginFormOptionsButtonText">Aanmaken</div>
                  <RiArrowRightLine />
                </a>
              </div>

            </div>

          </div>
        )
      break;

      case "forgot":
        return (
          <div className="LoginForm">

            <div className="LoginFormHeader">
              <div className="LoginFormHeaderTitle">Wachtwoord vergeten?</div>
            </div>

            <div className="LoginFormItems">
              <div className="LoginFormItem">
                <div className="LoginFormItemPre">E-mail adres</div>
                <div className="LoginFormItemInputView">
                  <input
                    ref={inputEmail}
                    className="LoginFormItemInput"
                    placeholder={''}
                    key={'input_forgot_email'}
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                        switch(key){
                          case "Enter":
                            if(!loading){
                              authForgot();
                            }
                          break;
                        }
                      }
                    }
                  />
                </div>
              </div>


              <div className="LoginFormOptions">
                <a onClick={
                  () => {
                    setAcceptDisclaimer(true);
                    setLoginViewPre('login')
                  }
                } className="LoginFormOptionsButton Left Forgot">
                  <div className="LoginFormOptionsButtonText">Terug gaan</div>
                  <RiArrowRightLine />
                </a>
                <a onClick={
                  () => {
                    authForgot();
                  }
                } className="LoginFormOptionsButton AccentHover">
                  <div className="LoginFormOptionsButtonText">Resetten</div>
                  <RiArrowRightLine />
                </a>
              </div>

            </div>

          </div>
        )
      break;

      case "signup":

        return (
          <div className="LoginForm">

            <div className="LoginFormHeader">
              <div className="LoginFormHeaderTitle">Maak jouw <b>DA</b> account aan.</div>
            </div>

            <div className="LoginFormItems">

              <div className="LoginFormItemTwin">
                <div className="LoginFormItem">
                  <div className="LoginFormItemPre">Voornaam</div>
                  <div className="LoginFormItemInputView">
                    <input
                      ref={inputFirstName}
                      className="LoginFormItemInput"
                      key={'input_signup_fname'}
                      placeholder={''}
                      onKeyUp={
                        (event) => {
                          const key = event.key;

                          switch(key){
                            case "Enter":
                              if(!loading){
                                authSignup();
                              }
                            break;
                          }
                        }
                      }
                    />
                  </div>
                </div>
                <div className="LoginFormItem">
                  <div className="LoginFormItemPre">Achternaam</div>
                  <div className="LoginFormItemInputView">
                    <input
                      ref={inputLastName}
                      className="LoginFormItemInput"
                      placeholder={''}
                      key={'input_signup_lname'}
                      onKeyUp={
                        (event) => {
                          const key = event.key;

                          switch(key){
                            case "Enter":
                              if(!loading){
                                authSignup();
                              }
                            break;
                          }
                        }
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="LoginFormItem">
                <div className="LoginFormItemPre">E-mail adres</div>
                <div className="LoginFormItemInputView">
                  <input
                    ref={inputEmail}
                    className="LoginFormItemInput"
                    placeholder={''}
                    key={'input_signup_email'}
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                        switch(key){
                          case "Enter":
                            if(!loading){
                              authSignup();
                            }
                          break;
                        }
                      }
                    }
                  />
                </div>
              </div>

              <div className="LoginFormItem">
                <div className="LoginFormItemPre">Wachtwoord</div>
                <div className="LoginFormItemInputView">
                  <input
                    ref={inputPassword}
                    className="LoginFormItemInput"
                    type={'password'}
                    key={'input_signup_password'}
                    placeholder={''}
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                        switch(key){
                          case "Enter":
                            if(!loading){
                              authSignup();
                            }
                          break;
                        }
                      }
                    }
                  />
                </div>
              </div>

              <div className="LoginFormItem">
                <div className="LoginFormItemPre">Wachtwoord verifiëren</div>
                <div className="LoginFormItemInputView">
                  <input
                    ref={inputPasswordRetype}
                    className="LoginFormItemInput"
                    type={'password'}
                    key={'input_signup_password_ver'}
                    placeholder={''}
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                        switch(key){
                          case "Enter":
                            if(!loading){
                              authSignup();
                            }
                          break;
                        }
                      }
                    }
                  />
                </div>
              </div>

              <div onClick={
                () => {
                  setAcceptDisclaimer(!acceptDisclaimer);
                }
              } className={`LoginFormDisclaimerWrapper ${ (acceptDisclaimer) ? 'Selected' : ''}`}>
                <div className="LoginFormDisclaimer">
                  <div className={ `LoginFormDisclaimerCheck` }>

                  </div>
                  <div className="LoginFormDisclaimerText">Ik heb de <a href={ config.homepage + 'info/disclaimer' } target={'_blank'}>Algemene voorwaarden</a> gelezen en ik ga hiermee akkoord.</div>
                </div>
              </div>

              <div className="LoginFormOptions">
                <a onClick={
                  () => {
                    setAcceptDisclaimer(true);
                    setLoginViewPre('login')
                  }
                } className="LoginFormOptionsButton Left LoginBack">
                  <div className="LoginFormOptionsButtonText">Ik heb een <b>DA</b> account</div>
                  <RiArrowRightLine />
                </a>
                <a onClick={
                  () => {
                    authSignup();
                  }
                } className="LoginFormOptionsButton AccentHover">
                  <div className="LoginFormOptionsButtonText">Registreren</div>
                  <RiArrowRightLine />
                </a>
              </div>

              <div className="LoginFormOrView">
                <div className="LoginFormOrDivider" />
                <div className="LoginFormOr">OF LOGIN VIA</div>
                <div className="LoginFormOrDivider" />
              </div>

              <a onClick={
                () => {
                  loginGoogle();
                }
              } className="LoginFormOptionsButton Social Go">
                <FcGoogle />
                <div className="LoginFormOptionsButtonText">Jouw <b>Google</b> account</div>
                <RiArrowRightLine />
              </a>

              {/*
              <a onClick={
                () => {
                  loginFacebook();
                }
              } className="LoginFormOptionsButton Social Fb">
                <RiFacebookFill />
                <div className="LoginFormOptionsButtonText">Jouw <b>Facebook</b> account</div>
                <RiArrowRightLine />
              </a>
              */}

            </div>
          </div>
        )
      break;

      default:
        return (
          <div className="LoginForm">

            <div className="LoginFormHeader">
              <div className="LoginFormHeaderTitle">Log in met jouw <b>DA</b> account.</div>
            </div>

            <div className="LoginFormItems">
              <div className="LoginFormItem">
                <div className="LoginFormItemPre">E-mail adres</div>
                <div className="LoginFormItemInputView">
                  <input
                    ref={inputEmail}
                    className="LoginFormItemInput"
                    placeholder={''}
                    key={'unput_login_email'}
                    // defaultValue={'web_admin@dadrogist.sr'}
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                        switch(key){
                          case "Enter":
                            if(!loading){
                              authLogin();
                            }
                          break;
                        }
                      }
                    }
                  />
                </div>
              </div>

              <div className="LoginFormItem">
                <div className="LoginFormItemPre">Wachtwoord</div>
                <div className="LoginFormItemInputView">
                  <input
                    ref={inputPassword}
                    className="LoginFormItemInput"
                    type={'password'}
                    key={'input_login_pass'}
                    placeholder={''}
                    // defaultValue={'password'}
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                        switch(key){
                          case "Enter":
                            if(!loading){
                              authLogin();
                            }
                          break;
                        }
                      }
                    }
                  />
                </div>
              </div>

              <div onClick={
                () => {
                  setAcceptDisclaimer(!acceptDisclaimer);
                }
              } className={`LoginFormDisclaimerWrapper ${ (acceptDisclaimer) ? 'Selected' : ''}`}>
                <div className="LoginFormDisclaimer">
                  <div className={ `LoginFormDisclaimerCheck` }>

                  </div>
                  <div className="LoginFormDisclaimerText">Ik heb de <a href={ config.homepage + 'info/disclaimer' } target={'_blank'}>Algemene voorwaarden</a> gelezen en ik ga hiermee akkoord.</div>
                </div>
              </div>

              <div className="LoginFormOptions">
                <a onClick={
                  () => {
                    setLoginViewPre('forgot')
                  }
                } className="LoginFormOptionsButton Left Forgot">
                  <div className="LoginFormOptionsButtonText">Wachtwoord vergeten</div>
                  <RiArrowRightLine />
                </a>
                <a onClick={
                  () => {
                    authLogin();
                  }
                } className="LoginFormOptionsButton AccentHover">
                  <div className="LoginFormOptionsButtonText">Inloggen</div>
                  <RiArrowRightLine />
                </a>
              </div>

              <div className="LoginFormOrView">
                <div className="LoginFormOrDivider" />
                <div className="LoginFormOr">OF LOGIN VIA</div>
                <div className="LoginFormOrDivider" />
              </div>

              <a onClick={
                () => {
                  loginGoogle();
                }
              } className="LoginFormOptionsButton Social Go">
                <FcGoogle />
                <div className="LoginFormOptionsButtonText">Jouw <b>Google</b> account</div>
                <RiArrowRightLine />
              </a>

              {/*
              <a onClick={
                () => {
                  loginFacebook();
                }
              } className="LoginFormOptionsButton Social Fb">
                <RiFacebookFill />
                <div className="LoginFormOptionsButtonText">Jouw <b>Facebook</b> account</div>
                <RiArrowRightLine />
              </a>
              */}

            </div>

            <div className="LoginFormSignupView">
              <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/swirl.png'})` }} />
              <div className="LoginFormHeader">
                <div className="LoginFormHeaderTitle">Nog geen <b>DA</b> account?</div>
              </div>

              <a onClick={
                () => {
                  setAcceptDisclaimer(false);
                  setLoginViewPre('signup');
                }
              } className="LoginFormOptionsButton Signup">
                <div className="LoginFormOptionsButtonText">Registreren</div>
                <RiArrowRightLine />
              </a>

            </div>

          </div>
        )
      break;
    }



  }

  useGoogleOneTapLogin({
    onSuccess: async credentialResponse => {

      if(credentialResponse.credential){
        const decoded = jwt_decode(credentialResponse.credential);

        if(decoded){
          const g_id = decoded.sub;
          const name = decoded.name;
          const email = decoded.email;
          const picture = decoded.picture;

          if(g_id != ''){
            loginGoogleFull(g_id, email, name, picture);
          }
        }
      }
    },
  });

  return (
    <div className={`Login ${ (loginView == 'signup') ? 'Signup' : ''}`}>

      <div className="LoginImageView" style={{ backgroundPosition: 'center right', backgroundImage: `url(${ config.homepage + 'data/content/login_background.png' })` }} >

      </div>

      <div className={`LoginContent ${ (loading) ? 'Loading' : ''}`}>
        { (loading) &&
        <div className="LoginLoadingOverlay">
          <div className="LoginLoading">
            <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
          </div>
        </div>
        }
        { responseView() }
        { contentView() }
      </div>


    </div>
  );
}

export default React.memo(Login);;
