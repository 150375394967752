import '../styles/Footer.css';
import config from '../constants/config';
 import {
   RiFacebookFill,
   RiWhatsappFill,
   RiInstagramFill,
   RiMailAddFill,
   RiHeart2Fill,
   RiHeartPulseFill
 } from "react-icons/ri";
 import {
   FaTiktok
 } from "react-icons/fa";
import { Tooltip as DaTooltip } from "react-tooltip";
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import loadingAnimation from '../animations/loading_circle.json';
import Lottie from "lottie-react";


function Footer(props) {
  const {
    categories,
    account, setAccount,
    menu, setMenu,
    view, setViewPre,
    setModalPre,
    token
  } = props;
  const inputNewsletter = useRef();
  const [ loading, setLoading ] = useState(false);

  let viewType = 'normal';

  let view_parsed = view;
  let subview = '';
  let showMobile = true;
  if((view).includes('categorie')){
    view_parsed = 'producten';
    subview = view.replace('categorie/', '');
  }else if((view).includes('account')){
    view_parsed = 'account';
    subview = view.replace('account/', '').replace('account', '');
  }else if((view).includes('producten')){

  }else if((view).includes('product')){
    view_parsed = 'product';
    subview = view.replace('product/', '');
  }else if((view).includes('blogs')){
    view_parsed = 'blogs';
    subview = view.replace('blogs/', '').replace('blogs', '');
  }else if((view).includes('blog')){
    view_parsed = 'blog';
    subview = view.replace('blog/', '').replace('blog', '');
  }

  switch(view_parsed){
    case "klantenservice":
      showMobile = false;
      viewType = 'mini';
    break;
    case "account":
      viewType = 'none';
    break;
    case "winkels":
    case "winkelmandje":
      viewType = 'mini';
    break;
  }

  const categoriesView = () => {

    const cat_items = (categories).map( (cat, index) => {

      const c_page = 'categorie/' + (cat.category.toLowerCase());
      const c_url = config.homepage + c_page;

      return (
        <a href={ c_url } key={'footer_cat_' + index} onClick={
          (event) => {

            setViewPre(c_page);

            event.preventDefault();
          } } className="FooterSectionLink">{ cat.category }</a>

      )
    })

    return cat_items;
  }

  const subscribeEmail = () => {
    const emailText = inputNewsletter.current?.value;

    setLoading(true);

    axios.post( config.api.url + 'newsletter/subscribe', {
      email: emailText
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      setLoading(false);
      setModalPre({
        title: 'Nieuwsbrief.',
        subtitle: response
      })
      inputNewsletter.current.value = '';


      if(success){
        const accountNew = data.account;

        if(account && accountNew){
          setAccount(accountNew);
        }

      }
    })
    .catch(function (error) {
      setLoading(false);
    });
  }

  const unsubscribeEmail = () => {

    if(account && account.subscribed){
      setLoading(true);

      axios.post( config.api.url + 'newsletter/unsubscribe', {
        email: account.email
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setLoading(false);
        if(success){
          const accountNew = data.account;

          if(account && accountNew){
            setAccount(accountNew);
          }

        }
      })
      .catch(function (error) {
        setLoading(false);
      });

    }
  }

  const urls = ['https://instagram.com/apura_innovations','https://facebook.com/ApuraInnovations'];
  const ai_url = urls[Math.floor(Math.random() * urls.length)];

  if(viewType == 'none') return (
    <div className="FooterBarFitted" />
  );

  return (

      <div className={`FooterBar ${viewType} ${ (!showMobile) ? 'HideDesktop' : '' }`}>
        <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/connected.png'})` }} />


        <div className="FixedWidth FooterSections">
          <div className="FooterSection">
            { (viewType == 'normal') &&
            <img className="FooterLogo" src={ config.homepage + 'data/logos/logo_0.png'} alt="DA Logo" />
            }
            { (viewType == 'normal') &&
            <div className="FooterSocials">
              <a target="_blank" href="https://www.facebook.com/dasuriname" className="FooterSocialItem" style={{ fontSize: 16}} id="footer-socials-fb">
                <RiFacebookFill />
              </a>

              <a target="_blank" href="https://www.instagram.com/dadrogistsuriname" className="FooterSocialItem" id="footer-socials-ig">
                <RiInstagramFill />
              </a>

              <a target="_blank" href="https://www.tiktok.com/@da_suriname" className="FooterSocialItem" id="footer-socials-ti">
                <FaTiktok style={{ fontSize: 15 }} />
              </a>

              {/* <a target="_blank" href="" className="FooterSocialItem" id="footer-socials-wa">
                <RiWhatsappFill />
              </a> */}

              <DaTooltip
                anchorId="footer-socials-fb"
                place="top"
                content="Facebook"
              />
              <DaTooltip
                anchorId="footer-socials-ig"
                place="top"
                content="Instagram"
              />
              <DaTooltip
                anchorId="footer-socials-ti"
                place="top"
                content="TikTok"
              />
              {/* <DaTooltip
                anchorId="footer-socials-wa"
                place="top"
                content="WhatsApp"
              /> */}
            </div>
            }
            <div className="FooterCopyWrapper">
              <div className="FooterCopyTitle">&copy; 2023 DA | Voel je goed!</div>
              <div className="FooterCopy">All Rights Reserved.</div>
            </div>

          </div>

          { (viewType == 'normal') &&
          <div className="FooterSection">
            <div className="FooterSectionTitle">Klantenservice</div>
            <a href={ config.homepage + 'info/klantenkaart' } onClick={
              (event) => {

                setViewPre('info/klantenkaart');

                event.preventDefault();
              }
            } className="FooterSectionLink">Klantenkaart</a>
            <a href={ config.homepage + 'winkels' } onClick={ (event) => { setViewPre('winkels'); event.preventDefault();  } } className="FooterSectionLink">Openingstijden</a>
            <a href={ config.homepage + 'winkels' } onClick={
              (event) => {

                setViewPre('winkels');

                event.preventDefault();
              }
            } className="FooterSectionLink">Contact</a>
            <a href={ config.homepage + 'klantenservice' } onClick={
              (event) => {

                setViewPre('klantenservice');

                event.preventDefault();
              }
            } className="FooterSectionLink">Veelgestelde vragen</a>
            <a href={ config.homepage + 'info/privacy' } onClick={
              (event) => {

                setViewPre('info/privacy');

                event.preventDefault();
              }
            } className="FooterSectionLink">Privacyregelement</a>
            <a href={ config.homepage + 'info/disclaimer' } onClick={
              (event) => {

                setViewPre('info/disclaimer');

                event.preventDefault();
              } 
            } className="FooterSectionLink">Disclaimer</a>
          </div>
          }

          <div className="FooterSectionGrid">

            { (viewType == 'normal') &&
            <div className="FooterSectionGridTwin">
              <div className="FooterSection">
                <div className="FooterSectionTitle">Assortiment</div>
                { categoriesView() }
              </div>

              <div className="FooterSection">

                <div className="FooterSectionTitle">DA Drogist</div>
                <a href={ config.homepage + 'over-ons' } onClick={
                  (event) => {

                    setViewPre('over-ons');

                    event.preventDefault();
                  }
                } className="FooterSectionLink">Over ons</a>
                <a href={ config.homepage + 'vacatures' } onClick={
                  (event) => {

                    setViewPre('vacatures');

                    event.preventDefault();
                  }
                } className="FooterSectionLink">Werken bij DA</a>

                <a target="_blank" href={ai_url} className="FooterCreator">
                  <div className="FooterCreaterPre">Crafted with</div>
                  <RiHeart2Fill className="FooterCreaterIcon" />
                  <div className="FooterCreaterPre">by</div>
                  <div className="FooterCreaterName"><b>Apura</b>Innovations.</div>
                </a>

              </div>
            </div>
            }

            { ((account && !account.subscribed) || !account)
            ?
            <div className="FooterNewsletterWrapper">

             { (loading) &&
              <div className="LoginLoadingOverlay">
                <div className="LoginLoading">
                  <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
                </div>
              </div>
              }
              <div className="FooterNewsletterPre">Meld je aan voor de <b>DA nieuwsbrief</b> en ontvang aanbiedingen en inspiratie</div>
              <div className="FooterNewsletter">
                <input
                  ref={inputNewsletter}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            subscribeEmail();
                          }
                        break;
                      }
                    }
                  }
                  placeholder="E-mail adres"
                  defaultValue={ (account && account.email != '') ? account.email : '' }
                />
                <a onClick={
                  () => {
                    subscribeEmail();
                  }
                } className="FooterNewsletterGo">
                  <span className="FooterNewsletterText">Aanmelden</span>
                  <RiMailAddFill style={{ fontSize: 12 }} />
                </a>
              </div>
            </div>
            :
            <div className="FooterNewsletterWrapper">
              <div className="FooterNewsletterPre">Je bent aangemeld voor de <b>DA nieuwsbrief</b> voor het ontvangen van aanbiedingen en inspiratie</div>
              <div className="FooterNewsletter Unsubscribe">

                <a onClick={
                  () => {
                    setModalPre({
                      title: 'Afmelden.',
                      subtitle: 'Ben je zeker dat je wilt afmelden?',
                      content: (
                        <>
                          <div className="modalContentOptions NoBorder">
                            <a onClick={
                              () => {

                                unsubscribeEmail();
                                setModalPre();
                              }
                            } className="modalContentOptionsButton C2a AccentHover">Afmelden</a>
                            <a onClick={
                              () => {

                                setModalPre();
                              }
                            } className="modalContentOptionsButton">Annuleren</a>
                          </div>
                        </>
                      )
                    })
                  }
                } className="FooterNewsletterGo Minimal">
                  <span className="FooterNewsletterText">Afmelden</span>
                  <RiMailAddFill style={{ fontSize: 12 }} />
                </a>
              </div>
            </div>
            }
          </div>
        </div>

      </div>
  );
}

export default Footer;
