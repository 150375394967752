import '../styles/Vacancies.css';
import config from '../constants/config';
import React, { useState, useEffect } from 'react';
import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiBriefcaseFill,
} from "react-icons/ri";
import axios from 'axios';
import loadingAnimation from '../animations/loading_circle.json';
import Lottie from "lottie-react";

import Banners from '../components/Banners';

function Vacancies(props) {
  const {
    subview,
    setViewPre
  } = props;
  const [ loading, setLoading ] = useState(false);
  const [ jobs, setJobs ] = useState([]);

  const fetchJobs = () => {

    setLoading(true);
    axios.post( config.api.url + 'vacancies', {}, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){
        const jobs = data.jobs;

        if(jobs){
          setJobs(jobs);
        }

      }
      setTimeout( () => {
        setLoading(false);
      }, 1);
    })
    .catch(function (error) {
      setLoading(false);
    });

  }

  // First
  useEffect( () => {

    fetchJobs();

  }, []);

  const jobsItems = () => {

    const jobsItems = jobs.map( (job, index) => {

      const j_page = 'vacatures/' + job.url;
      const j_url = config.homepage + j_page;

      return (
        <a href={j_url} className={`VacancyItem`} onClick={
          (event) => {
            setViewPre(j_page);

            event.preventDefault();
          }
        } key={'job_item_' + job.id}>

          <div className="VacancyItemDetails">
            <div className="VacancyItemIcon">
              <RiBriefcaseFill />
            </div>
            <div className="VacancyItemType">{ job.type }</div>
            <div className="VacancyItemTitle">{ job.title }</div>
            <div className="VacancyItemDescr">{ job.descr }</div>
            <div className="VacancyItemOptions">
              <div className="VacancyItemOptionsButton">Meer informatie</div>
            </div>
          </div>
        </a>
      )
    })

    return jobsItems;
  }

  return (
    <div className="Vacancies">
      <div className="FixedWidth">


        <div className={`LoginLoadingOverlay Animated ${ (loading) ? 'Loading' : '' }`}>
          { (loading) &&
          <div className="LoginLoading">
            <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
          </div>
          }
        </div>


        <div className="VacanciesHeader">
          <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/swirl.png'})` }} />
          <div className="VacanciesHeaderTitle">Werken bij <b>DA</b></div>
        </div>
        
        <div className="VacanciesCarouselContainer">
          <Banners
            { ... props}
           refreshBanners={true}
           bannerType={"Vacancies"}
          />
        </div>

        <div className="StoresItemsHeader">
          <div className="StoresItemsHeaderTitle">Open vacatures</div>
        </div>

        <div className="BlogsItems">
          { jobsItems() }
        </div>
      </div>
    </div>
  );
}

export default Vacancies;
