import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleLine,
 RiFileAddLine,

 RiCheckFill,
 RiCloseFill,

 RiToggleLine,
 RiToggleFill,
 RiImage2Fill,
 RiFileExcel2Fill,
 RiRefreshLine,


 RiSortDesc,
 RiSortAsc,
 RiArrowGoBackLine,
 RiArrowUpDownLine,
 RiAddCircleFill
} from "react-icons/ri";
import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";
import Resizer from 'react-image-file-resizer';

import DetailView from "../../../components/DetailView";
import DropDown from "../../../components/DropDown";
import ImageWithTransparencyCheck from "../../../components/ImageWithTransparencyCheck";
import SortItem from "../../../components/SortItem";


function CategoryDrop(props) {
  const { categories, onSelected } = props;
  const [ dropOpen, setDropOpen ] = useState(false);

  return (
    <div className="OrderItemOptionsButtons">
          <div className={`OrderItemOptionsButtonDrop`}>
              <div onClick={
                () => {
                  setDropOpen(!dropOpen);
                }
              } className="OrderItemOptionsButtonDropItem">Categorie veranderen naar ...</div>
              <div className={`OrderItemOptionsButtonDropView ${(dropOpen) ? 'Open' : ''}`}>

                  { (categories).map( (category, index) => {

                    return (
                      <div onClick={
                        () => {

                          onSelected(category);
                          setDropOpen(false);
                        }
                      } key={'cat_'+index} className="OrderItemOptionsButtonDropViewItem">{ category.category }</div>
                    )
                  })
                  }

              </div>
          </div>
        </div>
  );
}

function Products(props) {
  const {
    token, account,
    setModalPre,
    subview_data,
    searchQuery,
    setSettingsHeaderPre,
    showSettingsHeaderPre
  } = props;
  const [ products, setProducts ] = useState([]);
  const [ categorySelected, setCategorySelected ] = useState();
  const [ categories, setCategories ] = useState([]);
  const [ importResponse, setImportResponse ] = useState();
  const [ loading, setLoading ] = useState(false);
  const defaultColumns = ['ref_id', 'image', 'title', 'descr', 'category', 'price', 'stock', 'deleted'];
  const [ columns, setColumns ] = useState(['ref_id', 'image', 'title', 'descr', 'category', 'price', 'stock', 'deleted']);
  const [ syncResponseText, setSyncResponseText ] = useState('');
  const [ disabledColumns, setDisabledColumns ] = useState(['deleted']);
  const [ productsSelected, setProductsSelected ] = useState([]);

  const [isDragging, setIsDragging] = useState(false);
  const [isLeavingDropzone, setIsLeavingDropzone] = useState(false);

  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);
  const [ modal, setModal ] = useState();

  const [ sortBy, setSortBy ] = useState({
    type: 'id',
    sort: 'desc'
  });

  const scrollView = useRef();
  const inputFile = useRef();
  const inputImage = useRef();

  const inputTitle = useRef();
  const inputDescr = useRef();
  const inputPrice = useRef();
  const inputStock = useRef();

  const controller = new AbortController();

  const setSortByPre = (sort) => {

    setSortBy(sort);

    setTimeout( () => {

      fetchProducts(page, true, sort);
    }, 10);
  }

  const setDetailsPre = (mod) => {

    const timeout = (modal) ? 300 : 0;
    if(modal){
      setModal(modalOld=>{
        return {
          ...modalOld,
          hide: true
        }
      });
    }

    if(mod){
      showSettingsHeaderPre(false);
    }else{

      setLoading(false);

      showSettingsHeaderPre(true);
    }

    setTimeout( () => {
        setModal(mod);

        if(!mod){
          setImportResponse();
        }
    }, timeout)


    if(mod && mod.category){

      setCategorySelected({
        key: mod.category.id,
        value: mod.category.category
      })

    }

  }

  const selectFile = (file) => {

    if(file) {
      const uri = URL.createObjectURL(file);

      // Convert the file to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;

        setModal( modalOld => {
          return {
            ...modalOld,
            file: {
              uri: uri,
              base: base64String,
              name: file.name
            }
          }
        })
        productsImportCheck(base64String);
      };
      reader.readAsDataURL(file);

    }
  }

  const selectImage = (file) => {

    if(file) {
        try {

           let type = 'JPEG';
           switch(file.type){
             case "image/png":
              type = 'PNG';
             break;
           }

            Resizer.imageFileResizer(
            file,
            1000,
            1000,
            type, 90,0,
            uri => {

              if(modal){
                setModal( modalOld => {
                  return {
                    ...modalOld,
                    image: {
                      uri: URL.createObjectURL(file),
                      base: uri
                    }
                  }
                })

              }
            },
            'base64',
            500,
            500,
            );
        }   catch(err) {

        }
    }
  }

  const productsChangeCategory = (products = [], category = null) => {

    if(products.length > 0 && category != null){


      setLoading(true);
      axios.post( config.api.url + 'admin/products/category/change', {
        hash: token,
        products: products,
        category: category.id
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);


        if(success){

          setProductsSelected([]);
          fetchProducts(page, false);

        }

      })
      .catch(function (error) {
        setLoading(false);
      });

    }
  }

  const removeProduct = (product_id = 0) => {

    if(product_id > 0){

      setProducts(productsOld => {
        return productsOld.filter((p, i) => p.id != product_id)
      });

      setLoading(true);
      axios.post( config.api.url + 'admin/products/delete', {
        hash: token,
        product_id: product_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);

        if(success){

          fetchProducts(page, false);

        }

      })
      .catch(function (error) {
        setLoading(false);
      });

    }
  }
  
  const productsImportCheck = (base) => {

      if(base != ''){

        const columnsFinal = columns.filter(column => !disabledColumns.includes(column));

        // Do stuff
        setLoading(true);
        axios.post( config.api.url + 'admin/products/import/check', {
          hash: token,
          file: base,
          columns: columnsFinal
        }, {
            crossdomain: true,
            headers: {
              'api': config.api.key
            }
        })
        .then((json) => {
          const { success, response, data } = json.data;

          setTimeout( () => {
            setLoading(false);
          }, 500);

          if(success){

            setImportResponse(data);

          }else{

            setImportResponse();
            setModalPre({
              title: 'Oops.',
              subtitle: response
            })
          }

        })
        .catch(function (error) {
          setImportResponse();
          setLoading(false);
        });


      }else{

        setModalPre({
          title: 'Oops.',
          subtitle: 'Het bestand is niet correct.'
        })
      }

  }

  const productsImport = () => {
    if(modal && modal.file){
      const file = modal.file;

      if(file){

        const columnsFinal = columns.filter(column => !disabledColumns.includes(column));

        // Do stuff
        const base = file.base;
        setLoading(true);
        setSyncResponseText('');
        axios.post( config.api.url + 'admin/products/import', {
          hash: token,
          file: base,
          columns: columnsFinal
        }, {
            crossdomain: true,
            headers: {
              'api': config.api.key
            }
        })
        .then((json) => {
          const { success, response, data } = json.data;

          setTimeout( () => {
            setLoading(false);
          }, 1);
                    console.log(json.data)


          if(success){

            setSyncResponseText(response);
            setTimeout( () => {
              setDetailsPre();
            }, 1500);
            
            fetchProducts(page, false);

          }else{

            setModalPre({
              title: 'Oops.',
              subtitle: response
            })
          }

        })
        .catch(function (error) {
          setLoading(false);
        });


      }else{

        setModalPre({
          title: 'Oops.',
          subtitle: 'Het bestand is niet correct.'
        })
      }
    }

  }

  const productsSyncCheck = () => {

      // Do stuff
      setLoading(true);
      setSyncResponseText('');
      axios.post( config.api.url + 'admin/products/sync/check', {
        hash: token
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 500);

        console.log(json.data)

        if(success){

          setImportResponse(data);

        }else{

          setImportResponse();
          setModalPre({
            title: 'Oops.',
            subtitle: response
          })
        }

      })
      .catch(function (error) {
        setImportResponse();
        setLoading(false);
      });


  }

  const productsSync = () => {

    if(modal){

        // Do stuff
        setLoading(true);
        setSyncResponseText('');
        axios.post( config.api.url + 'admin/sync/import', {
          hash: token
        }, {
            crossdomain: true,
            headers: {
              'api': config.api.key
            }
        })
        .then((json) => {
          const { success, response, data } = json.data;

          setTimeout( () => {
            setLoading(false);
          }, 1);
                    console.log(json.data)


          if(success){

            setSyncResponseText(response);
            setTimeout( () => {
              setDetailsPre();
            }, 1500);
          
            fetchProducts(page, false);

          }else{

            setModalPre({
              title: 'Oops.',
              subtitle: response
            })
          }

        })
        .catch(function (error) {
          setLoading(false);
        });
        
    }

  }

  const productsSyncStockCheck = () => {


        // Do stuff
        setLoading(true);
        setSyncResponseText('');
        axios.post( config.api.url + 'admin/products/sync/stock', {
          hash: token
        }, {
            crossdomain: true,
            headers: {
              'api': config.api.key
            }
        })
        .then((json) => {
          const { success, response, data } = json.data;

          setTimeout( () => {
            setLoading(false);
          }, 1);

          setTimeout( () => {
            setDetailsPre();
          }, 1500);
          setSyncResponseText(response);
          
        console.log(json.data)

          if(success){

            fetchProducts(page, false);

          }else{

            setModalPre({
              title: 'Oops.',
              subtitle: response
            })
          }

        })
        .catch(function (error) {
          setLoading(false);
        });
        

  }

  const productSave = () => {

    document.activeElement.blur();
    if(modal && modal.id >= 0){
      const title = inputTitle.current?.value;
      const descr = inputDescr.current?.value;
      const price = inputPrice.current?.value;
      const stock = inputStock.current?.value;

      const image = (modal.image) ? ((typeof modal.image == 'string') ? modal.image : (modal.image).base) : '';

      const cat_id = (categorySelected) ? categorySelected.key : 0;

      setLoading(true);
      setSyncResponseText('');
      axios.post( config.api.url + 'admin/products/save', {
        hash: token,
        product_id: modal.id,
        image: image,
        title: title,
        descr: descr,
        price: price,
        cat_id: cat_id,
        stock: stock
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);

        console.log(json.data)

        if(success){

          setDetailsPre();
          fetchProducts(page, false);

        }else{

          setModalPre({
            title: 'Oops.',
            subtitle: response
          })
        }

      })
      .catch(function (error) {
        setLoading(false);
      });

    }

  }

  const toggleProductActive = (product_id = 0, product_index) => {

    if(product_id > 0){

      products[product_index].active = !products[product_index].active;

      if(modal){
        modal.active = products[product_index].active;
      }

      // setLoading(true);
      axios.post( config.api.url + 'admin/products/toggle', {
        hash: token,
        product_id: product_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        // setTimeout( () => {
        //   setLoading(false);
        // }, 1);



        if(success){

          fetchProducts(page, false);
        }

      })
      .catch(function (error) {
        // setLoading(false);
      });

    }
  }


  const fetchProducts = (pageFinal = page, shouldLoad = true, sortByFinal = sortBy) => {

    if(shouldLoad){
      setLoading(true);
    }


    axios.post( config.api.url + 'admin/products', {
      hash: token,
      query: searchQuery,
      sort_by: sortByFinal,
      page: pageFinal
    }, {
        signal: controller.signal,
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(shouldLoad){
        setTimeout( () => {
          setLoading(false);
        }, 1);
      }

      if(success){
        const products = data.products;
        const stats = data.stats;

        if(products){
          setProducts(products);
        }

        if(shouldLoad){
          scrollView.current?.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });


  }


  const fetchCategories = () => {

    axios.post( config.api.url + 'admin/categories', {
      hash: token
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;


      if(success){
        const categories = data.categories;

        if(categories){
          setCategories(categories);
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/producten/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchProducts(page);
  }

  const productsPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(products.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/producten/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a key={'page_dots_'+p_index} className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/producten/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/producten/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }

  
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setIsLeavingDropzone(false);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    const { relatedTarget } = e;

    // Check if the relatedTarget is null or outside the dropzone
    if (!relatedTarget || !e.currentTarget.contains(relatedTarget)) {
      setIsDragging(false);
      setIsLeavingDropzone(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    if (!isLeavingDropzone) {
      setIsDragging(true);
    }
  };

  const handleDropFile = (e) => {
    e.preventDefault();
    setIsDragging(false);
    setIsLeavingDropzone(false);

    const file = e.dataTransfer.files[0];

    if(file){
      selectFile(file)
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    setIsLeavingDropzone(false);

    const file = e.dataTransfer.files[0];

    if(file){
      selectImage(file)
    }
  };


  const modalView = () => {

    const mType = (modal && modal.type) ? modal.type : '';

    switch(mType){
      case "sync_stock":

        return (
            <div className={`ModalSide Import ${ (isDragging) ? 'Disable' : ''}`}>
              <div className="ModalSideHeader">
                <div className="ModalSideHeaderTitle">Voorraden synchronizeren</div>
                <a onClick={
                  () => {
                    setDetailsPre()
                  }
                } className="ModalSideHeaderClose">
                  <RiCloseLine />
                </a>
              </div>

              <div className="ModalSideContent" style={{ margin: "0 -25px", padding: "25px 40px" }}>

               
                { (loading) &&
                <div className="LoginLoadingCenter" style={{ marginBottom: 25 }}>
                  <div className="LoginLoading">
                    <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
                  </div>
                </div>
                }

                { (!loading && syncResponseText != '') &&
                <div className="modalResponseText">{ syncResponseText }</div>
                }

              </div>

            </div>
        )
      break;
      case "sync":

        return (
            <div className={`ModalSide Import ${ (isDragging) ? 'Disable' : ''}`}>
              <div className="ModalSideHeader">
                <div className="ModalSideHeaderTitle">Producten synchronizeren</div>
                <a onClick={
                  () => {
                    setDetailsPre()
                  }
                } className="ModalSideHeaderClose">
                  <RiCloseLine />
                </a>
              </div>

              <div className="ModalSideContent" style={{ margin: "0 -25px", padding: "25px 40px" }}>

               
                { (loading) &&
                <div className="LoginLoadingCenter" style={{ marginBottom: 25 }}>
                  <div className="LoginLoading">
                    <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
                  </div>
                </div>
                }

                { (!loading && syncResponseText != '') &&
                <div className="modalResponseText">{ syncResponseText }</div>
                }


                { (importResponse && syncResponseText == '' && !loading) &&
                <div className="SyncPreviewWrapper">

                  <div className="SyncPreviewItem">
                    <div className="SyncPreviewItemKey">Toegevoegde producten</div>
                    <div className="SyncPreviewItemValue">{ (importResponse && importResponse.counts) ? importResponse.counts.added : 'NVT' }</div>
                  </div>
                  <div className="SyncPreviewItem">
                    <div className="SyncPreviewItemKey">Aangepaste producten</div>
                    <div className="SyncPreviewItemValue">{ (importResponse && importResponse.counts) ? importResponse.counts.updated : 'NVT' }</div>
                  </div>
                  <div className="SyncPreviewItem">
                    <div className="SyncPreviewItemKey">Producten die worden verwijderd</div>
                    <div className="SyncPreviewItemValue">{ (importResponse && importResponse.counts) ? importResponse.counts.deleted : 'NVT' }</div>
                  </div>
                  <div className="SyncPreviewItem">
                    <div className="SyncPreviewItemKey">Producten die niet worden geimporteerd</div>
                    <div className="SyncPreviewItemValue">{ (importResponse && importResponse.counts) ? importResponse.counts.invalid : 'NVT' }</div>
                  </div>

                </div>
                }

              </div>

      

              <div className="modalContentOptions LightBorder">
                { (importResponse && !loading) &&
                <a onClick={
                  () => {
                    if(importResponse){

                      productsSync();
                    }else{
                      productsSyncCheck();
                    }
                  }
                } className={`modalContentOptionsButton C2a`}>{ (!importResponse) ? 'De check is niet uitgevoerd' : 'Synchronizeren' }</a>
                }
                <a onClick={
                  () => {

                    setDetailsPre();
                  }
                } className="modalContentOptionsButton">Annuleren</a>
              </div>

            </div>
        )
      break;

      case "import":

        return (
            <div className={`ModalSide Import ${ (isDragging) ? 'Disable' : ''}`}>
              <div className="ModalSideHeader">
                <div className="ModalSideHeaderTitle">Producten importeren</div>
                <a onClick={
                  () => {
                    setDetailsPre()
                  }
                } className="ModalSideHeaderClose">
                  <RiCloseLine />
                </a>
              </div>

              <div className="ModalSideContent" style={{ margin: "0 -25px", padding: "25px 40px" }}>
                { (modal) &&
                <div
                  className={`ModalSideContentImageWrapper ${ (isDragging) ? 'Enabled' : ''} `}
                  onDrop={handleDropFile}
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                >
                  {
                  (isDragging)
                  ?
                  <div className="ModalSideContentImageDrop">
                    <div className="ModalSideContentImageDropText">Laat de file hier los om te selecteren.</div>
                  </div>
                  :
                  <>
                    <div className={`ModalSideContentImage Circle ${ (modal && modal.file) ? 'Valid' : ''}`}>
                      { (modal && modal.file) &&
                      <RiFileExcel2Fill style={{ fontSize: 28, color: '#fff' }} />
                      }
                      <div className="ModalSideContentImageOverlay">
                        <div onClick={
                          () => {

                            inputFile.current?.click();

                          }
                        } className="ModalSideContentImageOverlayCircle">
                          <RiFileExcel2Fill />
                          <input ref={ inputFile } type="file" accept=".xls, .xlsx" style={{ display: 'none' }} onChange={
                            (event) => {
                               const file = event.target?.files[0];

                               selectFile(file);
                            }
                          } />
                        </div>
                      </div>
                    </div>
                    <div className="ModalSideContentImageDetails">
                      <div className="ModalSideContentImageDetailsText">{ (modal.file) ? 'File actief.' : 'Geen file geselecteerd.' }</div>
                      <div className="ModalSideContentImageDetailsHint">Sleep een file of klik op 'selecteer file'.</div>

                      { (!isDragging) &&
                      <div className="ModalSideContentImageDetailsOptions">
                        { (modal && modal.file && modal.file != '') &&
                        <a onClick={
                          () => {

                            setModal( modalOld => {
                              return {
                                ...modalOld,
                                file: ''
                              }
                            })
                            setImportResponse();

                          }
                        } className="ModalSideContentImageDetailsOptionsButton Delete">Verwijderen</a>
                        }
                        { (modal && modal.file && !importResponse) &&
                        <a onClick={
                          () => {

                            if(modal.file.base){

                              productsImportCheck(modal.file.base);
                            }

                          }
                        } className="ModalSideContentImageDetailsOptionsButton Retry">
                          <RiRefreshLine style={{ fontSize: 14 }} />
                        </a>
                        }
                        <a onClick={
                          () => {

                            inputFile.current?.click();
                          }
                        } className="ModalSideContentImageDetailsOptionsButton">Selecteer file</a>
                      </div>
                      }
                    </div>
                  </>
                  }

                </div>
                }

                { (!importResponse && !loading) &&
                <div className="SyncColumnsWrapper">
                  <div className="ModalSideContentInputPre">Kollommen ({ (columns.filter(column => !disabledColumns.includes(column))).length })</div>
                  <div className="SyncColumns">
                    { (columns).map( (column, c_index) => {

                      const isDisabled = (disabledColumns).find((c,i) => c == column);
                      const showDisable = (column == 'deleted');

                      return (
                        <SortItem onSortItems={onSortItems} sortId={c_index} items={columns} key={'column_' + c_index} className={`SyncColumnsItem ${ (isDisabled) ? 'Disabled' : '' } `}>
                          <div className="SyncColumnsItemText">{ column }</div>
                          { (showDisable) &&
                          <div onClick={
                            () => {
                              if(isDisabled){
                                setDisabledColumns(disabledColumns.filter((c) => c !== column));
                              }else{
                                setDisabledColumns([...disabledColumns, column]);
                              }
                            }
                          } className="SyncColumnsItemClose">
                            { (isDisabled)
                            ?
                            <RiToggleLine />
                            :
                            <RiToggleFill />
                            }
                          </div>
                          }
                        </SortItem>
                      )
                    })}
                  </div>
                  <div className="SyncColumnsItemHint">
                    { (JSON.stringify(defaultColumns) !== JSON.stringify(columns)) &&
                    <a onClick={
                      () => {

                        setColumns([...defaultColumns]);
                      }
                    } className="ModalSideContentImageDetailsOptionsButton Retry">
                      <RiRefreshLine style={{ fontSize: 12 }} />
                    </a>
                    }
                    <div className="SyncColumnsItemHintText">Sleep de kollommen in de juiste volgorde.</div>
                  </div>
                </div>
                }

                { (loading) &&
                <div className="LoginLoadingCenter">
                  <div className="LoginLoading">
                    <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
                  </div>
                </div>
                }

                { (importResponse && !loading) &&
                <div className="SyncPreviewWrapper">

                  <div className="SyncPreviewItem">
                    <div className="SyncPreviewItemKey">Toegevoegde producten</div>
                    <div className="SyncPreviewItemValue">{ (importResponse && importResponse.counts) ? importResponse.counts.added : 'NVT' }</div>
                  </div>
                  <div className="SyncPreviewItem">
                    <div className="SyncPreviewItemKey">Aangepaste producten</div>
                    <div className="SyncPreviewItemValue">{ (importResponse && importResponse.counts) ? importResponse.counts.updated : 'NVT' }</div>
                  </div>
                  <div className="SyncPreviewItem">
                    <div className="SyncPreviewItemKey">Producten die worden verwijderd</div>
                    <div className="SyncPreviewItemValue">{ (importResponse && importResponse.counts) ? importResponse.counts.deleted : 'NVT' }</div>
                  </div>
                  <div className="SyncPreviewItem">
                    <div className="SyncPreviewItemKey">Producten die niet worden geimporteerd</div>
                    <div className="SyncPreviewItemValue">{ (importResponse && importResponse.counts) ? importResponse.counts.invalid : 'NVT' }</div>
                  </div>

                </div>
                }

              </div>


              <div className="modalContentOptions LightBorder">
                <a onClick={
                  () => {

                    if(importResponse){
                      productsImport();
                    }
                  }
                } className={`modalContentOptionsButton ${ (modal && modal.file) ? ( (importResponse) ? 'C2a' : 'Disabled' ) : 'Disabled' }`}>{ (modal && modal.file) ? ((!importResponse) ? 'Bestand is niet correct' : 'Importeren') : 'Importeren' }</a>
                <a onClick={
                  () => {

                    setDetailsPre();
                  }
                } className="modalContentOptionsButton">Annuleren</a>
              </div>

            </div>
        )
      break;

      case "sync_main":
        return (
            <div className={`ModalSide Import ${ (isDragging) ? 'Disable' : ''}`}>
              <div className="ModalSideHeader">
                <div className="ModalSideHeaderTitle">Producten synchronizeren</div>
                <a onClick={
                  () => {
                    setDetailsPre()
                  }
                } className="ModalSideHeaderClose">
                  <RiCloseLine />
                </a>
              </div>

              <div className="ModalSideContent" style={{ margin: "0 -25px", padding: "25px 40px" }}>

                  <a onClick={
                        () => {

                          setDetailsPre({
                            id: 0,
                            type: 'sync'
                          });
                          productsSyncCheck();
                        }
                      } className="ModalSideContentButton">
                        <RiArrowUpDownLine style={{ fontSize: 14 }} />
                        <span className="ModalSideContentButtonText">Producten vanuit RetailNxt</span>
                      </a>

                      <a onClick={
                        () => {

                          setDetailsPre({
                            id: 0,
                            type: 'sync_stock'
                          });
                          productsSyncStockCheck();
                        }
                      } className="ModalSideContentButton">
                        <RiArrowUpDownLine style={{ fontSize: 14 }} />
                        <span className="ModalSideContentButtonText">Voorraad vanuit RetailNxt</span>
                      </a>
                      
                      <a onClick={
                        () => {

                          setDetailsPre({
                            id: 0,
                            type: 'import'
                          });
                        }
                      } className="ModalSideContentButton">
                        <RiFileAddLine style={{ fontSize: 14 }} />
                        <span className="ModalSideContentButtonText">Importeren vanuit een bestand</span>
                      </a>
            
              </div>


              <div className="modalContentOptions LightBorder">
                <a onClick={
                  () => {

                    setDetailsPre();
                  }
                } className="modalContentOptionsButton">Annuleren</a>
              </div>

            </div>
        )
      break;

      default:

        return (
            <div className={`ModalSide ${ (isDragging) ? 'Disable' : ''}`}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}>
              <div className="ModalSideHeader">
                <div className="ModalSideHeaderTitle">Product { (modal && modal.id > 0) ? 'bewerken' : 'aanmaken' }</div>

                { (modal && modal.id > 0) &&
                <a onClick={
                  () => {

                    if(modal && modal.id){
                      const modalIndex = (products).findIndex( (p, i) => p.id == modal.id);
                      toggleProductActive(modal.id, modalIndex);
                    }

                  }
                } className={`OrderItemOptionsButton ${ (modal.active) ? 'DeleteInverted' : 'AddInverted' }`}>
                  { (modal.active)
                  ?
                  <RiToggleFill style={{ fontSize: 14 }} />
                  :
                  <RiToggleLine style={{ fontSize: 14 }} />
                  }
                  <span className="OrderItemOptionsButtonText">{ (modal.active) ? 'Deactiveren' : 'Activeren' }</span>
                </a>
                }

                { (modal && modal.ref_id == 0) &&
                <a onClick={
                  () => {

                    setModalPre({
                      title: 'Verwijderen.',
                      subtitle: 'Ben je zeker dat je dit product wilt verwijderen?',
                      content: (
                        <>
                          <div className="modalContentOptions NoBorder">
                            <a onClick={
                              () => {

                                removeProduct(modal.id);
                                setDetailsPre();
                                setModalPre();
                              }
                            } className="modalContentOptionsButton Delete">Verwijderen</a>
                            <a onClick={
                              () => {

                                setModalPre();
                              }
                            } className="modalContentOptionsButton">Annuleren</a>
                          </div>
                        </>
                      )
                    })

                  }
                } className="OrderItemOptionsButton Delete">
                  <RiDeleteBin5Line style={{ fontSize: 14 }} />
                </a>
                }
                <a onClick={
                  () => {
                    setDetailsPre()
                  }
                } className="ModalSideHeaderClose">
                  <RiCloseLine />
                </a>
              </div>

              { (modal && modal.image && (typeof modal.image != 'string')) &&
              <ImageWithTransparencyCheck src={modal.image.uri} isTransparent={
                  (transparency) => {

                    setModal( modalOld => {
                      return {
                        ...modalOld,
                        image: {
                          ... modalOld.image,
                          transparent: (transparency) ? 'yes' : 'no'
                        }
                      }
                    })

                  }
                } />
              }

              <div className="ModalSideContent">
                { (modal) &&
                <div
                  className={`ModalSideContentImageWrapper ${ (isDragging) ? 'Enabled' : ''} `}
                  onDrop={handleDrop}
                >
                  {
                  (isDragging)
                  ?
                  <div className="ModalSideContentImageDrop">
                    <div className="ModalSideContentImageDropText">Laat de foto hier los om te selecteren.</div>
                  </div>
                  :
                  <>
                    <div className="ModalSideContentImage Square ProductImage" style={{ backgroundImage: `url(${ (modal.image) ? ((typeof modal.image == 'string') ? (modal.image) : (modal.image.uri)) : '' })` }}>
                      <div className="ModalSideContentImageOverlay">
                        <div onClick={
                          () => {

                            inputImage.current?.click();

                          }
                        } className="ModalSideContentImageOverlayCircle">
                          <RiImage2Fill />
                          <input ref={ inputImage } type="file" accept=".jpg, .jpeg, .png, .gif" style={{ display: 'none' }} onChange={
                            (event) => {
                               const file = event.target?.files[0];

                               selectImage(file);
                            }
                          } />
                        </div>
                      </div>
                    </div>
                    <div className="ModalSideContentImageDetails">
                      <div className="ModalSideContentImageDetailsText">{ (isDragging) ? 'Laat los om de foto te plaatsen.' : ((modal.image != '') ? 'Foto actief.' : 'Geen foto geselecteerd.') }</div>
                      <div className="ModalSideContentImageDetailsHint">Sleep een foto of klik op 'selecteer foto'.</div>

                      { (!isDragging) &&
                      <div className="ModalSideContentImageDetailsOptions">
                        { (modal && modal.image && modal.image != '') &&
                        <a onClick={
                          () => {

                            setModal( modalOld => {
                              return {
                                ...modalOld,
                                image: ''
                              }
                            })

                          }
                        } className="ModalSideContentImageDetailsOptionsButton Delete">Verwijderen</a>
                        }
                        <a onClick={
                          () => {

                            inputImage.current?.click();
                          }
                        } className="ModalSideContentImageDetailsOptionsButton">Selecteer foto</a>
                      </div>
                      }
                    </div>
                  </>
                  }

                </div>
                }


                { (modal && modal.image && modal.image.transparent != '' && (typeof modal.image != 'string') && modal.image.transparent == 'no') &&
                <div className="ModalSideContentMessage">De geselecteerde foto is niet transparant. Zorg ervoor dat het een witte of zwarte achtergrond heeft.</div>
                }

                <div className="ModalSideContentTwin Single">
                  <div className="ModalSideContentInput">
                    <div className="ModalSideContentInputPre">Titel</div>
                    <input
                      ref={inputTitle}
                      key={ JSON.stringify(modal ? modal.id : '') }
                      defaultValue={ (modal && modal.title) ? modal.title : '' }
                      placeholder={'DA Product'}
                      className={`ModalSideContentInputInput`}
                      onKeyUp={
                        (event) => {
                          const key = event.key;

                          switch(key){
                            case "Enter":
                              if(!loading){
                                productSave();
                              }
                            break;
                          }
                        }
                      }
                    />
                  </div>
                </div>

                <div className="ModalSideContentTwin Single">
                  <div className="ModalSideContentInput">
                    <div className="ModalSideContentInputPre">Omschrijving</div>
                    <textarea
                      ref={inputDescr}
                      key={ JSON.stringify(modal ? modal.id : '') }
                      placeholder={'...'}
                      className={`ModalSideContentInputTextarea`}
                      style={{ minHeight: 160 }}
                      defaultValue={ (modal && modal.descr) ? modal.descr : '' } />
                  </div>
                </div>

                <div className="ModalSideContentTwin Single">
                  <div className="ModalSideContentInput">
                    <div className="ModalSideContentInputPre">Category</div>

                    <DropDown
                      key={JSON.stringify(categorySelected)}
                      selected={(categorySelected) ? categorySelected.value : 'Selecteer item'}
                      list={(categories).map( (cat, index) => { return { key: cat.id, value: cat.category }})}
                      onSelect={
                        (item) => {

                          setCategorySelected(item)
                        }
                      }
                    />
                  </div>
                </div>

                <div className="ModalSideContentTwin">
                  <div className="ModalSideContentInput">
                    <div className="ModalSideContentInputPre">Prijs</div>
                    <input
                      ref={inputPrice}
                      key={ JSON.stringify(modal ? modal.id : '') }
                      defaultValue={ (modal && modal.price) ? modal.price : '' }
                      placeholder={'0.00'}
                      className={`ModalSideContentInputInput`}
                      onKeyUp={
                        (event) => {
                          const key = event.key;

                          switch(key){
                            case "Enter":
                              if(!loading){
                                productSave();
                              }
                            break;
                          }
                        }
                      }
                    />
                  </div>
                  <div className="ModalSideContentInput">
                    <div className="ModalSideContentInputPre">Voorraad</div>
                    <input
                      ref={inputStock}
                      key={ JSON.stringify(modal ? modal.id : '') }
                      defaultValue={ (modal && modal.stock) ? modal.stock : '' }
                      placeholder={'1'}
                      className={`ModalSideContentInputInput`}
                      onKeyUp={
                        (event) => {
                          const key = event.key;

                          switch(key){
                            case "Enter":
                              if(!loading){
                                productSave();
                              }
                            break;
                          }
                        }
                      }
                    />
                  </div>
                </div>

                <div className="modalCouponTitleDivider" />


              </div>

              <div className="modalContentOptions LightBorder">
                <a onClick={
                  () => {

                    productSave();
                  }
                } className="modalContentOptionsButton C2a">Opslaan</a>
                <a onClick={
                  () => {

                    setDetailsPre();
                  }
                } className="modalContentOptionsButton">Annuleren</a>
              </div>

            </div>
        )
      break;
    }



    return false;
  }

  const modalViewMemo = useMemo(() => {

    return modalView;
  }, [modal, isDragging, loading, importResponse, columns, disabledColumns, categorySelected]);


  useEffect( () => {

    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchProducts(page_parsed);
    }else{

    }

    fetchCategories();

  }, []);


  useEffect( () => {


    // SET HEADER
    setSettingsHeaderPre(
      <div className="SettingsContentContentNavigation HideDesktop">
        {/* <a onClick={
          () => {

            setDetailsPre({
              id: 0,
              type: 'sync'
            });
            productsSyncCheck();
          }
        } className="OrderItemOptionsButton AccentHover">
          <RiArrowUpDownLine style={{ fontSize: 14 }} />
          <span className="OrderItemOptionsButtonText">Synchronizeren</span>
        </a>

        <a onClick={
          () => {

            setDetailsPre({
              id: 0,
              type: 'sync_stock'
            });
            productsSyncStockCheck();
          }
        } className="OrderItemOptionsButton AccentHover">
          <RiArrowUpDownLine style={{ fontSize: 14 }} />
          <span className="OrderItemOptionsButtonText">Voorraad syncen</span>
        </a>
       
        <a onClick={
          () => {

            setDetailsPre({
              id: 0,
              type: 'import'
            });
          }
        } className="OrderItemOptionsButton AccentHover">
          <RiFileAddLine style={{ fontSize: 14 }} />
          <span className="OrderItemOptionsButtonText">Importeren</span>
        </a> */}

        { (productsSelected.length > 0) &&
        <CategoryDrop categories={categories} onSelected={
          (item) => {

            productsChangeCategory(productsSelected, item)
          }
        } />
        }
       
       { (productsSelected.length == 0) &&
        <a onClick={
          () => {

            setDetailsPre({
              id: 0,
              type: 'sync_main'
            });
          }
        } className="OrderItemOptionsButton AccentHover">
          <RiFileAddLine style={{ fontSize: 14 }} />
          <span className="OrderItemOptionsButtonText">Synchronizeren</span>
        </a>
        }

        { (productsSelected.length == 0) &&
        <a onClick={
          () => {

            setDetailsPre({
              id: 0,
            });
          }
        } className="OrderItemOptionsButton AccentHover">
          <RiAddCircleFill style={{ fontSize: 14 }} />
        </a>
        }

        
      </div>
    );

  }, [productsSelected, categories]);

  useEffect( () => {

    if(!loading){
      // controller.abort();
      fetchProducts();
    }

  }, [searchQuery])


  const onSortItems = (items) => {

    setColumns([...items]);
  }



  const products_items = (products).map( (product_item, index) => {

    const { category } = product_item;

    const isSelected = (productsSelected).includes(product_item.id);


    return (
      <div onClick={
        (e) => {

          if(e.ctrlKey || e.metaKey){

            if(isSelected){
              const newProds = (productsSelected.filter(product => parseInt(product) != parseInt(product_item.id)));
              setProductsSelected(newProds);
            }else{

              setProductsSelected(oldProducts=>{
                return [
                  ...oldProducts,
                  product_item.id
                ]
              })
            }


          }else if(e.shiftKey){
  
              // Do stuff if shift is pressed or not 
              if(e.shiftKey && productsSelected.length > 0){
  
                // Last id 
                const id = productsSelected[productsSelected.length - 1];
                const curr_id = product_item.id;
  
                const betwIds = (products).map(prod => {
  
                  if(!productsSelected.includes(prod.id) && ((prod.id >= curr_id && prod.id < id) || (prod.id <= curr_id && prod.id > id))){
                    return prod.id;
                  }
  
                  return false;
                }).filter(item => item !== false); 
                
  
  
                setProductsSelected(oldProducts=>{
                  return [
                    ...oldProducts,
                    ...betwIds
                  ]
                })
                console.log(betwIds);
  
              }
            
          }else if(productsSelected.length > 0){
            setProductsSelected([])
          }
          
        }
      } className={`OrderItem ${(isSelected) ? 'Selected' : ''}`} key={'product_item_' + product_item.id}>
        <div className="OrderItemDetailsText ID">#{ product_item.id }</div>
        <div className="OrderItemDetailsText Active">
          <span className={`OrderItemDetailsTextOption Circle ${ (product_item.active) ? 'Success' : 'Error' }`}>
            { (product_item.active)
            ?
            <RiCheckFill style={{ fontSize: 12 }} />
            :
            <RiCloseFill style={{ fontSize: 12 }} />
            }
          </span>
        </div>
        <div className="OrderItemDetailsText StatusMid">
          <div className="OrderItemDetailsStatus" style={{ backgroundColor: category.bg_color, color: category.text_color }}>{ category.category }</div>
        </div>
        <div className="OrderItemDetailsText Image">
          <div className="OrderItemDetailsTextImage ProductImage" style={{ backgroundImage: `url(${product_item.image})` }} />
        </div>
        <div className="OrderItemDetailsText NameLarge Bold">{ product_item.title }</div>
        <div className="OrderItemDetailsText Amount">SRD { product_item.price }</div>
        <div className="OrderItemDetailsText Stock">{ product_item.stock }</div>

        <div className="OrderItemOptions">

          <a onClick={
            (e) => {

              toggleProductActive(product_item.id, index);

              e.stopPropagation();
            }
          } className={`OrderItemOptionsButton ${ (product_item.active) ? 'DeleteInverted' : 'AddInverted' }`}>
            { (product_item.active)
            ?
            <RiToggleFill style={{ fontSize: 14 }} />
            :
            <RiToggleLine style={{ fontSize: 14 }} />
            }
          </a>

          <a onClick={
            (e) => {

              setDetailsPre(product_item);

              e.stopPropagation();
            }
          } className="OrderItemOptionsButton Edit">
            <RiFileShield2Line style={{ fontSize: 14 }} />
            <span className="OrderItemOptionsButtonText">{ 'Bewerken' }</span>
          </a>
        </div>

      </div>
    )
  })


  return (
    <div className="SettingsContentContent List">


      <div className="SettingsMessageText ShowMobile">Voorlopig alleen op desktop beschikbaar.</div>

      <div className={`settingsContentReal HideDesktop`}>
        <div className="SettingsContentNavigation">
          <div onClick={ () => {
            setSortByPre({
              type: 'id',
              sort: (sortBy && sortBy.type == 'id' && sortBy.sort == 'desc') ? 'asc' : 'desc'
            })
          } } className="SettingsContentNavigationItem ID">{ (sortBy && sortBy.type == 'id' && sortBy.sort == 'asc') ? (<RiSortDesc style={{ fontSize: 13 }} />) : 'ID' }</div>
          <div onClick={ () => {
            setSortByPre({
              type: 'active',
              sort: (sortBy && sortBy.type == 'active' && sortBy.sort == 'asc') ? 'desc' : 'asc'
            })
          } } className="SettingsContentNavigationItem Active">{ (sortBy && sortBy.type == 'active') ? ( (sortBy.sort == 'desc') ? (<RiSortDesc style={{ fontSize: 13 }} />) : (<RiSortAsc style={{ fontSize: 13 }} />)) : 'Actief' }</div>
          <div  onClick={ () => {
            setSortByPre({
              type: 'category',
              sort: (sortBy && sortBy.type == 'category' && sortBy.sort == 'asc') ? 'desc' : 'asc'
            })
          } } className="SettingsContentNavigationItem StatusMid">{ (sortBy && sortBy.type == 'category') ? ( (sortBy.sort == 'desc') ? (<RiSortDesc style={{ fontSize: 13 }} />) : (<RiSortAsc style={{ fontSize: 13 }} />)) : 'Categorie' }</div>
          <div className="SettingsContentNavigationItem Image">Foto</div>
          <div  onClick={ () => {
            setSortByPre({
              type: 'title',
              sort: (sortBy && sortBy.type == 'title' && sortBy.sort == 'asc') ? 'desc' : 'asc'
            })
          } } className="SettingsContentNavigationItem NameLarge">{ (sortBy && sortBy.type == 'title') ? ( (sortBy.sort == 'desc') ? (<RiSortDesc style={{ fontSize: 13 }} />) : (<RiSortAsc style={{ fontSize: 13 }} />)) : 'Naam' }</div>
          <div onClick={ () => {
            setSortByPre({
              type: 'price',
              sort: (sortBy && sortBy.type == 'price' && sortBy.sort == 'asc') ? 'desc' : 'asc'
            })
          } } className="SettingsContentNavigationItem Amount">{ (sortBy && sortBy.type == 'price') ? ( (sortBy.sort == 'desc') ? (<RiSortDesc style={{ fontSize: 13 }} />) : (<RiSortAsc style={{ fontSize: 13 }} />)) : 'Prijs' }</div>
          <div onClick={ () => {
            setSortByPre({
              type: 'stock',
              sort: (sortBy && sortBy.type == 'stock' && sortBy.sort == 'asc') ? 'desc' : 'asc'
            })
          } } className="SettingsContentNavigationItem Stock">{ (sortBy && sortBy.type == 'stock') ? ( (sortBy.sort == 'desc') ? (<RiSortDesc style={{ fontSize: 13 }} />) : (<RiSortAsc style={{ fontSize: 13 }} />)) : 'Voorraad' }</div>
          <div className="SettingsContentNavigationItem OptionsBig"></div>
        </div>

        <div className="GlobSettingsScroll " ref={scrollView}>

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }


          { (products_items.length > 0)
          ?
          products_items
          :
          <div className="SettingsMessageText ShowMobile">Geen producten.</div>
          }
        </div>

        { productsPagination() }
      </div>


      <DetailView width={550} modal={modal} setDetailsPre={setDetailsPre}>
        { modalViewMemo() }
      </DetailView>

    </div>
  )
}

export default Products;
