import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleFill,
 RiAddCircleLine,

 RiCheckFill,
 RiCloseFill,

 RiToggleLine,
 RiToggleFill,
 RiImage2Fill
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";
import Resizer from 'react-image-file-resizer';

import DetailView from "../../../components/DetailView";
import DropDown from "../../../components/DropDown";
import SortItem from "../../../components/SortItem";


function Ads(props) {
  const {
    token, account,
    setModalPre,
    subview_data,
    searchQuery
  } = props;
  const [ ads, setAds ] = useState([]);
  const [ urlSubs, setUrlSubs ] = useState([]);
  const [ urlSubSelected, setUrlSubSelected ] = useState();

  const [timeoutId, setTimeoutId] = useState(null);

  const [isDragging, setIsDragging] = useState(false);
  const [isLeavingDropzone, setIsLeavingDropzone] = useState(false);

  const [ adSelected, setAdselected ] = useState();
  const [ loading, setLoading ] = useState(false);
  const [ subLoading, setSubLoading ] = useState(false);

  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);
  const [ modal, setModal ] = useState();

  const scrollView = useRef();
  const inputImage = useRef();

  const inputTitle = useRef();
  const inputSubtitle = useRef();


  const controller = new AbortController();


  const fetchSubItems = (type = '', query = '') => {

    setSubLoading(true);
    axios.post( config.api.url + 'admin/subitems', {
      hash: token,
      type: type,
      query: query
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;


      if(success){
        const items = data.items;

        setUrlSubs(items);
      }

    })
    .catch(function (error) {
      setSubLoading(false);
    });
  }

  const setDetailsPre = (mod) => {

    const timeout = (modal) ? 300 : 0;
    if(modal){
      setModal(modalOld=>{
        return {
          ...modalOld,
          hide: true
        }
      });
    }
    setTimeout( () => {
        setModal(mod);
    }, timeout)

    if(mod && mod.type){

      setAdselected({
        key: mod.type,
        value: mod.type_text
      })
    }

    if(mod && mod.data){


      setUrlSubSelected({
        key: mod.data.key,
        value: mod.data.value
      });

      // Get items
    }else{
      setUrlSubSelected();
    }


  }


  const selectImage = (file) => {

    if(file) {
        try {

           let type = 'JPEG';
           switch(file.type){
             case "image/png":
              type = 'PNG';
             break;
           }

            Resizer.imageFileResizer(
            file,
            1000,
            1000,
            type, 90,0,
            uri => {

              if(modal){
                setModal( modalOld => {
                  return {
                    ...modalOld,
                    image: {
                      uri: URL.createObjectURL(file),
                      base: uri
                    }
                  }
                })

              }
            },
            'base64',
            500,
            500,
            );
        }   catch(err) {

        }
    }
  }


  const adSave = () => {

    document.activeElement.blur();
    if(modal && modal.id >= 0){
      const title = inputTitle.current?.value;
      const subtitle = inputSubtitle.current?.value;
      const image = (modal.image) ? ((typeof modal.image == 'string') ? modal.image : (modal.image).base) : '';

      const ref_id = (modal.ref_id > 0) ? modal.ref_id : '0';


      setLoading(true);
      axios.post( config.api.url + 'admin/ads/save', {
        hash: token,
        ad_id: modal.id,
        image: image,
        title: title,
        subtitle: subtitle,
        ref_id: ref_id
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);

        if(success){

          setDetailsPre();
          fetchAds(page, false);

        }else{

          setModalPre({
            title: 'Oops.',
            subtitle: response
          })
        }

      })
      .catch(function (error) {
        setLoading(false);
      });
    }

  }

  const toggleAdActive = (ad_id = 0, ad_index) => {

    if(ad_id > 0){

      ads[ad_index].active = !ads[ad_index].active;

      if(modal){
        modal.active = ads[ad_index].active;
      }

      // setLoading(true);
      axios.post( config.api.url + 'admin/ads/toggle', {
        hash: token,
        ad_id: ad_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        // setTimeout( () => {
        //   setLoading(false);
        // }, 1);

        if(success){

          fetchAds(page, false);
        }

      })
      .catch(function (error) {
        // setLoading(false);
      });

    }
  }

  const fetchAds = (pageFinal = page, shouldLoad = true) => {

    if(shouldLoad){
      setLoading(true);
    }

    axios.post( config.api.url + 'admin/ads', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        signal: controller.signal,
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(shouldLoad){
        setTimeout( () => {
          setLoading(false);
        }, 1);
      }



      if(success){
        const ads = data.ads;
        const stats = data.stats;

        if(ads){
          setAds(ads);
        }

        if(shouldLoad){
          scrollView.current?.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });


  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/ads/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchAds(page);
  }

  const adsPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(ads.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/ads/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/ads/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/ads/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setIsLeavingDropzone(false);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    const { relatedTarget } = e;

    // Check if the relatedTarget is null or outside the dropzone
    if (!relatedTarget || !e.currentTarget.contains(relatedTarget)) {
      setIsDragging(false);
      setIsLeavingDropzone(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    if (!isLeavingDropzone) {
      setIsDragging(true);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    setIsLeavingDropzone(false);

    const file = e.dataTransfer.files[0];

    if(file){
      selectImage(file)
    }
  };


  const modalView = () => {


    return (
        <div className={`ModalSide ${ (isDragging) ? 'Disable' : ''}`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}>
            <div className="ModalSideHeader">
              <div className="ModalSideHeaderTitle">Advertentie { (modal && modal.id > 0) ? 'bewerken' : 'aanmaken' }</div>

              { (modal && modal.id > 0) &&
              <a onClick={
                () => {

                  if(modal && modal.id){
                    const modalIndex = (ads).findIndex( (p, i) => p.id == modal.id);
                    toggleAdActive(modal.id, modalIndex);
                  }

                }
              } className={`OrderItemOptionsButton ${ (modal.active) ? 'DeleteInverted' : 'AddInverted' }`}>
                { (modal.active)
                ?
                <RiToggleFill style={{ fontSize: 14 }} />
                :
                <RiToggleLine style={{ fontSize: 14 }} />
                }
                <span className="OrderItemOptionsButtonText">{ (modal.active) ? 'Deactiveren' : 'Activeren' }</span>
              </a>
              }


              <a onClick={
                () => {
                  setDetailsPre()
                }
              } className="ModalSideHeaderClose">
                <RiCloseLine />
              </a>
            </div>

            <div className="ModalSideContent">
              { (modal) &&
              <div
                className={`ModalSideContentImageWrapper ${ (isDragging) ? 'Enabled' : ''} `}
                onDrop={handleDrop}
              >
                {
                (isDragging)
                ?
                <div className="ModalSideContentImageDrop">
                  <div className="ModalSideContentImageDropText">Laat de foto hier los om te selecteren.</div>
                </div>
                :
                <>
                <div className="ModalSideContentImage" style={{ backgroundImage: `url(${ (modal.image) ? ((typeof modal.image == 'string') ? (modal.image) : (modal.image.uri)) : '' })` }}>
                  <div className="ModalSideContentImageOverlay">
                    <div onClick={
                      () => {

                        inputImage.current?.click();

                      }
                    } className="ModalSideContentImageOverlayCircle">
                      <RiImage2Fill />
                      <input ref={ inputImage } type="file" accept=".jpg, .jpeg, .png, .gif" style={{ display: 'none' }} onChange={
                        (event) => {
                           const file = event.target?.files[0];

                           selectImage(file);
                        }
                      } />
                    </div>
                  </div>
                </div>
                <div className="ModalSideContentImageDetails">
                  <div className="ModalSideContentImageDetailsText">{ (modal.image != '') ? 'Foto actief.' : 'Geen foto geselecteerd.' }</div>
                  <div className="ModalSideContentImageDetailsHint">Sleep een foto of klik op 'selecteer foto'.</div>
                  <div className="ModalSideContentImageDetailsOptions">
                    { (modal && modal.image && modal.image != '') &&
                    <a onClick={
                      () => {

                        setModal( modalOld => {
                          return {
                            ...modalOld,
                            image: ''
                          }
                        })

                      }
                    } className="ModalSideContentImageDetailsOptionsButton Delete">Verwijderen</a>
                    }
                    <a onClick={
                      () => {

                        inputImage.current?.click();
                      }
                    } className="ModalSideContentImageDetailsOptionsButton">Selecteer foto</a>
                  </div>
                </div>
              </>
              }
            </div>
            }

            { /*
            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Type</div>

                <DropDown
                  key={JSON.stringify(adSelected)}
                  selected={(adSelected) ? adSelected.value : 'Selecteer item'}
                  list={([{ key: 'product', value: 'Product' }]).map( (type, index) => { return { key: type.key, value: type.value }})}
                  onSelect={
                    (item) => {

                      setAdselected(item)
                    }
                  }
                />
              </div>
            </div>
            */ }

            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Titel</div>
                <input
                  ref={inputTitle}
                  key={ (modal && modal.title) ? modal.title : '' }
                  defaultValue={ (modal && modal.title) ? modal.title : '' }
                  placeholder={'...'}
                  className={`ModalSideContentInputInput`}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            adSave();
                          }
                        break;
                      }
                    }
                  }
                />
              </div>
            </div>

            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Ondertitel</div>
                <input
                  ref={inputSubtitle}
                  key={ (modal && modal.subtitle) ? modal.subtitle : '' }
                  defaultValue={ (modal && modal.subtitle) ? modal.subtitle : '' }
                  placeholder={'...'}
                  className={`ModalSideContentInputInput`}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            adSave();
                          }
                        break;
                      }
                    }
                  }
                />
              </div>
            </div>

            <div className="modalCouponTitleDivider" />

            <div className="ModalSideContentInput">
              <div className="ModalSideContentInputPre">Actie</div>

              <div onClick={
                () =>{

                  setModal( modalOld => {
                    return {
                      ...modalOld,
                      ref_id: -1
                    }
                  })
                  setUrlSubSelected();

                }
              } className={`ModalSideContentItem`}>
                <div className="DropDownDropdownItemText">{ 'Geen handeling' }</div>
                { (modal && modal.ref_id == -1) &&
                <span className="DropDownDropdownItemCircle">
                  <RiCheckFill style={{ fontSize: 12, color: '#fff' }} />
                </span>
                }
              </div>

              <div onClick={
                () =>{

                  setModal( modalOld => {
                    return {
                      ...modalOld,
                      ref_id: 0
                    }
                  })
                  setUrlSubSelected();

                }
              } className={`ModalSideContentItem`}>
                <div className="DropDownDropdownItemText">{ 'Navigeren naar Product' }</div>
                { (modal && modal.ref_id >= 0) &&
                <span className="DropDownDropdownItemCircle">
                  <RiCheckFill style={{ fontSize: 12, color: '#fff' }} />
                </span>
                }
              </div>

              { (modal && modal.ref_id >= 0) &&
              <div className="ModalSideContentInputPre" style={{ marginTop: 10 }}>Product</div>
              }
              { (modal && modal.ref_id >= 0) &&
              <div className="ModalSideContentInputFull">


                <DropDown
                  selected={(urlSubSelected) ? urlSubSelected.value : 'Selecteer item'}
                  list={(urlSubs)}
                  onSearch={
                    (query) => {

                      fetchSubItems('product', query);
                    }
                  }
                  onSelect={
                    (item) => {

                      setUrlSubSelected(item);

                      let ext = {};
                      const title = inputTitle.current?.value;
                      const subtitle = inputSubtitle.current?.value;

                      if(item.data.title && (title == '' || title == modal.title)){
                        ext.title = item.data.title;
                      }
                      if(item.data.subtitle && (subtitle == '' || subtitle == modal.subtitle)){
                        ext.subtitle = item.data.subtitle;
                      }


                      if(item.data.image){
                        ext.image = item.data.image;
                      }

                      setModal( modalOld => {
                        return {
                          ...modalOld,
                          ...ext,
                          ref_id: item.key
                        }
                      });


                    }
                  }
                />
              </div>
              }
            </div>
            <div className="modalCouponTitleDivider" />


          </div>

          <div className="modalContentOptions LightBorder">
            <a onClick={
              () => {

                adSave();
              }
            } className="modalContentOptionsButton C2a">Opslaan</a>
            <a onClick={
              () => {

                setDetailsPre();
              }
            } className="modalContentOptionsButton">Annuleren</a>
          </div>

        </div>
    )
  }



  useEffect( () => {

    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchAds(page_parsed);
    }else{

    }

    fetchAds();
    fetchSubItems('product');

  }, []);

  useEffect( () => {

    if(!loading){
      // controller.abort();
      fetchAds();
    }

  }, [searchQuery])


  const ads_items = (ads).map( (ad_item, index) => {

    if(!ad_item) return false;

    const { category } = ad_item;

    return (
      <div className="OrderItem" key={'ad_item_' + ad_item.id}>
        <div className="OrderItemDetailsText IDSmall">#{ ad_item.id }</div>
        <div className="OrderItemDetailsText Active">
          <span className={`OrderItemDetailsTextOption Circle ${ (ad_item.active) ? 'Success' : 'Error' }`}>
            { (ad_item.active)
            ?
            <RiCheckFill style={{ fontSize: 12 }} />
            :
            <RiCloseFill style={{ fontSize: 12 }} />
            }
          </span>
        </div>
        <div className="OrderItemDetailsText Code">{ ad_item.key }</div>
        <div className="OrderItemDetailsText Link" style={{ color: ad_item.link_color, fontWeight: (ad_item.link != 'NVT') ? 'bold' : 'normal'  }}>{ ad_item.link }</div>
        <div className="OrderItemDetailsText Image">
          <div className="OrderItemDetailsTextImage" style={{ backgroundImage: `url(${ad_item.image})` }} />
        </div>
        <div className={`OrderItemDetailsText EmailFixed ${ (ad_item.title != '') ? 'Bold' : '' }`}>{ (ad_item.title != '') ? ad_item.title : 'Leeg.' }</div>

        <div className="OrderItemOptions">

          <a onClick={
            () => {

              toggleAdActive(ad_item.id, index);

            }
          } className={`OrderItemOptionsButton ${ (ad_item.active) ? 'DeleteInverted' : 'AddInverted' }`}>
            { (ad_item.active)
            ?
            <RiToggleFill style={{ fontSize: 14 }} />
            :
            <RiToggleLine style={{ fontSize: 14 }} />
            }
          </a>

          <a onClick={
            () => {

              setDetailsPre(ad_item);

            }
          } className="OrderItemOptionsButton Edit">
            <RiFileShield2Line style={{ fontSize: 14 }} />
            <span className="OrderItemOptionsButtonText">{ 'Bewerken' }</span>
          </a>
        </div>

      </div>
    )
  })


  return (
    <div className="SettingsContentContent List">

      <div className="SettingsContentContentNavigation">
      </div>

      <div className="SettingsMessageText ShowMobile">Voorlopig alleen op desktop beschikbaar.</div>

      <div className={`settingsContentReal HideDesktop`}>
        <div className="SettingsContentNavigation">
          <div className="SettingsContentNavigationItem IDSmall">ID</div>
          <div className="SettingsContentNavigationItem Active">Actief</div>
          <div className="SettingsContentNavigationItem Code">Locatie</div>
          <div className="SettingsContentNavigationItem Link">Link</div>
          <div className="SettingsContentNavigationItem Image">Foto</div>
          <div className="SettingsContentNavigationItem EmailFixed">Titel</div>
          <div className="SettingsContentNavigationItem OptionsBig"></div>
        </div>

        <div className="GlobSettingsScroll" ref={scrollView}>

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }


          { (ads_items.length > 0)
          ?
          <div className='sortable-list'>
          { ads_items }
          </div>
          :
          <div className="SettingsMessageText">Geen advertenties.</div>
          }
        </div>

        { adsPagination() }
      </div>


      <DetailView modal={modal} setDetailsPre={setDetailsPre}>
      { modalView() }
      </DetailView>

    </div>
  )
}

export default Ads;
