import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleLine,

 RiCheckFill,
 RiCloseFill,

 RiImage2Fill,

 RiToggleLine,
 RiToggleFill
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";
import Resizer from 'react-image-file-resizer';

import DetailView from "../../../components/DetailView";
import DropDown from "../../../components/DropDown";

function Vacancies(props) {
  const {
    token, account,
    setModalPre,
    searchQuery,
    subview_data,
    setSettingsHeaderPre,
    showSettingsHeaderPre
  } = props;
  const [ vacancies, setVacancies ] = useState([]);
  const [ stores, setStores ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);

  const [ modal, setModal ] = useState();

  const scrollView = useRef();


  const inputLink = useRef();
  const inputTitle = useRef();
  const inputDescr = useRef();

  const inputRequirements = useRef();


  const setDetailsPre = (mod) => {

    const timeout = (modal) ? 300 : 0;
    if(modal){
      setModal(modalOld=>{
        return {
          ...modalOld,
          hide: true
        }
      });
    }


    if(mod){
      showSettingsHeaderPre(false);
    }else{
      showSettingsHeaderPre(true);
    }
    setTimeout( () => {
        setModal(mod);
    }, timeout)

  }

  const fetchStores = () => {

    axios.post( config.api.url + 'admin/stores', {
      hash: token,
      query: searchQuery,
      page: 1
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;


      if(success){
        const stores = data.stores;

        if(stores){
          const storesNew = (stores).map( (store, index) => {

            return {
              key: store.name,
              value: store.name
            }
          })

          setStores(storesNew);
        }

      }

    })
    .catch(function (error) {

    });

  }

  const vacancySave = () => {

    document.activeElement.blur();
    if(modal && modal.id >= 0){
      const link = inputLink.current?.value;
      const title = inputTitle.current?.value;
      const descr = inputDescr.current?.value;
      const requirements = inputRequirements.current?.value;


      setLoading(true);
      axios.post( config.api.url + 'admin/vacancies/save', {
        hash: token,
        vacancy_id: modal.id,
        external_link: link,
        title: title,
        descr: descr,
        requirements: requirements,
        type: modal.type,
        location: modal.location
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);


        if(success){

          setDetailsPre();
          fetchVacancies(page, false);

        }else{

          setModalPre({
            title: 'Oops.',
            subtitle: response
          })
        }

      })
      .catch(function (error) {
        setLoading(false);
      });

    }

  }

  const toggleVacancyActive = (vacancy_id = 0, vacancy_index) => {

    if(vacancy_id > 0){

      vacancies[vacancy_index].active = !vacancies[vacancy_index].active;
      if(modal){
        modal.active = vacancies[vacancy_index].active;
      }

      // setLoading(true);
      axios.post( config.api.url + 'admin/vacancies/toggle', {
        hash: token,
        vacancy_id: vacancy_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        // setTimeout( () => {
        //   setLoading(false);
        // }, 1);


        if(success){

          fetchVacancies(page, false);
        }

      })
      .catch(function (error) {
        // setLoading(false);
      });

    }
  }

  const fetchVacancies = (pageFinal = page, shouldLoad = true) => {

    if(shouldLoad){
      setLoading(true);
    }
    axios.post( config.api.url + 'admin/vacancies', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(shouldLoad){
        setTimeout( () => {
          setLoading(false);
        }, 1);
      }


      if(success){
        const vacancies = data.vacancies;
        const stats = data.stats;


        if(vacancies){
          setVacancies(vacancies);
        }

        if(shouldLoad){
          scrollView.current?.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }

  const removeVacancy = (vacancy_id = 0) => {

    if(vacancy_id > 0){

      setVacancies(vacancyOld => {
        return vacancyOld.filter((v, i) => v.id != vacancy_id)
      });

      if(modal){
        setDetailsPre()
      }

      setLoading(true);
      axios.post( config.api.url + 'admin/vacancies/delete', {
        hash: token,
        vacancy_id: vacancy_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);

        console.log(json.data)

        if(success){

          fetchVacancies();

        }

      })
      .catch(function (error) {
        setLoading(false);
      });

    }
  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/vacancies/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchVacancies(page);
  }

  const vacanciesPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(vacancies.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/vacancies/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/vacancies/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/vacancies/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }


  const modalView = () => {

    return (
        <div className={`ModalSide`}>
          <div className="ModalSideHeader">
            <div className="ModalSideHeaderTitle">Vacature { 'bewerken' }</div>

            { (modal && modal.id > 0) &&
            <a onClick={
              () => {

                if(modal && modal.id){
                  const modalIndex = (vacancies).findIndex( (c, i) => c.id == modal.id);
                  toggleVacancyActive(modal.id, modalIndex);
                }

              }
            } className={`OrderItemOptionsButton ${ (modal.active) ? 'DeleteInverted' : 'AddInverted' }`}>
              { (modal.active)
              ?
              <RiToggleFill style={{ fontSize: 14 }} />
              :
              <RiToggleLine style={{ fontSize: 14 }} />
              }
              <span className="OrderItemOptionsButtonText">{ (modal.active) ? 'Deactiveren' : 'Activeren' }</span>
            </a>
            }
            { (modal && modal.id > 0) &&
            <a onClick={
              () => {

                setModalPre({
                  title: 'Verwijderen.',
                  subtitle: 'Ben je zeker dat je dit item wilt verwijderen?',
                  content: (
                    <>
                      <div className="modalContentOptions NoBorder">
                        <a onClick={
                          () => {

                            removeVacancy(modal.id);
                            setModalPre();
                          }
                        } className="modalContentOptionsButton Delete">Verwijderen</a>
                        <a onClick={
                          () => {

                            setModalPre();
                          }
                        } className="modalContentOptionsButton">Annuleren</a>
                      </div>
                    </>
                  )
                })

              }
            } className="OrderItemOptionsButton Delete">
              <RiDeleteBin5Line style={{ fontSize: 14 }} />
            </a>
            }
            <a onClick={
              () => {
                setDetailsPre()
              }
            } className="ModalSideHeaderClose">
              <RiCloseLine />
            </a>
          </div>

          <div className="ModalSideContent">


            <div className="ModalSideContentTwin">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Type</div>

                <DropDown
                  key={JSON.stringify((modal && modal.type) ? modal.type : '')}
                  selected={(modal) ? ((modal.type == 'fulltime') ? 'Full-time' : 'Part-time') : 'Selecteer type'}
                  list={([{ key: 'fulltime', value: 'Full-time' }, { key: 'parttime', value: 'Part-time' }]).map( (status, index) => { return { key: status.key, value: status.value }})}
                  onSelect={
                    (item) => {

                      setModal( modalOld => {
                        return {
                          ...modalOld,
                          type: item.key
                        }
                      })
                    }
                  }
                />
              </div>
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Locatie</div>

                <DropDown
                  key={JSON.stringify((modal && modal.location) ? modal.location : '')}
                  selected={(modal) ? modal.location : 'Selecteer type'}
                  list={([{ key: 'Elke', value: 'Elk filiaal' }, ...stores]).map( (status, index) => { return { key: status.key, value: status.value }})}
                  onSelect={
                    (item) => {

                      setModal( modalOld => {
                        return {
                          ...modalOld,
                          location: item.key
                        }
                      })
                    }
                  }
                />
              </div>
            </div>


            <div className="modalCouponTitleDivider" />

            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Externe Link</div>
                <input
                  ref={inputLink}
                  key={ JSON.stringify(modal) }
                  defaultValue={ (modal && modal.link) ? modal.link : '' }
                  placeholder={'...'}
                  className={`ModalSideContentInputInput`}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            vacancySave();
                          }
                        break;
                      }
                    }
                  }
                />
              </div>
            </div>

            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Titel</div>
                <input
                  ref={inputTitle}
                  key={ JSON.stringify(modal) }
                  defaultValue={ (modal && modal.title) ? modal.title : '' }
                  placeholder={'...'}
                  className={`ModalSideContentInputInput`}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            vacancySave();
                          }
                        break;
                      }
                    }
                  }
                />
              </div>
            </div>

            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Omschrijving</div>
                <textarea
                  rows={12}
                  defaultValue={ (modal && modal.descr) ? modal.descr : '' }
                  ref={inputDescr}
                  key={ JSON.stringify(modal) }
                  placeholder={'...'}
                  className={`ModalSideContentInputTextarea`}
                />
              </div>
            </div>

            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Vereisten</div>
                <textarea
                  rows={20}
                  defaultValue={ (modal && modal.requirements) ? modal.requirements : '' }
                  ref={inputRequirements}
                  key={ JSON.stringify(modal) }
                  placeholder={'...'}
                  className={`ModalSideContentInputInput`}
                />
              </div>
            </div>


          </div>

          <div className="modalContentOptions LightBorder">
            <a onClick={
              () => {

                vacancySave();
              }
            } className="modalContentOptionsButton C2a">Opslaan</a>
            <a onClick={
              () => {

                setDetailsPre();
              }
            } className="modalContentOptionsButton">Annuleren</a>
          </div>

        </div>
    )
  }



  useEffect( () => {

    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchVacancies(page_parsed);
    }else{

    }

    fetchStores();


    setSettingsHeaderPre(
      <div className="SettingsContentContentNavigation HideDesktop">
        <a onClick={
          () => {

            setDetailsPre({
              id: 0,
            });
          }
        } className="OrderItemOptionsButton AccentHover">
          <RiAddCircleLine style={{ fontSize: 14 }} />
          <span className="OrderItemOptionsButtonText">Aanmaken</span>
        </a>
      </div>
    )
  }, []);



  useEffect( () => {

    if(!loading){
      // controller.abort();
      fetchVacancies();
    }

  }, [searchQuery]);


  const vacancies_items = (vacancies).map( (vacancy_item, index) => {

    return (
      <div className="OrderItem" key={'order_item_' + vacancy_item.id}>
        <div className="OrderItemDetailsText IDSmall">#{ vacancy_item.id }</div>
        <div className="OrderItemDetailsText Active">
          <span className={`OrderItemDetailsTextOption Circle ${ (vacancy_item.active) ? 'Success' : 'Error' }`}>
            { (vacancy_item.active)
            ?
            <RiCheckFill style={{ fontSize: 12 }} />
            :
            <RiCloseFill style={{ fontSize: 12 }} />
            }
          </span>
        </div>
        <div className="OrderItemDetailsText Name Bold">{ vacancy_item.title }</div>
        <div className="OrderItemDetailsText StatusMid More">{ vacancy_item.type }</div>
        <div className="OrderItemDetailsText Date">{ vacancy_item.timestamp_last?.date }</div>

        <div className="OrderItemOptions">

          <a onClick={
            () => {

              toggleVacancyActive(vacancy_item.id, index);

            }
          } className={`OrderItemOptionsButton ${ (vacancy_item.active) ? 'DeleteInverted' : 'AddInverted' }`}>
            { (vacancy_item.active)
            ?
            <RiToggleFill style={{ fontSize: 14 }} />
            :
            <RiToggleLine style={{ fontSize: 14 }} />
            }
            <span className="OrderItemOptionsButtonText">{ (vacancy_item.active) ? 'Deactiveren' : 'Activeren' }</span>
          </a>

          <a onClick={
            () => {

              setModalPre({
                title: 'Verwijderen.',
                subtitle: 'Ben je zeker dat je dit item wilt verwijderen?',
                content: (
                  <>
                    <div className="modalContentOptions NoBorder">
                      <a onClick={
                        () => {

                          removeVacancy(vacancy_item.id);
                          setModalPre();
                        }
                      } className="modalContentOptionsButton Delete">Verwijderen</a>
                      <a onClick={
                        () => {

                          setModalPre();
                        }
                      } className="modalContentOptionsButton">Annuleren</a>
                    </div>
                  </>
                )
              })

            }
          } className="OrderItemOptionsButton Delete">
            <RiDeleteBin5Line style={{ fontSize: 14 }} />
          </a>

          <a onClick={
            () => {

              setDetailsPre(vacancy_item);

            }
          } className="OrderItemOptionsButton Edit">
            <RiFileShield2Line style={{ fontSize: 14 }} />
            <span className="OrderItemOptionsButtonText">{ 'Bewerken' }</span>
          </a>
        </div>

      </div>
    )
  })


  return (
    <div className="SettingsContentContent List">


      <div className="SettingsMessageText ShowMobile">Voorlopig alleen op desktop beschikbaar.</div>

      <div className={`settingsContentReal HideDesktop`}>
        <div className="SettingsContentNavigation">
          <div className="SettingsContentNavigationItem IDSmall">ID</div>
          <div className="SettingsContentNavigationItem Active">Actief</div>
          <div className="SettingsContentNavigationItem Name">Titel</div>
          <div className="SettingsContentNavigationItem StatusMid">Type</div>
          <div className="SettingsContentNavigationItem Date">Datum</div>
          <div className="SettingsContentNavigationItem OptionsBig"></div>
        </div>

        <div className="GlobSettingsScroll" ref={scrollView}>

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }


          { (vacancies_items.length > 0)
          ?
          vacancies_items
          :
          <div className="SettingsMessageText">Geen vacatures.</div>
          }
        </div>

        { vacanciesPagination() }
      </div>


      <DetailView width={750} modal={modal} setDetailsPre={setDetailsPre}>
      { modalView() }
      </DetailView>

    </div>
  )
}

export default Vacancies;
