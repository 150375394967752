import '../styles/Products.css';
import config from '../constants/config';
import Product from '../components/Product';
import {
  RiArrowRightSFill,
  RiArrowLeftFill,
  RiArrowRightFill,
  RiSortAsc,
  RiSortDesc,
  RiStarFill,
  RiStarLine,
  RiPriceTag3Fill,
  RiPriceTag3Line,
  RiCloseFill,
  RiFilterFill,
} from "react-icons/ri";
import { useState, useEffect } from 'react';
import loadingAnimation from '../animations/loading_circle.json';
import Lottie from "lottie-react";
import axios from 'axios';
import DALogo from '../components/three/DALogo';

function Products(props) {
  const { subview,
    view, setViewPre,
    categories,
    token, setModalPre
   } = props;
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(0);
  const [ loading, setLoading ] = useState(false);
  const [ products, setProducts ] = useState([]);
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ showFilters, setShowFilters ] = useState(false);
  const [ sortBy, setSortBy ] = useState('nieuwste');
  const [ priceClass, setPriceClass ] = useState('alle');
  const [ freeDeliveryBlock, setFreeDeliveryBlock ] = useState();

  let subviewParsed = '';
  let title = 'Alle Producten';
  let subtitle = 'alle';
  let subview_cleaned_items = [];

  let category;
  if(subview && subview != ''){

    const subview_cleaned = (subview).replace('-', ' ');
    subviewParsed = subview_cleaned;

    subview_cleaned_items = (subview_cleaned).split('/');

    if(subview_cleaned_items.length > 1){
      // Get main view
      subviewParsed = subview_cleaned_items[0];
    }

    // Get stuff
    category = (categories).find( (cat, cat_i) => cat.cat_key == subviewParsed);

    if(category){
      title = category.category;
      subtitle = category.cat_key;
    }

  }

  let title_ext = [];
  switch(sortBy){
    case "prijs_hoog":
      title_ext.push({
        type: 'sortBy',
        selected: 'Prijs: hoog naar laag'
      });
    break;
    case "prijs_laag":
      title_ext.push({
        type: 'sortBy',
        selected: 'Prijs: laag naar hoog'
      });
    break;
  }
  switch(priceClass){
    case "less_200":
      title_ext.push({
        type: 'priceClass',
        selected: 'Minder dan SRD 200'
      });
    break;
    case "less_500":
      title_ext.push({
        type: 'priceClass',
        selected: 'Minder dan SRD 500'
      });
    break;
    case "less_1000":
      title_ext.push({
        type: 'priceClass',
        selected: 'Minder dan SRD 1000'
      });
    break;
  }

  title = (title);

  const categories_full = [...categories]

  const fetchProducts = (type = 'alle', pageFinal = page, sortByFinal = sortBy, priceClassFinal = priceClass) => {

    setLoading(true);
    axios.post( config.api.url + 'products/type', {
      type: type,
      page: pageFinal,
      sort_by: sortByFinal,
      price_class: priceClassFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){
        const products = data.products;
        const stats = data.stats;

        if(products){
          setProducts(products);
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }
      setTimeout( () => {
        setLoading(false);
        setIsLoaded(true);
      }, 1);
    })
    .catch(function (error) {


      setLoading(false);
      setIsLoaded(true);
    });

  }

  const fetchInfo = () => {

    setLoading(true);
    axios.post( config.api.url + 'page', {
      page: 'products',
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){
        const pageObject = data.page;
        const blocksObjects = data.blocks;

        if(blocksObjects && blocksObjects.length > 0){
          setFreeDeliveryBlock(blocksObjects[0])
        }

      }


    })
    .catch(function (error) {


    });
  }

  // FIRST
  useEffect( () => {

    const page_parsed = parseInt(subview_cleaned_items[1]);
    const sub_cleaned = (subtitle).toLowerCase();

    if(subview_cleaned_items.length > 1){
      // Get main view
      setPage(page_parsed);
      fetchProducts(sub_cleaned, page_parsed);
    }else{
      fetchProducts(sub_cleaned, 1);
    }
    fetchInfo();

  }, []);

  useEffect( () => {

    if(isLoaded){
      const sub_cleaned = (subtitle).toLowerCase();

      // Collapse
      setShowFilters(false);
      setPage(1);
      fetchProducts(sub_cleaned, page);
    }

  }, [subtitle])


  const productsPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(products.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper">
        <div className="ProductsPagination">

        <a href={ config.homepage + subviewParsed + '/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftFill />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a key={'dots_'+p_index} className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + subviewParsed + '/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + subviewParsed + '/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightFill />
          </a>
          }

        </div>
      </div>
    )
  }

  const productItems = () => {

    const productItems = products.map( (prod_item, prod_index) => {

      return (
        <Product
          {... props}
          item={prod_item}
          key={'product_item_' + prod_index}
          token={token}
          setModalPre={setModalPre}
        />
      )
    })

    if(productItems.length == 0 && !loading){
      return (
        <div className="ProductsEmptyWrapper">
          <div className="ProductsEmpty">
            <div className="ProductsEmptySubtitle">Geen producten gevonden.</div>
          </div>
          <DALogo
            type={'logo'}

          />
        </div>
      )
    }

    return (
      <div className="ProductsItemsItems">
        <div className={`LoginLoadingOverlay Animated ${ (loading) ? 'Loading' : '' }`}>
          { (loading) &&
          <div className="LoginLoading">
            <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
          </div>
          }
        </div>
        { productItems }
      </div>
    );
  }

  const setPriceClassPre = (priceClass) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'categorie/' + subviewParsed + '/' + 1);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    const sub_cleaned = (subtitle).toLowerCase();
    setPriceClass(priceClass);
    setPage(1);
    fetchProducts(sub_cleaned, 1, sortBy, priceClass);
  }

  const setSortByPre = (sortBy) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'categorie/' + subviewParsed + '/' + 1);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    const sub_cleaned = (subtitle).toLowerCase();
    setSortBy(sortBy);
    setPage(1);
    fetchProducts(sub_cleaned, 1, sortBy);
  }

  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'categorie/' + subviewParsed + '/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    const sub_cleaned = (subtitle).toLowerCase();
    setPage(page);
    fetchProducts(sub_cleaned, page);
  }


  return (
    <div className="Products">
      <div className="FixedWidth">

        <div className="ProductsHeader">
          <div className="ProductsHeaderImage" />

          <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/swirl.png'})` }} />

        </div>

        <div className="ProductsWrapper">

          <div className="ProductsSide">

            <div className="ProductsSideHeader" onClick={
              () => {
                setShowFilters(!showFilters);
              }
            }>
              <div className="ProductsSideHeaderTitle">Filteren</div>

              <div className={`ProductsSideHeaderIcon ShowMobile`}>
                <RiFilterFill />
              </div>
            </div>

            <div className={`ProductsSideItemsWrapper ${ (showFilters) ? 'ShowMobile' : '' }`}>

              <a href={ config.homepage + 'producteNVTlle' } onClick={
                (event) => {
                  setViewPre('categorie/alle');
                  event.preventDefault()
                }
              } key={"category_all"} className={`ProductsSideHeaderSubtitle Selectable ${ (subviewParsed && subviewParsed == 'alle') ? 'Selected' : '' }`}>
                <div className="ProductsSideHeaderSubtitleText">Alle producten</div>
                <RiArrowRightSFill style={{ fontSize: 16 }} />
              </a>
              <div className="ProductsSideHeaderSubtitle">Categorie</div>
              <div className="ProductsSideItems">

                { (categories_full.length == 0)
                ?
                <div className="LoginLoadingOverlay InFill">
                  <div className="LoginLoading">
                    <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
                  </div>
                </div>
                :
                <>
                  { categories_full.map( (cat, cat_index) => {

                    const isSelected = (subviewParsed && subviewParsed == (cat.cat_key).toLowerCase());
                    const url = config.homepage + 'categorie/' + (cat.cat_key.toLowerCase());

                    return (
                      <a onClick={
                        (event) => {
                          setViewPre('categorie/' + cat.cat_key);
                          event.preventDefault()
                        }
                      } key={"category_" + cat.cat_key} className={`ProductsSideCategoryItem ${ (isSelected) ? 'Selected' : '' }`}>
                        <div className="ProductsSideCategoryItemTitle">{ cat.category }</div>
                        <RiArrowRightSFill style={{ fontSize: 16 }} />
                      </a>
                    )
                    })
                  }
                </>
                }
              </div>

              <div className="ProductsSideHeaderSubtitle">Sorteren</div>
              <div className="ProductsSideItems">
                  <a onClick={
                    (event) => {
                      setSortByPre('nieuwste');
                      setShowFilters(false);
                    }
                  } key={"category_sort_nieuwste"} className={`ProductsSideCategoryItem ${ (sortBy == 'nieuwste') ? 'SelectedBall' : '' }`}>
                    <div className="ProductsSideCategoryItemTitle">Nieuwste</div>
                    { (sortBy == 'nieuwste')
                    ?
                    <RiStarFill style={{ fontSize: 14 }} />
                    :
                    <RiStarLine style={{ fontSize: 14 }} />
                    }
                  </a>
                  <a onClick={
                    (event) => {
                      setSortByPre('prijs_hoog');
                      setShowFilters(false);
                    }
                  } key={"category_sort_prijs_prijs_hoog"} className={`ProductsSideCategoryItem ${ (sortBy == 'prijs_hoog') ? 'SelectedBall' : '' }`}>
                    <div className="ProductsSideCategoryItemTitle">Hoogste prijs</div>
                    <RiSortAsc style={{ fontSize: 14 }} />
                  </a>
                  <a onClick={
                    (event) => {
                      setSortByPre('prijs_laag');
                      setShowFilters(false);
                    }
                  } key={"category_sort_prijs_laag"} className={`ProductsSideCategoryItem ${ (sortBy == 'prijs_laag') ? 'SelectedBall' : '' }`}>
                    <div className="ProductsSideCategoryItemTitle">Laagste prijs</div>
                    <RiSortDesc style={{ fontSize: 14 }} />
                  </a>
              </div>

              <div className="ProductsSideHeaderSubtitle">Prijs</div>
              <div className="ProductsSideItems">
                  <a onClick={
                    (event) => {
                      setPriceClassPre('alle');
                      setShowFilters(false);
                    }
                  } key={"category_sort_alle"} className={`ProductsSideCategoryItem ${ (priceClass == 'alle') ? 'SelectedBall' : '' }`}>
                    <div className="ProductsSideCategoryItemTitle">Alle prijsklassen</div>
                    { (priceClass == 'alle')
                    ?
                    <RiPriceTag3Fill style={{ fontSize: 14 }} />
                    :
                    <RiPriceTag3Line style={{ fontSize: 14 }} />
                    }
                  </a>
                  <a onClick={
                    (event) => {
                      setPriceClassPre('less_200');
                      setShowFilters(false);
                    }
                  } key={"category_sort_less_200"} className={`ProductsSideCategoryItem ${ (priceClass == 'less_200') ? 'SelectedBall' : '' }`}>
                    <div className="ProductsSideCategoryItemTitle">Minder dan SRD 200</div>
                    { (priceClass == 'less_200')
                    ?
                    <RiPriceTag3Fill style={{ fontSize: 14 }} />
                    :
                    <RiPriceTag3Line style={{ fontSize: 14 }} />
                    }
                  </a>
                  <a onClick={
                    (event) => {
                      setPriceClassPre('less_500');
                      setShowFilters(false);
                    }
                  } key={"category_sort_less_500"} className={`ProductsSideCategoryItem ${ (priceClass == 'less_500') ? 'SelectedBall' : '' }`}>
                    <div className="ProductsSideCategoryItemTitle">Minder dan SRD 500</div>
                    { (priceClass == 'less_500')
                    ?
                    <RiPriceTag3Fill style={{ fontSize: 14 }} />
                    :
                    <RiPriceTag3Line style={{ fontSize: 14 }} />
                    }
                  </a>
                  <a onClick={
                    (event) => {
                      setPriceClassPre('less_1000');
                      setShowFilters(false);
                    }
                  } key={"category_sort_less_1000"} className={`ProductsSideCategoryItem ${ (priceClass == 'less_1000') ? 'SelectedBall' : '' }`}>
                    <div className="ProductsSideCategoryItemTitle">Minder dan SRD 1000</div>
                    { (priceClass == 'less_1000')
                    ?
                    <RiPriceTag3Fill style={{ fontSize: 14 }} />
                    :
                    <RiPriceTag3Line style={{ fontSize: 14 }} />
                    }
                  </a>
              </div>

              { (freeDeliveryBlock && freeDeliveryBlock.data != '') &&
              <div className="ProductsSideHighlight">
                <div className="ProductsSideHighlightText">{ (freeDeliveryBlock.data) }</div>
              </div>
              }

            </div>
          </div>

          <div className="ProductsItemsWrapper">

            <div className="ProductsItemsHeader">
              <div className="ProductsItemsHeaderTitle">{ title }</div>
              { (title_ext.length > 0) &&
              <div className="ProductsItemsHeaderSubItems">
                { (title_ext).map( (ext, e_ind) => {
                    return (
                      <span className="ProductsItemsHeaderSubItem" key={'subitem_'+e_ind}>
                        <span className="ProductsItemsHeaderSubItemText">{ ext.selected }</span>
                        <span onClick={
                          () => {
                            switch(ext.type){
                              case "sortBy":
                                setSortByPre('nieuwste');
                              break;
                              case "priceClass":
                                setPriceClassPre('alle');
                              break;
                            }
                          }
                        } className="ProductsItemsHeaderSubItemRemove">
                          <RiCloseFill />
                        </span>
                      </span>
                    )
                  })
                }
              </div>
              }
            </div>

            <div className="ProductsItems">

              { productItems() }

            </div>

            { productsPagination() }

          </div>

        </div>

      </div>
    </div>
  );
}

export default Products;
