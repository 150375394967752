import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleLine,

 RiCheckFill,
 RiCloseFill,

 RiToggleLine,
 RiToggleFill
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";

import DetailView from "../../../components/DetailView";


function Coupons(props) {
  const {
    token, account,
    setModalPre,
    searchQuery,
    subview_data,
    setMenuSelectedPre,
    setSettingsHeaderPre,
    showSettingsHeaderPre
  } = props;
  const [ coupons, setCoupons ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ loadingUsed, setLoadingUsed ] = useState(false);
  const [ coupon, setCoupon ] = useState(null);
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);
  const [ couponText, setCouponText ] = useState('');
  const [ amountText, setAmountText ] = useState('');
  const [ modal, setModal ] = useState();
  const inputCoupon = useRef();
  const inputCouponAmount = useRef();
  const inputCouponStock = useRef();
  const [ couponTextValid, setCouponTextValid ] = useState(false);
  const scrollView = useRef();


  const setDetailsPre = (mod) => {

    if(mod){
      setCouponText((mod.coupon) ? mod.coupon : '');
      setAmountText((mod.amount) ? mod.amount : '');
    }else{
      setCouponText('');
      setAmountText('');
    }

    const timeout = (modal) ? 300 : 0;
    if(modal){
      setModal(modalOld=>{
        return {
          ...modalOld,
          hide: true
        }
      });
    }


    if(mod){
      showSettingsHeaderPre(false);
    }else{
      showSettingsHeaderPre(true);
    }

    setTimeout( () => {
        setModal(mod);
    }, timeout)

    if(mod){
      setTimeout( () => {
        if(mod && mod.coupon_used > 0){
          fetchCouponOrders(mod);
        }
      }, 150);
    }
  }

  const fetchCouponOrders = (mod) => {

    if(mod && mod.id > 0){

      setLoadingUsed(true);
      axios.post( config.api.url + 'admin/coupons/orders', {
        hash: token,
        coupon_id: mod.id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setLoadingUsed(false);

        if(success){
          const orders = data.orders;


          if(orders.length > 0 && mod){

            mod.coupon_used_items = orders;
            // setModal(mod);
          }

        }

        setCouponTextValid(success);

      })
      .catch(function (error) {

        setLoadingUsed(false);

      });

    }
  }

  const verifyCouponText = (couponTextRaw = '') => {

    if(couponTextRaw.length > 1){

      axios.post( config.api.url + 'admin/coupons/verify', {
        hash: token,
        coupon: couponTextRaw,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;


        setCouponTextValid(success);

      })
      .catch(function (error) {

        setCouponTextValid(false);
      });

    }
  }


  const removeCoupon = (coupon_id = 0) => {

    if(coupon_id > 0){

      setCoupons(couponsOld => {
        return couponsOld.filter((c, i) => c.id != coupon_id)
      });

      setLoading(true);
      axios.post( config.api.url + 'admin/coupons/delete', {
        hash: token,
        coupon_id: coupon_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);


        if(success){

          fetchCoupons(page, false);
          // setFaq(faqOld => {
          //   return faqOld.filter((f, i) => f.id != faq_id)
          // });

        }

      })
      .catch(function (error) {
        setLoading(false);
      });

    }
  }

  const couponSave = () => {

    document.activeElement.blur();
    if(modal && modal.id >= 0){
      const coupon = inputCoupon.current?.value;
      const amount = inputCouponAmount.current?.value;
      const stock = inputCouponStock.current?.value;

      setLoading(true);
      axios.post( config.api.url + 'admin/coupons/save', {
        hash: token,
        coupon_id: modal.id,
        coupon: coupon,
        amount: amount,
        stock: stock
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);

        if(success){

          setDetailsPre();
          fetchCoupons(page, false);

        }else{

          setModalPre({
            title: 'Oops.',
            subtitle: response
          })
        }

      })
      .catch(function (error) {
        setLoading(false);
      });

    }
  }

  const toggleCouponActive = (coupon_id = 0, coupon_index) => {

    if(coupon_id > 0){

      coupons[coupon_index].active = !coupons[coupon_index].active;
      if(modal){
        modal.active = coupons[coupon_index].active;
      }

      // setLoading(true);
      axios.post( config.api.url + 'admin/coupons/toggle', {
        hash: token,
        coupon_id: coupon_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        // setTimeout( () => {
        //   setLoading(false);
        // }, 1);


        if(success){

          fetchCoupons(page, false);
        }

      })
      .catch(function (error) {
        // setLoading(false);
      });

    }
  }

  const fetchCoupons = (pageFinal = page, shouldLoad = true) => {
    
    if(shouldLoad){
      setLoading(true);
    }
    axios.post( config.api.url + 'admin/coupons', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;


      if(shouldLoad){
        setTimeout( () => {
          setLoading(false);
        }, 1);
      }

      if(success){
        const coupons = data.coupons;
        const stats = data.stats;


        if(coupons){
          setCoupons(coupons);
        }

        if(shouldLoad){
          scrollView.current?.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/coupons/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchCoupons(page);
  }

  const couponPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(coupons.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/coupons/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/coupons/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/coupons/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }


  const modalView = () => {

    return (
        <div className={`ModalSide`}>
          <div className="ModalSideHeader">
            <div className="ModalSideHeaderTitle">Coupon { (modal && modal.id == 0) ? 'aanmaken' : 'aanpassen' }</div>

            { (modal && modal.id > 0) &&
            <a onClick={
              () => {

                if(modal && modal.id){
                  const modalIndex = (coupons).findIndex( (c, i) => c.id == modal.id);
                  toggleCouponActive(modal.id, modalIndex);
                }

              }
            } className={`OrderItemOptionsButton ${ (modal.active) ? 'DeleteInverted' : 'AddInverted' }`}>
              { (modal.active)
              ?
              <RiToggleFill style={{ fontSize: 14 }} />
              :
              <RiToggleLine style={{ fontSize: 14 }} />
              }
              <span className="OrderItemOptionsButtonText">{ (modal.active) ? 'Deactiveren' : 'Activeren' }</span>
            </a>
            }

            { (modal && modal.coupon_used == 0) &&
            <a onClick={
              () => {

                setModalPre({
                  title: 'Verwijderen.',
                  subtitle: 'Ben je zeker dat je deze coupon wilt verwijderen?',
                  content: (
                    <>
                      <div className="modalContentOptions NoBorder">
                        <a onClick={
                          () => {

                            removeCoupon(modal.id);
                            setDetailsPre();
                            setModalPre();
                          }
                        } className="modalContentOptionsButton Delete">Verwijderen</a>
                        <a onClick={
                          () => {

                            setModalPre();
                          }
                        } className="modalContentOptionsButton">Annuleren</a>
                      </div>
                    </>
                  )
                })

              }
            } className="OrderItemOptionsButton Delete">
              <RiDeleteBin5Line style={{ fontSize: 14 }} />
            </a>}
            <a onClick={
              () => {
                setDetailsPre()
              }
            } className="ModalSideHeaderClose">
              <RiCloseLine />
            </a>
          </div>

          <div className="ModalSideContent">
            <div className="ModalSideContentCoupon">
              <div className="ModalSideContentCouponPre">Coupon</div>
              <div className="ModalSideContentCouponInputWrapper">
                <div className={`ModalSideContentCouponInput ${ (couponText.length > 1) ? ((couponTextValid || (modal && modal.coupon == couponText)) ? 'Valid' : 'Invalid') : '' }`}>
                  { ((couponText.length > 1 && couponTextValid) || (modal && modal.coupon == couponText)) &&
                  <div className="ModalSideContentCouponInputIcon">
                    <RiCheckFill />
                  </div>
                  }
                  <input
                    ref={inputCoupon}
                    value={couponText}
                    key={ JSON.stringify(modal) }
                    placeholder={'FREE15'}
                    disabled={ (modal && modal.coupon_used > 0) }
                    className={`ModalSideContentInputInput ${ (modal && modal.coupon_used > 0) ? 'Disabled' : '' }`}
                    onChange={
                      (event) => {

                        const cpText = (event.target.value).toUpperCase();
                        setCouponText(cpText);
                        verifyCouponText(cpText);
                      }
                    }
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                        switch(key){
                          case "Enter":
                            if(!loading){
                              couponSave();
                            }
                          break;
                        }
                      }
                    }
                  />
                </div>
              </div>
            </div>

            <div className="ModalSideContentTwin">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Bedrag</div>
                <input
                  ref={inputCouponAmount}
                  key={ JSON.stringify(modal) }
                  defaultValue={ (modal && modal.amount) ? modal.amount : '' }
                  placeholder={'SRD 100 / 25%'}
                  disabled={ (modal && modal.coupon_used > 0) }
                  className={`ModalSideContentInputInput ${ (modal && modal.coupon_used > 0) ? 'Disabled' : '' }`}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            couponSave();
                          }
                        break;
                      }
                    }
                  }
                  value={amountText}
                  onChange={
                    (event) => {

                      setAmountText((event.target.value).toUpperCase());
                    }
                  }
                />
              </div>
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Voorraad</div>
                <input
                  ref={inputCouponStock}
                  key={ JSON.stringify(modal) }
                  defaultValue={ (modal && modal.stock) ? modal.stock : '' }
                  placeholder={'1'}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            couponSave();
                          }
                        break;
                      }
                    }
                  }
                />
              </div>
            </div>

            <div className="modalCouponTitleDivider" />

              { (loadingUsed) &&
              <div className="LoginLoadingOverlay Dark Inline">
                <div className="LoginLoading">
                  <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
                </div>
              </div>
              }

              { (modal && modal.coupon_used_items?.length > 0) &&
              <div key={ JSON.stringify(modal.coupon_used_items) }>
                <div className="modalCouponTitle">Coupons gebruikt</div>

                {
                  (modal.coupon_used_items).map( (coup_item, coup_index) => {

                    return (
                      <div onClick={
                          () => {


                            setMenuSelectedPre({
                              key: 'bestellingen_all/order/' + coup_item.id
                            }, 20);
                          }
                        } className="modalCouponItem" key={'coup_item_' + coup_item.id}>
                        <div className="modalCouponItemDetails">
                          <div className="modalCouponItemDetailsName">{ coup_item.account?.name }</div>
                          <div className="modalCouponItemDetailsSubWrapper">
                            <div className="modalCouponItemDetailsSub">
                              <div className="modalCouponItemDetailsSubText">{ modal.coupon }</div>
                            </div>
                            <div className="modalCouponItemDetailsSubItemText">{ 'Items: ' + (coup_item.items_count) }</div>
                          </div>
                        </div>
                        <div className="modalCouponItemSub Column">
                          <div className="modalCouponItemSubPrice">{ (coup_item.totals_amount) }</div>
                          <div className="modalCouponItemSubPriceNew">{ (coup_item.final_amount) }</div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              }

            </div>

          <div className="modalContentOptions LightBorder">
            <a onClick={
              () => {

                couponSave();
              }
            } className="modalContentOptionsButton C2a">Opslaan</a>
            <a onClick={
              () => {

                setDetailsPre();
              }
            } className="modalContentOptionsButton">Annuleren</a>
          </div>

        </div>
    )
  }



  useEffect( () => {


    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchCoupons(page_parsed);
    }else{

    }


    // SET HEADER
    setSettingsHeaderPre(
      <div className="SettingsContentContentNavigation HideDesktop">
        <a onClick={
          () => {

            setDetailsPre({
              id: 0,
            });
          }
        } className="OrderItemOptionsButton AccentHover">
          <RiAddCircleLine style={{ fontSize: 14 }} />
          <span className="OrderItemOptionsButtonText">Aanmaken</span>
        </a>
      </div>
    );
  }, []);




    useEffect( () => {

      if(!loading){
        // controller.abort();
        fetchCoupons();
      }

    }, [searchQuery])


  const coupons_items = (coupons).map( (coupon, index) => {

    return (
      <div className="OrderItem" key={'coupon_item_' + index}>
        <div className="OrderItemDetailsText IDSmall">#{ coupon.id }</div>
        <div className="OrderItemDetailsText Active">
          <span className={`OrderItemDetailsTextOption Circle ${ (coupon.active) ? 'Success' : 'Error' }`}>
            { (coupon.active)
            ?
            <RiCheckFill style={{ fontSize: 12 }} />
            :
            <RiCloseFill style={{ fontSize: 12 }} />
            }
          </span>
        </div>
        <div className="OrderItemDetailsText Coupon Bold">{ coupon.coupon }</div>
        <div className="OrderItemDetailsText Amount">{ coupon.amount }</div>
        <div className="OrderItemDetailsText Stock">{ coupon.stock }x</div>
        <div className={`OrderItemDetailsText Stock ${ (coupon.coupon_used > 0) ? 'More' : '' }`}>{ coupon.coupon_used }</div>

        <div className="OrderItemOptions">

          <a onClick={
            () => {

              toggleCouponActive(coupon.id, index);

            }
          } className={`OrderItemOptionsButton ${ (coupon.active) ? 'DeleteInverted' : 'AddInverted' }`}>
            { (coupon.active)
            ?
            <RiToggleFill style={{ fontSize: 14 }} />
            :
            <RiToggleLine style={{ fontSize: 14 }} />
            }
            <span className="OrderItemOptionsButtonText">{ (coupon.active) ? 'Deactiveren' : 'Activeren' }</span>
          </a>

          <a onClick={
            () => {

              setDetailsPre(coupon);

            }
          } className="OrderItemOptionsButton Edit">
            <RiFileShield2Line style={{ fontSize: 14 }} />
            <span className="OrderItemOptionsButtonText">{ (coupon.coupon_used > 0) ? 'Bekijken' : 'Wijzigen' }</span>
          </a>
        </div>

      </div>
    )
  })


  return (
    <div className="SettingsContentContent List">

      <div className="SettingsMessageText ShowMobile">Voorlopig alleen op desktop beschikbaar.</div>

      <div className={`settingsContentReal HideDesktop`}>
        <div className="SettingsContentNavigation">
          <div className="SettingsContentNavigationItem IDSmall">ID</div>
          <div className="SettingsContentNavigationItem Active">Actief</div>
          <div className="SettingsContentNavigationItem Coupon">Coupon</div>
          <div className="SettingsContentNavigationItem Amount">Bedrag</div>
          <div className="SettingsContentNavigationItem Stock">Voorraad</div>
          <div className="SettingsContentNavigationItem Stock">Gebruikt</div>
          <div className="SettingsContentNavigationItem OptionsBig"></div>
        </div>

        <div className="GlobSettingsScroll" ref={scrollView}>

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }


          { (coupons_items.length > 0)
          ?
          coupons_items
          :
          <div className="SettingsMessageText">Geen coupons.</div>
          }
        </div>

        { couponPagination() }
      </div>


      <DetailView modal={modal} setDetailsPre={setDetailsPre}>
      { modalView() }
      </DetailView>

    </div>
  )
}

export default Coupons;
