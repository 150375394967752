import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleLine,

 RiCheckFill,
 RiCloseFill,

 RiToggleLine,
 RiToggleFill
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";

import DetailView from "../../../components/DetailView";


function Carts(props) {
  const {
    token, account,
    setModalPre,
    searchQuery,
    subview_data
  } = props;
  const [ carts, setCarts ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);

  const [ modal, setModal ] = useState();

  const scrollView = useRef();


  const setDetailsPre = (mod) => {

    const timeout = (modal) ? 300 : 0;
    if(modal){
      setModal(modalOld=>{
        return {
          ...modalOld,
          hide: true
        }
      });
    }
    setTimeout( () => {
        setModal(mod);
    }, timeout)

  }


  const fetchCarts = (pageFinal = page, shouldLoad = true) => {

    if(shouldLoad){
      setLoading(true);
    }
    axios.post( config.api.url + 'admin/carts', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(shouldLoad){
        setTimeout( () => {
          setLoading(false);
        }, 1);
      }

      if(success){
        const carts = data.carts;
        const stats = data.stats;


        if(carts){
          setCarts(carts);
        }

        if(shouldLoad){
          scrollView.current?.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/winkelmandjes/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchCarts(page);
  }

  const cartsPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(carts.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/winkelmandjes/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/winkelmandjes/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/winkelmandjes/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }


  const modalView = () => {


    return (
        <div className={`ModalSide`}>
          <div className="ModalSideHeader">
            <div className="ModalSideHeaderTitle">Winkelmandje { 'bekijken' }</div>


            <a onClick={
              () => {
                setDetailsPre()
              }
            } className="ModalSideHeaderClose">
              <RiCloseLine />
            </a>
          </div>

          <div className="ModalSideContent End">

            <div key={ JSON.stringify(modal) }>
              <div className="modalCouponTitle">Item(s)</div>

              { (modal && modal.items) &&
                (modal.items).map( (cart_item, cart_index) => {

                  const { qty, product } = cart_item;
                  const finalPrice = (parseInt(qty) * parseFloat(product.price)).toFixed(2);

                  return (
                    <div className="modalCouponItem End" key={'coup_item_' + cart_index}>
                      <div className="modalCouponItemImageWrapper">
                        <div className="modalCouponItemImage" style={{ backgroundImage: `url(${ (product.image) })` }}/>
                      </div>
                      <div className="modalCouponItemDetails">
                        <div className="modalCouponItemDetailsName">{ product.title }</div>
                        <div className="modalCouponItemDetailsSubWrapper">
                          <div className="modalCouponItemDetailsSub" style={{ backgroundColor: (qty > 1) ? colors.green : '#3b3b3b' }}>
                            <div className="modalCouponItemDetailsSubText">{ qty + 'x' }</div>
                          </div>
                          <div className="modalCouponItemDetailsSubItemText">{ 'SRD ' + (product.price) }</div>
                        </div>
                      </div>
                      <div className="modalCouponItemSub">
                        <div className="modalCouponItemSubPrice"></div>
                        <div className="modalCouponItemSubPriceNew">SRD { (finalPrice) }</div>
                      </div>
                    </div>
                  )
                })
              }
              { (modal) &&
              <div className="modalSideFinal">
                <div className="modalSideFinalPre">Totaal bedrag</div>
                <div className="modalSideFinalAmount">SRD { modal.totals_amount }</div>
              </div>
              }
            </div>

          </div>

          <div className="modalContentOptions LightBorder">
            <a onClick={
              () => {

                setDetailsPre();
              }
            } className="modalContentOptionsButton">Sluiten</a>
          </div>

        </div>
    )
  }



  useEffect( () => {

    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchCarts(page_parsed);
    }else{

    }

  }, []);


  useEffect( () => {

    if(!loading){
      // controller.abort();
      fetchCarts();
    }

  }, [searchQuery]);


  const carts_items = (carts).map( (cart_item, index) => {


    return (
      <div className="OrderItem" key={'cart_item_' + cart_item.id}>
        <div className="OrderItemDetailsText IDSmall">#{ cart_item.id }</div>
        <div className="OrderItemDetailsText Name Bold">{ cart_item.account?.name }</div>
        <div className="OrderItemDetailsText Amount">{ cart_item.items_count }</div>
        <div className="OrderItemDetailsText Amount">SRD { cart_item.totals_amount }</div>
        <div className="OrderItemDetailsText Date">{ cart_item.timestamp_last?.date }</div>
        <div className="OrderItemDetailsText Date">{ cart_item.timestamp?.date }</div>

        <div className="OrderItemOptions">

          <a onClick={
            () => {

              setDetailsPre(cart_item);

            }
          } className="OrderItemOptionsButton Edit">
            <RiFileShield2Line style={{ fontSize: 14 }} />
            <span className="OrderItemOptionsButtonText">{ 'Bekijken' }</span>
          </a>
        </div>

      </div>
    )
  })


  return (
    <div className="SettingsContentContent List">


      <div className="SettingsMessageText ShowMobile">Voorlopig alleen op desktop beschikbaar.</div>

      <div className={`settingsContentReal HideDesktop`}>
        <div className="SettingsContentNavigation">
          <div className="SettingsContentNavigationItem IDSmall">ID</div>
          <div className="SettingsContentNavigationItem Name">Naam</div>
          <div className="SettingsContentNavigationItem Amount">Item(s)</div>
          <div className="SettingsContentNavigationItem Amount">Totaal</div>
          <div className="SettingsContentNavigationItem Date">Aangepast</div>
          <div className="SettingsContentNavigationItem Date">Datum</div>
          <div className="SettingsContentNavigationItem OptionsBig"></div>
        </div>

        <div className="GlobSettingsScroll" ref={scrollView}>

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }

          { (carts_items.length > 0)
          ?
          carts_items
          :
          <div className="SettingsMessageText">Geen winkelmandjes.</div>
          }
        </div>

        { cartsPagination() }
      </div>


      <DetailView modal={modal} setDetailsPre={setDetailsPre}>
      { modalView() }
      </DetailView>

    </div>
  )
}

export default Carts;
