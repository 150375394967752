import config from '../constants/config';
import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';


const DetailView = forwardRef((props, ref) => {
  const { modal, setDetailsPre, width } = props;

  const maxWidth = (width && width > 0) ? width : 525;

  return (

      <div className={`ModalSideOverlay ${ (modal && !modal.hide) ? 'Show' : '' }`}>

      <div className={`ModalSideOverlayBackground`}>
      </div>

      <div className="ModalSideOverlayBackgroundText" onClick={
        () => {

          setDetailsPre();
        }
      }>Klik hier om te sluiten.</div>

      <div className="DetailViewWrapper" style={{ maxWidth: maxWidth }}>
        {props.children}
      </div>

    </div>
  );
});

export default (DetailView);
