import './App.css';
import { CookiesProvider } from 'react-cookie';
import { useCookies } from 'react-cookie';

// HEADER ETC
import Header from './components/Header';
import Footer from './components/Footer';
import Menu from './components/Menu';

// Views
import Home from './views/Home';

import Stores from './views/Stores';

import Blogs from './views/Blogs';
import BlogDetails from './views/BlogDetails';

import CustomerService from './views/CustomerService';
import Cart from './views/Cart';

// Auth
import Login from './views/Auth/Login';
import Account from './views/Account/Settings';

// Products
import Products from './views/Products';
import ProductDetails from './views/ProductDetails';
import AboutUs from './views/AboutUs';
import Vacancies from './views/Vacancies';
import VacancyInfo from './views/VacancyInfo';

// Blanks
import Empty from './views/Empty';
import Blank from './views/Blank';
import Info from './views/Info';

// Modal system ## DEV
import Notifications from './components/Notifications';
import CustomerAI from './components/CustomerAI';


import config from './constants/config';
import "react-tooltip/dist/react-tooltip.css";
import {Route, Link, Routes, useNavigate, useHistory} from 'react-router-dom';
import React, { Animated, useRef, useState, useEffect, useMemo } from 'react';
import {
  RiCloseLine
} from 'react-icons/ri';
import axios from 'axios';
import useScrollBlock from './components/Scroller';
import { DndProvider, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { FacebookProvider } from 'react-facebook';

import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import AuthOneTap from './components/GoogleOneTap';

function App() {
  const [menu, setMenu] = useState(null);
  const [view, setView] = useState('loading');
  const [viewData, setViewData] = useState(null);
  const [viewLoading, setViewLoading] = useState(true);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const notification = useRef();
  const [ categories, setCategories ] = useState([]);
  const [ account, setAccount ] = useState(null);
  const [ cartCount, setCartCount ] = useState(0);
  const [ accountNew, setAccountNew ] = useState(false);
  const [ token, setToken ] = useState(null);
  const [ showAIChat, setShowAIChat ] = useState(false);
  const [ sessionHash, setSessionHash ] = useState('');

  const [ cookies, setCookie, removeCookie ] = useCookies(['account-hash', 'allow-cookies', 'show-ai-chat']);

  const [ blockScroll, allowScroll ] = useScrollBlock();

  const [ modal, setModal ] = useState();
  const [ alert, setAlert ] = useState();

  const [ canStoreCookies, setCanStoreCookies ] = useState(false);
  const [ showCookiesPopup, setShowCookiesPopup ] = useState(false);


  let homepage = ''; // DEV

  function setViewPre(view, closeMenu = true, updateHistory = true){
    let title_cleaned = view.charAt(0).toUpperCase() + view.slice(1);

    view = view.toLowerCase();
    const now = Math.ceil((new Date()).getTime() / 1000);
    // let previous_url = ((window.location.pathname).replace('/da', '')).substring(1);
    let previous_url = ((window.location.pathname).replace('', '')).substring(1);

    if(closeMenu){
      setMenu();
    }

    // config.title.pre + title_cleaned
    if(updateHistory){
      window.history.pushState({"z":"z"}, null, config.homepage + view);
    }
    setViewLoading(true);
    setTimeout( () => {

      setView(view);
      setViewLoading(false);
      setTimeout( () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }, 50);

      // Check fast
      if(previous_url.includes('blog/')){
        previous_url = (viewData && viewData.previous_url) ? viewData.previous_url : 'blogs';
      }

      setViewData({
        previous: previous_url,
        timestamp: now
      });

    }, 150);
  }

  const setShouldUpdatePre = () => {
    setShouldUpdate(!shouldUpdate)
  }

  const setCartCountPre = (countNew) => {

    const newCount = cartCount + countNew;

    setCartCount(newCount);
  }

  const setModalPre = (modalData = null) => {

    setModal(modalData);

    if(!modalData){
      // allowScroll();
    }else{
      // blockScroll();
    }
  }

  const setAlertPre = (alertData = null) => {

    setAlert(alertData);

    if(!alertData){
      // allowScroll();
    }else{
      // blockScroll();
    }
  }

  const setAccountPre = (account, token) => {

    if(token == ''){
      removeCookie('account-hash', { path: '/' });
    }else{

      if(canStoreCookies){
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1);
        setCookie('account-hash', token, { path: '/', expires: expirationDate });
      }
    }
    setToken(token);


    if(account && account.notifications_new > 0 && !accountNew){
      setAccountNew(true);
    }else if(account && account.notifications_new == 0 && accountNew){
      setAccountNew(false);
    }

    setAccount(account);
  }

  const getAd = (type = '', c2a = null) => {

    axios.post( config.api.url + 'ad', {
      type: type
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){
        const ad = data.ad;


        if(ad && c2a && typeof c2a == 'function'){
          c2a(ad)
        }

      }

    })
    .catch(function (error) {

    });

  }

  const fetchCategories = () => {

    axios.post( config.api.url + 'categories', {
      
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){
        const categories = data.categories;

        if(categories){
          setCategories(categories);
        }

      }

    })
    .catch(function (error) {

    });

  }


  const accountLogout = () => {

    if(token != ''){
      googleLogout();
      axios.post( config.api.url + 'auth/logout', {
        hash: token
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key,
            'session': sessionHash
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        if(success){

          setAccountPre(null, '');

        }else{

        }


      })
      .catch(function (error) {

          setAccountPre(null, '');

      });
    }

  }


  const createSession = (c2a = null) => {

    axios.post( config.api.url + 'auth/session/create', {
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;
      console.log(json.data)

      if(success){
        const hash = data.hash;
        
        if(hash != ''){
          setSessionHash(hash);
        }

        if(c2a && typeof c2a == 'function'){
          c2a();
        }

      }else{

      }


    })
    .catch(function (error) {


    });

  }

  const accountInfo = (hash = token) => {

    if(hash != ''){
      axios.post( config.api.url + 'auth/fetch', {
        hash: hash
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key,
            'session': sessionHash
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        if(success){
          const account = data.account;
          const token = data.token;
          const cart_count = account.cart_count;

          if(cart_count && cart_count > 0){
            setCartCount(cart_count);
          }

          if(account && token != ''){
            setAccountPre(account, token);
          }

        }else{

        }


      })
      .catch(function (error) {


      });
    }

  }

  useEffect(() => {
    const handlePopState = (event) => {


      // window.history.pushState(null, null, window.location.pathname);


      // Display an alert message
      const pathName = (window.location.pathname).replace(homepage + '/', '/').replace('/', '');

      if(pathName != '/' && pathName != '' && pathName != view){

        setViewPre(pathName, true, false)
      }else{
        setViewPre('home')
      }

    };

    // Add a popstate event listener to intercept the back button
    window.addEventListener('popstate', handlePopState);

    const pathName = (window.location.pathname).replace(homepage + '/', '/').replace('/', '');

    if(pathName != '/' && pathName != '' && pathName != view){

      setViewPre(pathName)
    }else{
      setViewPre('home')
    }

    createSession(() => {


      // Check account
      if(cookies['account-hash']){
        const hash = cookies['account-hash'];

        accountInfo(hash);
      }

      fetchCategories();
    });


    if(cookies['allow-cookies']){
      const allow = cookies['allow-cookies'];

      setCanStoreCookies( (allow == 'true') ? true : false );

    }else{
      setTimeout( () => {
        setShowCookiesPopup(true)
      }, 1000);
    }

      const showChat = cookies['show-ai-chat'];
      setShowAIChat( (showChat && showChat == 'false') ? false : true );


    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };


  }, []);

  const setShowCookiesPopupPre = (show) => {
    setShowCookiesPopup(show);
  }

  const allowCookies = () => {

    // SET COOKIE
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 1);
    setCookie('allow-cookies', 'true', { path: '/', expires: expirationDate });

    if(token != ''){
      setCookie('account-hash', token, { path: '/', expires: expirationDate });
    }

    setCanStoreCookies(true);
    setShowCookiesPopup(false);
  }
  const denyCookies = () => {

    // SET COOKIE
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 1);
    setCookie('allow-cookies', 'false', { path: '/', expires: expirationDate });

    if(token != ''){
      removeCookie('account-hash', { path: '/' });
    }

    setCanStoreCookies(false);
    setShowCookiesPopup(false);
  }


  const loginGoogleFull = (id, email, name, picture = '') => {

    // setLoading(true);
    axios.post( config.api.url + 'auth/login/google', {
      id: id,
      email: email,
      name: name,
      avatar: picture
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){
        const account = data.account;
        const token = data.token;

        if(viewData && viewData.previous){
          setViewPre(viewData.previous);
        }
        if(account && token != ''){
          setAccountPre(account, token);

          const now = Math.ceil((new Date()).getTime());
        }

      }else{
        setModalPre('Oops', response);
      }

      // setLoading(false);
    })
    .catch(function (error) {

      // setLoading(false);
    });


  }

  const getUserInfo = async (tokenResponse) => {

    // setLoading(true);
    axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
    })
    .then((json) => {
      const userInfo = json.data;


      if(userInfo){
        const g_id = userInfo.sub;
        const name = userInfo.name;
        const email = userInfo.email;
        const picture = userInfo.picture;

        if(g_id != ''){

          loginGoogleFull(g_id, email, name, picture);

        }else{
          // setLoading(false);
          setModalPre('Oops', 'Iets is verkeerd gegaan, probeer het nogmaals.');
        }

      }else{
        // setLoading(false);
        setModalPre('Oops', 'Iets is verkeerd gegaan, probeer het nogmaals.');
      }

    })
    .catch(function (error) {

      // setLoading(false);

    });

  };

  const ContentComponent = (childProps) => {
    let view_parsed = view;
    let subview = '';
    if((view).includes('categorie/')){
      view_parsed = 'producten';
      subview = view.replace('categorie/', '');
    }else if((view).includes('account/')){
      view_parsed = 'account';
      subview = view.replace('account/', '').replace('account', '');
    }else if((view).includes('producten/')){

    }else if((view).includes('blogs/')){
      view_parsed = 'blogs';
      subview = view.replace('blogs/', '').replace('blogs', '');
    }else if((view).includes('blog/')){
      view_parsed = 'blog';
      subview = view.replace('blog/', '').replace('blog', '');
    }else if((view).includes('product/')){
      view_parsed = 'product';
      subview = view.replace('product/', '');
    }else if((view).includes('winkels/')){
      view_parsed = 'winkels';
      subview = view.replace('winkels/', '').replace('winkels', '');
    }else if((view).includes('info/')){
      view_parsed = 'info';
      subview = view.replace('info/', '').replace('info', '');
    }else if((view).includes('vacatures/')){
      view_parsed = 'vacatures';
      subview = view.replace('vacatures/', '').replace('vacatures', '');
    }else if(view == 'contact'){
      view_parsed = 'over-ons';
      subview = 'contact';
    }

    switch(view_parsed){

      case "home":
        return (
          <Home
            {... childProps}
            categories={categories}
            setViewPre={setViewPre}
            getAd={getAd}
            account={account}
            token={token}
            setModalPre={setModalPre}
            sessionHash={sessionHash}
          />
        )
      break;

      case "account":

        let showForced = false;
        if((subview).includes('wachtwoord/')){
          showForced = true;
        }


        if(account && !showForced){
          return (
            <Account
              {... childProps}
              account={account}
              token={token}
              setAccountPre={setAccountPre}
              setViewPre={setViewPre}
              viewData={viewData}
              subview={subview}
              accountLogout={accountLogout}
              accountInfo={accountInfo}

              setAccountNew={setAccountNew}

              setShouldUpdatePre={setShouldUpdatePre}

              modal={modal}
              setModalPre={setModalPre}
              setAlertPre={setAlertPre}
              sessionHash={sessionHash}
            />
          );
        }else{
          return (
            <Login
              {... childProps}
              setAccountPre={setAccountPre}
              setViewPre={setViewPre}
              viewData={viewData}
              subview={subview}
              setModalPre={setModalPre}
              loginGoogleFull={loginGoogleFull}
              getUserInfo={getUserInfo}
              sessionHash={sessionHash}
            />
          )
        }
      break;

      case "producten":
        return (
          <Products
            {... childProps}
            categories={categories}
            subview={subview}
            setViewPre={setViewPre}
            account={account}
            token={token}
            setModalPre={setModalPre}
            sessionHash={sessionHash}
          />
        )
      break;

      case "product":
        return (
          <ProductDetails
            {... childProps}
            categories={categories}
            viewData={viewData}
            subview={subview}
            setViewPre={setViewPre}
            setModalPre={setModalPre}
            account={account}
            token={token}
            accountInfo={accountInfo}
            setCartCountPre={setCartCountPre}
            sessionHash={sessionHash}
          />
        )
      break;

      case "winkels":
        return (
          <Stores
            {... childProps}
            subview={subview}
            account={account}
            token={token}
            sessionHash={sessionHash}
          />
        )
      break;

      case "blogs":
        return (
          <Blogs
            {... childProps}
            subview={subview}
            setViewPre={setViewPre}
            account={account}
            token={token}
            sessionHash={sessionHash}
          />
        )
      break;

      case "blog":
        return (
          <BlogDetails
            {... childProps}
            viewData={viewData}
            subview={subview}
            setViewPre={setViewPre}
            account={account}
            token={token}
            sessionHash={sessionHash}
          />
        )
      break;

      case "klantenservice":
        return (
          <CustomerService
            {... childProps}
            subview={subview}
            account={account}
            token={token}
            setModalPre={setModalPre}
            setViewPre={setViewPre}
            sessionHash={sessionHash}
          />
        )
      break;

      case "winkelmandje":
        return (
          <Cart
            {... childProps}
            categories={categories}
            viewData={viewData}
            subview={subview}
            setViewPre={setViewPre}
            account={account}
            token={token}
            setModalPre={setModalPre}
            setCartCount={setCartCount}
            setCartCountPre={setCartCountPre}
            sessionHash={sessionHash}
          />
        )
      break;

      case "info":
        return (
          <Info
            {... childProps}
            viewData={viewData}
            subview={subview}
            setViewPre={setViewPre}
            account={account}
            token={token}
            sessionHash={sessionHash}
          />
        )
      break;

      case "over-ons":
        return (
          <AboutUs
            {... childProps}
            viewData={viewData}
            subview={subview}
            setViewPre={setViewPre}
            setModalPre={setModalPre}
            account={account}
            token={token}
            sessionHash={sessionHash}
          />
        )
      break;

      case "vacatures":

        if(subview != ""){

          return (
            <VacancyInfo
              {... childProps}
              viewData={viewData}
              subview={subview}
              setViewPre={setViewPre}
              account={account}
              token={token}
              setModalPre={setModalPre}
              sessionHash={sessionHash}
            />
          )
        }else{

          return (
            <Vacancies
              {... childProps}
              viewData={viewData}
              subview={subview}
              setViewPre={setViewPre}
              account={account}
              token={token}
              sessionHash={sessionHash}
            />
          )
        }
      break;

      case "loading":
        return false;
      break;

      default:
        return (
          <Empty

          />
        )
      break;
    }

    return false;
  }

  const ModalView = (props) => {

    const modalContent = (modal && modal.content) ? modal.content : null;

    return (
      <div className={`modalOverlay ${(modal) ? 'Show': ''}`} onClick={
        () => {

        }
      }>

        <div className={`modalView ${ (modal) ? 'Show' : ''}`} onClick={
          (event) => {
            event.preventDefault();
          }
        }>
          { (modal) &&
          <div className="modalHeader">
            <div className="modalHeaderTitleWrapper">
              <div className="modalHeaderTitle">{ (modal.title) && modal.title }</div>
              { (modal.subtitle && modal.subtitle != '') &&
              <div className="modalHeaderSubtitle">{ modal.subtitle }</div>
              }
            </div>
            <a onClick={
              () => {
                setModalPre();

                if(modal.close && typeof modal.close.action == 'function'){
                  modal.close.action();
                }
              }
            } className="modalClose">
              <RiCloseLine />
            </a>
          </div>
          }
          { (modal && modalContent) &&
          <div className="modalContent">

            { modalContent }

          </div>
          }
        </div>

      </div>
    );
  }
  const AlertView = (props) => {

    const alertContent = (alert && alert.content) ? alert.content : null;

    return (
      <div className={`modalOverlay Front ${(alert) ? 'Show': ''}`} onClick={
        () => {

        }
      }>

        <div className={`modalView ${ (alert) ? 'Show' : ''}`} onClick={
          (event) => {
            event.preventDefault();
          }
        }>
          { (alert) &&
          <div className="modalHeader">
            <div className="modalHeaderTitleWrapper">
              <div className="modalHeaderTitle">{ (alert.title) && alert.title }</div>
              { (alert.subtitle && alert.subtitle != '') &&
              <div className="modalHeaderSubtitle">{ alert.subtitle }</div>
              }
            </div>
            <a onClick={
              () => {
                setAlertPre();

                if(alert.close && typeof alert.close.action == 'function'){
                  alert.close.action();
                }
              }
            } className="modalClose">
              <RiCloseLine />
            </a>
          </div>
          }
          { (alert && alertContent) &&
          <div className="modalContent">

            { alertContent }

          </div>
          }
        </div>

      </div>
    );
  }
  const CookiesPopupView = (props) => {


    return (
      <div className={`popupOverlay ${ (showCookiesPopup) ? 'Show' : '' }`} onClick={
        () => {

        }
      }>

        <div className={`popupView ${ (showCookiesPopup) ? 'Show' : '' }`} onClick={
          (event) => {
            event.preventDefault();
          }
        }>
          <div className="popupViewHeader">
            <img src={ config.homepage + 'data/icons/cookies.png' } draggable={false} />
          </div>
          <div className="popupViewContent">
            <div className="popupViewContentTitle">Koekjes!</div>
            <div className="popupViewContentSubtitle">Onze website gebruikt cookies om je een heerlijke online ervaring te bezorgen. Smakelijk surfen!</div>
          </div>
          <div className="popupViewOptions">
            <div className="popupViewOptionsButton C2a" onClick={
              () => {
                allowCookies();
              }
            }>Ik ga akkoord</div>
            <div className="popupViewOptionsButton" onClick={
              () => {
                denyCookies();
              }
            }>Liever niet</div>
          </div>
        </div>

      </div>
    );
  }
  const contentComponentMemo = useMemo(ContentComponent);
  const contentModalView = useMemo(ModalView);
  const contentAlertView = useMemo(AlertView);
  const cookiesPopupView = useMemo(CookiesPopupView);


  return (
    <CookiesProvider>
      <FacebookProvider appId={config.facebook.login.appId}>
        <GoogleOAuthProvider clientId={config.google.login.clientId}>
          <DndProvider backend={HTML5Backend}>
            <div className={`App`}>



              <Header
                key={shouldUpdate}
                menu={menu}
                setMenu={setMenu}
                view={view}
                account={account}
                setViewPre={setViewPre}
                setAccountPre={setAccountPre}
                accountLogout={accountLogout}
                cartCount={cartCount}
                accountNew={accountNew}
                setShowCookiesPopupPre={setShowCookiesPopupPre}
              />

              <Menu
                menu={menu}
                categories={categories}
                setMenu={setMenu}
                view={view}
                setViewPre={setViewPre}
              />

              { (view != 'loading') &&
              <div className={`AppContent ${ (viewLoading) ? 'Loading' : ''}`}>

                { contentComponentMemo }

                <Footer
                  token={token}
                  account={account}
                  setAccount={setAccount}
                  menu={menu}
                  categories={categories}
                  setMenu={setMenu}
                  view={view}
                  setViewPre={setViewPre}
                  setModalPre={setModalPre}
                />


              </div>
              }

              { contentModalView }
              { contentAlertView }
              { cookiesPopupView }

              

            </div>
          </DndProvider>
        </GoogleOAuthProvider>
      </FacebookProvider>
    </CookiesProvider>
  );
}

export default React.memo(App);
