import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleLine,

 RiFileChartLine
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";


function NewsletterEmails(props) {
  const {
    token, account,
    setModalPre,
    searchQuery,
    subview_data,
    setSettingsHeaderPre
  } = props;
  const [ emails, setEmails ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);

  const exportNewsletterEmails = () => {

    setLoading(true);
    axios.post( config.api.url + 'admin/newsletter/export', {
      hash: token
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      setTimeout( () => {
        setLoading(false);
      }, 1);

      if(success){
        const file = data.file;

        if(file){
          window.location = (file);
        }

      }else{

        setTimeout( () => {
          setModalPre({
            title: 'Oops.',
            subtitle: response
          })
        }, 150);
      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }

  const fetchEmails = (pageFinal = page) => {

    setLoading(true);
    axios.post( config.api.url + 'admin/newsletter/emails', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      setTimeout( () => {
        setLoading(false);
      }, 1);


      if(success){
        const emails = data.emails;
        const stats = data.stats;

        if(emails){
          setEmails(emails);
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/nieuwsletter/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchEmails(page);
  }

  const emailsPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(emails.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/nieuwsletter/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/nieuwsletter/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/nieuwsletter/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }

  useEffect( () => {


    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchEmails(page_parsed);
    }else{

    }


    setSettingsHeaderPre(
      <div className="SettingsContentContentNavigation HideDesktop">
        <a onClick={
          () => {

            setModalPre({
              title: 'Exporteren.',
              subtitle: 'Alle e-mails exporteren als CSV.',
              content: (
                <>
                  <div className="modalContentOptions NoBorder">
                    <a onClick={
                      () => {

                        exportNewsletterEmails();
                        setModalPre();
                      }
                    } className="modalContentOptionsButton C2a AccentHover">Exporteren</a>
                    <a onClick={
                      () => {

                        setModalPre();
                      }
                    } className="modalContentOptionsButton">Annuleren</a>
                  </div>
                </>
              )
            })

          }
        } className="OrderItemOptionsButton AccentHover">
          <RiFileChartLine style={{ fontSize: 14 }} />
          <span className="OrderItemOptionsButtonText">Exporteren</span>
        </a>
      </div>
    )

  }, []);



  useEffect( () => {

    if(!loading){
      // controller.abort();
      fetchEmails();
    }

  }, [searchQuery]);


  const emails_items = (emails).map( (email_item, index) => {

    return (
      <div className="OrderItem" key={'email_item_' + index}>
        <div className="OrderItemDetailsText IDSmall">#{ email_item.id }</div>
        <div className="OrderItemDetailsText Email">{ email_item.email }</div>
        <div className="OrderItemDetailsText Date">{ email_item.timestamp?.date }</div>
        <div className="OrderItemOptions"></div>
      </div>
    )
  })


  return (
    <div className="SettingsContentContent List">


      <div className="SettingsMessageText ShowMobile">Voorlopig alleen op desktop beschikbaar.</div>

      <div className={`settingsContentReal HideDesktop`}>
        <div className="SettingsContentNavigation">
          <div className="SettingsContentNavigationItem IDSmall">ID</div>
          <div className="SettingsContentNavigationItem Email">E-mail</div>
          <div className="SettingsContentNavigationItem Date">Datum</div>
          <div className="SettingsContentNavigationItem OptionsBig"></div>
        </div>

        <div className="GlobSettingsScroll">

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }

          { (emails_items.length > 0)
          ?
          emails_items
          :
          <div className="SettingsMessageText">Geen e-mail adressen.</div>
          }
        </div>

        { emailsPagination() }
      </div>

    </div>
  )
}

export default NewsletterEmails;
