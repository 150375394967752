import '../styles/AboutUs.css';
import config from '../constants/config';
import React, { useRef, useState, useEffect } from 'react';
import {
  RiArrowRightSLine,
  RiPhoneFill,
  RiSendPlane2Fill,
  RiFacebookFill,
  RiWhatsappFill,
  RiInstagramFill,
  RiBriefcaseFill,
  RiCloseFill,
  RiCustomerServiceFill,
  RiStore3Fill
  } from "react-icons/ri";
import {
  FaTiktok
} from "react-icons/fa";
import axios from 'axios';
import loadingAnimation from '../animations/loading_circle.json';
import Lottie from "lottie-react";


function AboutUs(props) {
  const { subview,
    view, setViewPre,
    viewData,
    setModalPre
   } = props;
   const [ loading, setLoading ] = useState(false);
   const [ page, setPage ] = useState();
   const [ blocks, setBlocks ] = useState([]);
   const [ form, setForm ] = useState();
   const [ contactMode, setContactMode ] = useState(false);
   const [isReasonOpen, setIsReasonOpen] = useState(false);
   const [selectedReason, setSelectedReason] = useState("");

   const reasons = [
    'Klacht',
    'Mijn Account',
    'Mijn Bestelling',
    'Mijn Betaling',
    'Mijn Bezorging'
   ]

   const inputFirstName = useRef();
   const inputLastName = useRef();
   const inputPhone = useRef();
   const inputMessage = useRef();
   const inputEmail = useRef();

   const sendMessage = () => {

     setLoading(true);
     axios.post( config.api.url + 'contact/send', {
       first_name: inputFirstName.current?.value,
       last_name: inputLastName.current?.value,
       phone: inputPhone.current?.value,
       email: inputEmail.current?.value,
       reason: selectedReason,
       message: inputMessage.current?.value
     }, {
         crossdomain: true,
         headers: {
           'api': config.api.key,
           'session': props.sessionHash
         }
     })
     .then((json) => {
       const { success, response, data } = json.data;

       if(success){

         setModalPre({
           title: 'Yay.',
           subtitle: response
         })
         setViewPre('over-ons');

         inputFirstName.current.value = '';
         inputLastName.current.value = '';
         inputPhone.current.value = '';
         inputEmail.current.value = '';
         setSelectedReason("...")
         inputMessage.current.value = '';

       }else{
         setModalPre({
           title: 'Oops.',
           subtitle: response
         })
       }

       setTimeout( () => {
         setLoading(false);
       }, 1);
     })
     .catch(function (error) {
       setLoading(false);
     });

   }

   const contentView = () => {

    let blockContent = [];
     const blockItems = (blocks).map( (block, index) => {

       switch(block.type){
         case "image_normal":
           if(block.data != ''){
              const images = (block.data.split(',')).map( (image, ind) => {

                return (
                  <img src={ image } draggable={'false'} key={'image' + ind} />
                )
              })

              blockContent.push(
                <div className="BlockItemView">
                  <div className="BlockItemImage Normal"  key={'block_'+index}>
                    { images }
                  </div>
                </div>
              )
           }
         break;
         case "image_full":
           if(block.data != ''){
              const images = (block.data.split(',')).map( (image, ind) => {

                return (
                  <img src={ image } draggable={'false'} key={'image' + ind} />
                )
              })

              blockContent.push(
                <div className="BlockItemView">
                  <div className="BlockItemImage Full"  key={'block_'+index}>
                    { images }
                  </div>
                </div>
              )
           }
         break;
         case "text":
           if(block.data != ''){
              blockContent.push(
                <div className="BlockItemView">
                  <div className="BlockItemText" dangerouslySetInnerHTML={{__html: block.data }} key={'block_'+index} />
                </div>
              )
           }
         break;
       }
     })

     if(blockContent.length > 0){
       return (
         <div className={`BlockItemWrapper`} onClick={
           () => {
             if(contactMode){
               setViewPre('over-ons')
             }
           }
         }>
          { blockContent }
         </div>
       )
     }

     return false;
   }

   useEffect( () => {

     const fetchInfo = () => {
       setLoading(true);
       axios.post( config.api.url + 'page', {
         page: 'over-ons',
       }, {
           crossdomain: true,
           headers: {
             'api': config.api.key,
             'session': props.sessionHash
           }
       })
       .then((json) => {
         const { success, response, data } = json.data;

         if(success){
           const pageObject = data.page;
           const blocksObjects = data.blocks;

           if(blocksObjects){
             setBlocks(blocksObjects);
           }
           if(pageObject){
             setPage(pageObject);
           }

         }

         setLoading(false);

       })
       .catch(function (error) {

         setLoading(false);
       });
     }

      fetchInfo();

   }, [])


   useEffect( () => {


      if(subview == 'contact' && !contactMode){
        setContactMode(true);
      }else if(contactMode){
        setContactMode(false);
      }


   }, [subview])

  return (
    <div className={`AboutUs ${ (contactMode) ? 'ContactMode' : '' }`}>
      <div className="FixedWidth">
        <div className="AboutUsSide">
          <div className="AboutUsSideTitleView HideDesktop">
            <div className="AboutUsSideTitle">EEN BEETJE<br/>OVER ONS.</div>
          </div>
          <div className="AboutUsSideOptions">
            <div className="AboutUsSideOptionsTitle">Meer informatie nodig?</div>

            <div onClick={
                () => {
                  if(contactMode){
                    setViewPre('over-ons');
                  }else{
                    setViewPre('contact');
                  }
                }
              } className={`AboutUsSideOption AccentHover ${ (contactMode) ? 'Close' : '' }`}>
              <div className="AboutUsSideOptionText">{ (contactMode) ? 'Annuleren' : 'Stuur ons een bericht' }</div>
              { (!contactMode && (inputFirstName.current?.value != '' || inputLastName.current?.value != '' || inputPhone.current?.value != '' || inputMessage.current?.value != '')) &&
              <div className="AboutUsSideOptionStatus">DOORGAAN</div>
              }
              { (!contactMode)
              ?
              <RiSendPlane2Fill className="AboutUsSideOptionIcon" style={{ fontSize: 13 }} />
              :
              <RiCloseFill className="AboutUsSideOptionIcon" style={{ fontSize: 13 }} />
              }
            </div>

            <div className="AboutUsSideOptionSocials">
              <a target="_blank" href="https://www.facebook.com/dasuriname" className="AboutUsSideOptionSocial Fb" id="socials-fb">
                <div className="AboutUsSideOptionSocialText">Volg ons op Facebook</div>
                <RiFacebookFill className="AboutUsSideOptionSocialIcon" />
              </a>

              <a target="_blank" href="https://www.instagram.com/dadrogistsuriname" className="AboutUsSideOptionSocial Ig" id="socials-ig">
                <div className="AboutUsSideOptionSocialText">Volg ons op Instagram</div>
                <RiInstagramFill className="AboutUsSideOptionSocialIcon" />
              </a>

              <a target="_blank" href="https://www.tiktok.com/@da_suriname" className="AboutUsSideOptionSocial Ti" id="socials-ti">
                <div className="AboutUsSideOptionSocialText">Volg ons op TikTok</div>
                <FaTiktok className="AboutUsSideOptionSocialIcon" />
              </a>
{/* 
              <a target="_blank" href="" className="AboutUsSideOptionSocial Wa" id="socials-wa">
                <div className="AboutUsSideOptionSocialText">Stuur ons een bericht op WhatsApp</div>
                <RiWhatsappFill className="AboutUsSideOptionSocialIcon" />
              </a> */}
            </div>

            <div className="AboutUsSideOptionOthers">

              <a href={ config.homepage + 'winkels' } onClick={
                (event) => {

                  setViewPre('winkels')
                  event.preventDefault();
                }
              } className="AboutUsSideOptionOther">
                <div className="AboutUsSideOptionSocialText">Op zoek naar een van onze filialen?</div>
                <RiStore3Fill className="AboutUsSideOptionSocialIcon" />
              </a>

              <a href={ config.homepage + 'winkels' } onClick={
                (event) => {

                  setViewPre('klantenservice')
                  event.preventDefault();
                }
              } className="AboutUsSideOptionOther">
                <div className="AboutUsSideOptionSocialText">Antwoorden op algemene vragen</div>
                <RiCustomerServiceFill className="AboutUsSideOptionSocialIcon" />
              </a>
            </div>


          </div>

          <div className="AboutSideFooterView">
            <div className="AboutUsSignature">DA Drogisterij</div>
            <div className="AboutSideFooterText">© { new Date().getFullYear() } DA | Voel je gezond!</div>
          </div>
        </div>

        <div className="AboutUsContent">
          <div className="VacancyDetailsApplyView">

            <div className="VacancyDetailsApplyHeader">
              <RiBriefcaseFill />
              <div className="VacancyDetailsApplyHeaderTitle">Stuur ons een bericht</div>
            </div>

            <div className="VacancyFormItems">

              <div className="VacancyFormItemTwin">
                <div className="VacancyFormItem">
                  <div className="VacancyFormPre">Voornaam</div>
                  <div className="VacancyFormInputView">
                    <input
                      ref={inputFirstName}
                      defaultValue={ (form && form.first_name) ? form.first_name : '' }
                      placeholder={'...'}
                      className={`VacancyFormInput`}
                      onKeyUp={
                        (event) => {
                          const key = event.key;

                          switch(key){
                            case "Enter":

                            break;
                          }
                        }
                      }
                    />
                  </div>
                </div>
                <div className="VacancyFormItem">
                  <div className="VacancyFormPre">Achternaam</div>
                  <div className="VacancyFormInputView">
                    <input
                      ref={inputLastName}
                      defaultValue={ (form && form.last_name) ? form.last_name : '' }
                      placeholder={'...'}
                      className={`VacancyFormInput`}
                      onKeyUp={
                        (event) => {
                          const key = event.key;

                          switch(key){
                            case "Enter":

                            break;
                          }
                        }
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="VacancyFormItemTwin">
                <div className="VacancyFormItem">
                  <div className="VacancyFormPre">Telefoonnummer</div>
                  <div className="VacancyFormInputView">
                    <input
                      ref={inputPhone}
                      defaultValue={ (form && form.phone) ? form.phone : '' }
                      placeholder={'...'}
                      className={`VacancyFormInput`}
                      onKeyUp={
                        (event) => {
                          const key = event.key;

                          switch(key){
                            case "Enter":

                            break;
                          }
                        }
                      }
                    />
                  </div>
                </div>

              </div>

              <div className="VacancyFormItemTwin">
                <div className="VacancyFormItem">
                  <div className="VacancyFormPre">Email</div>
                  <div className="VacancyFormInputView">
                    <input
                      ref={inputEmail}
                      defaultValue={ (form && form.email) ? form.email : '' }
                      placeholder={'...'}
                      className={`VacancyFormInput`}
                      onKeyUp={
                        (event) => {
                          const key = event.key;

                          switch(key){
                            case "Enter":

                            break;
                          }
                        }
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="VacancyFormItemTwin">
                <div className="VacancyFormItem">
                  <div className="VacancyFormPre">Waar gaat je vraag over?</div>
                  <div className="VacancyFormInputView" style={{flexDirection: 'column'}}>
                    <div className=''>

                      <div className='VacancyFormInput Dropdown' onClick={() => {
                        setIsReasonOpen(!isReasonOpen)
                      }}>{(selectedReason != '') ? selectedReason : "Selecteer een keuze..."}</div>
                      
                      
                      { (isReasonOpen) &&
                      <div className='VacancyFormDropdownContainer'>
                      {
                        reasons.map((reason, index) => (
                          <div className='ContactFormDropdownOption' onClick={() => {
                            setSelectedReason(reason)
                            setIsReasonOpen(false)

                          }}>{reason}</div>
                        ))
                      }
                      </div>
                      }
                    </div>
                  </div>
                </div>

              </div>

              <div className="VacancyFormItem">
                <div className="VacancyFormPre">Bericht</div>
                <div className="VacancyFormInputView">
                  <textarea
                    ref={inputMessage}
                    rows={6}
                    placeholder={'...'}
                    className={`VacancyFormTextarea`}
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                        switch(key){
                          case "Enter":

                          break;
                        }
                      }
                    }
                  >{ (form && form.message) ? form.message : '' }</textarea>
                </div>
              </div>

            </div>

            <div className="VacancyFormOptions">
              <a onClick={
                (event) => {

                  sendMessage();

                  event.preventDefault();
                }
              } className="VacancyFormOptionsButton AccentHover">Verzenden</a>
                <a onClick={
                  (event) => {

                    setViewPre('over-ons');

                    event.preventDefault();
                  }
                } className="VacancyFormOptionsButton Back">Annuleren</a>
            </div>


          </div>

          <div className="AboutUsSideTitleView ShowMobile">
            <div className="AboutUsSideTitle">EEN BEETJE<br/>OVER ONS.</div>
          </div>

          { (loading)
          ?
          <div className="LoginLoadingOverlay Inline">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          :
          contentView()
          }
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
