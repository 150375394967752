import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,
 RiCheckDoubleLine,

 RiAddCircleLine,

 RiCheckFill,
 RiCloseFill,

 RiImage2Fill,
 RiFileTextFill,

 RiToggleLine,
 RiToggleFill
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";
import Resizer from 'react-image-file-resizer';

import DetailView from "../../../components/DetailView";
import DropDown from "../../../components/DropDown";

function Applicants(props) {
  const {
    token, account,
    setModalPre,
    searchQuery,
    subview_data,
    fetchSettings
  } = props;
  const [ applicants, setApplicants ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);
  const [ modal, setModal ] = useState();
  const scrollView = useRef();


  const setDetailsPre = (mod) => {


      const timeout = (modal) ? 300 : 0;
      if(modal){
        setModal(modalOld=>{
          return {
            ...modalOld,
            hide: true
          }
        });
      }
      setTimeout( () => {
          setModal(mod);
      }, timeout)

  }

  const fetchApplicants = (pageFinal = page, shouldLoad = true) => {

    if(shouldLoad){
      setLoading(true);
    }
    axios.post( config.api.url + 'admin/applicants', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(shouldLoad){
        setTimeout( () => {
          setLoading(false);
        }, 1);
      }


      if(success){
        const applicants = data.applicants;
        const stats = data.stats;


        if(applicants){
          setApplicants(applicants);
        }

        if(shouldLoad){
          scrollView.current?.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }

  const toggleApplicantHandled = (application_id = 0, applicant_index) => {

    if(application_id > 0){

      applicants[applicant_index].handled = !applicants[applicant_index].handled;
      if(modal){
        modal.handled = applicants[applicant_index].handled;
      }

      // setLoading(true);
      axios.post( config.api.url + 'admin/applicants/toggle', {
        hash: token,
        application_id: application_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        // setTimeout( () => {
        //   setLoading(false);
        // }, 1);


        if(success){
          fetchSettings();
          fetchApplicants(page, false);
        }

      })
      .catch(function (error) {
        // setLoading(false);
      });

    }
  }

  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/sollicitanten/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchApplicants(page);
  }

  const applicantsPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(applicants.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/sollicitanten/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/sollicitanten/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/sollicitanten/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }


  const modalView = () => {

    return (
        <div className={`ModalSide`}>
          <div className="ModalSideHeader">
            <div className="ModalSideHeaderTitle">Sollicitatie { 'bekijken' }</div>

            <a onClick={
              () => {
                setDetailsPre()
              }
            } className="ModalSideHeaderClose">
              <RiCloseLine />
            </a>
          </div>

          <div className="ModalSideContent">

            { (modal) &&
            <div className="modalAccountView Account">

              <div className="modalAccountDetailsWrapper">
                <div className="modalAccountAvatar" style={{ backgroundImage: `url(${ (modal.image) })` }} />
                <div className="modalAccountDetails">
                  <div className="modalAccountDetailsName">{ modal.name }</div>
                  <div className="modalAccountDetailsPhone">{ modal.phone }</div>
                  <div className="modalAccountDetailsPhone">{ modal.email }</div>
                </div>
              </div>

            </div>
            }

            { (modal && modal.vacancy != '') &&
            <div className="ModalSideVacancy">
              <div className="ModalSideVacancyText">{ modal.vacancy }</div>
            </div>
            }


            { (modal && modal.cv) &&
            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">CV</div>
                <div className="ModalSideContentInputTextNormal">
                  <div className="VacancyFormItemFile">
                    <div className={`VacancyFormItemFileIcon Selected`}>
                      <RiFileTextFill />
                    </div>
                    <div className="VacancyFormItemFileTitle">{ modal.cv.name }</div>
                    <a href={modal.cv.url} target={'_blank'} className="VacancyFormItemFileButton">{ 'Bekijken' }</a>
                  </div>
                </div>
              </div>
            </div>
            }


            { (modal) &&
            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Motivatie</div>
                <div className="ModalSideContentInputTextNormal Padded">{ modal.motivation }</div>
              </div>
            </div>
            }



          </div>

          <div className="modalContentOptions LightBorder">
            <a onClick={
              () => {

                setDetailsPre();
              }
            } className="modalContentOptionsButton">Sluiten</a>
          </div>

        </div>
    )
  }



  useEffect( () => {

    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchApplicants(page_parsed);
    }else{

    }


  }, []);



  useEffect( () => {

    if(!loading){
      // controller.abort();
      fetchApplicants();
    }

  }, [searchQuery]);


  const applicants_items = (applicants).map( (applicant, index) => {

    return (
      <div className="OrderItem" key={'applicant_item_' + applicant.id}>
        <div className="OrderItemDetailsText IDSmall">#{ applicant.id }</div>
        <div className="OrderItemDetailsText ActiveLarge">
          <span className={`OrderItemDetailsTextOption Circle ${ (applicant.handled) ? 'Success' : 'Error' }`}>
            { (applicant.handled)
            ?
            <RiCheckFill style={{ fontSize: 12 }} />
            :
            <RiCloseFill style={{ fontSize: 12 }} />
            }
          </span>
        </div>
        <div className="OrderItemDetailsText EmailFixed Bold">{ applicant.vacancy }</div>
        <div className="OrderItemDetailsText Name">{ applicant.name }</div>
        <div className="OrderItemDetailsText Phone">{ applicant.phone }</div>
        <div className="OrderItemDetailsText Date">{ applicant.timestamp?.date }</div>

        <div className="OrderItemOptions">

          { (applicant && !applicant.handled) &&
          <a onClick={
            () => {

                setModalPre({
                  title: 'Markeren as behandeld.',
                  subtitle: 'Deze sollicatie as behandeld markeren?',
                  content: (
                    <>
                      <div className="modalContentOptions NoBorder">
                        <a onClick={
                          () => {

                            if(applicant && applicant.id){
                              toggleApplicantHandled(applicant.id, index);
                            }
                            setModalPre();
                          }
                        } className="modalContentOptionsButton Accept">Behandeld!</a>
                        <a onClick={
                          () => {

                            setModalPre();
                          }
                        } className="modalContentOptionsButton">Annuleren</a>
                      </div>
                    </>
                  )
                })

            }
          } className="OrderItemOptionsButton Accept">
            <RiCheckDoubleLine style={{ fontSize: 14 }} />
          </a>
          }

          <a onClick={
            () => {

              setDetailsPre(applicant);

            }
          } className="OrderItemOptionsButton Edit">
            <RiFileShield2Line style={{ fontSize: 14 }} />
            <span className="OrderItemOptionsButtonText">{ 'Bekijken' }</span>
          </a>
        </div>

      </div>
    )
  })


  return (
    <div className="SettingsContentContent List">

      <div className="SettingsMessageText ShowMobile">Voorlopig alleen op desktop beschikbaar.</div>

      <div className={`settingsContentReal HideDesktop`}>
        <div className="SettingsContentNavigation">
          <div className="SettingsContentNavigationItem IDSmall">ID</div>
          <div className="SettingsContentNavigationItem ActiveLarge">Behandeld</div>
          <div className="SettingsContentNavigationItem EmailFixed">Vacature</div>
          <div className="SettingsContentNavigationItem Name">Naam</div>
          <div className="SettingsContentNavigationItem Phone">Telefoonnummer</div>
          <div className="SettingsContentNavigationItem Date">Datum</div>
          <div className="SettingsContentNavigationItem OptionsBig"></div>
        </div>

        <div className="GlobSettingsScroll" ref={scrollView}>

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }


          { (applicants_items.length > 0)
          ?
          applicants_items
          :
          <div className="SettingsMessageText">Geen sollicitanten.</div>
          }
        </div>

        { applicantsPagination() }
      </div>


      <DetailView width={500} modal={modal} setDetailsPre={setDetailsPre}>
      { modalView() }
      </DetailView>

    </div>
  )
}

export default Applicants;
