import '../styles/Cart.css';
import config from '../constants/config';
import {
  RiBankLine,
  RiHandCoinLine,
  RiLockLine,
  RiSubtractFill,
  RiAddFill,
  RiDeleteBin6Line,
  RiFileList2Line,
  RiAddBoxFill,
  RiBankCard2Line,
  RiCheckboxCircleFill,
  RiCloseFill,
  RiStore3Line
} from "react-icons/ri";
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Tooltip as DaTooltip } from "react-tooltip";
import loadingAnimation from '../animations/loading_circle.json';
import successAnimation from '../animations/success_white.json';
import cartAnimation from '../animations/cart.json';
import Lottie from "lottie-react";
import { useCookies } from 'react-cookie';
import DropDown from "../components/DropDown";

function Cart(props) {
  const {
    setViewPre,
    account,
    token,
    setModalPre,
    setCartCount,
    setCartCountPre
  } = props;
  const [ paymentMethod, setPaymentMethod ] = useState(0);
  const [ deletePreIndex, setDeletePreIndex ] = useState(-1);
  const [ deliveryAddress, setDeliveryAddress ] = useState(-1);
  const [ loading, setLoading ] = useState(false);
  const [ couponLoading, setCouponLoading ] = useState(false);
  const [ addressLoading, setAddressLoading ] = useState(false);
  const [ orderLoading, setOrderLoading ] = useState(false);

  const [ orderPlaced, setOrderPlaced ] = useState(false);
  const [ cart, setCart ] = useState([]);
  const [ list, setList ] = useState([]);
  const [ contact, setContact ] = useState({
    name: '',
    phone: ''
  });
  const [ addresses, setAddresses ] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [ placedOrderData, setPlacedOrderData ] = useState(null);

  const inputCoupon  = useRef();
  const inputAddress  = useRef();
  const inputContactName  = useRef();
  const inputContactPhone  = useRef();

  const [ areas, setAreas ] = useState([]);
  const [ areaSelected, setAreaSelected ] = useState();

  const [ coupon, setCoupon ] = useState(null);
  const [ couponText, setCouponText ] = useState('');
  const [ stores, setStores ] = useState([]);
  const [ storeSelected, setStoreSelected ] = useState(0);
  const [ cookies, setCookie, removeCookie ] = useCookies(['cart-coupon']);

  useEffect( () => {

    if(account){
      const { name, phone } = account;

      // Check account
      if(cookies['cart-coupon']){
        const coupon = cookies['cart-coupon'];

        setCouponText(coupon);
        setTimeout( () => {
          applyCoupon(true);
        }, 300);
      }

      fetchStores();
      fetchAreas();
      fetchCart();

      setContact( contactOld => {
        return {
          name: name,
          phone: phone
        }
      });

    }else{
      setCart([]);
    }
  }, [account])


  const placeOrder = () => {
    const { name, phone } = contact;

    if(account && cart.length > 0){

      const paymentMethodText = (paymentMethod == 0) ? 'cash' : 'pos';
      const store = (stores).find( (st, ind) => ind == storeSelected);
      const discountAmount = cartDiscountAmount();
      const cartTotalsAmount = cartTotals() - discountAmount;

      const order = {
        contact: {
          name: name,
          phone: phone
        },
        payment: {
          method: paymentMethodText,
        },
        totals: cartTotalsAmount,
        delivery_type: (!deliveryAddress) ? 'pickup' : 'delivery',
        delivery: (deliveryAddress) ? {
          id: deliveryAddress.id,
          address: deliveryAddress.address,
          area: deliveryAddress.area.area,
          fee: deliveryAddress.delivery_fee
        } : {
          id: store.id,
          name: store.name,
          address: store.address
        },
        discount: (coupon) ? {
          id: coupon.id,
          coupon: coupon.coupon,
          amount: coupon.amount
        } : null,
        items: cart
      };

      setOrderLoading(true);
      axios.post( config.api.url + 'account/order/place', {
        hash: token,
        order: order
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key,
            'session': props.sessionHash
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        console.log(json.data)

        if(success){
          fetchCart(true);
          setOrderPlaced(true);
          setPlacedOrderData(data);
          removeCookie('cart-coupon', { path: '/' });
        }else{
          fetchCart(true);
          setPlacedOrderData();
          setModalPre({
            title: 'Oops.',
            subtitle: response
          });
        }
        setOrderLoading(false);
      })
      .catch(function (error) {
        setOrderLoading(false);
      });

    }else{
      setModalPre({
        title: 'Oops.',
        subtitle: 'Je bent niet ingelogd.'
      })
    }

  }

  const setDeliveryAddressPre = (del) => {

    setDeliveryAddress(del)
  }

  const fetchStores = () => {
    axios.post( config.api.url + 'stores', null, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){
        const stores = data.stores;

        if(stores){
          setStores(stores);
        }

      }

    })
    .catch(function (error) {
    });
  }

  const removeDeliveryAddress = (delivery_id = 0) => {

    if(delivery_id == 0){

      setModalPre({
        title: 'Oops.',
        subtitle: 'Leveringsadres komt niet voor, probeer deze pagina te herladen.'
      })
    }else{

      setAddressLoading(true);
      axios.post( config.api.url + 'account/addresses/delete', {
        hash: token,
        delivery_id: delivery_id
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        if(success){
          fetchCart(false, true);
          inputAddress.current.value = '';
        }
        setAddressLoading(false);
      })
      .catch(function (error) {
        setAddressLoading(false);
      });
    }
  }

  const addDeliveryAddress = () => {
    const addressText = inputAddress.current?.value;
    const addressArea = (areaSelected) ? areaSelected.key : 0;

    if(addressText == '' || addressArea == 0){

      setModalPre({
        title: 'Oops.',
        subtitle: 'Vul de velden in om een leveringsadres toe te voegen.'
      })
    }else{

      setAddressLoading(true);
      axios.post( config.api.url + 'account/addresses/add', {
        hash: token,
        address: addressText,
        area_id: addressArea
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key,
            'session': props.sessionHash
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        if(success){
          fetchCart(false, true);
          inputAddress.current.value = '';
        }
        setAddressLoading(false);
      })
      .catch(function (error) {
        setAddressLoading(false);
      });
    }
  }

  const fetchAreas = () => {
    axios.post( config.api.url + 'areas', null, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;


      if(success){
        const areas = data.areas;

        if(stores){
          setAreas(areas);
        }

      }

    })
    .catch(function (error) {
    });
  }

  const applyCoupon = (reset = false) => {
    const couponText = inputCoupon.current?.value;

    setCouponLoading(true);

    axios.post( config.api.url + 'account/cart/coupon', {
      coupon: couponText
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      setCouponLoading(false);
      if(success){
        const coupon_object = data.coupon;

        if(coupon_object){

          setCookie('cart-coupon', couponText, { path: '/' });
          setCoupon(coupon_object);
        }

      }else{
        setCoupon();
        removeCookie('cart-coupon', { path: '/' });

        if(reset){
          setCouponText('');
        }
      }
    })
    .catch(function (error) {
        setCouponLoading(false);
    });

  }

  const updateQTY = (index, qty = 0) => {
    if(index < (cart.length) && qty > 0){
      const cartObj = [...cart][index].qty;

      const cart_new = [...cart];
      cart_new[index].qty = qty;
      cart_new[index].qty_raw = qty;

      const cart_count_diff = (qty - cartObj);
      setCartCountPre(cart_count_diff);

      setCart(cart_new);
      updateCart(cart_new);

    }
  }

  const removeItem = (index) => {
    if(index < (cart.length)){
      const cartObj = [...cart][index].qty;

      const cart_new = [...cart];
      cart_new.splice(index, 1);

      const cart_count_diff = (cartObj) * -1;
      setCartCountPre(cart_count_diff);

      setCart(cart_new);
      updateCart(cart_new);
    }
  }

  const removeLaterItem = (index) => {
    if(index < (list.length)){
      const listObj = [...list][index].qty;

      const list_new = [...list];
      list_new.splice(index, 1);

      setList(list_new);
      updateList(list_new);
    }
  }

  useEffect(() => {
   // Cleanup function to clear the timeout when the component unmounts
   return () => {
     if (timeoutId) {
       clearTimeout(timeoutId);
     }
   };
 }, [timeoutId]);


 const laterToCart = (item, index) => {

   const list_new = [...list];
   list_new.splice(index, 1);
   setList(list_new);

   axios.post( config.api.url + 'account/cart/later/transfer', {
     hash: token,
     item: item
   }, {
       crossdomain: true,
       headers: {
         'api': config.api.key,
         'session': props.sessionHash
       }
   })
   .then((json) => {
     const { success, response, data } = json.data;

     if(success){
       fetchCart(false);
     }

   })
   .catch(function (error) {

   });
 }

 const saveLater = (item, index) => {


   const cart_new = [...cart];
   cart_new.splice(index, 1);
   setCart(cart_new);

   axios.post( config.api.url + 'account/cart/later/save', {
     hash: token,
     item: item
   }, {
       crossdomain: true,
       headers: {
         'api': config.api.key,
         'session': props.sessionHash
       }
   })
   .then((json) => {
     const { success, response, data } = json.data;


     if(success){
       fetchCart(false);
     }

   })
   .catch(function (error) {

   });
 }

 const updateList = (listFinalToSend = list) => {

   let listFinal = [];
   for(let l = 0; l < listFinalToSend.length; l++){
     const listObject = listFinalToSend[l];

     listFinal.push({
       qty: listObject.qty_raw,
       product: {
         id: listObject.product.id
       }
     });
   }

   axios.post( config.api.url + 'account/list/update', {
     hash: token,
     items: listFinal
   }, {
       crossdomain: true,
       headers: {
         'api': config.api.key,
         'session': props.sessionHash
       }
   })
   .then((json) => {
     const { success, response, data } = json.data;

     if(success){

       const newTimeoutId = setTimeout(() => {

         fetchCart(false);

       }, 500);

       setTimeoutId(newTimeoutId);

     }
   })
   .catch(function (error) {
   });
 }

  const updateCart = (cartFinalToSend = cart) => {

    let cartFinal = [];
    for(let c = 0; c < cartFinalToSend.length; c++){
      const cartObject = cartFinalToSend[c];

      cartFinal.push({
        qty: cartObject.qty_raw,
        product: {
          id: cartObject.product.id
        }
      });
    }

    axios.post( config.api.url + 'account/cart/update', {
      hash: token,
      items: cartFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){


        const newTimeoutId = setTimeout(() => {

          fetchCart(false);

        }, 500);

        setTimeoutId(newTimeoutId);

      }
    })
    .catch(function (error) {
    });
  }

  const fetchCart = (shouldLoad = true, forceSelect = false) => {

    if(shouldLoad){
      setLoading(true);
    }
    axios.post( config.api.url + 'account/cart', {
      hash: token
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(shouldLoad){
        setTimeout( () => {
          setLoading(false);
        }, 1);
      }

      if(success){
        const cart = data.cart;
        const cart_totals = data.cart_totals;
        const later = data.later;
        const addresses = data.addresses;


        if(addresses){
          setAddresses(addresses);
          const lastAddress = addresses.length ? addresses[addresses.length - 1] : null;
          if(lastAddress && forceSelect){
            setDeliveryAddressPre((lastAddress) ? lastAddress : null);
          }else if(deliveryAddress){
            const newAddres = (addresses).find( (ad, a_ind) => ad.id == deliveryAddress.id);

            if(newAddres){
              setDeliveryAddressPre(newAddres);
            }else{
              setDeliveryAddressPre(null);
            }
          }
        }else{
          if(deliveryAddress){
            setDeliveryAddressPre(null);
          }
        }

        if(later){
          setList(later);
        }

        if(cart_totals >= 0){
          setCartCount(cart_totals)
        }

        if(cart){
          setCart(cart);
        }

      }else{
        setCartCount(0)
      }
    })
    .catch(function (error) {
      if(shouldLoad){
        setLoading(false);
      }
    });

  }

  const cartTotals = () => {
    let totals = 0;

    for(let ct = 0; ct < cart.length; ct++){
      const cart_item = cart[ct];
      const product = cart_item.product;
      const product_totals = toFixedNoRound(cart_item.qty * product.price, 2);

      totals = (parseFloat(totals) + parseFloat(product_totals));
    }


    return toFixedNoRound(parseFloat(totals), 2);
  }

  const cartDiscountAmount = () => {
    let discount = 0;

    if(coupon){

      const cartTotalsAmount = cartTotals();
      if((coupon.amount).includes('%')){
        const percentage = parseFloat((coupon.amount).replaceAll('%', ''));
        discount = (cartTotalsAmount / 100) * percentage;
      }else if((coupon.amount).includes('SRD')){
        discount = parseFloat((coupon.amount).replaceAll('SRD', ''));
      }else if((coupon.amount) > 0){
        discount = parseFloat(coupon.amount);
      }

    }

    return parseFloat(discount).toFixed(2);
  }

  const toFixedNoRound = (num, precision = 1) => {
    const factor = Math.pow(10, precision);
    return ((Math.floor(num * factor) / factor)).toFixed(2);
  }

  const couponView = () => {

    if(!coupon) return false;

    const cartTotalsAmount = cartTotals();
    const discountAmount = cartDiscountAmount();
    let subtotals = (cartTotalsAmount);

    if(deliveryAddress){
      const delivery_fee = toFixedNoRound(parseFloat(deliveryAddress.delivery_fee), 2);

      subtotals = toFixedNoRound((parseFloat(subtotals) + parseFloat(delivery_fee)), 2);
    }

    return (
      <>
        <div className="CartTotalsItem">
          <div className="CartTotalsItemPre">Subtotaal zonder korting</div>
          <div className="CartTotalsItemAmount">SRD <b>{ subtotals }</b></div>
        </div>
        <div className="CartTotalsItem Discount">
          <div className="CartTotalsItemPre" style={{ color: '#e0197d' }}>Korting ({ coupon.amount })</div>
          <div className="CartTotalsItemAmountView">
            <div className="CartTotalsItemAmount" style={{ color: '#e0197d' }}>SRD<b>{ parseFloat(discountAmount).toFixed(2) }</b></div>
            <a onClick={
              () => {
                removeCookie('cart-coupon', { path: '/' });
                setCoupon(null);
              }
            } className="CartTotalsItemAmountButton">
              <RiCloseFill />
            </a>
          </div>
        </div>
      </>
    )
  }

  const listItemsView = () => {

    if(loading) return false;

    const listItems = (list).map( (list_item, list_index) => {

      const product = list_item.product;
      const product_totals = (list_item.qty * product.price).toFixed(2);

      const page = 'product/' + product.id + '-' + (product.title.toLowerCase()).replaceAll(' ', '-');
      const p_url = config.homepage + page;
      return (
        <div className={`CartItem ${ (list_item.qty == 0) ? 'Error' : '' }`} key={'list_item_' + list_index}>

          <div className="CartItemDetailsWrapper">
            <div className="CartItemImageWrapper">
              <div className="CartItemImage">
                <img src={product.image} key={'list_item_image_' + product.image} draggable={false} />
              </div>
            </div>
            <div className="CartItemDetails">
              <a href={ p_url } target={'_blank'} className="CartItemDetailsTitle">{ product.title }</a>
              <div className="CartItemDetailsPrice">SRD { product.price }</div>

              <div className="CartItemSaveOptions">
                <div onClick={
                  () => {

                    setModalPre({
                      title: 'Item verwijderen.',
                      subtitle: 'Dit item verwijderen uit jouw lijst?',
                      content: (
                        <>
                          <div className="modalContentOptions NoBorder">
                            <a onClick={
                              () => {

                                removeLaterItem(list_index);
                                setModalPre();
                              }
                            } className="modalContentOptionsButton C2a AccentHover">Verwijderen</a>
                            <a onClick={
                              () => {

                                setModalPre();
                              }
                            } className="modalContentOptionsButton">Annuleren</a>
                          </div>
                        </>
                      )
                    });
                  }
                } className="CartItemSaveOptionsButton">Verwijderen</div>
              </div>
            </div>
          </div>
          <div className="CartItemRestWrapper">
            <div className="CartItemRestText Bold">{ list_item.qty }x</div>
            <div className={`CartItemPrice ${(parseInt(product_totals) == 0 ? 'NA' : '')}`}>{ (parseInt(product_totals) > 0) ? (<>SRD { product_totals }</>) : 'NVT' }</div>

            <div className="CartItemOptions">
              <a onClick={
                () => {
                  laterToCart(list_item, list_index);
                }
              } className="CartItemOptionsButton Add" id={'list_item_add_' + list_index}>
                <RiAddFill />
              </a>

              <DaTooltip
                anchorId={'list_item_add_'+ list_index}
                place="left"
                content={ 'Toevoegen aan winkelmandje' }
              />
            </div>
          </div>
        </div>
      )
    });

    if(listItems.length == 0) return false;

    return (
      <div className="CartLaterView">
        <div className="CartLaterTitle">Item(s) voor later</div>
        {listItems}
      </div>
    );
  }

  const cartItemsView = () => {

    if(loading) return false;

    let subtotals = 0.00;
    const cartItems = (cart).map( (cart_item, cart_index) => {

      const product = cart_item.product;
      const product_totals = (cart_item.qty * product.price).toFixed(2);

      subtotals = (parseFloat(subtotals) + parseFloat(product_totals)).toFixed(2);

      const page = 'product/' + product.id + '-' + (product.title.toLowerCase()).replaceAll(' ', '-');
      const p_url = config.homepage + page;

      const misCount = (cart_item.qty_raw != cart_item.qty);
      let misCountText = '';

      if(misCount && cart_item.qty_raw > cart_item.qty){
        const left = (cart_item.qty_raw) - (cart_item.qty);


        misCountText = left + ' ' + (left == 1 ? 'kan' : 'kunnen') + ' niet worden besteld wegens onze voorraad.';
      }

      return (
        <div className={`CartItem ${ (cart_item.qty == 0) ? 'Error' : '' }`} key={'cart_item_' + cart_index}>

          <div className="CartItemDetailsWrapper">
            <div className="CartItemImageWrapper">
              <div className="CartItemImage">
                <img src={product.image} key={'cart_item_image_' + product.image} draggable={false} />
              </div>
            </div>
            <div className="CartItemDetails">
              <a href={ p_url } target={'_blank'} className="CartItemDetailsTitle">{ product.title }</a>
              <div className="CartItemDetailsPrice">SRD { product.price }</div>
              { (misCount && misCountText) &&
              <div className="CartItemDetailsError">{ misCountText }</div>
              }
              <div className="CartItemSaveOptions">
                <div onClick={
                  () => {
                    saveLater(cart_item, cart_index);
                  }
                } className="CartItemSaveOptionsButton">Opslaan voor later</div>
              </div>
            </div>
          </div>
          <div className="CartItemRestWrapper">
            <div className={`CartItemFlipper ${ (cart_item.qty > 1) ? 'Multiple' : '' }`}>
              <a onClick={
                () => {
                  const removeQty = (cart_item.qty > 1) ? (cart_item.qty - 1) : 1;
                  if(removeQty <= product.stock){
                    updateQTY(cart_index, removeQty);
                  }else{
                    updateQTY(cart_index, product.stock);
                  }
                }
              } className="CartItemFlipperItem">
                <RiSubtractFill />
              </a>
              <div className="CartItemFlipperText">{ cart_item.qty }</div>
              <a onClick={
                () => {

                  const addQty = (parseInt(cart_item.qty) + 1);
                  if(addQty <= product.stock){
                    updateQTY(cart_index, addQty);
                  }else{
                    updateQTY(cart_index, product.stock);
                  }

                }
              } className="CartItemFlipperItem">
                <RiAddFill />
              </a>
            </div>

            <div className={`CartItemPrice ${(parseInt(product_totals) == 0 ? 'NA' : '')}`}>{ (parseInt(product_totals) > 0) ? (<>SRD { product_totals }</>) : 'NVT' }</div>

            <div className="CartItemOptions">
              { (deletePreIndex >= 0 && deletePreIndex == cart_index)
              ?
              <a onClick={
                () => {
                  removeItem(cart_index);
                }
              } className="CartItemOptionsButton Confirm" id={'cart_item_delete_' + cart_index}>
                <span className="CartItemOptionsButtonText">Bevestigen</span>
                <RiDeleteBin6Line />
              </a>
              :
              <a onClick={
                () => {
                  setDeletePreIndex(cart_index);
                }
              } className="CartItemOptionsButton" id={'cart_item_delete_' + cart_index}>
                <RiDeleteBin6Line />
              </a>
              }
            </div>
          </div>
        </div>
      )
    });

    let ob = toFixedNoRound((subtotals / 110) * 10, 2);
    ob = 0;
    let totals = toFixedNoRound(subtotals * 1, 2);
    let delivery_fee = -1;

    if(deliveryAddress){
      delivery_fee = toFixedNoRound(parseFloat(deliveryAddress.delivery_fee), 2);
    }

    const subtotals_limit = subtotals;
    subtotals = toFixedNoRound(totals - ob, 2);

    const discountAmount = cartDiscountAmount();
    if(discountAmount > 0){
      totals = toFixedNoRound(totals - discountAmount, 2);

      // subtotals = ((totals / 110) * 100).toFixed(2);
      // const ob_real = (parseFloat((totals / 110) * 10)).toFixed(4);
      // ob = toFixedNoRound(ob_real, 2);
    }


    if(delivery_fee > 0){
      totals = toFixedNoRound((parseFloat(totals) + parseFloat(delivery_fee)), 2);
    }
    if(subtotals < 0){
      subtotals = 0;
    }
    if(ob < 0){
      ob = 0;
    }
    if(totals < 0){
      totals = 0;
    }

    return (
      <>
        { (cartItems.length > 0)
        ?
        cartItems
        :
        <div className="CartItemsEmpty">
          <RiFileList2Line />
          <div className="CartItemsEmptyText">Geen item(s) in jouw winkelmandje.</div>
        </div>
        }



        { (!orderPlaced) &&
        <div className="CartCouponViewWrapper">
          <div className="CartCouponView">

            { (couponLoading) &&
            <div className="LoginLoadingOverlay">
              <div className="LoginLoading">
                <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
              </div>
            </div>
            }


            <div className="CartCouponTitle">Heb je een <b>DA</b> coupon?</div>
            <div className="CartCoupon">

              <input
                ref={inputCoupon}
                className="CartCouponInput"
                placeholder={''}
                value={couponText}
                autoCapitalize = {"characters"}
                onChange={
                  (event) => {

                    setCouponText((event.target.value).toUpperCase());
                  }
                }
                onKeyUp={
                  (event) => {
                    const key = event.key;

                    switch(key){
                      case "Enter":
                        if(!couponLoading){
                          applyCoupon();
                        }
                      break;
                    }
                  }
                }
              />
              <a onClick={
                () => {
                  applyCoupon();
                }
              } className="CartCouponButton">
                <span className="CartCouponButtonText">{ (coupon) ? 'Wijzigen' : 'Toepassen' }</span>
                { (coupon) &&
                <RiCheckboxCircleFill style={{ marginLeft: 10 }} />
                }
              </a>
            </div>
          </div>
          <div className="CartTotals">
            <div className="CartTotalsItem">
              <div className="CartTotalsItemPre">Subtotaal</div>
              <div className="CartTotalsItemAmount">SRD <b>{ subtotals }</b></div>
            </div>
            {/* <div className="CartTotalsItem">
              <div className="CartTotalsItemPre">BTW (10%)</div>
              <div className="CartTotalsItemAmount">SRD <b>{ ob }</b></div>
            </div> */}
            { (delivery_fee >= 0) &&
            <div className="CartTotalsItem">
              <div className="CartTotalsItemPre">Levering</div>
              <div className={`CartTotalsItemAmount ${ (delivery_fee == 0) ? 'Free' : '' }`}>{ (delivery_fee == 0) ? (<b>GRATIS</b>) : (<>SRD <b> { delivery_fee }</b></>) }</div>
            </div>
            }
            { couponView() }
            <div className="CartTotalsDivider" />
            <div className="CartTotalsItem">
              <div className="CartTotalsItemPre">Totaal</div>
              <div className={`CartTotalsItemAmount Highlight ${ (totals == 0) ? 'Free' : '' }`}>{ (totals == 0) ? (<b>GRATIS</b>) : (<>SRD <b>{ totals }</b></>) }</div>
            </div>
            { (subtotals_limit < 400) &&
            <div className="CartTotalsLimit">De minimale besteding om een bestelling te plaatsen is SRD 400</div>
            }
          </div>
        </div>
        }
      </>
    )
  }

  const addressesView = () => {
    const addresses_items = (addresses).map( (address, index) => {

      const isSelected = (deliveryAddress && deliveryAddress.id == address.id);

      return (
        <a onClick={
          () => {
            if(deliveryAddress > 0){
              setDeliveryAddressPre(null);
            }else{
              setDeliveryAddressPre(address);
            }
          }
        } key={'address_'+address.id} className={`CartPaymentSelectorItem ${(isSelected) ? 'Selected' : '' }`}>
          <div className="CartPaymentSelectorItemHeader">
            <RiHandCoinLine style={{ fontSize: 14 }} />
            <div className="CartPaymentSelectorItemHeaderTitle">{ address.address }</div>
            <div className="CartPaymentSelectorItemDelete" onClick={
              () => {

                setModalPre({
                  title: 'Leveringsadres verwijderen.',
                  subtitle: 'Dit leveringsadres verwijderen?',
                  content: (
                    <>
                      <div className="modalContentOptions NoBorder">
                        <a onClick={
                          () => {

                            removeDeliveryAddress(address.id)
                            setModalPre();
                          }
                        } className="modalContentOptionsButton C2a AccentHover">Verwijderen</a>
                        <a onClick={
                          () => {

                            setModalPre();
                          }
                        } className="modalContentOptionsButton">Annuleren</a>
                      </div>
                    </>
                  )
                });
              }
            }>
              <RiCloseFill />
            </div>
          </div>
          { (address.last_used) &&
          <div className="CartPaymentSelectorItemDescr">Als laatst gebruikt om jouw bestelling te ontvangen.</div>
          }
        </a>
      )
    })

    if(addresses_items.length == 0) return false;

    return (
      <>
        <div className="CartPaymentContentTitle" style={{ marginTop: 15 }}>Laat het leveren aan de</div>
        { addresses_items }
      </>
    );
  }

  const orderButton = () => {
    let valid = false;
    const contactName = inputContactName.current?.value;
    const contactPhone = inputContactPhone.current?.value;

    const subtotals = cartTotals();
    let backgroundColor = '#7b7b7b';
    let textColor = '#1b1b1b';
    let buttonText = (account) ? 'Afrekenen' : 'Log in om af te rekenen';
    let hintMessage = 'Je bent niet ingelogd';
    const areItemsEmpty = cart.some(item => item.qty == 0);

    if(cart.length == 0){
      valid = false;
      buttonText = 'Geen item(s) om af te rekenen';
      hintMessage = 'Geen item(s) om af te rekenen';
    }else{
      if(account){
        valid = false;
        buttonText = '';
        hintMessage = 'Selecteer';

        if(!deliveryAddress){

          const store = (stores).find( (st, ind) => ind == storeSelected);

          if(store){

            // PICKUP
            valid = true;
            buttonText = 'Afrekenen om op te halen bij ' + store.name;
            backgroundColor = '#e0197d';
            textColor = '#fff';
            hintMessage = '';
          }

        }else if(deliveryAddress){

          const address = (addresses).find( (ad, aind) => ad.id == deliveryAddress.id);

          if(address){
            // DELIVERY
            valid = true;
            buttonText = 'Afrekenen voor levering naar ' + address.address;
            backgroundColor = '#e0197d';
            textColor = '#fff';
            hintMessage = '';
          }
        }

        if(contact.name == '' || contact.phone == ''){
          valid = false;
          buttonText = 'Vul jouw contact gegevens in';
          backgroundColor = '#7b7b7b';
          textColor = '#1b1b1b';
          hintMessage = '';
        }

      }
    }

    if(deliveryAddress == null){
      valid = false;
      buttonText = 'Kies het adres om jouw item(s) te laten leveren';
      backgroundColor = '#7b7b7b';
      textColor = '#1b1b1b';
    }

    if(areItemsEmpty){
      valid = false;
      buttonText = '1 of meer item(s) zijn niet in voorraad';
      backgroundColor = '#7b7b7b';
      textColor = '#1b1b1b';
    }

    if(subtotals < 400){
      valid = false;
      backgroundColor = '#d63636';
      textColor = '#fff';
      buttonText = 'De minimale besteding nog niet bereikt';
    }

    return (
      <>
        <a id={'order-button'} onClick={
          (event) => {

            if(account){

              if(valid){
                setModalPre({
                  title: 'Ready om te bestellen?',
                  subtitle: (<>
                    Het adres voor levering is <b>{ deliveryAddress.address }</b>.<br />
                    Is dat correct?
                  </>),
                  content: (
                    <>
                      <div className="modalContentOptions NoBorder">
                        <a onClick={
                          () => {

                            placeOrder();
                            setModalPre();
                          }
                        } className="modalContentOptionsButton C2a AccentHover">Bestellen</a>
                        <a onClick={
                          () => {

                            setModalPre();
                          }
                        } className="modalContentOptionsButton">Annuleren</a>
                      </div>
                    </>
                  )
                })
              }
            }else{
              setViewPre('account');
            }

            event.preventDefault();
          }
        } className="CartPaymentOptionsButton AccentHover" style={{
          backgroundColor: backgroundColor,
          color: textColor
        }}>
          <div className="CartPaymentOptionsButtonText">{ buttonText }</div>
          <RiLockLine style={{ color: textColor }} />
        </a>

        { (hintMessage != '') &&
        <DaTooltip
          anchorId="order-button"
          place="top"
          content={ hintMessage }
        />
        }
      </>
    )
  }


  const storesView = () => {

    if(deliveryAddress) return false;

    const storesObjects = (stores).map( (store, index) => {

      const isSelected = (storeSelected == index);

      return (
        <div onClick={
          () => {

            setStoreSelected(index);

          }
        } className={`CartStoreItem ${ (isSelected) ? 'Selected' : '' }`} key={ 'store_item_' + index }>
          <div className="CartStoreImage" style={{ backgroundImage: `url('${ (store.image) }')` }} />
          <div className="CartStoreDetails">
            <div className="CartStoreDetailsTitle">{ store.name }</div>
            <div className="CartStoreDetailsAddress">{ store.address }</div>
          </div>
        </div>
      )
    })

    return (
      <div className="CartStoresView">

        { storesObjects }

      </div>
    )
  }

  return (
    <div className={`Cart ${ (orderPlaced) ? 'Placed' : '' }`} onClick={
      () => {
        if(deletePreIndex >= 0){
          setDeletePreIndex(-1);
        }
      }
    }>
      <div className="FixedWidth">

        <div className="CartWrapper">

          <div className="CartContent">
            <div className="CartContentTitle">Winkelmandje</div>
            <div className="CartContentItems">

              { (loading) &&
              <div className="LoginLoadingOverlay Inline" style={{ marginBottom: 10 }}>
                <div className="LoginLoading">
                  <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
                </div>
              </div>
              }

              { cartItemsView() }

              { listItemsView() }

            </div>
          </div>

          { (orderPlaced)
          ?
          <div className="CartPlaced">

            <div className="CartPlacedAnimationView">
              <Lottie className="CartPlacedAnimation"  animationData={successAnimation} loop={false} />
            </div>
            <div className="CartPlacedModal">
              <div className="CartPlacedModalTitle">Jouw bestelling is geplaatst!</div>
              <div className="CartPlacedModalSubtitle">Je krijgt een confirmatie e-mail als bevestiging.</div>
              <div className="CartPlacedModalSubtitle">Er wordt contact opgemaakt door DA Drogisterij voor verdere afhandeling.</div>
              <div className="CartPlacedModalOptions">
                <a href={ config.homepage } onClick={
                  (event) => {
                    setViewPre('home');

                    event.preventDefault();
                  } } className="CartPlacedModalOptionsButton">Naar de voorpagina</a>
                { (placedOrderData) &&
                <a href={ config.homepage + 'account/bestellingen/order/' + placedOrderData.order_id } onClick={
                  (event) => {
                    setViewPre('account/bestellingen/order/' + placedOrderData.order_id);

                    event.preventDefault();
                  } } className="CartPlacedModalOptionsButton C2a">Jouw bestelling bekijken</a>
                  }
              </div>
            </div>

          </div>
          :
          ((cart.length > 0)
          ?
          <div className="CartPayment">

            { (orderLoading) &&
            <div className="LoginLoadingOverlay">
              <div className="LoginLoading">
                <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
              </div>
            </div>
            }

            <div className="CartContentTitle">Afrekenen</div>
            <div className="CartPaymentContent">
              <div className="CartPaymentContentTitle">Contactgegevens</div>

              <div className="CartInformationItems">
                <div className="cartInformationItem">
                  <div className="cartInformationItemPre">Naam</div>
                  <input
                    ref={inputContactName}
                    className="cartInformationItemInput"
                    defaultValue={ (account && account.name) ? account.name : '' }
                    placeholder={'John Doe'}
                    onChange={
                      (event) => {
                        const value = event.target.value;
                        setContact( contactOld => {
                          return {
                            ... contactOld,
                            name: value
                          }
                        });
                      }
                    }
                  />
                </div>

                <div className="cartInformationItem">
                  <div className="cartInformationItemPre">Telefoonnummer</div>
                  <input
                    ref={inputContactPhone}
                    className="cartInformationItemInput"
                    defaultValue={ (account && account.phone) ? account.phone : '' }
                    placeholder={'597 8888888'}
                    onChange={
                      (event) => {
                        const value = event.target.value;
                        setContact( contactOld => {
                          return {
                            ... contactOld,
                            phone: value
                          }
                        });
                      }
                    }
                  />
                </div>

              </div>


              <div className="CartPaymentContentTitle">Betalingsmethode</div>
              <div className="CartPaymentSelector Rowed">

                <a onClick={
                  () => {
                    setPaymentMethod(0);
                  }
                } className={`CartPaymentSelectorItem ${(paymentMethod == 0) ? 'Selected' : '' }`}>
                  <div className="CartPaymentSelectorItemHeader">
                    <RiHandCoinLine style={{ fontSize: 14 }} />
                    <div className="CartPaymentSelectorItemHeaderTitle">Contant bij overdracht</div>
                  </div>
                </a>

                <a onClick={
                  () => {
                    setPaymentMethod(1);
                  }
                }className={`CartPaymentSelectorItem ${(paymentMethod == 1) ? 'Selected' : '' }`}>
                  <div className="CartPaymentSelectorItemHeader">
                    <RiBankCard2Line style={{ fontSize: 14 }} />
                    <div className="CartPaymentSelectorItemHeaderTitle">POS bij overdracht</div>
                  </div>
                </a>

                {/*
                <a onClick={
                  () => {
                    setPaymentMethod(2);
                  }
                }className={`CartPaymentSelectorItem ${(paymentMethod == 2) ? 'Selected' : '' }`}>
                  <div className="CartPaymentSelectorItemHeader">
                    <RiBankLine style={{ fontSize: 14 }} />
                    <div className="CartPaymentSelectorItemHeaderTitle">Overmaking</div>
                  </div>
                  <div className="CartPaymentSelectorItemDescr">Je krijgt een referentie code die je in de comments plaatst bij het overmaken.</div>
                </a>
                */ }

              </div>

              <div className="CartPaymentSelector">

                {/*
                <a onClick={
                  () => {
                    setDeliveryAddressPre(null);
                  }
                } className={`CartPaymentSelectorItem ${(!deliveryAddress) ? 'Selected' : '' }`}>
                  <div className="CartPaymentSelectorItemHeader">
                    <RiStore3Line style={{ fontSize: 14 }} />
                    <div className="CartPaymentSelectorItemHeaderTitle">Ophalen bij een van onze filialen</div>
                  </div>
                  <div className="CartPaymentSelectorItemDescr">Je selecteert zelf welk filiaal jou schikt.</div>
                </a>
                { storesView() }
                */}


                { addressesView() }


                <div className="CartAddressWrapper">
                  <div className="CartAddressHeader">Nieuw adres?</div>
                  <div className="CartAddress">
                    <input
                      ref={inputAddress}
                      className="CartAddressInput"
                      placeholder={'Johannes Mungrastraat'}
                    />
                    <div className="CartAddressArea">
                      <DropDown
                        selected={(areaSelected) ? areaSelected.value : 'Buurt'}
                        list={(areas).map( (area, index) => { return { key: area.id, value: area.area }})}
                        onSelect={
                          (item) => {

                            setAreaSelected(item)
                          }
                        }
                      />
                    </div>
                  </div>
                  <a onClick={
                    () => {
                      if(!addressLoading){
                        // setViewPre('account/leveringsaddressen');
                        addDeliveryAddress();
                      }
                  }} className={`CartPaymentSelectorItemAddAddress`}>
                    <div className="CartPaymentSelectorItemAddAddressText">{ (addressLoading) ? 'Aan het toevoegen' : 'Leveringsadres toevoegen' }</div>
                    <RiAddBoxFill style={{ fontSize: 14 }} />
                  </a>
                </div>

              </div>

            </div>
            <div className="CartPaymentOptions">
              { orderButton() }
            </div>

          </div>
          :
          <div className="CartEmptyView">
            <div className="CartPlacedAnimationView">
              <Lottie className="CartPlacedAnimation" style={{ width: 120, height: 120, marginBottom: 15 }}  animationData={cartAnimation} loop={true} />
            </div>
            <div className="CartPlacedModal">
              <div className="CartPlacedModalTitle">Geen item(s) in jouw winkelmandje.</div>
              <div className="CartPlacedModalSubtitle">Voeg items toe om jouw bestelling te plaatsen.</div>
              { (!account) &&
              <div className="CartPlacedModalSubtitle">Vergeet niet om jouw DA account super-snel aan te maken.</div>
              }
              <div className="CartPlacedModalOptions">
                <a href={ config.homepage } onClick={
                  (event) => {
                    setViewPre('home');

                    event.preventDefault();
                  } } className="CartPlacedModalOptionsButton">Naar de voorpagina</a>
                <a href={ config.homepage + 'account/bestellingen' } onClick={
                  (event) => {
                    setViewPre('categorie/alle');

                    event.preventDefault();
                  } } className="CartPlacedModalOptionsButton C2a">Producten bekijken</a>
              </div>
            </div>
          </div>
          )
          }

        </div>

      </div>
    </div>
  );
}

export default Cart;
