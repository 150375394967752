import '../styles/Vacancy.css';
import config from '../constants/config';
import React, { useState, useEffect, useRef } from 'react';
import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiBriefcaseFill,
  RiArrowGoBackFill,
  RiImage2Fill,
  RiFileTextFill
} from "react-icons/ri";
import axios from 'axios';
import loadingAnimation from '../animations/loading_circle.json';
import Lottie from "lottie-react";
import Resizer from 'react-image-file-resizer';


function Vacancy(props) {
  const {
    subview,
    setViewPre,
    viewData,
    setModalPre
  } = props;
  const [ loading, setLoading ] = useState(false);
  const [ job, setJob ] = useState();
  const [ form, setForm ] = useState();
  const [ applyMode, setApplyMode ] = useState(false);
  const [ formSent, setFormSent ] = useState(false);

  const inputCV = useRef();
  const inputImage = useRef();
  const inputFirstName = useRef();
  const inputLastName = useRef();
  const inputPhone = useRef();
  const inputEmail = useRef();
  const inputMessage = useRef();

  const setApplyModePre = (apply) => {

    setApplyMode(apply);
  }

  // APPLY FORM
  const sendApplicant = () => {
    console.log(form.image.base)
    setLoading(true);
    axios.post( config.api.url + 'vacancy/apply', {
      vacancy_id: job.id,
      image: (form && form.image) ? form.image.base : '',
      cv: (form && form.cv) ? form.cv.base : '',
      first_name: inputFirstName.current?.value,
      last_name: inputLastName.current?.value,
      phone: inputPhone.current?.value,
      email: inputEmail.current?.value,
      motivation: inputMessage.current?.value
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      console.log(json);

      if(success){

        setModalPre({
          title: 'Yay.',
          subtitle: response
        })
        setViewPre('vacatures/' + job.url)
        setFormSent(true);
      }else{
        setModalPre({
          title: 'Oops.',
          subtitle: response
        })
      }

      setTimeout( () => {
        setLoading(false);
      }, 1);
    })
    .catch(function (error) {
      setLoading(false);
      console.log(error)
    });



  }
  const sendApplicantPre = () => {

    if(job){

      sendApplicant();
      // setModalPre({
      //   title: 'Sollicitatie verzenden?',
      //   subtitle: 'Controleer jouw gegevens voor het verzenden van jouw sollicitatie.',
      //   content: (
      //     <>
      //       <div className="modalContentOptions NoBorder">
      //         <a onClick={
      //           () => {
      //
      //             sendApplicant();
      //             setModalPre();
      //           }
      //         } className="modalContentOptionsButton C2a AccentHover">Verzenden</a>
      //         <a onClick={
      //           () => {
      //
      //             setModalPre();
      //           }
      //         } className="modalContentOptionsButton">Annuleren</a>
      //       </div>
      //     </>
      //   )
      // })


    }
  }
  const selectImage = (event) => {
    let fileInput = false;
    if(event.target.files[0]) {
        fileInput = true;
    }

    if(fileInput) {
        try {
            Resizer.imageFileResizer(
            event.target.files[0],
            1000,
            1000,
            'JPEG', 90,0,
            uri => {

              console.log(uri)
              setForm( formOld => {
                return {
                  ...formOld,
                  image: {
                    uri: URL.createObjectURL(event.target.files[0]),
                    base: uri
                  }
                }
              })

            },
            'base64',
            500,
            500,
            );
        }   catch(err) {

        }
    }

  }

  const selectFile = (event) => {
    let fileInput = false;
    if(event.target.files[0]) {
        fileInput = true;
    }

    if(fileInput) {
      const file = (event.target.files[0]);
      const uri = URL.createObjectURL(file);

      // Convert the file to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;

        console.log(base64String)

        setForm( formOld => {
          return {
            ...formOld,
            cv: {
              uri: uri,
              base: base64String,
              name: file.name
            }
          }
        })
      };
      reader.readAsDataURL(file);

    }
  }


  // Get subview
  let id = 0;
  if(subview){
    const subviews = (subview + '').split('-');
    const subviews_end = (/\//.test(subview)) ? (((subview + '').split('/'))[((subview + '').split('/')).length - 1]) : '';

    if(subviews_end != '' && !applyMode){
      setApplyModePre(true);
    }else if(subviews_end == '' && applyMode){
      setApplyModePre(false);
    }

    id = subviews[0];
  }

  const fetchVacancyInfo = () => {

    setLoading(true);
    axios.post( config.api.url + 'vacancy', {
      vacancy_id: id,
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){
        const job = data.job;


        if(job){
          setJob(job);
        }

      }

      setTimeout( () => {
        setLoading(false);
      }, 1);
    })
    .catch(function (error) {
      setLoading(false);
    });

  }

  // First
  useEffect( () => {

    fetchVacancyInfo();

  }, [id]);


  const apply_page = (job) ? ('vacatures/' + job.url + '/apply') : '';


  return (
    <div className={`Vacancy ${ (applyMode) ? 'ApplyMode' : '' }`}>
      <div className="FixedWidth">

        <div className={`LoginLoadingOverlay Animated ${ (loading) ? 'Loading' : '' }`}>
          { (loading) &&
          <div className="LoginLoading">
            <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
          </div>
          }
        </div>

        <div className="VacanciesHeader Mini">
          <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/swirl.png'})` }} />
        </div>

        <div className="ProductNavigationWrapper">
          <div className="ProductNavigation">
            <a href={ config.homepage + ((applyMode && job) ? ('vacatures/' + job.url) : 'vacatures') } onClick={
              (event) => {

                if(applyMode && job){
                  setViewPre('vacatures/' + job.url)
                }else{
                  setViewPre('vacatures')
                }

                event.preventDefault();
              }
            } className="ProductNavigationHome">
              <RiArrowLeftLine />
            </a>


            <a href={ config.homepage + ((applyMode && job) ? ('vacatures/' + job.url) : 'vacatures') } onClick={
              (event) => {

                if(applyMode && job){
                  setViewPre('vacatures/' + job.url)
                }else{
                  setViewPre('vacatures')
                }

                event.preventDefault();
              }
            } className="ProductNavigationItem">
              <div className="ProductNavigationItemText">Terug naar { (applyMode) ? 'vacature' : 'vacatures'}</div>
              <RiArrowGoBackFill style={{ fontSize: 11 }} />
            </a>

          </div>
        </div>


        { (job) &&
        <div className="VacancyDetailSplitter">
          <div className="VacancyDetailSplitterContent">
            <div className="VacancyDetailsApplyView">

              { (job.type) &&
              <div className="VacancyDetailsApplyHeader">
                <RiBriefcaseFill />
                <div className="VacancyDetailsApplyHeaderTitle">{ job.title }</div>
              </div>
              }

              <div className="VacancyFormItems">
                <div className="VacancyFormImageView">
                  <div className="VacancyFormPre">Foto</div>
                  <div className="ModalSideContentImageWrapper">
                    <div className="ModalSideContentImage Circle" style={{ backgroundImage: `url(${ (form && form.image) ? ((typeof form.image == 'string') ? (form.image) : (form.image.uri)) : '' })` }}>
                      <div className="ModalSideContentImageOverlay">
                        <div onClick={
                          () => {

                            inputImage.current?.click();

                          }
                        } className="ModalSideContentImageOverlayCircle">
                          <RiImage2Fill />
                          <input ref={ inputImage } type="file" accept=".jpg, .jpeg, .png, .gif" style={{ display: 'none' }} onChange={selectImage} />
                        </div>
                      </div>
                    </div>
                    <div className="ModalSideContentImageDetails">
                      <div className="ModalSideContentImageDetailsText">{ (form && form.image != '') ? 'Foto geselecteerd.' : 'Geen foto geselecteerd.' }</div>
                      <div className="ModalSideContentImageDetailsOptions">
                        { (form && form.image && form.image != '') &&
                        <a onClick={
                          () => {

                            setForm( formOld => {
                              return {
                                ...formOld,
                                image: ''
                              }
                            })

                          }
                        } className="ModalSideContentImageDetailsOptionsButton Delete">Verwijderen</a>
                        }
                        <a onClick={
                          () => {

                            inputImage.current?.click();
                          }
                        } className="ModalSideContentImageDetailsOptionsButton">Selecteer foto</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="VacancyFormItemTwin">
                  <div className="VacancyFormItem">
                    <div className="VacancyFormPre">Voornaam</div>
                    <div className="VacancyFormInputView">
                      <input
                        ref={inputFirstName}
                        defaultValue={ (form && form.first_name) ? form.first_name : '' }
                        placeholder={'...'}
                        className={`VacancyFormInput`}
                        onKeyUp={
                          (event) => {
                            const key = event.key;

                            switch(key){
                              case "Enter":

                              break;
                            }
                          }
                        }
                      />
                    </div>
                  </div>
                  <div className="VacancyFormItem">
                    <div className="VacancyFormPre">Achternaam</div>
                    <div className="VacancyFormInputView">
                      <input
                        ref={inputLastName}
                        defaultValue={ (form && form.last_name) ? form.last_name : '' }
                        placeholder={'...'}
                        className={`VacancyFormInput`}
                        onKeyUp={
                          (event) => {
                            const key = event.key;

                            switch(key){
                              case "Enter":

                              break;
                            }
                          }
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="VacancyFormItemTwin">
                  <div className="VacancyFormItem">
                    <div className="VacancyFormPre">Telefoonnummer</div>
                    <div className="VacancyFormInputView">
                      <input
                        ref={inputPhone}
                        defaultValue={ (form && form.phone) ? form.phone : '' }
                        placeholder={'...'}
                        className={`VacancyFormInput`}
                        onKeyUp={
                          (event) => {
                            const key = event.key;

                            switch(key){
                              case "Enter":

                              break;
                            }
                          }
                        }
                      />
                    </div>
                  </div>
                  <div className="VacancyFormItem">
                    <div className="VacancyFormPre">E-mail adres</div>
                    <div className="VacancyFormInputView">
                      <input
                        ref={inputEmail}
                        defaultValue={ (form && form.email) ? form.email : '' }
                        placeholder={'...'}
                        className={`VacancyFormInput`}
                        onKeyUp={
                          (event) => {
                            const key = event.key;

                            switch(key){
                              case "Enter":

                              break;
                            }
                          }
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="VacancyFormItem">
                  <div className="VacancyFormPre">CV (pdf, docx)</div>
                  <div className="VacancyFormItemFile">
                    <div className={`VacancyFormItemFileIcon ${ (form && form.cv) ? 'Selected' : '' }`}>
                      <RiFileTextFill />
                    </div>
                    <input ref={ inputCV } type="file" accept=".pdf, .doc, .docx" style={{ display: 'none' }} onChange={selectFile} />
                    <div className="VacancyFormItemFileTitle">{ (form && form.cv) ? (form.cv.name) : 'Geen file geselecteerd.' }</div>
                    <a onClick={
                      (event) => {

                        inputCV.current?.click();

                        event.preventDefault();
                      }
                    } className="VacancyFormItemFileButton">{ (form && form.cv) ? 'File selecteren' : 'File selecteren' }</a>
                  </div>
                </div>

                <div className="VacancyFormItem">
                  <div className="VacancyFormPre">Motivatie</div>
                  <div className="VacancyFormInputView">
                    <textarea
                      ref={inputMessage}
                      rows={6}
                      placeholder={'...'}
                      className={`VacancyFormTextarea`}
                      onKeyUp={
                        (event) => {
                          const key = event.key;

                          switch(key){
                            case "Enter":

                            break;
                          }
                        }
                      }
                    >{ (form && form.message) ? form.message : '' }</textarea>
                  </div>
                </div>

              </div>

              <div className="VacancyFormOptions">
                <a onClick={
                  (event) => {

                    sendApplicantPre();

                    event.preventDefault();
                  }
                } className="VacancyFormOptionsButton AccentHover">Verzenden</a>
                <a onClick={
                  (event) => {

                  setViewPre('vacatures/' + job.url)

                    event.preventDefault();
                  }
                } className="VacancyFormOptionsButton Back">Annuleren</a>
              </div>


            </div>
            <div className="VacancyDetailsContent" onClick={
              () => {
                if(applyMode){
                  setViewPre('vacatures/' + job.url)
                }
              }
            }>

              <div className="VacancyDetailsContentTitle">{ job.title }</div>

              { (job.type) &&
              <div className="VacancyDetailsContentItem Rowed Bold">
                <div className="VacancyDetailsContentItemTitle">{ 'Type' }</div>
                <div className="VacancyDetailsContentItemText">{ job.type }</div>
              </div>
              }
              { (job.location) &&
              <div className="VacancyDetailsContentItem Rowed">
                <div className="VacancyDetailsContentItemTitle">{ 'Winkel' }</div>
                <div className="VacancyDetailsContentItemText">{ job.location }</div>
              </div>
              }

              <div className="VacancyDetailsContentText" dangerouslySetInnerHTML={{ __html: job.descr }} />

              { (job.requirements != '') &&
              <div className="VacancyDetailsContentItem">
                <div className="VacancyDetailsContentItemTitle">{ 'Vereisten' }</div>
                { (job.requirements_array.length == 0)
                ?
                <div className="VacancyDetailsContentItemText" dangerouslySetInnerHTML={{ __html: job.requirements }} />
                :
                <div className="VacancyDetailsContentItems">
                  { (job.requirements_array).map( (item, ind) => {

                    return (
                      <div className="VacancyDetailsContentReqItem" key={'req_'+ind}>{ item }</div>
                    )
                  })

                  }
                </div>
                }

              </div>
              }

            </div>
          </div>
          <div className="VacancyDetailsSideWrapper">
            <div className="VacancyDetailsSide">
              <div className="VacancyDetailsSideTitle">{ (applyMode) ? 'Vul jouw gegevens in.' : 'Geïnteresseerd?' }</div>
              <div className="VacancyDetailsSideText">{ (applyMode) ? (<>Vul het formulier in met jouw persoonlijke gegevens, zodat wij een goed beeld krijgen van jouw achtergrond en vaardigheden.<br/><br/>We waarderen jouw interesse in deze vacature en kijken uit naar jouw sollicitatie!</>) : (<>Spreekt deze vacature jou aan?<br/>Aarzel dan niet om te solliciteren.</>) }</div>
              { (!applyMode && !formSent) &&
              <div className="VacancyDetailsSideOptions">
                <a href={ job.external_link } 

                target='_blank'
                // onClick={
                //   (event) => {

                //     // setViewPre(apply_page)

                //     event.preventDefault();
                //   }} 
                className="VacancyDetailsSideOptionsButton AccentHover">{ (form) ? 'Doorgaan met solliciteren' : 'Solliciteren' }</a>
              </div>
              }
            </div>
          </div>
        </div>
        }

      </div>
    </div>
  );
}

export default Vacancy;
