import '../styles/DropDown.css';
import config from '../constants/config';
import colors from '../constants/colors';
import React, { useState, useEffect, forwardRef, useRef } from 'react';
import {
  RiCheckFill
} from "react-icons/ri";
import { sortable } from 'react-sortable';



const SortItem = forwardRef((props, ref) => {

  return (
    <div {...props}>
      { props.children }
    </div>
  )
});

export default sortable(SortItem);
