import '../styles/Menu.css';
import config from '../constants/config';
import {
 RiFacebookFill,
 RiWhatsappFill,
 RiInstagramFill,

 RiCustomerServiceLine,
 RiUser3Line,
 RiShoppingBag3Line,
 RiArrowRightSLine,
 RiStore3Line,

 RiArrowLeftLine,
 RiCloseFill,
 RiBriefcaseLine,

 RiSearchLine
} from "react-icons/ri";
 import {
   FaTiktok
 } from "react-icons/fa";
 import { Tooltip as DaTooltip } from "react-tooltip";
 import React, { Animated, useRef, useState, useEffect } from 'react';



function Menu(props) {
  const {
    menu, setMenu,
    view, setViewPre,
    categories
  } = props;
  const [ subMenu, setSubMenu ] = useState(null);

  const setMenuPre = (mm = null) => {

    switch(mm){

      case "home":
      case "winkels":
      case "blogs":
      case "klantenservice":
      case "account":
      case "winkelmandje":
      case "vacatures":
      case "over-ons":
      case "contact":
        setViewPre(mm);
        setMenu(null);
      break;

      case "aanbiedingen":
        setViewPre('categorie/aanbiedingen')
      break;

      default:
        setMenu(mm);
      break;
    }
    setSubMenu(null);
  }

  const thirdMenu = () => {


    let subMenuTitle = (subMenu) ? subMenu.category : '';
    let subContent = (<div className="MenuItems"></div>);

    return (
      <div className={`MenuNav Subber ${(subMenu && subMenu != 'menu') ? 'Show' : ''}`}>

        <div className="MenuHeader">
          <div className="MenuHeaderText">{ subMenuTitle }</div>
          <a onClick={
            () => {
              setMenuPre(menu);
            }
          } className="MenuNavClose">
            <RiArrowLeftLine />
          </a>
        </div>

        { subContent }


      </div>
    )
  }

  const secondMenu = () => {

    let menuTitle = '...';
    let content = (<div className="MenuItems"></div>);

    switch(menu){
      case "producten":
        menuTitle = 'Producten';

        const product_items = (categories).map( (cat, index) => {

          if(cat.category == 'Aanbiedingen') return false;

          const isSelected = (subMenu && subMenu.category == cat.category);
          const url = config.homepage + 'categorie/' + (cat.category.toLowerCase());

          return (
            <a href={ url } onClick={
              (event) => {

                setViewPre('categorie/' + (cat.category.toLowerCase()));
                setSubMenu( isSelected ? null : cat);

                event.preventDefault();
              }
            } key={'cat_' + index} className={`MenuItem ${ (isSelected) ? 'Selected' : ''}`}>
              <div className="MenuItemTitle">{ cat.category }</div>
              <RiArrowRightSLine />
            </a>
          )
        })

        content = (
          <div className="MenuItems">

            <a href={ config.homepage + 'categorie/alle' } onClick={
              (event) => {
                setSubMenu();
                setViewPre('categorie/alle');

                event.preventDefault();
              }
            } key={'cat_first'} className={`MenuItem`}>
              <div className="MenuItemTitle">Alle producten</div>
              <RiArrowRightSLine />
            </a>

            { product_items }

          </div>
        )

      break;

      case "aanbiedingen":
        menuTitle = 'Aanbiedingen';
      break;
    }


    return (
      <div className={`MenuNav Sub ${(menu && menu != 'menu') ? 'Show' : ''}`}>

        <div className="MenuHeader">
          <div className="MenuHeaderText">{ menuTitle }</div>
          <a onClick={
            () => {
              setMenu('menu');
            }
          } className="MenuNavClose">
            <RiArrowLeftLine />
          </a>
        </div>

        { content }


      </div>
    )
  }


  return (
    <div className={`MenuWrapper ${ (menu) ? 'Show' : ''}`}>
      <div className="MenuNav Main">

        <div className="MenuHeader">
          <div className="MenuHeaderText">Menu</div>
          <a href={ config.homepage } onClick={
            (event) => {
              setMenuPre();
              event.preventDefault();
            }
          } className="MenuNavClose">
            <RiCloseFill />
          </a>
        </div>

        <div className="MenuItems">

          <a href={ config.homepage + 'home/' } onClick={
            (event) => {
              setMenuPre('home');
              event.preventDefault();
            }
          } className={`MenuItem Main ${ (!menu) ? 'Selected' : ''}`}>
            <div className="MenuItemTitle">Home</div>
          </a>

          <a href={ config.homepage + 'home/' } onClick={
            (event) => {
              setMenuPre( (menu != 'producten') ? 'producten' : 'menu');
              event.preventDefault();
            }
          } className={`MenuItem Main ${ (menu == 'producten') ? 'Selected' : ''}`}>
            <div className="MenuItemTitle">Producten</div>
            <RiArrowRightSLine />
          </a>

          <a href={ config.homepage + 'aanbiedingen/' } onClick={
            (event) => {
              setMenuPre('aanbiedingen');
              // setMenuPre( (menu != 'aanbiedingen') ? 'aanbiedingen' : 'menu');
              event.preventDefault();
            }
          } className={`MenuItem Main ${ (menu == 'aanbiedingen') ? 'Selected' : ''}`}>
            <div className="MenuItemTitle">Aanbiedingen</div>
          </a>

          <a href={ config.homepage + 'winkels/' } onClick={
            (event) => {
              setMenuPre('winkels');
              event.preventDefault();
            }
          } className={`MenuItem Main ${ (menu == 'filialen') ? 'Selected' : ''}`}>
            <div className="MenuItemTitle">Winkels</div>
          </a>

          <a href={ config.homepage + 'blogs/' } onClick={
            (event) => {
              setMenuPre('blogs');
              event.preventDefault();
            }
          } className={`MenuItem Main ${ (menu == 'filialen') ? 'Selected' : ''}`}>
            <div className="MenuItemTitle">Blogs</div>
          </a>

          <div className="MenuItemDivider" />


          <a href={ config.homepage + 'account' } onClick={
            (event) => {
              setMenuPre('account');
              event.preventDefault();
            }
          }  className="MenuItem">
            <div className="MenuItemIcon">
              <RiUser3Line />
            </div>
            <div className="MenuItemTitle">Account</div>
            <RiArrowRightSLine />
          </a>

          <a href={ config.homepage + 'klantenservice' } onClick={
            (event) => {
              setMenuPre('klantenservice');
              event.preventDefault();
            }
          }  className="MenuItem">
            <div className="MenuItemIcon">
              <RiCustomerServiceLine />
            </div>
            <div className="MenuItemTitle">Klantenservice <span className="MenuItemTitleBadge" /></div>

            <RiArrowRightSLine />
          </a>

          <a href={ config.homepage + 'winkelmandje' } onClick={
            (event) => {
              setMenuPre('winkelmandje');
              event.preventDefault();
            }
          }  className="MenuItem">
            <div className="MenuItemIcon">
              <RiShoppingBag3Line />
            </div>
            <div className="MenuItemTitle">Winkelmandje</div>
            <RiArrowRightSLine />
          </a>

          <a href={ config.homepage + 'vacatures' } onClick={
            (event) => {
              setMenuPre('vacatures');
              event.preventDefault();
            }
          }  className="MenuItem">
            <div className="MenuItemIcon">
              <RiBriefcaseLine />
            </div>
            <div className="MenuItemTitle">Werken bij DA</div>
            <RiArrowRightSLine />
          </a>

          <a href={ config.homepage + 'over-ons' } onClick={
            (event) => {
              setMenuPre('over-ons');
              event.preventDefault();
            }
          }  className="MenuItem showMobile">
            <div className="MenuItemIcon">
              <RiStore3Line />
            </div>
            <div className="MenuItemTitle">Over ons</div>
            <RiArrowRightSLine />
          </a>

          <div className="MenuItemDivider" />

          <div className="MenuItemSubtitle">Vind ons op social media</div>
          <div className="MenuSocials">


            <a target="_blank" href="https://www.facebook.com/dasuriname" className="MenuSocialsItem Fb" id="socials-fb">
              <RiFacebookFill />
            </a>

            <a target="_blank" href="https://www.instagram.com/dadrogistsuriname" className="MenuSocialsItem Ig" id="socials-ig">
              <RiInstagramFill />
            </a>

            <a target="_blank" href="https://www.tiktok.com/@da_suriname" className="MenuSocialsItem Ti" id="socials-ti">
              <FaTiktok />
            </a>


            <DaTooltip
              anchorId="socials-fb"
              place="bottom"
              content="Facebook"
            />
            <DaTooltip
              anchorId="socials-ig"
              place="bottom"
              content="Instagram"
            />
            <DaTooltip
              anchorId="socials-ti"
              place="bottom"
              content="TikTok"
            />
            <DaTooltip
              anchorId="socials-wa"
              place="bottom"
              content="WhatsApp"
            />
          </div>

        </div>

      </div>

      {  secondMenu() }

      { /*thirdMenu() */ }

      <div onClick={
        () => {
          setMenuPre();
        }
      } className="MenuOverlay" />
    </div>
  );
}

export default Menu;
