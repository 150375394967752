import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleLine,

 RiCheckFill,
 RiCloseFill,

 RiToggleLine,
 RiToggleFill,

 RiImage2Fill,

 RiBold,
 RiUnderline,
 RiItalic
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";
import { Editor, EditorState, RichUtils, ContentState, convertToRaw, convertFromRaw } from 'draft-js';

import 'draft-js/dist/Draft.css';
import htmlToDraft from 'html-to-draftjs';

import Resizer from 'react-image-file-resizer';
import DropDown from '../../../components/DropDown';


function SettingsBlogs(props) {
  const {
    token, account,
    setModalPre,
    searchQuery,
    subview_data,
    setSettingsHeaderPre,
    showSettingsHeaderPre
  } = props;
  const [ blogs, setBlogs ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ blogSelected, setBlogSelected ] = useState(null);
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);

  const inputImage = useRef();
  const inputTitle = useRef();

  const [ urlSubs, setUrlSubs ] = useState([]);
  const [ urlSubSelected, setUrlSubSelected ] = useState();
  const [ productUrl, setProductUrl ] = useState('');
  const [ subType, setSubType ] = useState('product');

  const [isDragging, setIsDragging] = useState(false);
  const [isLeavingDropzone, setIsLeavingDropzone] = useState(false);

  const [ editorState, setEditorState ] = React.useState(
    () => EditorState.createEmpty(),
  );
  const handleKeyCommand = (command) => {


    let nextState = null;
    if(command === 'bold'){

      nextState = RichUtils.toggleInlineStyle(editorState, "BOLD");
      setEditorState(nextState);

      return 'handled';
    }else if(command === 'underline'){

      nextState = RichUtils.toggleInlineStyle(editorState, "UNDERLINE");
      setEditorState(nextState);

      return 'handled';
    }else if(command === 'italic'){

      nextState = RichUtils.toggleInlineStyle(editorState, "ITALIC");
      setEditorState(nextState);

      return 'handled';
    }
    return 'not-handled';
  }

  const htmlToDraftBlocks = (html) => {

   html = html.replaceAll(/\s+/g,' ');

   const blocksFromHtml = htmlToDraft(html);


   const { contentBlocks, entityMap } = blocksFromHtml;
   const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
   const editorState = EditorState.createWithContent(contentState);

   return editorState;
  }

  const setBlogSelectedPre = (selected) => {

    if(selected){
      const { content } = selected;

      if(content && content != ''){
        setEditorState(htmlToDraftBlocks(content))
      }
    }

    if(selected){
      showSettingsHeaderPre(false);
    }else{
      showSettingsHeaderPre(true);
    }

    setBlogSelected(selected);
  }

  const fetchBlogs = (pageFinal = page, shouldLoad = true) => {

    if(shouldLoad){
      setLoading(true);
    }
    axios.post( config.api.url + 'admin/blogs', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(shouldLoad){
        setTimeout( () => {
          setLoading(false);
        }, 1);
      }

      if(success){
        const blogs = data.blogs;
        const stats = data.stats;

        if(blogs){
          setBlogs(blogs);
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/blogs/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchBlogs(page);
  }

  const blogsPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(blogs.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/blogs/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/blogs/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/blogs/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }

  useEffect( () => {


    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchBlogs(page_parsed);
    }else{

    }


    setSettingsHeaderPre(
      <div className="SettingsContentContentNavigation HideDesktop">
        <a onClick={
          () => {

            setBlogSelectedPre({
              id: 0,
              title: '',
              content: ' '
            });
          }
        } className="OrderItemOptionsButton AccentHover">
          <RiAddCircleLine style={{ fontSize: 14 }} />
          <span className="OrderItemOptionsButtonText">Aanmaken</span>
        </a>
      </div>
    )
  }, []);


  useEffect( () => {

    if(!loading){
      // controller.abort();
      fetchBlogs();
    }

  }, [searchQuery])

  const toggleBlogActive = (blog_id = 0, blog_index) => {

    if(blog_id > 0){


      blogs[blog_index].active = !blogs[blog_index].active;
      if(blogSelected){
        blogSelected.active = blogs[blog_index].active;
      }

      // setLoading(true);
      axios.post( config.api.url + 'admin/blogs/toggle', {
        hash: token,
        blog_id: blog_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        // setTimeout( () => {
        //   setLoading(false);
        // }, 1);


        if(success){

          fetchBlogs(page, false);
        }

      })
      .catch(function (error) {
        // setLoading(false);
      });

    }
  }

  const removeBlog = (blog_id = 0) => {

    if(blog_id > 0){

      setBlogs(blogsOld => {
        return blogsOld.filter((b, i) => b.id != blog_id)
      });

      if(blogSelected){
        setBlogSelectedPre(null)
      }

      setLoading(true);
      axios.post( config.api.url + 'admin/blogs/delete', {
        hash: token,
        blog_id: blog_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);

        // console.log(json.data)

        if(success){

          fetchBlogs();

        }

      })
      .catch(function (error) {
        setLoading(false);
      });

    }
  }

  const insert = (main_string, ins_string, pos) => {
    if(typeof(pos) == "undefined"){
      pos = 0;
    }
    if(typeof(ins_string) == "undefined"){
      ins_string = '';
    }
    return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
  }


  const selectImage = (file) => {

    if(file) {
        try {

           let type = 'JPEG';
           switch(file.type){
             case "image/png":
              type = 'PNG';
             break;
           }

            Resizer.imageFileResizer(
            file,
            1000,
            1000,
            type, 90,0,
            uri => {

              if(blogSelected){
                setBlogSelected( blogOld => {
                  return {
                    ...blogOld,
                    image: {
                      uri: URL.createObjectURL(file),
                      base: uri
                    }
                  }
                })
              }
            },
            'base64',
            500,
            500,
            );
        }   catch(err) {

        }
    }
  }


  const saveBlogItem = () => {
    const contentState = editorState.getCurrentContent();
    const title = inputTitle.current?.value;
    const blogContentObject = convertToRaw(contentState);
    const c2a = (productUrl) ? JSON.stringify({
      url: productUrl
    }) : '';


    // Go Through blocks
    let text = '';
    const blocks = blogContentObject.blocks;
    for(let b = 0; b < blocks.length; b++){
      const block = blocks[b];

      let localText = block.text;
      const styleChanges = block.inlineStyleRanges;

      let realOffset = 0;
      for(let t = 0; t < styleChanges.length; t++){
        const styleChange = styleChanges[t];


        const offset = realOffset + styleChange.offset;
        const length = styleChange.length;

        switch(styleChange.style){

          case "BOLD":
            localText = insert(localText, '<strong>', offset);
            localText = insert(localText, '</strong>', offset + length + 8);

            realOffset = realOffset + (8 + 9);
          break;
          case "UNDERLINE":
            localText = insert(localText, '<u>', offset);
            localText = insert(localText, '</u>', offset + length + 3);

            realOffset = realOffset + (3 + 4);
          break;
          case "ITALIC":
            localText = insert(localText, '<i>', offset);
            localText = insert(localText, '</i>', offset + length + 3);

            realOffset = realOffset + (3 + 4);
          break;
        }
      }

      text += localText + '<br/>';
    }


    // Replace
    text = text.replace(/(?:\r\n|\r|\n)/g, '<br/>');
    text = text.replace(/(<br\/>\s*)+$/, "");

    const image = (blogSelected.image) ? ((typeof blogSelected.image == 'string') ? blogSelected.image : (blogSelected.image).base) : '';

    if(text != ''){
      setLoading(true);
      axios.post( config.api.url + 'admin/blogs/save', {
        hash: token,
        blog_id: blogSelected.id,
        image: image,
        title: title,
        content: text,
        c2a: c2a,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);

        // console.log(json.data)

        if(success){

          const listIndex = (blogs).findIndex( (b, i) => blogSelected.id == b.id);
          if(listIndex >= 0){
            blogs[listIndex].title = title;
            blogs[listIndex].content = text;
          }

          // Set stuff
          fetchBlogs();
          setBlogSelectedPre(null);

          // Save

        }else{
          setModalPre({
            title: 'Oops.',
            subtitle: response
          })
        }

      })
      .catch(function (error) {
        setLoading(false);
      });
    }else{
      setModalPre({
        title: 'Oops.',
        subtitle: 'Velden zijn leeg.'
      })
    }

  }




  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setIsLeavingDropzone(false);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    const { relatedTarget } = e;

    // Check if the relatedTarget is null or outside the dropzone
    if (!relatedTarget || !e.currentTarget.contains(relatedTarget)) {
      setIsDragging(false);
      setIsLeavingDropzone(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    if (!isLeavingDropzone) {
      setIsDragging(true);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    setIsLeavingDropzone(false);

    const file = e.dataTransfer.files[0];

    if(file){
      selectImage(file)
    }
  };

  const fetchSubItems = (type = 'product', query = '') => {

    setSubType(type);
    // setSubLoading(true);
    axios.post( config.api.url + 'admin/subitems', {
      hash: token,
      type: type,
      query: query
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;


      if(success){
        const items = data.items;

        setUrlSubs(items);

      }

    })
    .catch(function (error) {
      // setSubLoading(false);
    });
  }

  useEffect(() => {
    
    fetchSubItems();

  },[editorState])

  const editorView = () => {

    const inlineStyle = editorState.getCurrentInlineStyle();
    const isBold = inlineStyle.has("BOLD");
    const isUnderline = inlineStyle.has("UNDERLINE");
    const isItalic = inlineStyle.has("ITALIC");


    return (
      <div className={`editorContent ${(blogSelected) ? 'Show' : ''}`}>
        <div className="editorContentSplitter">
          <div className={`ModalSide ${ (isDragging) ? 'Disable' : ''}`}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}>
            <div className="ModalSideHeader">
              <div className="ModalSideHeaderTitle">Blog { (blogSelected && blogSelected.id > 0) ? 'bewerken' : 'aanmaken' }</div>

              { (blogSelected && blogSelected.id > 0) &&
              <a onClick={
                () => {

                  if(blogSelected && blogSelected.id){
                    const modalIndex = (blogs).findIndex( (b, i) => b.id == blogSelected.id);
                    toggleBlogActive(blogSelected.id, modalIndex);
                  }

                }
              } className={`OrderItemOptionsButton ${ (blogSelected.active) ? 'DeleteInverted' : 'AddInverted' }`}>
                { (blogSelected.active)
                ?
                <RiToggleFill style={{ fontSize: 14 }} />
                :
                <RiToggleLine style={{ fontSize: 14 }} />
                }
                <span className="OrderItemOptionsButtonText">{ (blogSelected.active) ? 'Deactiveren' : 'Activeren' }</span>
              </a>
              }

              { (blogSelected && blogSelected.id > 0) &&
              <a onClick={
                () => {

                  setModalPre({
                    title: 'Verwijderen.',
                    subtitle: 'Ben je zeker dat je dit item wilt verwijderen?',
                    content: (
                      <>
                        <div className="modalContentOptions NoBorder">
                          <a onClick={
                            () => {

                              removeBlog(blogSelected.id);
                              setModalPre();
                            }
                          } className="modalContentOptionsButton Delete">Verwijderen</a>
                          <a onClick={
                            () => {

                              setModalPre();
                            }
                          } className="modalContentOptionsButton">Annuleren</a>
                        </div>
                      </>
                    )
                  })

                }
              } className="OrderItemOptionsButton Delete">
                <RiDeleteBin5Line style={{ fontSize: 14 }} />
              </a>
              }
            </div>

            <div className="ModalSideContent">
              { (blogSelected) &&
              <div
                className={`ModalSideContentImageWrapper ${ (isDragging) ? 'Enabled' : ''} `}
                onDrop={handleDrop}
              >
                {
                (isDragging)
                ?
                <div className="ModalSideContentImageDrop">
                  <div className="ModalSideContentImageDropText">Laat de foto hier los om te selecteren.</div>
                </div>
                :
                <>
                  <div className="ModalSideContentImage" style={{ backgroundImage: `url(${ (blogSelected.image) ? ((typeof blogSelected.image == 'string') ? (blogSelected.image) : (blogSelected.image.uri)) : '' })` }}>
                    <div className="ModalSideContentImageOverlay">
                      <div onClick={
                        () => {

                          inputImage.current?.click();

                        }
                      } className="ModalSideContentImageOverlayCircle">
                        <RiImage2Fill />
                        <input ref={ inputImage } type="file" accept=".jpg, .jpeg, .png, .gif" style={{ display: 'none' }} onChange={
                          (event) => {
                             const file = event.target?.files[0];

                             selectImage(file);
                          }
                        } />
                      </div>
                    </div>
                  </div>
                  <div className="ModalSideContentImageDetails">
                    <div className="ModalSideContentImageDetailsText">{ (blogSelected.image != '') ? 'Foto actief.' : 'Geen foto geselecteerd.' }</div>
                    <div className="ModalSideContentImageDetailsHint">Sleep een foto of klik op 'selecteer foto'.</div>
                    <div className="ModalSideContentImageDetailsOptions">
                      { (blogSelected && (blogSelected.image) && blogSelected.image != '') &&
                      <a onClick={
                        () => {

                          setBlogSelected( blogOld => {
                            return {
                              ...blogOld,
                              image: ''
                            }
                          })

                        }
                      } className="ModalSideContentImageDetailsOptionsButton Delete">Verwijderen</a>
                      }
                      <a onClick={
                        () => {

                          inputImage.current?.click();
                        }
                      } className="ModalSideContentImageDetailsOptionsButton">Selecteer foto</a>
                    </div>
                  </div>
                </>
                }
              </div>
              }


              <div className="ModalSideContentTwin Single" style={{flexDirection: 'column'}}>
                <div className="ModalSideContentInput" style={{width: '100%', marginBottom: '20px'}}>
                  <div className="ModalSideContentInputPre">Titel</div>
                  <input
                    ref={inputTitle}
                    key={ JSON.stringify(blogSelected) }
                    defaultValue={ (blogSelected && blogSelected.title) ? blogSelected.title : '' }
                    placeholder={'Titel van het artikel'}
                    className={`ModalSideContentInputInput`}
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                        switch(key){
                          case "Enter":
                            if(!loading){
                              saveBlogItem();
                            }
                          break;
                        }
                      }
                    }
                    style={{width: '100%'}}
                  />
                </div>
                <div className="ModalSideContentInput" style={{width: '100%'}}>
                  <div className="ModalSideContentInputPre" style={{marginTop: '20px'}}>Product</div>
                  <div className="ModalSideContentInputFull">
                    <DropDown 
                      key={JSON.stringify(subType)}
                      selected={(urlSubSelected) ? urlSubSelected.value : 'Selecteer item'}
                      list={(urlSubs).map( (type, index) => { return { key: type.key, value: type.value }})}
                      onSearch={
                        (query) => {

                          if(subType != ''){
                            fetchSubItems(subType, query);
                          }
                        }
                      }
                      onSelect={
                        (item) => {
                          const b_url = 'product/' + item.key;

                          setUrlSubSelected(item);
                          setProductUrl(b_url);

                          // console.log(urlSubSelected);
                        }
                      }
                    />
                  </div>

                </div>
              </div>


            </div>

          </div>
          <div className="editorContentDetails Flexed">
            <div className="editorHeader Float">
              <a onClick={
                () => {
                  setBlogSelectedPre(null)
                }
              } className="editorHeaderClose">
                <RiCloseLine />
              </a>
            </div>
            <div className="editorNavigation NoTop">
              <button onClick={ () => {
                const nextState = RichUtils.toggleInlineStyle(editorState, "BOLD");
                setEditorState(nextState);
              }} className={`editorNavigationButton ${(isBold) ? 'Selected' : ''}`}>
                <RiBold />
              </button>
              { /*
              <button onClick={ () => {
                const nextState = RichUtils.toggleInlineStyle(editorState, "UNDERLINE");

                setEditorState(nextState);
              }} className={`editorNavigationButton ${(isUnderline) ? 'Selected' : ''}`}>
                <RiUnderline />
              </button>
              <button onClick={ () => {
                const nextState = RichUtils.toggleInlineStyle(editorState, "ITALIC");

                setEditorState(nextState);
              }} className={`editorNavigationButton ${(isItalic) ? 'Selected' : ''}`}>
                <RiItalic />
              </button>
              */ }
            </div>
            <div className="editorEditor">
              <Editor
              editorState={editorState}
              onChange={setEditorState}
              handleKeyCommand={handleKeyCommand}
              />
            </div>


            <div className="modalContentOptions LightBorder">
              <a onClick={
                () => {
                  saveBlogItem();
                }
              } className="modalContentOptionsButton C2a">Opslaan</a>
              <a onClick={
                () => {

                  setBlogSelectedPre(null)
                }
              } className="modalContentOptionsButton">Annuleren</a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const blogs_items = (blogs).map( (blog_item, index) => {

    return (
      <div className="OrderItem" key={'blog_item_' + index}>
        <div className="OrderItemDetailsText IDSmall">#{ blog_item.id }</div>
        <div className="OrderItemDetailsText Active">
          <span className={`OrderItemDetailsTextOption Circle ${ (blog_item.active) ? 'Success' : 'Error' }`}>
            { (blog_item.active)
            ?
            <RiCheckFill style={{ fontSize: 12 }} />
            :
            <RiCloseFill style={{ fontSize: 12 }} />
            }
          </span>
        </div>
        <div className="OrderItemDetailsText Image">
          <div className="OrderItemDetailsTextImage" style={{ backgroundImage: `url(${blog_item.image})` }} />
        </div>
        <div className="OrderItemDetailsText Title Bold">{ blog_item.title }</div>
        <div className="OrderItemDetailsText Date">{ blog_item.timestamp_last?.date }</div>

        <div className="OrderItemOptions">

          <a onClick={
            () => {

              toggleBlogActive(blog_item.id, index);

            }
          } className={`OrderItemOptionsButton ${ (blog_item.active) ? 'DeleteInverted' : 'AddInverted' }`}>
            { (blog_item.active)
            ?
            <RiToggleFill style={{ fontSize: 14 }} />
            :
            <RiToggleLine style={{ fontSize: 14 }} />
            }
            <span className="OrderItemOptionsButtonText">{ (blog_item.active) ? 'Deactiveren' : 'Activeren' }</span>
          </a>

          <a onClick={
            () => {

              setModalPre({
                title: 'Verwijderen.',
                subtitle: 'Ben je zeker dat je dit item wilt verwijderen?',
                content: (
                  <>
                    <div className="modalContentOptions NoBorder">
                      <a onClick={
                        () => {


                          removeBlog(blog_item.id);
                          setModalPre();
                        }
                      } className="modalContentOptionsButton Delete">Verwijderen</a>
                      <a onClick={
                        () => {

                          setModalPre();
                        }
                      } className="modalContentOptionsButton">Annuleren</a>
                    </div>
                  </>
                )
              })

            }
          } className="OrderItemOptionsButton Delete">
            <RiDeleteBin5Line style={{ fontSize: 14 }} />
          </a>
          <a onClick={
            () => {

              setBlogSelectedPre(blog_item);

            }
          } className="OrderItemOptionsButton Edit">
            <RiFileShield2Line style={{ fontSize: 14 }} />
            <span className="OrderItemOptionsButtonText">Wijzigen</span>
          </a>
        </div>
      </div>
    )
  })


  return (
    <div className="SettingsContentContent List">

      { editorView() }


      <div className="SettingsMessageText ShowMobile">Voorlopig alleen op desktop beschikbaar.</div>

      <div className={`settingsContentReal HideDesktop ${(blogSelected) ? 'Show' : ''}`}>
        <div className="SettingsContentNavigation Margin">
          <div className="SettingsContentNavigationItem IDSmall">ID</div>
          <div className="SettingsContentNavigationItem Active">Actief</div>
          <div className="SettingsContentNavigationItem Image">Foto</div>
          <div className="SettingsContentNavigationItem Title">Titel</div>
          <div className="SettingsContentNavigationItem Date">Aangepast</div>
          <div className="SettingsContentNavigationItem OptionsBig"></div>
        </div>

        <div className="GlobSettingsScroll">

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }


          { (blogs_items.length > 0)
          ?
          blogs_items
          :
          <div className="SettingsMessageText">Geen blogs.</div>
          }
        </div>

        { blogsPagination() }
      </div>

    </div>
  )
}

export default SettingsBlogs;
