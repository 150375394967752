import React from 'react';
import ReactDOM from 'react-dom/client';
// import ReactDOM from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './responsive.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
// ReactDOM.render(
//   <App />,
//   document.getElementById("root")
// );
