import '../styles/CustomerAI.css';
import config from '../constants/config';
import colors from '../constants/colors';
import React, { useState, useEffect, useRef } from 'react';
import { Tooltip as DaTooltip } from "react-tooltip";
import {
  RiCheckFill,
  RiCloseLine,
  RiChatSmile2Fill,
  RiMessage3Fill,
  RiSendPlane2Fill,
  RiUserSmileLine,
  RiArrowRightLine,
  RiArrowDownSLine,
  RiFileSearchFill,
  RiReplyAllFill
} from "react-icons/ri";
import Moment from 'react-moment';
import ScrollToBottom from 'react-scroll-to-bottom';
import loadingAnimation from '../animations/loading_circle.json';
import typingAnimation from '../animations/typing.json';
import loginAnimation from '../animations/app_showcase.json';
import Lottie from "lottie-react";
import axios from "axios";

import useSound from 'use-sound';
import newMessageSound from '../sounds/new_message.mp3';

function CustomerAI(props) {
  const {
    setViewPre,
    setModalPre,
    token,
    account,
    view,
    hideChat,
    showAIChat
  } = props;
  const [ showChatMessages, setShowChatMessages ] = useState(false);
  const [ newMessage, setNewMessage ] = useState(false);
  const [ play ] = useSound(newMessageSound);


  // AI CHATS
 const inputChat  = useRef();
 const chatScrollView = useRef();
 const chatThinking = useRef();
 const [ aiID, setAiID ] = useState(0);
 const [ aiThinking, setAiThinking ] = useState(false);
 const [ loading, setLoading ] = useState(false);
  const [ messages, setMessages ] = useState([
    {
      role: 'assistant',
      content: 'Hi, waarmee kan ik je helpen?',
      content_raw: 'Hi, waarmee kan ik je helpen?',
      date: Math.floor(((new Date()).getTime()) / 1000)
    }
  ]);


  let showChat = true;
  let view_parsed = view;
  if((view).includes('category')){
    view_parsed = 'producten';
  }else if((view).includes('account')){
    view_parsed = 'account';
  }else if((view).includes('producten')){

  }else if((view).includes('product')){
    view_parsed = 'product';
  }else if((view).includes('blogs')){
    view_parsed = 'blogs';
  }else if((view).includes('blog')){
    view_parsed = 'blog';
  }

  switch(view_parsed){
    case "klantenservice":
    case "login":
    case "account":
      showChat = false;
    break;
  }


  const fetchChats = () => {

    axios.post( config.api.url + 'chats', {
      hash: token
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      console.log(json.data)

      if(success){
        const chat = data.chat;
        const chats = data.chats;

        if(chat && chat.id > 0){
          setAiID(chat.id);
        }

        if(chats && chats.length > 0){
          setMessages(chats);
        }

      }

    })
    .catch(function (error) {

    });

  }

  useEffect( () => {

    if(showChatMessages && !aiThinking){
      fetchChats();
    }

    if(showChatMessages){
      if(inputChat){
        inputChat.current?.focus();
      }
    }

  }, [showChatMessages, showChat]);

  useEffect( () => {

    if(!account){
      setMessages( messages.filter( (message, index) => index == 0) );
    }

  }, [account]);

  useEffect( () => {

    if(showChatMessages && newMessage){
      setNewMessage(false)
    }

  }, [newMessage]);





  if(!showChat || (!account && messages.length > 1)) return false;


  const now = Math.floor(Date.now() / 1000);

  let cus_class = '';

  if(showAIChat){

    if(showChat){
      cus_class = '';
    }else {

    }
  }else{
    if(showChatMessages){
      cus_class = 'Full Show';
    }else{
      cus_class = 'Full';
    }
  }

  const setNewMessagePre = (newMess) => {

    play();
    setNewMessage(newMess);

  }

  const sendChat = () => {
    const message = inputChat.current?.value;

    if(message != ''){
      const now = Math.floor(((new Date()).getTime()) / 1000)

      const newMessage = {
        role: 'user',
        content: message,
        content_raw: message,
        date: now
      };

      const newMessages = [...messages, newMessage];
      setMessages(newMessages);


      // Clear
      inputChat.current.value = '';

      const sendMessages = [...(messages).map( (msg, index) => {
        return {
          role: msg.role,
          content: msg.content_raw,
          date: msg.date
        }
      }), {
        role: 'user',
        content: message,
        date: now
      }];

      // Clear
      inputChat.current.value = '';

      setTimeout( () => {
        setAiThinking(true);

        setTimeout( () => {


          // chatThinking.current?.scrollIntoView({ behavior: "smooth", block: "end" });

          axios.post( config.api.url + 'helpdesk/chat', {
            hash: token,
            messages: sendMessages,
            chat_id: aiID
          }, {
              crossdomain: true,
              headers: {
                'api': config.api.key
              }
          })
          .then((json) => {
            const { success, response, data } = json.data;


            if(response && response != ''){
              const newMessageAssistant = data.chat;

              setNewMessagePre(true);
              setMessages(msgs => [...msgs, newMessageAssistant]);

              if(data && data.chat_id > 0){
                setAiID(data.chat_id);
              }

            }

            setAiThinking(false);
          })
          .catch(function (error) {


            setAiThinking(false);
          });

        }, 150);

      }, 150)


    }
  }

  const messagesContent = () => {


    const messagesObjects = (messages).map( (message, index) => {

      if(!message.content) return false;
      const nextMessage = ((index + 1) < messages.length) ? ((messages[index + 1].role == message.role) ? true : false) : false;
      const messageContentFinal = (message.content).replace(/\u([\dA-Fa-f]{4})/g, (match, hex) => {
        return String.fromCodePoint(parseInt(hex, 16));
      });


      let chat_data = null;
      if(message.has_links){
        const link_data = message.data;

        chat_data = (
          <div onClick={
            () => {
              setViewPre(link_data.url);
              setShowChatMessages(false);
            }
          } className="ChatMessageItemLink">
            <RiFileSearchFill />
            <div className="ChatMessageItemLinkText">{ link_data.text }</div>
            <RiArrowRightLine />
          </div>
        )
      }


      return (
        <div className={`ChatMessageItemWrapper ${ (message.role) } ${ (nextMessage) ? 'Grouped' : '' }`} key={'chat_' + index}>
          <div className="ChatMessageItem">
            <div className="ChatMessageItemAvatarWrapper">
            { (message.role != 'user') &&
            <div className="ChatMessageItemAvatar" style={{ backgroundImage: `url(${ config.homepage + 'data/logos/logo_flat.png'  })` }} />
            }
            <div className="ChatMessageItemTextView">
              <div className="ChatMessageItemText" dangerouslySetInnerHTML={{__html: messageContentFinal }}></div>
              { chat_data }
            </div>
            { (message.role == 'user' && !nextMessage) &&
            <div className="ChatMessageItemAvatar" style={{ backgroundImage: `url('${ account.avatar }')` }}>
              { (account.avatar == '') &&
              <RiUserSmileLine />
              }
            </div>
            }
            </div>
            { (!nextMessage) &&
            <div className="ChatMessageItemDate"><Moment fromNow unix>{ message.date }</Moment></div>
            }
          </div>
        </div>
      )
    });


    if(account){
      const lastSent = (messages[messages.length - 1].role == 'user');

      if(aiThinking){
        messagesObjects.push(
          <div ref={chatThinking} className={`ChatMessageItemWrapper assistant`} key={'chat_loading'}>
            <div className="ChatMessageItem">
              <div className="ChatMessageItemAvatarWrapper">
              <div className="ChatMessageItemAvatar" style={{ backgroundImage: `url(${ config.homepage + 'data/logos/logo_flat.png'  })` }} />
              <div className="ChatMessageItemText Small">

                <div className="LoginLoading">
                  <Lottie className="LoginLoadingAnimation" animationData={typingAnimation} loop={true} />
                </div>

              </div>
              </div>
            </div>
          </div>
        )
      }

    }

    // RESET
    if(messages.length > 10){
      messagesObjects.push(
        <div className={`ChatResetView`} key={'chat_reset'}>
          <div className="ChatResetPre">Wijkt de conversatie af?</div>
          <div className={`ChatReset`} onClick={
            () => {
              setModalPre({
                title: 'Opnieuw beginnen.',
                subtitle: 'Start een verse converstatie als het teveel afwijkt.',
                content: (
                  <>
                    <div className="modalContentOptions NoBorder">
                      <a onClick={
                        () => {
                          setMessages([
                            {
                              role: 'assistant',
                              content: 'Hi, waarmee kan ik je helpen?',
                              content_raw: 'Hi, waarmee kan ik je helpen?',
                              date: Math.floor(((new Date()).getTime()) / 1000)
                            }
                          ]);
                          setAiID(0);
                          setModalPre();
                        }
                      } className="modalContentOptionsButton C2a AccentHover">Opnieuw beginnen</a>
                      <a onClick={
                        () => {

                          setModalPre();
                        }
                      } className="modalContentOptionsButton">Annuleren</a>
                    </div>
                  </>
                )
              })
            }
          }>

            <div className="ChatResetText">CONVERSATIE RESETTEN</div>
            <RiReplyAllFill />

          </div>
        </div>
      )
    }

    return messagesObjects;
  }

  const aiChats = () => {


    return (
      <div className="CustomerServiceChat">
        <div className="CustomerServiceChatHeader">
          <RiMessage3Fill style={{ fontSize: 16 }} />
          <div className="CustomerServiceChatHeaderTitle">DA Instant hulp</div>
          <div className="CustomerServiceChatHeaderAI">AI POWERED</div>
          <a onClick={
            (event) => {
              setShowChatMessages(false);

              event.preventDefault();
            }
          } className="CustomerAIHeaderClose">
            <RiArrowDownSLine />
          </a>
        </div>
        <ScrollToBottom className="CustomerServiceChatContent" scrollViewClassName="CustomerServiceChatContentItems" followButtonClassName="NoShowButton">
          { messagesContent() }
        </ScrollToBottom>
        <div className="CustomerServiceChatMessageView">
          <input
            ref={inputChat}
            className="CustomerServiceChatMessageInput"
            placeholder={'Begin met typen ...'}
            onKeyUp={
              (event) => {
                const key = event.key;

                switch(key){
                  case "Enter":
                    if(!aiThinking && account){
                      sendChat();
                    }

                    if(!account){
                      setShowChatMessages(false);
                      setModalPre({
                        title: 'Je bent niet ingelogd.',
                        subtitle: 'Maak eenmalig een account aan bij DA en shop met veel plezier!',
                        content: (
                          <>
                            <div className="modalContentContent Row">
                              <div className="modalContentAnimationWrapper">
                                <Lottie className="modalContentAnimation" animationData={loginAnimation} loop={true} />
                              </div>
                              <div className="LoginFormSignupView">
                                <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/swirl.png'})` }} />
                                <div className="LoginFormHeader">
                                  <div className="LoginFormHeaderTitle">Nog geen <b>DA</b> account?</div>
                                </div>

                                <a onClick={
                                  () => {
                                    setViewPre('account/registreren');
                                    setModalPre();
                                  }
                                } className="LoginFormOptionsButton Signup">
                                  <div className="LoginFormOptionsButtonText">Registreren</div>
                                  <RiArrowRightLine />
                                </a>

                                <div className="LoginFormOrView">
                                  <div className="LoginFormOrDivider" />
                                  <div className="LoginFormOr">OF</div>
                                  <div className="LoginFormOrDivider" />
                                </div>

                                <a onClick={
                                  () => {
                                    setViewPre('account/login');
                                    setModalPre();
                                  }
                                } className="LoginFormOptionsButton Margined">
                                  <div className="LoginFormOptionsButtonText">Inloggen</div>
                                  <RiArrowRightLine />
                                </a>

                              </div>
                            </div>
                            <div className="modalContentOptions NoBorder">
                              <a onClick={
                                () => {

                                  setModalPre();
                                }
                              } className="modalContentOptionsButton">Annuleren</a>
                            </div>
                          </>
                        )
                      })
                    }
                  break;
                }
              }
            }
          />
          <a href="#" onClick={
            (event) => {

              if(!aiThinking && account){
                sendChat();
              }

              if(!account){
                setShowChatMessages(false);
                setModalPre({
                  title: 'Je bent niet ingelogd.',
                  subtitle: 'Maak eenmalig een account aan bij DA en shop met veel plezier!',
                  content: (
                    <>
                      <div className="modalContentContent Row">
                        <div className="modalContentAnimationWrapper">
                          <Lottie className="modalContentAnimation" animationData={loginAnimation} loop={true} />
                        </div>
                        <div className="LoginFormSignupView">
                          <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/swirl.png'})` }} />
                          <div className="LoginFormHeader">
                            <div className="LoginFormHeaderTitle">Nog geen <b>DA</b> account?</div>
                          </div>

                          <a onClick={
                            () => {
                              setViewPre('account/registreren');
                              setModalPre();
                            }
                          } className="LoginFormOptionsButton Signup">
                            <div className="LoginFormOptionsButtonText">Registreren</div>
                            <RiArrowRightLine />
                          </a>

                          <div className="LoginFormOrView">
                            <div className="LoginFormOrDivider" />
                            <div className="LoginFormOr">OF</div>
                            <div className="LoginFormOrDivider" />
                          </div>

                          <a onClick={
                            () => {
                              setViewPre('account/login');
                              setModalPre();
                            }
                          } className="LoginFormOptionsButton Margined">
                            <div className="LoginFormOptionsButtonText">Inloggen</div>
                            <RiArrowRightLine />
                          </a>

                        </div>
                      </div>
                      <div className="modalContentOptions NoBorder">
                        <a onClick={
                          () => {

                            setModalPre();
                          }
                        } className="modalContentOptionsButton">Annuleren</a>
                      </div>
                    </>
                  )
                })
              }
              event.preventDefault();
            }
          } className={`CustomerServiceChatMessageButton ${ (aiThinking) ? 'Disabled' : '' }`}>
            <RiSendPlane2Fill />
          </a>
         </div>
      </div>
    )
  }


  return (
    <div className={`CustomerAI ${cus_class} ${(newMessage) ? 'New' : ''}`}>

      <div className={`FullOverlay`} onClick={
        () => {
          setShowChatMessages(false);
        }
      } />

      { (showAIChat) &&
      <a id={'customer-ai'} href={config.homepage + 'klantenservice'} onClick={
        (event) => {

          setViewPre('klantenservice');
          hideChat();

          event.preventDefault();
        }
      } className="CustomerAIButtonChatsWrapper">
        <div className={`CustomerAIButtonChats`}>
          <div className="CustomerAIButtonChatsAvatar" style={{ backgroundImage: `url(${ config.homepage + 'data/logos/logo_flat.png'  })` }} />
          <div className="CustomerAIButtonChatsChat">
            <div className="CustomerAIButtonChatsChatText">Hoi, hoe kan ik je helpen?</div>
            <div className="CustomerAIButtonChatsChatDate"><Moment fromNow unix>{ now }</Moment></div>
          </div>
        </div>
      </a>
      }
      { (showAIChat) &&
      <a onClick={
        (event) => {
          hideChat();
          event.preventDefault();
        }
      } className="CustomerAIButtonChatsClose">
        <RiCloseLine />
      </a>
      }

      { (!showAIChat) &&
      <div className={`CustomerAIMessages ${ (showChatMessages) ? 'Show' : ''}`}>

        { aiChats() }

      </div>
      }

      <div onClick={
        () => {

          if(showAIChat){
            hideChat();
          }
          setTimeout( () => {
            setShowChatMessages(!showChatMessages);

            if(newMessage){
              setNewMessage(false);
            }
          }, (showAIChat) ? 300 : 0)
        }
      } className={`CustomerAIButton ${ (aiThinking) ? 'Loading' : '' }`}>
        <div className="CustomerAIButtonIcon">
          <RiChatSmile2Fill />
        </div>
        { (newMessage) &&
        <span className="CustomerAIButtonBadge" />
        }
      </div>

      <DaTooltip
        anchorId="customer-ai"
        place="left"
        content={ 'Chat met DA Bot' }
      />
    </div>
  );
}

export default (CustomerAI);
