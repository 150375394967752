import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,
 RiCheckDoubleLine,

 RiAddCircleLine,

 RiCheckFill,
 RiCloseFill,

 RiImage2Fill,
 RiFileTextFill,

 RiToggleLine,
 RiToggleFill,
 RiArrowGoBackLine
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";
import Resizer from 'react-image-file-resizer';

import ReactApexChart from 'react-apexcharts';
import DropDown from "../../../components/DropDown";

import dutchStrings from 'react-timeago/lib/language-strings/nl'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

function AnalDashboard(props) {
  const {
    token, account,
    setSettingsHeaderPre,
    searchQuery,
    subview_data,
  } = props;
  const [ loading, setLoading ] = useState(false);

  const formatter = buildFormatter(dutchStrings);
  const currentMonthNumber = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();


  const [ month, setMonth ] = useState(currentMonthNumber);
  const [ year, setYear ] = useState(currentYear);
  const [ stats, setStats ] = useState();
  const controller = new AbortController();

  function transformNumberToArray(number, val = -1, end = '') {
    if (number < 1) {
      return [];
    }
    
    return Array.from({ length: number }, (_, index) => (val == -1) ? (index == (number -1) ? 'Rest' : ((index + 1)+ end)) : (val + end));
  } 

  const fetchAnalytics = (shouldLoad = true) => {

    if(shouldLoad){
      setLoading(true);
    }

    if(controller){
      controller.abort();
    }

    axios.post( config.api.url + 'admin/analytics/dash', {
      signal: controller.signal,
      hash: token,
      month: month,
      year: year
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;
      
      if(shouldLoad){
        setTimeout( () => {
          setLoading(false);
        }, 1);
      }


      if(success){
        const stats = data.stats;


        if(stats){
          setStats(stats);
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
      console.log(error)
    });

  }
  
  const rangeView = () => {
    if (month < 1 || month > 12) {
        return "Ongeldig maandnummer";
    }

    const weekdays = ["Zondag", "Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag"];
    const months = [
        "januari", "februari", "maart", "april", "mei", "juni",
        "juli", "augustus", "september", "oktober", "november", "december"
    ];

    // Calculate the first day of the specified month
    const firstDayOfMonth = new Date(year, month - 1, 1);

    // Calculate the last day of the specified month
    const lastDayOfMonth = new Date(year, month, 0);

    const formattedFirstDay = `${weekdays[firstDayOfMonth.getDay()]}, ${firstDayOfMonth.getDate()} ${months[firstDayOfMonth.getMonth()]} ${firstDayOfMonth.getFullYear()}`;
    const formattedLastDay = `${weekdays[lastDayOfMonth.getDay()]}, ${lastDayOfMonth.getDate()} ${months[lastDayOfMonth.getMonth()]} ${lastDayOfMonth.getFullYear()}`;

    return `${formattedFirstDay} - ${formattedLastDay}`;
 }

  function getMonthName(monthNumber) {
    const monthNamesDutch = [
        "Januari", "Februari", "Maart", "April", "Mei", "Juni",
        "Juli", "Augustus", "September", "Oktober", "November", "December"
    ];

      if (monthNumber >= 1 && monthNumber <= 12) {
          return monthNamesDutch[monthNumber - 1];
      } else {
          return "Invalid month number";
      }
  }


  useEffect( () => {

    
    fetchAnalytics(true);
    
    setSettingsHeaderPre(
      <div className="DashDashBlockOptionsFlipper">
          <div onClick={
              () => {
                if(month == 1){
                    setYear(year - 1);
                    setMonth(12);
                }else{
                    setMonth(month - 1);
                }
              }
          } className={`DashDashBlockOptionsFlipperNum ${(month > 0) ? 'Active' : ''}`}>
              <RiArrowLeftLine />
              <div className="DashDashBlockOptionsFlipperNumText">Vorige Maand</div>
          </div>
          <div className="DashDashBlockOptionsFlipperTextView">
            <div className="DashDashBlockOptionsFlipperText">{ getMonthName(month) + ((year < currentYear) ? (' ' + year) : '') }</div>
            { ((year == currentYear && month != currentMonthNumber) || (year < currentYear)) &&
            <div onClick={
                () => {
                    setYear(currentYear);
                    setMonth(currentMonthNumber);
                }
            } className={`DashDashBlockOptionsFlipperReset`}>
                <RiArrowGoBackLine />
                <div className="DashDashBlockOptionsFlipperResetText">{ 'Huidige Maand' }</div>
              </div>
            }
          </div>
          <div onClick={
              () => {

                if(year < currentYear && month <= 12){
                      if(month == 12){
                        setMonth(1);
                        setYear(year + 1);
                      }else{
                        setMonth(month + 1);
                      }
                }else if(month < currentMonthNumber){
                      setMonth(month + 1);
                }
              }
          } className={`DashDashBlockOptionsFlipperNum ${ ( (year < currentYear && month <= 12) || (month < currentMonthNumber) ) ? 'Active' : '' }`}>
              <div className="DashDashBlockOptionsFlipperNumText">Volgende Maand</div>
              <RiArrowRightLine />
          </div>
      </div>
  )

  }, [month, year]);





  // DASH 
  let seriesNew = [
    {
        name: 'Activiteiten',
        data: (stats && stats.events.length > 0) ? stats.events : []
      }
  ];
  
  const optionsNew = {
      chart: {
        foreColor: "#1b1b1b",
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 20,
          left: 0,
          blur: 15,
          opacity: 0.15
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: []
          },
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 5
        }
      },
      colors: ['#e0197d'],
      dataLabels: {
        enabled: false,
        style: {
          fontWeight: 'bold',
          fontSize: 12
        },
        offsetY: 3
      },
      title: {
        text: 'Activiteiten voor de maand ' + getMonthName(month) + ' ' + year,
        align: 'left'
      },
      grid: {
        borderColor: 'transparent',
        row: {
          colors: ['#f5f5f9', 'transparent'], 
          opacity: 0.5
        },
      },
      xaxis: {
        categories: transformNumberToArray((stats && stats.events.length > 0) ? stats.events : []),
      },
      yaxis: {
        title: {
          text: 'Aantal Activiteiten',
          style: {
              fontSize: '12px',
              color: '#9b9b9b',
              fontWeight: 500,
          },
        },
        labels: {
          style: {
            colors: '#9b9b9b',
            fontSize: '10px'
          },
        },
        min: 0
      },
      tooltip: {
          custom: function({series, seriesIndex, dataPointIndex, w}) {

            return '<div class="ChartTooltip">' +
              '<span class="ChartTooltipText">' + 'Activiteiten' + ': ' + series[seriesIndex][dataPointIndex] + '</span>' +
              '</div>'
          }
      }
  };


  const itemsList = (type) => {
    let itemsObjects = [];

    if(!stats) return false;

    let max = 0;
    switch(type){
      case "pages":

        max = (stats.pages && stats.pages.length > 0) ? stats.pages[0].times : 0;
        itemsObjects = (stats.pages).map( (item, index) => {

          const perc = Math.ceil( (item.times / max) * 100);

          return (
            <div className="DashDashTwinItemItem" key={'page_'+index}>
              <div className="DashDashTwinItemItemDetails">
                <div className="DashDashTwinItemItemText">{ item.title }</div>
                <div className="DashDashTwinItemItemTimes">{ item.times }x</div>
              </div>
              <div className="DashDashTwinItemItemBar">
                <div className="DashDashTwinItemItemBarValue" style={{ width: (perc) + '%' }}></div>
              </div>
            </div>
          )
        })
      break;
      case "products":

        max = (stats.pages && stats.products.length > 0) ? stats.products[0].times : 0;

        itemsObjects = (stats.products).map( (item, index) => {

          const perc = Math.ceil( (item.times / max) * 100);

          return (
            <div className="DashDashTwinItemItem" key={'product_'+index}>
              <div className="DashDashTwinItemItemDetails">
                <div className="DashDashTwinItemItemText">{ item.title } { perc }</div>
                <div className="DashDashTwinItemItemTimes">{ item.times }x</div>
              </div>
              <div className="DashDashTwinItemItemBar">
                <div className="DashDashTwinItemItemBarValue" style={{ width: (perc) + '%' }}></div>
              </div>
            </div>
          )
        })
      break;
      case "sessions":

        max = (stats.pages && stats.sessions.length > 0) ? stats.sessions[0].times : 0;
        itemsObjects = (stats.sessions).map( (item, index) => {

          const perc = Math.ceil( (item.times / max) * 100);

          return (
            <div className="DashDashTwinItemItem Item" key={'session_'+index}>
                <div className="DashDashTwinItemItemText">{ item.name }</div>
                <div className="DashDashTwinItemItemTimes">{ item.time }</div>
            </div>
          )
        })
      break;

      default:
      break;
    }


    return itemsObjects;
  }
  

  return (
    <div className="SettingsAnalytics">
      <div className="SettingsAnalyticsDateWrapper">
        <div className="SettingsAnalyticsDate">{ rangeView() }</div>
      </div>
              
      <div className="DashDashChart MarginTop">
        { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
        }
          
        <ReactApexChart options={optionsNew} series={seriesNew} type={'area'} height={300} />
      </div>   

      <div className="DashDashTwin">

        <div className="DashDashTwinItem">
            <div className="DashDashTwinItemTitle">Meest Bezochte pagina's</div>
            <div className="DashDashTwinItemItems">

              { itemsList('pages') }
              
            </div>
        </div>

        <div className="DashDashTwinItem">
            <div className="DashDashTwinItemTitle">Meest Bekeken Producten</div>
            <div className="DashDashTwinItemItems">

             { itemsList('products') }

            </div>
        </div>

        <div className="DashDashTwinItem">
            <div className="DashDashTwinItemTitle">Sessies van Gebruikers</div>
            <div className="DashDashTwinItemItems">

              { itemsList('sessions') }

            </div>
        </div>


      </div>
      
    </div>
  )
}

export default AnalDashboard;
