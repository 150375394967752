import '../styles/Home.css';
import config from '../constants/config';
import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiCalendarTodoFill,
  RiBookReadFill
} from "react-icons/ri";

import Product from '../components/Product';
import Banners from '../components/Banners';

import ScrollToTop from "react-scroll-to-top";
import React, { useState, useEffect, useRef } from 'react';
import loadingAnimation from '../animations/loading_circle.json';
import Lottie from "lottie-react";
import axios from 'axios';

function Home(props) {
  const {
    setViewPre,
    categories,
    getAd,
    setModalPre,
    token
  } = props;

  const [ productsNew, setProductsNew ] = useState([]);
  const [ productsSale, setProductsSale ] = useState([]);
  const [ productsSaleType, setProductsSaleType ] = useState('normal');

  const [ blogs, setBlogs ] = useState([]);
  const [ blogFeatured, setBlogFeatured ] = useState(null);

  const [ adBannerTop, setAdBannerTop ] = useState();
  const [ adBannerBottom, setAdBannerBottom ] = useState();
  const [ adNew, setAdNew ] = useState();
  const [ adSale, setAdSale ] = useState();

  const [ isLoading, setIsLoading ] = useState(false);

  const productsScrollNew = useRef();
  const productsScrollSale = useRef();

  // Functions
  const fetchBlogs = () => {

    axios.post( config.api.url + 'blogs/home', {
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){
        const featured = data.featured;
        const blogs = data.blogs;
        const stats = data.stats;

        if(featured){
          setBlogFeatured(featured);
        }

        if(blogs){
          setBlogs(blogs);
        }

        if(stats){
        }

      }
    })
    .catch(function (error) {

    });

  }


  const fetchProducts = (type = 'nieuwe_limited') => {

    setIsLoading(true);
    axios.post( config.api.url + 'products/type', {
      type: type,
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;
      setIsLoading(false);
      if(success){
        const product_type = data.product_type;
        const products = data.products;
        console.log(data)

        if(products){
          switch(type){
            case "actie_limited":
              if(product_type){
                setProductsSaleType(product_type)
              }
              setProductsSale(products);
            break;

            case "nieuwe_limited":
              setProductsNew(products);
            break;
          }
        }

      }

    })
    .catch(function (error) {

      setIsLoading(false);

    });

  }


  useEffect( () => {

    fetchBlogs();
    fetchProducts('nieuwe_limited');
    fetchProducts('actie_limited');

    // Get ads
    getAd('home_banner_top', (ad) => {
      if(ad){
        setAdBannerTop(ad);
      }
    })
    getAd('home_banner_bottom', (ad) => {
      if(ad){
        setAdBannerBottom(ad);
      }
    })
    getAd('home_new', (ad) => {
      if(ad){
        setAdNew(ad);
      }
    })
    getAd('home_sale', (ad) => {
      if(ad){
        setAdSale(ad);
      }
    })

  }, []);

  const productsNewParsed = (index = -1) => {

    if(index >= 0){

      if(productsNew.length > index){
        const productsNewItem = productsNew[index];
        const {
          id,
          image,
          title,
          descr,
          price,
          category
        } = productsNewItem;

        const prices = (price + '').split('.');
        const page = 'product/' + id + '-' + (title.toLowerCase()).replaceAll(' ', '-');
        const p_url = config.homepage + page;

        return {
          product: productsNewItem,
          prices: prices,
          c2a: {
            text: 'Bekijken',
            href: p_url,
            view: page
          }
        }
      }
      return null;
    }else{
      return (productsNew.length > 0) ? (productsNew) : [];
    }
  }
  const productsSaleParsed = (index = -1) => {

    if(index >= 0){

      if(productsSale.length > index){
        const productsSaleItem = productsSale[index];
        const {
          id,
          image,
          title,
          descr,
          price,
          category
        } = productsSaleItem;

        const prices = (price + '').split('.');
        const page = 'product/' + id + '-' + (title.toLowerCase()).replaceAll(' ', '-');
        const p_url = config.homepage + page;


        return {
          product: productsSaleItem,
          prices: prices,
          c2a: {
            text: 'Bekijken',
            href: p_url,
            view: page
          }
        }
      }
      return null;
    }else{
      return (productsSale.length > 0) ? (productsSale) : [];
    }
  }

  const productsNewItems = productsNewParsed();

  const productsSaleItems = productsSaleParsed();



  return (
    <div className="Home">

      <div className="FixedWidth">

        <div className="HomeBannerWrapper">

          <Banners
           { ... props}
           refreshBanners={true}
           bannerType={"Home"}
          />

          { (adBannerTop || adBannerBottom) &&
          <div className="HomeBannerSide">

            { (adBannerTop) &&
            <div className={`HomeBannerSideItem ${ (adBannerTop.c2a_url == '') ? 'Full' : '' }`}>

              { (adBannerTop.c2a_url != '') &&
              <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/swirl.png'})` }} />
              }

              <div className="HomeBannerSideItemImage">
                <img draggable={false} src={ adBannerTop.image } />
              </div>
              <div className="HomeBannerSideItemDetails">
                <div className="HomeBannerSideItemDetailsTitle">{ adBannerTop.title }</div>
                <div className="HomeBannerSideItemDetailsSubtitle">{ adBannerTop.subtitle }</div>
                { (adBannerTop.c2a_url != '') &&
                <div className="HomeBannerSideItemOptions">
                  <a href={ config.homepage + adBannerTop.c2a_url } onClick={
                    (event) => {

                      setViewPre(adBannerTop.c2a_url);

                      event.preventDefault();
                    }
                  } className="HomeBannerSideItemOptionsButton">{ adBannerTop.c2a_text }</a>
                </div>
                }
              </div>
            </div>
            }


            { (adBannerBottom) &&
            <div className={`HomeBannerSideItem ${ (adBannerBottom.c2a_url == '') ? 'Full' : '' }`}>


              { (adBannerBottom.c2a_url != '') &&
              <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/swirl.png'})` }} />
              }

              <div className="HomeBannerSideItemImage">
                <img draggable={false} src={ adBannerBottom.image } />
              </div>
              <div className="HomeBannerSideItemDetails">
                <div className="HomeBannerSideItemDetailsTitle">{ adBannerBottom.title }</div>
                <div className="HomeBannerSideItemDetailsSubtitle">{ adBannerBottom.subtitle }</div>
                { (adBannerBottom.c2a_url != '') &&
                <div className="HomeBannerSideItemOptions">
                  <a href={ config.homepage + adBannerBottom.c2a_url } onClick={
                    (event) => {

                      setViewPre(adBannerBottom.c2a_url);

                      event.preventDefault();
                    }
                  } className="HomeBannerSideItemOptionsButton">{ adBannerBottom.c2a_text }</a>
                </div>
                }
              </div>
            </div>
            }

          </div>
          }
        </div>

        <div className="HomeContentItem">
          <div className="HomeContentItemTitle"><b style={{ color: '#e0197d' }}>DA</b>'s alles wat je nodig hebt</div>
          <div className="HomeContentItemContent">

            { (categories.length == 0)
            ?
            <div className="LoginLoadingOverlay Inline">
              <div className="LoginLoading">
                <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
              </div>
            </div>
            :
            <>

              {
              (categories.map( (cat_item, cat_index) => {

                const url = config.homepage + 'categorie/' + (cat_item.category.toLowerCase());

                return (
                  <a href={url} onClick={
                    (event) => {
                      setViewPre('categorie/' + cat_item.category);
                      event.preventDefault()
                    }
                  } key={'cat_item_' + cat_index} className="HomeContentItemCategory" style={{ borderBottomColor: cat_item.color }}>
                    <div className="HomeContentItemCategoryIcon">
                      <img src={cat_item.icon} draggable={'false'} />
                    </div>
                    <div className="HomeContentItemCategoryTitle">{ cat_item.category }</div>
                    <div className="HomeContentItemCategoryArrow">
                      <RiArrowRightLine />
                    </div>
                  </a>
                )
              }))
              }

            </>
            }

          </div>
        </div>


        <div className="HomeContentItem NoPaddedTop">
          <div className="HomeContentItemSplitter">
            { (adNew) &&
            <div className={`HomeContentItemSplitterIntro ${ (adNew.c2a_url == '') ? 'Full' : '' }`}>

              { (adNew.c2a_url != '') &&
              <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/swirl.png'})` }} />
              }

              { (adNew.image != '') &&
              <div className="HomeBannerSideItemImage">
                <img draggable={false} src={ adNew.image } />
              </div>
              }

              <div className="HomeBannerSideItemDetails">
                <div className="HomeBannerSideItemDetailsTitle">{ adNew.title }</div>
                <div className="HomeBannerSideItemDetailsSubtitle">{ adNew.subtitle }</div>
                { (adNew.c2a_url != '') &&
                <div className="HomeBannerSideItemOptions">
                  <a href={ config.homepage + adNew.c2a_url } onClick={
                    (event) => {

                      setViewPre(adNew.c2a_url);

                      event.preventDefault();
                    }
                  } className="HomeBannerSideItemOptionsButton">{ adNew.c2a_text }</a>
                </div>
                }
              </div>
            </div>
            }

            <div className="HomeContentItemSplitterItemsWrapper">
            <div className="HomeContentItemTitleView">
              <div className="HomeContentItemTitle">Nieuwe producten</div>
              <a href={ config.homepage + 'categorie/alle' } onClick={
                (event) => {
                  setViewPre('categorie/alle');

                  event.preventDefault();
                }
              } className="HomeContentItemTitleButton AccentHover">Nieuwe producten bekijken</a>
            </div>
              <div className="HomeContentItemSplitterItemsView">

                { (productsNewItems.length == 0 && isLoading)
                ?
                <div className="LoginLoadingOverlay">
                  <div className="LoginLoading">
                    <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
                  </div>
                </div>
                :
                <div className="HomeContentItemSplitterItems" ref={productsScrollNew}>
                  { productsNewItems.map( (prod_item, prod_index) => {

                    return (
                      <Product
                        {... props}
                        id={`new_product_` + prod_item.id}
                        item={prod_item}
                        key={'product_item_' + prod_index}
                        token={token}
                        setModalPre={setModalPre}
                        setHovered={
                          (pid) => {
                            if(productsScrollNew){

                              setTimeout( () => {
                                const item = document.getElementById(pid);
                                if(item){
                                  item.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
                                }
                              }, 300)

                            }
                          }
                        }
                      />
                    )
                    })
                  }
                </div>
                }

              </div>
              <div className={`HomeContentItemSplitterItemsOptions ShowMobile`}>
                <a href={ config.homepage + 'categorie/alle' } onClick={
                  (event) => {
                    setViewPre('categorie/alle');

                    event.preventDefault();
                  }
                } className="HomeContentItemTitleButton AccentHover">Nieuwe producten bekijken</a>
              </div>
            </div>
          </div>
          <div className="HomeContentItemSplitter">
            { (adSale) &&
            <div className={`HomeContentItemSplitterIntro ${ (adSale.c2a_url == '') ? 'Full' : '' }`}>

              { (adSale.c2a_url != '') &&
              <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/swirl.png'})` }} />
              }

              { (adSale.image != '') &&
              <div className="HomeBannerSideItemImage">
                <img draggable={false} src={ adSale.image } />
              </div>
              }

              <div className="HomeBannerSideItemDetails">
                <div className="HomeBannerSideItemDetailsTitle">{ adSale.title }</div>
                <div className="HomeBannerSideItemDetailsSubtitle">{ adSale.subtitle }</div>
                { (adSale.c2a_url != '') &&
                <div className="HomeBannerSideItemOptions">
                  <a href={ config.homepage + adSale.c2a_url } onClick={
                    (event) => {

                      setViewPre(adSale.c2a_url);

                      event.preventDefault();
                    }
                  } className="HomeBannerSideItemOptionsButton">{ adSale.c2a_text }</a>
                </div>
                }
              </div>
            </div>
            }


            <div className="HomeContentItemSplitterItemsWrapper">
              <div className="HomeContentItemTitleView">
                <div className="HomeContentItemTitle">{ (productsSaleType == 'views') ? 'Meest bekeken' : 'DA Aanbiedingen' }</div>
                  <a href={ config.homepage + 'categorie/aanbiedingen' } onClick={
                  (event) => {

                    if(productsSaleType == 'views'){

                      setViewPre('categorie/alle');
                    }else{

                      setViewPre('categorie/aanbiedingen');
                    }

                    event.preventDefault();
                  }
                } className="HomeContentItemTitleButton AccentHover">{ (productsSaleType == 'views') ? 'Alle producten bekijken' : 'DA Acties bekijken' }</a>
              </div>
              <div className="HomeContentItemSplitterItemsView">

                { (productsSaleItems.length == 0 && isLoading)
                ?
                <div className="LoginLoadingOverlay Inline">
                  <div className="LoginLoading">
                    <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
                  </div>
                </div>
                :
                <div className="HomeContentItemSplitterItems" ref={productsScrollSale}>
                  { productsSaleItems.map( (prod_item, prod_index) => {

                    return (
                      <Product
                        {... props}
                        id={`sale_product_` + prod_item.id}
                        item={prod_item}
                        key={'product_item_' + prod_index}
                        token={token}
                        setModalPre={setModalPre}
                      />
                    )
                    })
                  }
                </div>
                }

              </div>
              <div className={`HomeContentItemSplitterItemsOptions ShowMobile`}>
                <a href={ config.homepage + 'categorie/aanbiedingen' } onClick={
                (event) => {
                  setViewPre('categorie/aanbiedingen');

                  event.preventDefault();
                }
              } className="HomeContentItemTitleButton AccentHover">DA Acties bekijken</a>
              </div>
            </div>
          </div>
        </div>


        <div className="HomeContentItem">
          <div className="HomeContentItemTitle">Blogs en informatie</div>
          <div className="HomeContentItemNewsBlock">

            <div className="HomeContentItemNewsHighlighted">

              { (blogs.length > 0 && blogFeatured) &&
              <div className="HomeContentItemNewsHighlightedModal">
                <div className="HomeContentItemNewsHighlightedModalTitle">{ blogFeatured.title }</div>
                <div className="HomeContentItemNewsHighlightedModalText">{ blogFeatured.summ }</div>
                <div className="HomeContentItemNewsHighlightedModalInfo">

                  <div className="HomeContentItemNewsHighlightedModalInfoItem"><RiCalendarTodoFill style={{ marginRight: 8, marginBottom: -1, fontSize: 12 }} />{ blogFeatured.timestamp.date }</div>
                  <div className="HomeContentItemNewsHighlightedModalInfoItem"><RiBookReadFill style={{ marginRight: 8, marginBottom: -1, fontSize: 12 }} />{ blogFeatured.category }</div>
                  <div className="HomeContentItemNewsHighlightedModalInfoItem">

                    <a href={ config.homepage + 'blog/' + blogFeatured.url } onClick={
                      (event) => {

                        setViewPre('blog/' + blogFeatured.url);
                        event.preventDefault();
                      }
                    } className="HomeContentItemNewsHighlightedModalInfoItemMore AccentHover">
                      <div className="HomeContentItemNewsHighlightedModalInfoItemMoreText">Lezen</div>
                      <RiArrowRightLine style={{ marginLeft: 8,fontSize: 12 }} />
                    </a>
                  </div>

                </div>
              </div>
              }

              { (blogFeatured) &&
              <div className="HomeContentItemNewsHighlightedImage"  style={{ backgroundImage: `url(${blogFeatured.image})` }} />
              }

            </div>


            <div className="HomeContentItemNewsItemsWrapper">
              <div className="HomeContentItemNewsItemsTitle">Meest gelezen artikelen</div>
              <div className="HomeContentItemNewsItems">
                { (blogs.length == 0)
                ?
                <div className="LoginLoadingOverlay Inline">
                  <div className="LoginLoading">
                    <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
                  </div>
                </div>
                :
                <>
                  { blogs.map( (news_item, news_index) => {

                     const b_page = 'blog/' + news_item.url;
                     const b_url = config.homepage + b_page;

                    return (
                      <a href={b_url} onClick={
                        (event) => {

                          setViewPre(b_page);

                          event.preventDefault();
                        }
                      } key={'news_item_' + news_index} className="HomeContentItemNewsItem">
                        <div className="HomeContentItemNewsItemImage" style={{ backgroundImage: `url(${news_item.image})` }} />
                        <div className="HomeContentItemNewsItemDetails">
                          <div className="HomeContentItemNewsItemDetailsTitle">{ news_item.title }</div>
                          <div className="HomeContentItemNewsItemDetailsDate">{ news_item.timestamp.date }</div>
                          <div className="HomeContentItemNewsItemDetailsText">{ news_item.summ }</div>
                        </div>
                        <div className="HomeContentItemNewsItemArrow">

                        </div>
                      </a>
                    )
                    })
                  }
                </>
                }
              </div>
              <div className="HomeContentItemNewsOptions">
                <a href={ config.homepage + 'blogs' } onClick={
                  (event) => {
                    setViewPre('blogs')
                    event.preventDefault();
                  }
                } className="HomeContentItemNewsOptionsButton">Alle blogs bekijken</a>
              </div>

            </div>


          </div>
        </div>


      </div>

    </div>
  );
}

export default Home;
