import '../styles/Empty.css';
import config from '../constants/config';
import DALogo from '../components/three/DALogo';
import React from 'react'

function Empty() {

  return (
    <div className="Empty">
      <div className="EmptyModal">
        <div className="EmptyModalTitleDivider" />
        <div className="EmptyModalTitle">DA Universe 4.04</div>
        <div className="EmptyModalText">Hmm... waar zijn we nou beland?</div>
      </div>
      <DALogo


      />

    </div>
  );
}

export default React.memo(Empty);
