import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleLine,

 RiShoppingBagLine,

 RiFileChartLine
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";


function Notifications(props) {
  const {
    token, account,
    setModalPre,
    setViewPre,
    searchQuery,
    fetchSettings,
    subview_data,
    setMenuSelectedPre,
    setAccountNew

  } = props;
  const [ notifications, setNotifications ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ loaded, setLoaded ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);


  const fetchNotifications = (pageFinal = page) => {

    setLoading(true);
    axios.post( config.api.url + 'account/notifications', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      setTimeout( () => {
        setLoading(false);
      }, 1);

      console.log(json.data);

      if(success){
        const notifications = data.notifications;
        const stats = data.stats;


        if(!loaded){
          setLoaded(true);
        }

        setAccountNew(false);

        if(notifications){
          setNotifications(notifications);

          fetchSettings();
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/notificaties/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchNotifications(page);
  }

  const notificationsPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(notifications.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/notificaties/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/notificaties/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/notificaties/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }

  useEffect( () => {


    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){

      // Get main view
      setPage(page_parsed);
      fetchNotifications(page_parsed);
    }else{
      fetchNotifications();
    }

  }, []);



  useEffect( () => {

    if(!loading && loaded){
      // controller.abort();
      fetchNotifications();
    }


  }, [searchQuery]);


  const notifications_items = (notifications).map( (notification, index) => {

    const data = notification.data;

    return (
      <div className="NotificationWrapper" key={'notification_' + notification.id} onClick={
        () => {

          // setViewPre('account/bestellingen/order/' + data.id);
          if(data && data.id > 0){
            setMenuSelectedPre({
              key: 'bestellingen/order/' + data.id
            }, 2);
          }
        }
      }>
        { (!notification.seen) &&
        <div className="NotificationNew" />
        }
        <div className="NotificationDetails">
          <div className={`NotificationDetailsNotification ${ (!notification.seen) ? 'Unseen' : '' }`}>{ notification.notification }</div>
          { (data) &&
          <div className="NotificationDetailsText" style={{ color: data.status_color }}>{ data.status }</div>
          }
          <div className="NotificationDetailsDate">{ notification.timestamp?.date } om { notification.timestamp?.time }</div>
        </div>
        <div className="NotificationIcon">
          <RiShoppingBagLine />
        </div>
      </div>
    );
  })


  return (
    <div className="SettingsContentContent List">

      <div className="settingsContentReal Flexed">
        <div className="GlobSettingsScroll">

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }


          { (notifications_items.length > 0)
          ?
          notifications_items
          :
          <div className="SettingsMessageText">Geen notificaties.</div>
          }

        </div>

        { notificationsPagination() }
      </div>

    </div>
  )
}

export default Notifications;
