import React, { useEffect, useRef } from 'react';


const ImageWithTransparencyCheck = ({ src, isTransparent }) => {
  const canvasRef = useRef(null);

    const checkImageTransparency = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const image = new Image();
      image.src = src;

      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        const hasTransparency = Array.from(data).some((_, index) => index % 4 === 3 && data[index] < 255);

        console.log('Image has transparency:', hasTransparency);
        isTransparent(hasTransparency);

      };
    };

    useEffect(() => {

      checkImageTransparency();

  }, [src]);

  return <canvas ref={canvasRef} style={{ display: 'none' }} />;
};

export default ImageWithTransparencyCheck;
