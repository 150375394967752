import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleLine,

 RiBold,
 RiUnderline,
 RiItalic
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";
import { Editor, EditorState, RichUtils, ContentState, convertToRaw, convertFromRaw } from 'draft-js';

import 'draft-js/dist/Draft.css';
import htmlToDraft from 'html-to-draftjs';



function SettingsFAQ(props) {
  const {
    token, account,
    setModalPre,
    searchQuery,
    subview_data,
    setSettingsHeaderPre,
    showSettingsHeaderPre
  } = props;
  const [ faq, setFaq ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ faqSelected, setFaqSelected ] = useState(null);
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);
  const inputQuestion = useRef();

  const [ editorState, setEditorState ] = React.useState(
    () => EditorState.createEmpty(),
  );
  const handleKeyCommand = (command) => {


    let nextState = null;
    if(command === 'bold'){

      nextState = RichUtils.toggleInlineStyle(editorState, "BOLD");
      setEditorState(nextState);

      return 'handled';
    }else if(command === 'underline'){

      nextState = RichUtils.toggleInlineStyle(editorState, "UNDERLINE");
      setEditorState(nextState);

      return 'handled';
    }else if(command === 'italic'){

      nextState = RichUtils.toggleInlineStyle(editorState, "ITALIC");
      setEditorState(nextState);

      return 'handled';
    }
    return 'not-handled';
  }

  const htmlToDraftBlocks = (html) => {

   html = html.replaceAll(/\s+/g,' ');

   const blocksFromHtml = htmlToDraft(html);


   const { contentBlocks, entityMap } = blocksFromHtml;
   const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
   const editorState = EditorState.createWithContent(contentState);

   return editorState;
  }

  const setFaqSelectedPre = (selected) => {

    if(selected){
      const { answer } = selected;

      if(answer && answer != ''){
        setEditorState(htmlToDraftBlocks(answer))
      }
    }

    if(selected){
      showSettingsHeaderPre(false);
    }else{
      showSettingsHeaderPre(true);
    }

    setFaqSelected(selected);
  }

  const fetchFAQ = (pageFinal = page) => {

    setLoading(true);
    axios.post( config.api.url + 'admin/faq', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      setTimeout( () => {
        setLoading(false);
      }, 1);


      if(success){
        const faq = data.faq;
        const stats = data.stats;

        if(faq){
          setFaq(faq);
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/faq/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchFAQ(page);
  }

  const faqPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(faq.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/faq/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/faq/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/faq/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }

  useEffect( () => {


    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchFAQ(page_parsed);
    }else{

    }

    setSettingsHeaderPre(
      <div className="SettingsContentContentNavigation HideDesktop">
        <a onClick={
          () => {

            setFaqSelectedPre({
              id: 0,
              question: '',
              answer: ' '
            });

          }
        } className="OrderItemOptionsButton AccentHover">
          <RiAddCircleLine style={{ fontSize: 14 }} />
          <span className="OrderItemOptionsButtonText">Aanmaken</span>
        </a>
      </div>
    )

  }, []);

  useEffect( () => {

    if(!loading){
      // controller.abort();
      fetchFAQ();
    }

  }, [searchQuery]);

  const removeFAQ = (faq_id = 0) => {

    if(faq_id > 0){

      setFaq(faqOld => {
        return faqOld.filter((f, i) => f.id != faq_id)
      });

      setLoading(true);
      axios.post( config.api.url + 'admin/faq/delete', {
        hash: token,
        faq_id: faq_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);


        if(success){

          fetchFAQ();
          // setFaq(faqOld => {
          //   return faqOld.filter((f, i) => f.id != faq_id)
          // });

        }

      })
      .catch(function (error) {
        setLoading(false);
      });

    }
  }

  const insert = (main_string, ins_string, pos) => {
    if(typeof(pos) == "undefined"){
      pos = 0;
    }
    if(typeof(ins_string) == "undefined"){
      ins_string = '';
    }
    return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
   }

  const saveFaqItem = () => {
    const contentState = editorState.getCurrentContent();
    const question = inputQuestion.current?.value;
    const faqAnswerObject = convertToRaw(contentState);

    // Go Through blocks
    let text = '';
    const blocks = faqAnswerObject.blocks;
    for(let b = 0; b < blocks.length; b++){
      const block = blocks[b];

      let localText = block.text;
      const styleChanges = block.inlineStyleRanges;

      let realOffset = 0;
      for(let t = 0; t < styleChanges.length; t++){
        const styleChange = styleChanges[t];


        const offset = realOffset + styleChange.offset;
        const length = styleChange.length;

        switch(styleChange.style){

          case "BOLD":
            localText = insert(localText, '<strong>', offset);
            localText = insert(localText, '</strong>', offset + length + 8);

            realOffset = realOffset + (8 + 9);
          break;
          case "UNDERLINE":
            localText = insert(localText, '<u>', offset);
            localText = insert(localText, '</u>', offset + length + 3);

            realOffset = realOffset + (3 + 4);
          break;
          case "ITALIC":
            localText = insert(localText, '<i>', offset);
            localText = insert(localText, '</i>', offset + length + 3);

            realOffset = realOffset + (3 + 4);
          break;
        }
      }

      text += localText + '<br/>';
    }


    // Replace
    text = text.replace(/(?:\r\n|\r|\n)/g, '<br/>');
    text = text.replace(/(<br\/>\s*)+$/, "");

    if(text != ''){
      setLoading(true);
      axios.post( config.api.url + 'admin/faq/save', {
        hash: token,
        faq_id: faqSelected.id,
        question: question,
        answer: text
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);

        if(success){

          const listIndex = (faq).findIndex( (f, i) => faqSelected.id == f.id);
          if(listIndex >= 0){
            faq[listIndex].question = question;
            faq[listIndex].answer_clean = text;
          }

          // Set stuff
          fetchFAQ();
          setFaqSelectedPre(null);

          // Save

        }else{
          setModalPre({
            title: 'Oops.',
            subtitle: response
          })
        }

      })
      .catch(function (error) {
        setLoading(false);
      });
    }else{
      setModalPre({
        title: 'Oops.',
        subtitle: 'Velden zijn leeg.'
      })
    }

  }

  const editorView = () => {

    const inlineStyle = editorState.getCurrentInlineStyle();
    const isBold = inlineStyle.has("BOLD");
    const isUnderline = inlineStyle.has("UNDERLINE");
    const isItalic = inlineStyle.has("ITALIC");


    return (
      <div className={`editorContent ${(faqSelected) ? 'Show' : ''}`}>
        <div className="editorHeader">
          <div className="editorHeaderTitle">
            <input
              ref={inputQuestion}
              className="editorHeaderInput"
              placeholder={'De vraag'}
              key={ JSON.stringify(faqSelected) }
              defaultValue={ (faqSelected) ? faqSelected.question : '' }
            />
          </div>
          <a onClick={
            () => {
              saveFaqItem();
            }
          } className="editorHeaderButton">
            <div className="editorHeaderButtonText">Opslaan</div>
            <RiSave2Line />
          </a>
          <a onClick={
            () => {
              setFaqSelectedPre(null)
            }
          } className="editorHeaderClose">
            <RiCloseLine />
          </a>
        </div>
        <div className="editorNavigation">
          <button onClick={ () => {
            const nextState = RichUtils.toggleInlineStyle(editorState, "BOLD");

            setEditorState(nextState);
          }} className={`editorNavigationButton ${(isBold) ? 'Selected' : ''}`}>
            <RiBold />
          </button>
          { /*
          <button onClick={ () => {
            const nextState = RichUtils.toggleInlineStyle(editorState, "UNDERLINE");

            setEditorState(nextState);
          }} className={`editorNavigationButton ${(isUnderline) ? 'Selected' : ''}`}>
            <RiUnderline />
          </button>
          <button onClick={ () => {
            const nextState = RichUtils.toggleInlineStyle(editorState, "ITALIC");

            setEditorState(nextState);
          }} className={`editorNavigationButton ${(isItalic) ? 'Selected' : ''}`}>
            <RiItalic />
          </button>
          */ }
        </div>
        <div className="editorEditor">
          <Editor
          editorState={editorState}
          onChange={setEditorState}
          handleKeyCommand={handleKeyCommand}
          />
        </div>
      </div>
    )
  }

  const faq_items = (faq).map( (faq_item, index) => {

    return (
      <div className="OrderItem" key={'faq_item_' + index}>
        <div className="OrderItemDetailsText IDSmall">#{ faq_item.id }</div>
        <div className="OrderItemDetailsText Question Bold">{ faq_item.question }</div>
        <div className="OrderItemDetailsText Answer">{ faq_item.answer_clean }</div>
        <div className="OrderItemDetailsText Date">{ faq_item.timestamp_last?.date }</div>

        <div className="OrderItemOptions">
          <a onClick={
            () => {

              setModalPre({
                title: 'Verwijderen.',
                subtitle: 'Ben je zeker dat je dit item wilt verwijderen?',
                content: (
                  <>
                    <div className="modalContentOptions NoBorder">
                      <a onClick={
                        () => {

                          removeFAQ(faq_item.id);
                          setModalPre();
                        }
                      } className="modalContentOptionsButton Delete">Verwijderen</a>
                      <a onClick={
                        () => {

                          setModalPre();
                        }
                      } className="modalContentOptionsButton">Annuleren</a>
                    </div>
                  </>
                )
              })

            }
          } className="OrderItemOptionsButton Delete">
            <RiDeleteBin5Line style={{ fontSize: 14 }} />
          </a>
          <a onClick={
            () => {

              setFaqSelectedPre(faq_item);

            }
          } className="OrderItemOptionsButton Edit">
            <RiFileShield2Line style={{ fontSize: 14 }} />
            <span className="OrderItemOptionsButtonText">Wijzigen</span>
          </a>
        </div>
      </div>
    )
  })


  return (
    <div className="SettingsContentContent List">

      { editorView() }

      <div className="SettingsMessageText ShowMobile">Voorlopig alleen op desktop beschikbaar.</div>


      <div className={`settingsContentReal HideDesktop ${(faqSelected) ? 'Show' : ''}`}>
        <div className="SettingsContentNavigation Margin">
          <div className="SettingsContentNavigationItem IDSmall">ID</div>
          <div className="SettingsContentNavigationItem Question">Vraag</div>
          <div className="SettingsContentNavigationItem Answer">Antwoord</div>
          <div className="SettingsContentNavigationItem Date">Aangepast</div>
          <div className="SettingsContentNavigationItem OptionsBig"></div>
        </div>

        <div className="GlobSettingsScroll">

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }

          { (faq_items.length > 0)
          ?
          faq_items
          :
          <div className="SettingsMessageText">Geen veelgestelde vragen.</div>
          }
        </div>

        { faqPagination() }
      </div>

    </div>
  )
}

export default SettingsFAQ;
