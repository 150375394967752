import '../../styles/Settings.css';
import config from '../../constants/config';
import colors from '../../constants/colors';
import {
 RiArrowRightSLine,

 RiMapPinLine,
 RiShieldUserLine,
 RiShieldKeyholeLine,
 RiShoppingCart2Line,
 RiPhoneCameraLine,
 RiSettings3Line,

 RiArchiveDrawerLine,
 RiBookReadLine,
 RiLifebuoyLine,
 RiTeamLine,
 RiSlideshow2Line,
 RiFileTextLine,
 RiStore3Line,
 RiMailSendLine,
 RiShoppingBag3Line,
 RiCouponLine,
 RiCustomerServiceLine,
 RiDraftLine,

 RiUser3Line,
 RiMailOpenLine,
 RiPhoneLine,
 RiLockPasswordLine,

 RiSearchLine,
 RiEditBoxLine,
 RiAdvertisementLine,
 RiBriefcaseLine,
 RiUser2Line,
 RiImage2Fill,

 RiRefundLine,
 RiComputerLine,

 RiLogoutCircleRLine,
 RiFileShield2Line,
 RiBarChartGroupedLine
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

// Import views
import SettingsFAQ from './Sections/FAQ';
import SettingsNewsletterEmails from './Sections/NewsletterEmails';
import SettingsDeliveryAddresses from './Sections/DeliveryAddresses';
import SettingsBanners from './Sections/Banners';
import SettingsCoupons from './Sections/Coupons';
import SettingsAccounts from './Sections/Accounts';
import SettingsStores from './Sections/Stores';
import SettingsBlogs from './Sections/Blogs';
import SettingsCarts from './Sections/Carts';
import SettingsOrdersAll from './Sections/OrdersAll';
import SettingsCustomerService from './Sections/CustomerService';
import SettingsProducts from './Sections/Products';
import SettingsAds from './Sections/Ads';
import SettingsContent from './Sections/Content';
import SettingsVacancies from './Sections/Vacancies';
import SettingsApplicants from './Sections/Applicants';
import SettingsContactForms from './Sections/ContactForms';

import SettingsAnalDashboard from './Sections/AnalDashboard';

import Orders from './Sections/Orders';
import Notifications from './Sections/Notifications';

import Resizer from 'react-image-file-resizer';

function Settings(props) {
  const {
    account,
    token,
    accountLogout,
    view,
    setViewPre,
    subview,

    accountInfo,
    setAlertPre,

    modal,
    setModal,
    setModalPre,
  } = props;

  const inputName = useRef();
  const inputEmail = useRef();

  const inputImage = useRef();

  const [ subViewFinal, setSubViewFinal ] = useState('');

  const inputPasswordCurrent = useRef();
  const inputPassword = useRef();
  const inputPasswordRe = useRef();
  const [ settings, setSettings ] = useState();
  const [ settingsHeader, setSettingsHeader ] = useState();
  const [ showSettingsHeader, setShowSettingsHeader ] = useState(true);
  const [ profileImage, setProfileImage ] = useState();

  const [ menu, setMenu ] = useState( (account && account.level == 5) ? [
    {
      type: 'header',
      item: 'Opties'
    },
    {
      icon: (
        <RiShieldUserLine style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'account',
      menu: 'Account'
    },
    {
      icon: (
        <RiShoppingCart2Line style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'bestellingen',
      menu: 'Bestellingen'
    },
    // {
    //   icon: (
    //     <RiMapPinLine style={{ fontSize: 15 }} />
    //   ),
    //   type: 'item',
    //   key: 'leveringsaddressen',
    //   menu: 'Leveringsaddressen'
    // },
    {
      icon: (
        <RiPhoneCameraLine style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'notificaties',
      menu: 'Notificaties',
      new: true
    },
    // {
    //   icon: (
    //     <RiShieldKeyholeLine style={{ fontSize: 15 }} />
    //   ),
    //   type: 'item',
    //   key: 'security',
    //   menu: 'Beveiliging'
    // },
    // {
    //   icon: (
    //     <RiSettings3Line style={{ fontSize: 15 }} />
    //   ),
    //   type: 'item',
    //   key: 'instellingen',
    //   menu: 'Instellingen'
    // },
    {
      type: 'header',
      item: 'Beheren'
    },
    {
      icon: (
        <RiArchiveDrawerLine style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'producten',
      full: true,
      menu: 'Producten'
    },
    {
      icon: (
        <RiShoppingBag3Line style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'bestellingen_all',
      full: true,
      menu: 'Alle Bestellingen'
    },
    {
      icon: (
        <RiShoppingBag3Line style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'winkelmandjes',
      full: true,
      menu: 'Winkelmandjes'
    },
    {
      icon: (
        <RiCouponLine style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'coupons',
      full: true,
      menu: 'Coupons'
    },
    {
      icon: (
        <RiTeamLine style={{ fontSize: 15, marginTop: -1 }} />
      ),
      type: 'item',
      key: 'gebruikers',
      full: true,
      menu: 'Gebruikers'
    },
    {
      icon: (
        <RiDraftLine style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'contact_formulieren',
      full: true,
      menu: 'Contact Formulieren'
    },
    {
      icon: (
        <RiBriefcaseLine style={{ fontSize: 15, marginTop: -1 }} />
      ),
      type: 'item',
      key: 'vacatures',
      full: true,
      menu: 'Vacatures'
    },
    {
      icon: (
        <RiUser2Line style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'sollicitanten',
      full: true,
      menu: 'Sollicitanten'
    },
    {
      icon: (
        <RiMailSendLine style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'nieuwsbrief',
      full: true,
      menu: 'Nieuwsbrief e-mails'
    },
    {
      icon: (
        <RiCustomerServiceLine style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'customerservice_ai',
      full: true,
      menu: 'AI Chats'
    },
    {
      type: 'header',
      item: 'Analytics'
    },
    {
      icon: (
        <RiBarChartGroupedLine style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'analytics_dash',
      full: true,
      menu: 'Analytics Dashboard',
    },
    {
      type: 'header',
      item: 'Content'
    },
    {
      icon: (
        <RiSlideshow2Line style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'banners',
      full: true,
      menu: 'Banners',
      menu_sub: 'Sleep de banners om de volgorde aan te passen.'
    },
    {
      icon: (
        <RiAdvertisementLine style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'ads',
      full: true,
      menu: 'Advertenties'
    },
    {
      icon: (
        <RiFileTextLine style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'content',
      full: true,
      menu: 'Content'
    },
    {
      icon: (
        <RiStore3Line style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'winkels',
      full: true,
      menu: 'Winkels'
    },
    {
      icon: (
        <RiBookReadLine style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'blogs',
      full: true,
      menu: 'Blogs'
    },
    {
      icon: (
        <RiLifebuoyLine style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'faq',
      full: true,
      menu: 'Veelgestelde vragen'
    }
  ] : [
    {
      type: 'header',
      item: 'Opties'
    },
    {
      icon: (
        <RiShieldUserLine style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'account',
      menu: 'Account'
    },
    {
      icon: (
        <RiShoppingCart2Line style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'bestellingen',
      menu: 'Bestellingen'
    },
    // {
    //   icon: (
    //     <RiMapPinLine style={{ fontSize: 15 }} />
    //   ),
    //   type: 'item',
    //   key: 'leveringsaddressen',
    //   menu: 'Leveringsaddressen'
    // },
    {
      icon: (
        <RiPhoneCameraLine style={{ fontSize: 15 }} />
      ),
      type: 'item',
      key: 'notificaties',
      menu: 'Notificaties',
      new: true
    },
    // {
    //   icon: (
    //     <RiSettings3Line style={{ fontSize: 15 }} />
    //   ),
    //   type: 'item',
    //   key: 'instellingen',
    //   menu: 'Instellingen'
    // }
  ]);
  const [ menuSelected, setMenuSelected ] = useState(1);
  const [ searchQuery, setSearchQuery ] = useState('');
  const menuObject = (menuSelected < menu.length) ? menu[menuSelected] : null;

  const fetchSettings = () => {

    axios.post( config.api.url + 'account/settings', {
      hash: token
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){
        const settings = data.settings;

        if(settings){
          setSettings(settings);
        }
      }

    })
    .catch(function (error) {

    });

  }

  const showSettingsHeaderPre = (show) => {

    setShowSettingsHeader(show);
  }

  const setSettingsHeaderPre = (header) => {

    setShowSettingsHeader(false);

    setTimeout( () => {
      setSettingsHeader(header);

      setTimeout( () => {
        setShowSettingsHeader(true);
      }, 50);
    }, 150);

  }

  const accountPasswordRequest = () => {


    axios.post( config.api.url + 'account/password/request', {
      hash: token
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){

        setModalPre();
        setTimeout( () => {
          setModalPre({
            title: 'Yay.',
            subtitle: response
          })
        }, 150);
        accountInfo();

      }else{

        // SMALL MODAL ?
        setAlertPre({
          title: 'Oops',
          subtitle: response
        })
      }

    })
    .catch(function (error) {

      console.log(error.response);

    });
  }


  const accountSave = (type) => {

    const type_real = type.key;
    document.activeElement.blur();

    let key = '';
    let val = '';
    let val_ver = '';
    let canContinue = true;
    switch(type_real){
      case "name":
        key = 'name';
        val = inputName.current?.value;
      break;
      case "email":
        key = 'email';
        val = inputEmail.current?.value;
        setModalPre();
      break;
      case "password":
        key = 'password';
        val = inputPassword.current?.value;
        const valRe = inputPasswordRe.current?.value;

        if(val != valRe){
          canContinue = false;
          setAlertPre({
            title: 'Oops',
            subtitle: 'Wachtwoorden komen niet overeen met elkaar.'
          })
        }

        val_ver = inputPasswordCurrent.current?.value;
      break;
    }


    if(canContinue){
      axios.post( config.api.url + 'account/save', {
        hash: token,
        key: key,
        val: val,
        val_ver: val_ver
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        if(success){

          setModalPre();
          setTimeout( () => {
            setModalPre({
              title: 'Yay.',
              subtitle: response
            })
          }, 150);
          accountInfo();

        }else{

          // SMALL MODAL ?
          setAlertPre({
            title: 'Oops',
            subtitle: response
          })
        }

      })
      .catch(function (error) {

        console.log(error.response);

      });
    }else{

          console.log("FAILED");
    }

  }

  const selectImage = (file) => {

    if(file) {
      try {

          let type = 'JPEG';
          switch(file.type){
            case "image/png":
            type = 'PNG';
            break;
          }

          Resizer.imageFileResizer(
          file,
          1000,
          1000,
          type, 90,0,
          uri => {

            setProfileImage({
              uri: URL.createObjectURL(file),
              base: uri
            })
            
          },
          'base64',
          500,
          500,
          );
      }catch(err) {

      }
    }

  }

  // FIRST TIME
  useEffect(() => {

    if(token != ''){
      accountInfo();
      fetchSettings();
    }

  }, [token]);

  useEffect( () => {

    let view_parsed = subview;
    let subViewFinalData = '';
    if((subview).includes('faq')){
      view_parsed = 'faq';
    }else if((subview).includes('bestellingen_all')){
      view_parsed = 'bestellingen_all';
    }else if((subview).includes('bestellingen')){
      view_parsed = 'bestellingen';
      subViewFinalData = subview.replace('bestellingen/', '').replace('bestellingen', '');
    }else if((subview).includes('leveringsaddressen')){
      view_parsed = 'leveringsaddressen';
    }else if((subview).includes('notificaties')){
      view_parsed = 'notificaties';
    }else if((subview).includes('producten')){
      view_parsed = 'producten';
    }else if((subview).includes('banners')){
      view_parsed = 'banners';
    }else if((subview).includes('ads')){
      view_parsed = 'ads';
    }else if((subview).includes('content')){
      view_parsed = 'content';
    }else if((subview).includes('winkels')){
      view_parsed = 'winkels';
    }else if((subview).includes('blogs')){
      view_parsed = 'blogs';
    }else if((subview).includes('customerservice_ai')){
      view_parsed = 'customerservice_ai';
    }else if((subview).includes('contact_formulieren')){
      view_parsed = 'contact_formulieren';
    }else if((subview).includes('vacatures')){
      view_parsed = 'vacatures';
    }else if((subview).includes('sollicitanten')){
      view_parsed = 'sollicitanten';
    }else if((subview).includes('gebruikers')){
      view_parsed = 'gebruikers';
    }else if((subview).includes('nieuwsbrief')){
      view_parsed = 'nieuwsbrief';
    }else if((subview).includes('winkelmandjes')){
      view_parsed = 'winkelmandjes';
    }else if((subview).includes('coupons')){
      view_parsed = 'coupons';
    }else if((subview).includes('content')){
      view_parsed = 'content';
    }


    if(view_parsed != ''){
      // FIND MENU ITEM
      const menuItemIndex = menu.findIndex( (m, i) => m.key == view_parsed);

      if(menuItemIndex >= 0){
        setMenuSelectedPre(null, menuItemIndex, subViewFinalData);
      }
    }

  }, [subview])


  if(!menuObject) return false;

  let subText = 'Gebruiker';

  switch(account.level){
    case "5":
      subText = 'Administrator';
    break;
  }



  const setMenuSelectedPre = (item, index, data = null) => {


    if(index != menuSelected){
      let subviewFinal = '';
      if(item && item.key){
        subviewFinal = item.key;
        window.history.pushState({"z":"z"}, null, config.homepage + 'account/' + item.key);
      }

      switch(index){
        case 1:
          accountInfo();
        break;
      }


      // Reset query
      setSearchQuery('');
      setSubViewFinal(subviewFinal);
      setMenuSelected(index);
      setSettingsHeaderPre();

      if(data){
        setSubViewFinal(data);
      }
    }
  }

  const menuItems = (menu).map( (item, index) => {


    switch(item.type){
      case "header":
        return (
          <div className="SettingsHeader" key={'menu_header_' + index}>
            <div className="SettingsHeaderText">{ item.item }</div>
          </div>
        )
      break;
      case "item":
        const isSelected = (index == menuSelected);

        const count = (settings && settings.counts[item.key]) ? settings.counts[item.key] : 0;

        return (
          <a href="#" onClick={
            (event) => {
              setMenuSelectedPre(item, index);
              event.preventDefault();
            }
          } key={'menu_' + index} className={`SettingsItem ${ (isSelected) ? 'Selected' : ''}`}>
            { (item.icon) && item.icon }
            <div className="SettingsItemTextView">
              <div className="SettingsItemText">{ item.menu }</div>
              { (count > 0) &&
              <div className="SettingsItemCount">{ count }</div>
              }
            </div>
            <RiArrowRightSLine />
          </a>
        )
      break;
    }

    return false;
  })

  const detailView = () => {

    let view_parsed = menuObject.key;
    let subview_data = '';
    if((view_parsed).includes('faq')){
      view_parsed = 'faq';
      subview_data = subViewFinal.replace('faq/', '').replace('faq', '');
    }else if((view_parsed).includes('bestellingen_all')){
      view_parsed = 'bestellingen_all';
      subview_data = subViewFinal.replace('bestellingen_all/', '').replace('bestellingen_all', '');
    }else if((view_parsed).includes('bestellingen')){
      view_parsed = 'bestellingen';
      subview_data = subViewFinal.replace('bestellingen/', '').replace('bestellingen', '');
    }else if((view_parsed).includes('leveringsaddressen')){
      view_parsed = 'leveringsaddressen';
      subview_data = subViewFinal.replace('leveringsaddressen/', '').replace('leveringsaddressen', '');
    }else if((view_parsed).includes('notificaties')){
      view_parsed = 'notificaties';
      subview_data = subViewFinal.replace('notificaties/', '').replace('notificaties', '');
    }else if((view_parsed).includes('producten')){
      view_parsed = 'producten';
      subview_data = subViewFinal.replace('producten/', '').replace('producten', '');
    }else if((view_parsed).includes('banners')){
      view_parsed = 'banners';
      subview_data = subViewFinal.replace('banners/', '').replace('banners', '');
    }else if((view_parsed).includes('ads')){
      view_parsed = 'ads';
      subview_data = subViewFinal.replace('ads/', '').replace('ads', '');
    }else if((view_parsed).includes('content')){
      view_parsed = 'content';
      subview_data = subViewFinal.replace('content/', '').replace('content', '');
    }else if((view_parsed).includes('winkels')){
      view_parsed = 'winkels';
      subview_data = subViewFinal.replace('winkels/', '').replace('winkels', '');
    }else if((view_parsed).includes('blogs')){
      view_parsed = 'blogs';
      subview_data = subViewFinal.replace('blogs/', '').replace('blogs', '');
    }else if((view_parsed).includes('customerservice_ai')){
      view_parsed = 'customerservice_ai';
      subview_data = subViewFinal.replace('customerservice_ai/', '').replace('customerservice_ai', '');
    }else if((view_parsed).includes('vacatures')){
      view_parsed = 'vacatures';
      subview_data = subViewFinal.replace('vacatures/', '').replace('vacatures', '');
    }else if((view_parsed).includes('sollicitanten')){
      view_parsed = 'sollicitanten';
      subview_data = subViewFinal.replace('sollicitanten/', '').replace('sollicitanten', '');
    }else if((view_parsed).includes('contact_formulieren')){
      view_parsed = 'contact_formulieren';
      subview_data = subViewFinal.replace('contact_formulieren/', '').replace('contact_formulieren', '');
    }else if((view_parsed).includes('gebruikers')){
      view_parsed = 'gebruikers';
      subview_data = subViewFinal.replace('gebruikers/', '').replace('gebruikers', '');
    }else if((view_parsed).includes('nieuwsbrief')){
      view_parsed = 'nieuwsbrief';
      subview_data = subViewFinal.replace('nieuwsbrief/', '').replace('nieuwsbrief', '');
    }else if((view_parsed).includes('winkelmandjes')){
      view_parsed = 'winkelmandjes';
      subview_data = subViewFinal.replace('winkelmandjes/', '').replace('winkelmandjes', '');
    }else if((view_parsed).includes('coupons')){
      view_parsed = 'coupons';
      subview_data = subViewFinal.replace('coupons/', '').replace('coupons', '');
    }

    switch(view_parsed){
      case "security":

        const security = [
          {
            icon: (<RiComputerLine />),
            key: 'logins',
            option: 'Logins',
            value: 'Ingelogd op 4 locaties'
          },
          // {
          //   icon: (<RiRefundLine />),
          //   key: 'limiet',
          //   option: 'Bestel limiet',
          //   value: 'SRD 1000'
          // }
        ];

        const security_items = (security).map( (secure, index) => {


          return (
            <a onClick={
              () => {
                setModalPre({
                  title: secure.option
                })
              }
            } className="SettingsContentItem" key={'setting_item_' + index}>
              { (secure.icon) &&
              <div className="SettingsContentItemIcon">
                { secure.icon }
              </div>
              }

              <div className="SettingsContentItemTitle">{ secure.option }</div>
              <div className="SettingsContentItemSubtitleView">
                <div className="SettingsContentItemSubtitle">{ secure.value }</div>
              </div>
            </a>
          )
        })

        return (
          <div className="SettingsContentContent">

            { security_items }



          </div>
        )
      break;

      case "account":

        const options = [
          {
            icon: (<RiUser3Line />),
            key: 'name',
            option: 'Naam',
            value: account.name
          },
          {
            icon: (<RiMailOpenLine />),
            key: 'email',
            option: 'Email',
            value: account.email
          },
          // {
          //   icon: (<RiPhoneLine />),
          //   key: 'phone',
          //   option: 'Telefoonnummer',
          //   value: (account.phone_text != '+ ') ? account.phone_text : 'NVT'
          // },
          {
            icon: (<RiLockPasswordLine />),
            key: 'password',
            option: 'Wachtwoord',
            value: '********'
          }
        ];

        const options_items = (options).map( (option, index) => {

          let canEdit = true;
          let optionValue = (option && option.value) ? option.value : '';
          let contentView = null;
          let optionSub = null;
          let c2a_button_text = 'Opslaan';

          switch(option.key){
            case "phone":
              if(option.value == 'NVT'){
                optionSub = (<div className="SettingsContentItemSubText">{ 'Geen telefoonnummer aangesloten.' }</div>);
              }
              canEdit = false;
            break;
            case "name":
              contentView = (
                  <div className="modalContentContent">
                    <div className="modalContentInput">
                      <input
                        ref={inputName}
                        defaultValue={ optionValue }
                        placeholder={'...'}
                        className={`ModalSideContentInputInput`}

                      />
                    </div>
                  </div>
              );
            break;
            case "email":
              if(!account.email_verified){
                optionSub = (<div className="SettingsContentItemSubText">{ 'Niet geverifieerd.' }</div>);
              }
              contentView = (
                  <div className="modalContentContent">
                    <div className="modalContentInput">
                      <input
                        ref={inputEmail}
                        defaultValue={ optionValue }
                        placeholder={'...'}
                        className={`ModalSideContentInputInput`}

                      />
                    </div>
                  </div>
              );
            break;
            case "password":
              if(account.password_set){
                contentView = (
                    <div className="modalContentContent">
                      <div className="modalContentInputPre">Huidig wachtwoord</div>
                      <div className="modalContentInput">
                        <input
                          ref={inputPasswordCurrent}
                          defaultValue={ '' }
                          placeholder={'...'}
                          className={`ModalSideContentInputInput`}

                        />
                      </div>
                      <div className="modalContentInputPre">Nieuw wachtwoord</div>
                      <div className="modalContentInput">
                        <input
                          ref={inputPassword}
                          defaultValue={ '' }
                          placeholder={'...'}
                          className={`ModalSideContentInputInput`}

                        />
                      </div>
                      <div className="modalContentInputPre">Nieuw wachtwoord herhalen</div>
                      <div className="modalContentInput">
                        <input
                          ref={inputPasswordRe}
                          defaultValue={ '' }
                          placeholder={'...'}
                          className={`ModalSideContentInputInput`}

                        />
                      </div>
                    </div>
                );
              }else{
                c2a_button_text = 'Aanvragen';
                contentView = (
                    <div className="modalContentContent">

                      <div className="modalContentContentError">
                        <div className="modalContentContentTitle">Geen wachtwoord.</div>
                        <div className="modalContentContentSubtitle">Jouw account heeft geen wachtwoord. Wij sturen jou een e-mail na de aanvraag om jouw wachtwoord aan te maken.</div>

                        <div className="modalContentContentOptions">
                          <div className="modalContentContentOptionsButton">Aanvragen</div>
                        </div>
                      </div>

                    </div>
                );
              }

            break;
          }

          return (
            <a onClick={
              () => {

                if(canEdit){

                  setModalPre({
                    title: option.option + ' wijzigen',
                    content: (
                      <>
                        { contentView }
                        <div className="modalContentOptions">
                          <a onClick={
                            () => {


                              if(c2a_button_text == 'Aanvragen'){
                                accountPasswordRequest();
                              }else{
                                accountSave(option);
                              }
                            }
                          } className="modalContentOptionsButton C2a AccentHover">{ c2a_button_text }</a>
                          <a onClick={
                            () => {

                              setModalPre();
                            }
                          } className="modalContentOptionsButton">Annuleren</a>
                        </div>
                      </>
                    )
                  })
                }
              }
            } className="SettingsContentItem" key={'setting_item_' + index}>
              { (option.icon) &&
              <div className="SettingsContentItemIcon">
                { option.icon }
              </div>
              }

              <div className="SettingsContentItemTitle">{ option.option }</div>
              <div className="SettingsContentItemSubtitleView">
                <div className="SettingsContentItemSubtitle">{ optionValue }</div>
                { optionSub }
              </div>
            </a>
          )
        })

        return (
          <div className="SettingsContentContent GlobSettingsScroll">

            { options_items }



            <div className="SettingsContentOptionsLogout">
              <a onClick={
                () => {
                  setModalPre({
                    title: 'Uitloggen.',
                    subtitle: 'Ben je zeker dat je wilt uitloggen?',
                    content: (
                      <>
                        <div className="modalContentOptions NoBorder">
                          <a onClick={
                            () => {

                              accountLogout();
                              setModalPre();
                            }
                          } className="modalContentOptionsButton C2a AccentHover">Uitloggen</a>
                          <a onClick={
                            () => {

                              setModalPre();
                            }
                          } className="modalContentOptionsButton">Annuleren</a>
                        </div>
                      </>
                    )
                  })
                }
              } className="SettingsContentItem" key={'setting_item_logout'}>
                <div className="SettingsContentItemIcon">
                  <RiLogoutCircleRLine />
                </div>

                <div className="SettingsContentItemTitle">...</div>
                <div className="SettingsContentItemSubtitleView">
                  <div className="SettingsContentItemSubtitle">Uitloggen</div>
                </div>
              </a>
            </div>

          </div>
        )
      break;

      case "banners":
        return (
          <SettingsBanners
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          setMenuSelectedPre={setMenuSelectedPre}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "leveringsaddressen":
        return (
          <SettingsDeliveryAddresses
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          setMenuSelectedPre={setMenuSelectedPre}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "ads":
        return (
          <SettingsAds
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          setMenuSelectedPre={setMenuSelectedPre}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "bestellingen":
        return (
          <Orders
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          fetchSettings={fetchSettings}
          setMenuSelectedPre={setMenuSelectedPre}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "vacatures":
        return (
          <SettingsVacancies
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          fetchSettings={fetchSettings}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "sollicitanten":
        return (
          <SettingsApplicants
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          fetchSettings={fetchSettings}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "contact_formulieren":
        return (
          <SettingsContactForms
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          fetchSettings={fetchSettings}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "content":
        return (
          <SettingsContent
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          fetchSettings={fetchSettings}
          setMenuSelectedPre={setMenuSelectedPre}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "notificaties":
        return (
          <Notifications
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          fetchSettings={fetchSettings}
          setMenuSelectedPre={setMenuSelectedPre}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "faq":
        return (
          <SettingsFAQ
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "nieuwsbrief":
        return (
          <SettingsNewsletterEmails
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "coupons":
        return (
          <SettingsCoupons
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          fetchSettings={fetchSettings}
          setMenuSelectedPre={setMenuSelectedPre}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}

          />
        )
      break;

      case "gebruikers":
        return (
          <SettingsAccounts
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "winkels":
        return (
          <SettingsStores
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "blogs":
        return (
          <SettingsBlogs
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "winkelmandjes":
        return (
          <SettingsCarts
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "bestellingen_all":
        return (
          <SettingsOrdersAll
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          fetchSettings={fetchSettings}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "customerservice_ai":
        return (
          <SettingsCustomerService
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "producten":
        return (
          <SettingsProducts
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      case "analytics_dash":
        return (
          <SettingsAnalDashboard
          { ... props }
          subview_data={subview_data}
          searchQuery={searchQuery}
          setSettingsHeaderPre={setSettingsHeaderPre}
          showSettingsHeaderPre={showSettingsHeaderPre}
          />
        )
      break;

      default:
        break;

    }

    return false;
  }

  const searchView = () => {

    let view_parsed = menuObject.key;
    let showSearch = true;

    if((view_parsed).includes('account')){
      return false;
    }else if((view_parsed).includes('leveringsaddressen')){
      return false;
    }else if((view_parsed).includes('security')){
      return false;
    }else if((view_parsed).includes('instellingen')){
      return false;
    }else if((view_parsed).includes('analytics')){
      showSearch = false;
    }


    return (
      <div className="HeaderNavSearchWrapper">

          <div className={`settingsHeaderOptionsView ${ (showSettingsHeader) ? 'Show' : ''}`}>
            <div className="settingsHeaderOptions">
              { (settingsHeader) && settingsHeader }
            </div>
          </div>
          { (showSearch) &&
          <div className="HeaderNavSearch">
            <input
              className="HeaderNavSearchInput"
              placeholder={'Zoeken naar ...'}
              value={searchQuery}
              onChange={
                (event) => {
                  const query = event.target?.value;
                  setSearchQuery(query);
                }
              }
            />
            <span className="HeaderNavSearchButton">
              <RiSearchLine />
            </span>
          </div>
          }

      </div>
    )
  }

  return (
    <div className="Settings">
      <div className="FixedWidth">

        <div className={`SettingsSide ${ (menuObject && menuObject.full) ? 'Full' : '' }`}>
          <div className="SettingsSideHeader">
            {
            (account.avatar == '') ?

            <div className="SettingsSideHeaderAvatar" style={{ backgroundImage: `url('${ config.homepage + 'data/logos/logo_0.png' }')` }} referrerPolicy="no-referrer" onClick={() => {
              //  inputImage.current?.click();
            }}>
              <div className='SettingsSideHeaderIconContainer'>
                <RiImage2Fill style={{fontSize: 15, color: '#fff'}}/>
                 <input ref={inputImage} type="file" accept=".jpg, .jpeg, .png, .gif" style={{ display: 'none' }} 
                  />
              </div>
            </div>
            :
            <div className="SettingsSideHeaderAvatar" style={{ backgroundImage: `url('${ account.avatar }')` }} referrerPolicy="no-referrer" >
              
            </div>
            }

            <div className="SettingsSideHeaderDetails">
              <div className="SettingsSideHeaderDetailsName">{ account.name }</div>
              <div className="SettingsSideHeaderDetailsSubtitle">{ subText }</div>
            </div>
          </div>

          <div className="SettingsSideItems">
          { menuItems }
          </div>

        </div>

        <div className="SettingsContent">
          <div className="SettingsContentTitleView">

            <div className="SettingsContentTitleWrapper">
              <div className="SettingsContentTitle">{ menuObject.menu }</div>
              { (menuObject.menu_sub) &&
              <div className="SettingsContentSubtitle">{ menuObject.menu_sub }</div>
              }
            </div>

            { searchView() }


          </div>

          { detailView() }
        </div>


      </div>
    </div>
  );
}

export default Settings;
