import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleLine,

 RiCheckFill,
 RiCloseFill,

 RiImage2Fill,

 RiToggleLine,
 RiToggleFill,

 RiSortDesc,
 RiSortAsc
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";
import Resizer from 'react-image-file-resizer';

import DetailView from "../../../components/DetailView";

function Accounts(props) {
  const {
    token, account,
    setModalPre,
    searchQuery,
    subview_data,
    setSettingsHeaderPre,
    showSettingsHeaderPre
  } = props;
  const [ accounts, setAccounts ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);

  const [ modal, setModal ] = useState();

  const scrollView = useRef();

  const inputImage = useRef();

  const inputName = useRef();
  const inputPhone = useRef();
  const inputEmail = useRef();
  const inputPassword = useRef();

  const [ sortBy, setSortBy ] = useState({
    type: 'id',
    sort: 'desc'
  });

  const setSortByPre = (sort) => {

    setSortBy(sort);

    setTimeout( () => {

      fetchAccounts(page, true, sort);
    }, 10);
  }


  const setDetailsPre = (mod) => {

    const timeout = (modal) ? 300 : 0;
    if(modal){
      setModal(modalOld=>{
        return {
          ...modalOld,
          hide: true
        }
      });
    }


    if(mod){
      showSettingsHeaderPre(false);
    }else{
      showSettingsHeaderPre(true);
    }
    setTimeout( () => {
        setModal(mod);
    }, timeout)

  }

  const selectImage = (event) => {
    let fileInput = false;
    if(event.target.files[0]) {
        fileInput = true;
    }

    if(fileInput) {
        try {
            Resizer.imageFileResizer(
            event.target.files[0],
            1000,
            1000,
            'JPEG', 90,0,
            uri => {

              if(modal){
                setModal( modalOld => {
                  return {
                    ...modalOld,
                    avatar: {
                      uri: URL.createObjectURL(event.target.files[0]),
                      base: uri
                    },
                    avatar_raw: {
                      uri: URL.createObjectURL(event.target.files[0]),
                      base: uri
                    }
                  }
                })

              }
            },
            'base64',
            500,
            500,
            );
        }   catch(err) {

        }
    }
  }


  const accountSave = () => {

    document.activeElement.blur();
    if(modal && modal.id >= 0){
      const name = inputName.current?.value;
      const phone = inputPhone.current?.value;
      const email = inputEmail.current?.value;
      const password = inputPassword.current?.value;

      const image = (modal.avatar_raw) ? ((typeof modal.avatar_raw == 'string') ? (modal.avatar_raw) : (modal.avatar_raw.base)) : ''

      setLoading(true);
      axios.post( config.api.url + 'admin/accounts/save', {
        hash: token,
        account_id: modal.id,
        image: image,
        name: name,
        phone: phone,
        email: email,
        password: password
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);

        if(success){

          setDetailsPre();
          fetchAccounts(page, false);

        }else{

          setModalPre({
            title: 'Oops.',
            subtitle: response
          })
        }

      })
      .catch(function (error) {
        setLoading(false);
      });

    }

  }

  const toggleAccountActive = (account_id = 0, account_index) => {

    if(account_id > 0){

      accounts[account_index].active = !accounts[account_index].active;
      if(modal){
        modal.active = accounts[account_index].active;
      }

      // setLoading(true);
      axios.post( config.api.url + 'admin/accounts/toggle', {
        hash: token,
        account_id: account_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        // setTimeout( () => {
        //   setLoading(false);
        // }, 1);


        if(success){

          fetchAccounts(page, false);
        }

      })
      .catch(function (error) {
        // setLoading(false);
      });

    }
  }

  const fetchAccounts = (pageFinal = page, shouldLoad = true, sortByFinal = sortBy) => {

    if(shouldLoad){
      setLoading(true);
    }
    axios.post( config.api.url + 'admin/accounts', {
      hash: token,
      query: searchQuery,
      sort_by: sortByFinal,
      page: pageFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(shouldLoad){
        setTimeout( () => {
          setLoading(false);
        }, 1);
      }

      if(success){
        const accounts = data.accounts;
        const stats = data.stats;


        if(accounts){
          setAccounts(accounts);
        }

        if(shouldLoad){
          scrollView.current?.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/gebruikers/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchAccounts(page);
  }

  const accountsPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(accounts.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/gebruikers/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/gebruikers/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/gebruikers/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }


  const modalView = () => {

    return (
        <div className={`ModalSide`}>
          <div className="ModalSideHeader">
            <div className="ModalSideHeaderTitle">Gebruiker { 'bewerken' }</div>

            { (modal && modal.id > 0) &&
            <a onClick={
              () => {

                if(modal && modal.id){
                  const modalIndex = (accounts).findIndex( (c, i) => c.id == modal.id);
                  toggleAccountActive(modal.id, modalIndex);
                }

              }
            } className={`OrderItemOptionsButton ${ (modal.active) ? 'DeleteInverted' : 'AddInverted' }`}>
              { (modal.active)
              ?
              <RiToggleFill style={{ fontSize: 14 }} />
              :
              <RiToggleLine style={{ fontSize: 14 }} />
              }
              <span className="OrderItemOptionsButtonText">{ (modal.active) ? 'Deactiveren' : 'Activeren' }</span>
            </a>
            }

            <a onClick={
              () => {
                setDetailsPre()
              }
            } className="ModalSideHeaderClose">
              <RiCloseLine />
            </a>
          </div>

          <div className="ModalSideContent">
            { (modal) &&
            <div className="ModalSideContentImageWrapper">
              <div className="ModalSideContentImage Circle" style={{ backgroundImage: `url(${ (modal.avatar) ? ((typeof modal.avatar == 'string') ? (modal.avatar) : (modal.avatar.uri)) : '' })` }}>
                <div className="ModalSideContentImageOverlay">
                  <div onClick={
                    () => {

                      inputImage.current?.click();

                    }
                  } className="ModalSideContentImageOverlayCircle">
                    <RiImage2Fill />
                    <input ref={ inputImage } type="file" accept=".jpg, .jpeg, .png, .gif" style={{ display: 'none' }} onChange={selectImage} />
                  </div>
                </div>
              </div>
              <div className="ModalSideContentImageDetails">
                <div className="ModalSideContentImageDetailsText">{ (modal.avatar_raw != '') ? 'Foto actief.' : 'Geen foto geselecteerd.' }</div>
                <div className="ModalSideContentImageDetailsOptions">
                  { (modal && modal.avatar_raw && modal.avatar_raw != '') &&
                  <a onClick={
                    () => {

                      setModal( modalOld => {
                        return {
                          ...modalOld,
                          avatar_raw: '',
                          avatar: ''
                        }
                      })

                    }
                  } className="ModalSideContentImageDetailsOptionsButton Delete">Verwijderen</a>
                  }
                  <a onClick={
                    () => {

                      inputImage.current?.click();
                    }
                  } className="ModalSideContentImageDetailsOptionsButton">Selecteer foto</a>
                </div>
              </div>
            </div>
            }

            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Naam</div>
                <input
                  ref={inputName}
                  key={ JSON.stringify(modal) }
                  defaultValue={ (modal && modal.name) ? modal.name : '' }
                  placeholder={'John Doe'}
                  className={`ModalSideContentInputInput`}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            accountSave();
                          }
                        break;
                      }
                    }
                  }
                />
              </div>
            </div>

            <div className="ModalSideContentTwin">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Telefoonnummer</div>
                <input
                  ref={inputPhone}
                  key={ JSON.stringify(modal) }
                  defaultValue={ (modal && modal.phone_raw) ? modal.phone_raw : '' }
                  placeholder={'597 ...'}
                  className={`ModalSideContentInputInput`}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            accountSave();
                          }
                        break;
                      }
                    }
                  }
                />
              </div>
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">E-mail</div>
                <input
                  ref={inputEmail}
                  key={ JSON.stringify(modal) }
                  defaultValue={ (modal && modal.email) ? modal.email : '' }
                  placeholder={'email@gmail.com'}
                  className={`ModalSideContentInputInput`}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            accountSave();
                          }
                        break;
                      }
                    }
                  }
                />
              </div>
            </div>

            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Wachtwoord wijzigen</div>
                <input
                  ref={inputPassword}
                  key={ JSON.stringify(modal) }
                  defaultValue={ '' }
                  placeholder={''}
                  className={`ModalSideContentInputInput`}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            accountSave();
                          }
                        break;
                      }
                    }
                  }
                />
              </div>
            </div>

            <div className="modalCouponTitleDivider" />


          </div>

          <div className="modalContentOptions LightBorder">
            <a onClick={
              () => {

                accountSave();
              }
            } className="modalContentOptionsButton C2a">Opslaan</a>
            <a onClick={
              () => {

                setDetailsPre();
              }
            } className="modalContentOptionsButton">Annuleren</a>
          </div>

        </div>
    )
  }



  useEffect( () => {

    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchAccounts(page_parsed);
    }else{

    }

    setSettingsHeaderPre(
        <div className="SettingsContentContentNavigation HideDesktop">
          <a onClick={
            () => {

              setDetailsPre({
                id: 0,
              });
            }
          } className="OrderItemOptionsButton AccentHover">
            <RiAddCircleLine style={{ fontSize: 14 }} />
            <span className="OrderItemOptionsButtonText">Aanmaken</span>
          </a>
        </div>
    )

  }, []);



  useEffect( () => {

    if(!loading){
      // controller.abort();
      fetchAccounts();
    }

  }, [searchQuery]);


  const accounts_items = (accounts).map( (account_item, index) => {

    return (
      <div className="OrderItem" key={'order_item_' + account_item.id}>
        <div className="OrderItemDetailsText ID">#{ account_item.id }</div>
        <div className="OrderItemDetailsText Active">
          <span className={`OrderItemDetailsTextOption Circle ${ (account_item.active) ? 'Success' : 'Error' }`}>
            { (account_item.active)
            ?
            <RiCheckFill style={{ fontSize: 12 }} />
            :
            <RiCloseFill style={{ fontSize: 12 }} />
            }
          </span>
        </div>
        <div className="OrderItemDetailsText Image">
          <div className="OrderItemDetailsTextImage Circle" style={{ backgroundImage: `url(${account_item.avatar})` }} />
        </div>
        <div className="OrderItemDetailsText NameMid Bold">{ account_item.name }</div>
        <div className="OrderItemDetailsText EmailFixed">{ account_item.email }</div>
        <div className="OrderItemDetailsText Date">{ account_item.timestamp_signed_up?.date }</div>

        <div className="OrderItemOptions">

          <a onClick={
            () => {

              toggleAccountActive(account_item.id, index);

            }
          } className={`OrderItemOptionsButton ${ (account_item.active) ? 'DeleteInverted' : 'AddInverted' }`}>
            { (account_item.active)
            ?
            <RiToggleFill style={{ fontSize: 14 }} />
            :
            <RiToggleLine style={{ fontSize: 14 }} />
            }
          </a>

          <a onClick={
            () => {

              setDetailsPre(account_item);

            }
          } className="OrderItemOptionsButton Edit">
            <RiFileShield2Line style={{ fontSize: 14 }} />
            <span className="OrderItemOptionsButtonText">{ 'Bewerken' }</span>
          </a>
        </div>

      </div>
    )
  })


  return (
    <div className="SettingsContentContent List">



      <div className="SettingsMessageText ShowMobile">Voorlopig alleen op desktop beschikbaar.</div>

      <div className={`settingsContentReal HideDesktop`}>
        <div className="SettingsContentNavigation">
          <div onClick={ () => {
            setSortByPre({
              type: 'id',
              sort: (sortBy && sortBy.type == 'id' && sortBy.sort == 'desc') ? 'asc' : 'desc'
            })
          } } className="SettingsContentNavigationItem ID">{ (sortBy && sortBy.type == 'id' && sortBy.sort == 'asc') ? (<RiSortDesc style={{ fontSize: 13 }} />) : 'ID' }</div>
          <div onClick={ () => {
            setSortByPre({
              type: 'active',
              sort: (sortBy && sortBy.type == 'active' && sortBy.sort == 'asc') ? 'desc' : 'asc'
            })
          } } className="SettingsContentNavigationItem Active">{ (sortBy && sortBy.type == 'active') ? ( (sortBy.sort == 'desc') ? (<RiSortDesc style={{ fontSize: 13 }} />) : (<RiSortAsc style={{ fontSize: 13 }} />)) : 'Actief' }</div>
          <div className="SettingsContentNavigationItem Image">Foto</div>  <div onClick={ () => {
              setSortByPre({
                type: 'name',
                sort: (sortBy && sortBy.type == 'name' && sortBy.sort == 'asc') ? 'desc' : 'asc'
              })
            } } className="SettingsContentNavigationItem NameMid">{ (sortBy && sortBy.type == 'name') ? ( (sortBy.sort == 'desc') ? (<RiSortDesc style={{ fontSize: 13 }} />) : (<RiSortAsc style={{ fontSize: 13 }} />)) : 'Naam' }</div>
          <div onClick={ () => {
              setSortByPre({
                type: 'email',
                sort: (sortBy && sortBy.type == 'email' && sortBy.sort == 'asc') ? 'desc' : 'asc'
              })
            } } className="SettingsContentNavigationItem EmailFixed">{ (sortBy && sortBy.type == 'email') ? ( (sortBy.sort == 'desc') ? (<RiSortDesc style={{ fontSize: 13 }} />) : (<RiSortAsc style={{ fontSize: 13 }} />)) : 'E-mail' }</div>
          <div onClick={ () => {
              setSortByPre({
                type: 'date',
                sort: (sortBy && sortBy.type == 'date' && sortBy.sort == 'asc') ? 'desc' : 'asc'
              })
            } } className="SettingsContentNavigationItem Date">{ (sortBy && sortBy.type == 'date') ? ( (sortBy.sort == 'desc') ? (<RiSortDesc style={{ fontSize: 13 }} />) : (<RiSortAsc style={{ fontSize: 13 }} />)) : 'E-mail' }</div>
          <div className="SettingsContentNavigationItem OptionsBig"></div>
        </div>

        <div className="GlobSettingsScroll" ref={scrollView}>

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }


          { (accounts_items.length > 0)
          ?
          accounts_items
          :
          <div className="SettingsMessageText">Geen accounts.</div>
          }
        </div>

        { accountsPagination() }
      </div>


      <DetailView modal={modal} setDetailsPre={setDetailsPre}>
      { modalView() }
      </DetailView>

    </div>
  )
}

export default Accounts;
