import '../styles/Stores.css';
import config from '../constants/config';
import GoogleMapReact from 'google-map-react';
import React, { useRef, useState, useEffect } from 'react';
import {
  RiArrowRightSLine,
  RiPhoneFill
} from "react-icons/ri";
import axios from 'axios';
import loadingAnimation from '../animations/loading_circle.json';
import Lottie from "lottie-react";


function Stores(props) {
  const {
    subview,
  } = props;

  const defaultMapData = {
    center: {
      lat: 5.823517432841453,
      lng: -55.170229881781026
    },
    zoom: 14
  };
  const [ mapData, setMapData ] = useState(defaultMapData);

  const map = useRef();
  const [ storeSelected, setStoreSelected ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ stores, setStores ] = useState([]);

 // Get subview
 let id = 0;
 if(subview){
   const subviews = (subview + '').split('-');
   id = subviews[0];
 }


  useEffect( () => {

    fetchStores();

  }, []);

  useEffect( () => {

    if(id > 0){
      const store_sele = (stores).find((st, ins) => st.id == id);
      if(store_sele){
        scrollMapToPosition(store_sele.location);
        setStoreSelected(store_sele);
      }
    }

  }, [id]);


  const fetchStores = () => {
    
    setLoading(true);
    axios.post( config.api.url + 'stores', null, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;


      if(success){
        const stores = data.stores;

        if(stores){
          setStores(stores);

            if(id > 0){
              const store_sel = (stores).find((st, ins) => st.id == id);
              if(store_sel){
                scrollMapToPosition(store_sel.location);
                setStoreSelected(store_sel);
              }
            }
        }

      }
      setTimeout( () => {
        setLoading(false);
      }, 1);
    })
    .catch(function (error) {
      setLoading(false);
    });
  }

  const scrollMapToPosition = (location) => {

    if(location){

      setMapData({
        center: {
          lat: location.lat,
          lng: location.lng
        },
        zoom: 16
      });


    }else{

      setMapData(defaultMapData)
    }
  }

  const storesSorted = (stores);

  const mapObjects = (stores).map( (store, index) => {

    const location = store.location;

    if(!location) return false;

    return (
      <div
        key={'store_map_' + store.id}
        className="StoreMarkerWrapper"
        lat={ location.lat }
        lng={ location.lng }
      >
        <div className="StoreMarker">
          <div className="StoreMarkerImage">
            <img src={ config.homepage + 'data/logos/logo_0.png'} draggable={false} />
          </div>
          <div className="StoreMarkerDetails">
            <div className="StoreMarkerDetailsTitle">{ store.name }</div>
            <div className="StoreMarkerDetailsAddress">{ store.address }</div>
          </div>
        </div>
        <div className="StoreMarkerLeg" />
      </div>
    )
  });

  const mapListObjects = (storesSorted).map( (store, index) => {

    const location = store.location;
    const isSelected = (storeSelected && storeSelected.name == store.name);


    return (
      <div
        key={'store_list_' + store.id}
        className={`StoreItemWrapper ${ (isSelected) ? 'Selected' : ''}`}
        lat={ (location) ? location.lat : '' }
        lng={ (location) ? location.lng : '' }
        onMouseEnter={
          () => {
            scrollMapToPosition(location);
            setStoreSelected(store);
          }
        }
      >
        <div className="StoreItem">
          <div className="StoreItemImage" style={{ backgroundImage: `url(${store.image})` }}>
            { (store.image == '') &&
            <img src={ config.homepage + 'data/logos/logo_0.png'} draggable={false} />
            }
          </div>
          <div className="StoreItemDetails">
            <div className="StoreItemDetailsTitle">{ store.name }</div>
            { (isSelected) &&
            <div className="StoreItemDetailsPhoneView">
              <RiPhoneFill style={{ fontSize: 12 }} />
              <div className="StoreItemDetailsPhone">{ store.phone }</div>
            </div>
            }
            <div className="StoreItemDetailsAddress">{ store.address }</div>
          </div>
          <RiArrowRightSLine />
        </div>

        { (isSelected) &&
        <div className="StoreItemHours">

          <div className="StoreItemHoursTitle">Openingstijden</div>

          { (store && store.opening_hours) &&
          (store.opening_hours).map( (hour, ind) => {

            return (
            <div className="StoreItemHour" key={'opening_hours_' + ind}>
              <div className="StoreItemHourKey">{ hour.days }</div>
              <div className="StoreItemHourValue">{ hour.time }</div>
            </div>
            )
          })
          }

        </div>
        }
      </div>
    )
  });

  return (
    <div className="Stores" onMouseLeave={
      () => {
        // scrollMapToPosition();
        // setStoreSelected(null);
      }
    }>

      <div className={`LoginLoadingOverlay Animated ${ (loading) ? 'Loading' : '' }`}>
        { (loading) &&
        <div className="LoginLoading">
          <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
        </div>
        }
      </div>

      <div className="StoresWrapper" >
        <div className="FixedWidth">
          <div className="StoresItems">
            <div className="StoresItemsHeader">
              <div className="StoresItemsHeaderTitle">Onze  <b style={{ color: '#e0197d' }}>DA</b> winkels</div>
            </div>
            <div className="StoresItemsItems">
              { mapListObjects }
            </div>
          </div>
        </div>
      </div>

      <div className="StoresWhite" onMouseEnter={
        () => {
          scrollMapToPosition();
          setStoreSelected(null);
        }
      }>

      </div>
      <div className="StoresMap">
        <GoogleMapReact
          ref={map}
          bootstrapURLKeys={{ key: config.google.api }}
          center={mapData.center}
          zoom={mapData.zoom}
          options={{
            styles: config.google.style
          }}
        >
          { mapObjects }
        </GoogleMapReact>
      </div>


    </div>
  );
}

export default Stores;
