import '../styles/Blogs.css';
import config from '../constants/config';
import React, { useState, useEffect } from 'react';
import {
  RiArrowLeftLine,
  RiArrowRightLine,
} from "react-icons/ri";
import axios from 'axios';
import loadingAnimation from '../animations/loading_circle.json';
import Lottie from "lottie-react";


function Blogs(props) {
  const { subview,
    view, setViewPre,
    viewData
   } = props;
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);
  const [ loading, setLoading ] = useState(false);

  const [ blogs, setBlogs ] = useState([]);
  const [ blogFeatured, setBlogFeatured ] = useState(null);

  let subviewParsed = '';
  let subview_cleaned_items = [];
  if(subview && subview != ''){

    subview_cleaned_items = (subview).split('/');

    if(subview_cleaned_items.length > 1){
      // Get main view
      subviewParsed = subview_cleaned_items[0];
    }

  }

   const setPagePre = (page) => {

     window.history.pushState({"z":"z"}, null, config.homepage + 'blogs/' + page);
     window.scrollTo({
       top: 0,
       left: 0,
       behavior: 'smooth'
     });

     setPage(page);
     fetchBlogs(page);
   }

   const fetchBlogs = (pageFinal = page) => {

     setLoading(true);
     axios.post( config.api.url + 'blogs', {
       page: pageFinal,
     }, {
         crossdomain: true,
         headers: {
           'api': config.api.key,
           'session': props.sessionHash
         }
     })
     .then((json) => {
       const { success, response, data } = json.data;

       if(success){
         const featured = data.featured;
         const blogs = data.blogs;
         const stats = data.stats;


         if(featured){
           setBlogFeatured(featured);
         }

         if(blogs){
           setBlogs(blogs);
         }

         if(stats){
           const { pages } = stats;

           if(pages > 0){
             setPages(pages);
           }
         }

       }
       setTimeout( () => {
         setLoading(false);
       }, 1);
     })
     .catch(function (error) {
       setLoading(false);
     });

   }

   // First
   useEffect( () => {

     if(subview_cleaned_items.length > 0){
        const page_parsed = parseInt(subview_cleaned_items[0]);

       // Get main view
       setPage(page_parsed);
       fetchBlogs(page_parsed);
     }else{
       fetchBlogs(1);
     }


   }, []);

   const blogsItems = () => {

     const blogsObjects = blogs.map( (blog, index) => {

       const b_page = 'blog/' + blog.url;
       const b_url = config.homepage + b_page;

       return (
         <a href={b_url} className={`BlogsItem`} onClick={
           (event) => {
             setViewPre(b_page);

             event.preventDefault();
           }
         } key={'blog_item_' + blog.id}>
            <div className="BlogsItemImage" key={'blog_item_image_' + blog.image} style={{ backgroundImage: `url(${blog.image})` }} />
            <div className="BlogsItemDetails">
              <div className="BlogsItemDetailsTitle">{ blog.title }</div>
              <div className="BlogsItemDetailsDate">{ blog.timestamp.date }</div>
              <div className="BlogsItemDetailsText">{ blog.summ }</div>
            </div>
         </a>
       )
     })

     return blogsObjects;
   }

   const productsPagination = () => {

     let pageItems = [];
     const limit_low = (page - 2);
     const limit_high = (page + 2);

     if(blogs.length == 0) return false;

     for(let p = 1; p <= pages; p++){

       if(page < 10){

         if(p <= 10){
           pageItems.push(p)
         }
       }else{
         if(p >= limit_low && p <= limit_high){
           pageItems.push(p)
         }
       }
     }

     if(pages > 10){
       if(page >= 10){
         pageItems.unshift('...');
         pageItems.unshift(1);
       }

       if(page < (pages - 2)){
         pageItems.push('...');
       }

       if(!(pageItems.includes(pages))){
         pageItems.push(pages);
       }
     }

     return (
       <div className="ProductsPaginationWrapper" style={{ paddingBottom: 25 }}>
         <div className="ProductsPagination">

         <a href={ config.homepage + '/blogs/' + ((page > 1) ? (page - 1) : 1) } onClick={
           (event) => {

             if(page > 1){
               setPagePre(page - 1);
             }

             event.preventDefault();
           }
         } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
             <RiArrowLeftLine />
             <div className="ProductsPaginationArrowText">Vorige</div>
           </a>
           { pageItems.map( (pagination, p_index) => {

             if(pagination == '...'){
               return (
                 <a className="ProductsPaginationDots">...</a>
               )
             }else{
               return (
                 <a href={ config.homepage + '/blogs/' + (pagination) } onClick={
                   (event) => {

                     setPagePre(pagination);

                     event.preventDefault();
                   }
                 } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
               )
             }

           })
           }

           { (pages > 1) &&
           <a href={ config.homepage + '/blogs/' + (page + 1) } onClick={
             (event) => {

               if(page < pages){
                 setPagePre(page + 1);
               }

               event.preventDefault();
             }
           } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
             <div className="ProductsPaginationArrowText">Volgende</div>
             <RiArrowRightLine />
           </a>
           }

         </div>
       </div>
     )
   }

  let bf_page = '';
  let bf_url = '';
  if(blogFeatured){
    bf_page = 'blog/' + blogFeatured.url;
    bf_url = config.homepage + bf_page;
  }


  return (
    <div className="Blogs">

      <div className={`LoginLoadingOverlay Animated ${ (loading) ? 'Loading' : '' }`}>
        { (loading) &&
        <div className="LoginLoading">
          <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
        </div>
        }
      </div>

      { (page == 1 && blogFeatured) &&
      <div className="BlogsHeaderWrapper">
        <div className="FixedWidth">
          <div className="BlogsHeader"  style={{ backgroundImage: `url(${blogFeatured.image})` }} >

            <div className="BlogsHeaderDetails">
              <div className="BlogsHeaderDetailsTitle">{ blogFeatured.title }</div>
              <div className="BlogsHeaderDetailsDate">{ blogFeatured.timestamp.date }</div>
              <div className="BlogsHeaderDetailsText" dangerouslySetInnerHTML={{__html: blogFeatured.summ}} />
              <div className="BlogsHeaderOptions">
                <a href={ bf_url } onClick={
                  (event) => {
                    setViewPre(bf_page);

                    event.preventDefault();
                  }
                } className="BlogsHeaderOptionsButton">Lezen</a>
              </div>
            </div>

            <div className="BlogsHeaderImageFloatWrapper">
              <div className="BlogsHeaderImageFloat">
                <div className="BlogsHeaderImageFloatImage"  style={{ backgroundImage: `url(${blogFeatured.image})` }}>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      }
      <div className="FixedWidth">

        { (blogs.length > 0) &&
        <div className="StoresItemsHeader">
          <div className="StoresItemsHeaderTitle">Overige artikelen { (page > 1) ? (' | pagina ' +page + '') : ''}</div>
        </div>
        }
        <div className="BlogsItems">
          { blogsItems() }
        </div>

        { productsPagination() }

      </div>
    </div>
  );
}

export default Blogs;
