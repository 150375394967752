import '../styles/Product.css';
import config from '../constants/config';
import {
  RiArrowRightLine,
  RiShoppingBagFill,
} from "react-icons/ri";
import { Tooltip as DaTooltip } from "react-tooltip";
import { useState, useEffect } from 'react';
import axios from 'axios';

const ProductItem = ({ setHovered, id: p_id, token, setModalPre, item, setViewPre }) => {
    const {
      id,
      image,
      title,
      descr,
      price,
      stock
    } = item;
    const [isHovered, setIsHovered] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [showImage, setShowImage] = useState(true);

    useEffect(() => {
      return () => {
        // Clear the timeout when the component is unmounted or the state changes
        clearTimeout(timeoutId);
      };
    }, [timeoutId]);


    if(!("title" in item)) return false;

    const prices = (price + '').split('.');
    const page = 'product/' + id + '-' + (title.toLowerCase()).replaceAll(' ', '-');
    const p_url = config.homepage + page;
    const pid = (p_id && p_id != '') ? (p_id) : ('product_item_' + id);
    const category = item.category;

    const handleMouseEnter = () => {
      const id = setTimeout(() => {

        setIsHovered(true);
        if(setHovered){
          setHovered(pid);
        }

      }, 1000);
      setTimeoutId(id);
    };

    const handleMouseLeave = () => {
      clearTimeout(timeoutId);
      setTimeoutId(null);
      setIsHovered(false);
    };

    const addToCart = () => {

      axios.post( config.api.url + 'product/add', {
        product_id: id,
        qty: 1,
        hash: token
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setModalPre({
          title: (success) ? 'Toegevoegd.' : 'Oops.',
          close: {
            action: () => {

            }
          },
          subtitle: response,
          content: (
            <>
              <div className="modalContentOptions NoBorder">
                <a onClick={
                  () => {
                    setViewPre('winkelmandje');
                    setModalPre();
                  }
                } className="modalContentOptionsButton C2a AccentHover">Afrekenen</a>
                <a onClick={
                  () => {
                    setModalPre();
                  }
                } className="modalContentOptionsButton">Verder winkelen</a>
              </div>
            </>
          )
        })

        if(success){

        }

      })
      .catch(function (error) {

      });
    }

    return (
      <div id={ pid }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`ProductItem ${(isHovered) ? 'Expand' : ''}`} key={'product_' + id}>
        <a href={p_url} onClick={
          (event) => {
            setViewPre(page);

            event.preventDefault();
          }
        } className="ProductItemContainer">

          <div className="ProductItemImage" style={{
          }}>

            { (showImage) &&
            <img onError={
                () => {
                  setShowImage(false);
                }
              } src={image} alt={ item.title } draggable={false} />
            }

            <span className="ProductItemCategory" style={{
              backgroundColor: category.bg_color,
              color: category.text_color
            }}>{ category.category }</span>
          </div>
          <div className="ProductItemDetails">
            <div className="ProductItemTitle">{ title }</div>
            <div className="ProductItemSubtitle">{ (isHovered) ? '...' : descr }</div>
            <div className="ProductItemPriceView">

              <span className="ProductItemPricePre">{ 'Vanaf' }</span>
              <span className="ProductItemPrice">
                <span className="ProductItemPriceCurr">{ 'SRD' }</span>
                <span className="ProductItemPriceAmount">{ prices[0] }</span>
                <span className="ProductItemPriceSubamount">{ prices[1] }</span>
              </span>

            </div>
          </div>
        </a>

        <div className="ProductItemOptionsWrapper">
          <div className="ProductItemOptions">
            <div className="ProductItemOptionsContent">
              <div className="ProductItemOptionsTitle">Productbeschrijving</div>
              <div className="ProductItemOptionsText">{ descr }</div>
            </div>
            { (token && token != '' && stock > 0) &&
            <div className="ProductItemOptionsOptions">
              <a onClick={
                (event) => {
                  addToCart();

                  event.preventDefault();
                  event.stopPropagation();
                }
              } className="ProductItemOptionsOptionsButton">
                <div className="ProductItemOptionsOptionsButtonText">+1 toevoegen</div>
                <RiShoppingBagFill />
              </a>
            </div>
            }
          </div>
        </div>

      </div>
    );
};

export default ProductItem;
