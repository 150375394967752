import '../styles/Info.css';
import config from '../constants/config';
import React, { useRef, useState, useEffect } from 'react';
import {
  RiArrowRightSLine,
  RiPhoneFill
} from "react-icons/ri";
import axios from 'axios';
import loadingAnimation from '../animations/loading_circle.json';
import Lottie from "lottie-react";


function Info(props) {
  const { subview,
    view, setViewPre,
    viewData
   } = props;
   const [ loading, setLoading ] = useState(false);
   const [ page, setPage ] = useState();
   const [ blocks, setBlocks ] = useState([]);

   const contentView = () => {

    let blockContent = [];
     const blockItems = (blocks).map( (block, index) => {

       switch(block.type){
         case "image_normal":
           if(block.data != ''){
              const images = (block.data.split(',')).map( (image, ind) => {

                return (
                  <img src={ image } draggable={'false'} key={'image' + ind} />
                )
              })

              blockContent.push(
                <div className="BlockItemView">
                  <div className="BlockItemImage Normal"  key={'block_'+index}>
                    { images }
                  </div>
                </div>
              )
           }
         break;
         case "text":
           if(block.data != ''){
              blockContent.push(
                <div className="BlockItemView">
                  <div className="BlockItemText" dangerouslySetInnerHTML={{__html: block.data }} key={'block_'+index} />
                </div>
              )
           }
         break;
       }
     })

     if(blockContent.length > 0){
       return (
         <div className={`BlockItemWrapper`}>
          { (page) &&
          <div className={`BlockItemTitle`}>{ page.title }</div>
          }
          { blockContent }
         </div>
       )
     }

     return false;
   }

   useEffect( () => {

     const fetchInfo = () => {
       setLoading(true);
       axios.post( config.api.url + 'page', {
         page: subview,
       }, {
           crossdomain: true,
           headers: {
             'api': config.api.key,
             'session': props.sessionHash
           }
       })
       .then((json) => {
         const { success, response, data } = json.data;

         if(success){
           const pageObject = data.page;
           const blocksObjects = data.blocks;


           if(blocksObjects){
             setBlocks(blocksObjects);
           }
           if(pageObject){
             setPage(pageObject);
           }

         }

         setLoading(false);

       })
       .catch(function (error) {

         setLoading(false);
       });
     }


     switch(subview){
       case "disclaimer":
       case "privacy":
       case "klantenkaart":
        fetchInfo();
       break;
     }

   }, [subview])

  switch(subview){
    case "disclaimer":
    case "privacy":
    case "klantenkaart":

      return (
        <div className="Info" key={'Info' + subview}>
          <div className="FixedWidth">

            <div className="InfoSplitter">
              <div className="InfoSplitterSide">
                <div className="InfoSplitterSideTitle">Meer items</div>
                <a href={ config.homepage + 'info/privacy'} onClick={
                  (event) => {
                    setViewPre('info/privacy');
                    event.preventDefault();
                  }
                } className={ `InfoSplitterSideItem ${ (subview == 'privacy') ? 'Selected' : ''}` }>
                  <div className="InfoSplitterSideItemTitle">Privacyregelement</div>
                  <RiArrowRightSLine />
                </a>
                <a href={ config.homepage + 'info/disclaimer'} onClick={
                  (event) => {
                    setViewPre('info/disclaimer');
                    event.preventDefault();
                  }
                } className={ `InfoSplitterSideItem ${ (subview == 'disclaimer') ? 'Selected' : ''}` }>
                  <div className="InfoSplitterSideItemTitle">Disclaimer</div>
                  <RiArrowRightSLine />
                </a>
                <a href={ config.homepage + 'info/klantenkaart'} onClick={
                  (event) => {
                    setViewPre('info/klantenkaart');
                    event.preventDefault();
                  }
                } className={ `InfoSplitterSideItem ${ (subview == 'klantenkaart') ? 'Selected' : ''}` }>
                  <div className="InfoSplitterSideItemTitle">Klantenkaart</div>
                  <RiArrowRightSLine />
                </a>
              </div>
              <div className="InfoSplitterInfo">

                  { (loading)
                  ?
                  <div className="LoginLoadingOverlay Inline">
                    <div className="LoginLoading">
                      <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
                    </div>
                  </div>
                  :
                  contentView()
                  }

              </div>
            </div>

          </div>
        </div>
      );
    break;
  }


  return false;
}

export default Info;
