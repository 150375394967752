import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleLine,

 RiCheckFill,
 RiCloseFill,

 RiToggleLine,
 RiToggleFill,
 RiImage2Fill
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";
import Resizer from 'react-image-file-resizer';

import DetailView from "../../../components/DetailView";


function Stores(props) {
  const {
    token, account,
    setModalPre,
    searchQuery,
    subview_data,
    setSettingsHeaderPre,
    showSettingsHeaderPre
  } = props;
  const [ stores, setStores ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);
  const [ modal, setModal ] = useState();

  const [isDragging, setIsDragging] = useState(false);
  const [isLeavingDropzone, setIsLeavingDropzone] = useState(false);

  const scrollView = useRef();
  const inputImage = useRef();

  const inputName = useRef();
  const inputAddress = useRef();
  const inputArea = useRef();
  const inputPhone = useRef();

  const inputOpeningHoursWeek = useRef();
  const inputOpeningHoursSat = useRef();
  const inputOpeningHoursSunday = useRef();

  const inputLocationLat = useRef();
  const inputLocationLng = useRef();

  const setDetailsPre = (mod) => {

    const timeout = (modal) ? 300 : 0;
    if(modal){
      setModal(modalOld=>{
        return {
          ...modalOld,
          hide: true
        }
      });
    }
    
    if(mod){
      showSettingsHeaderPre(false);
    }else{
      showSettingsHeaderPre(true);
    }

    setTimeout( () => {
        setModal(mod);
    }, timeout)

  }


  const selectImage = (file) => {

    if(file) {
        try {

           let type = 'JPEG';
           switch(file.type){
             case "image/png":
              type = 'PNG';
             break;
           }

            Resizer.imageFileResizer(
            file,
            1000,
            1000,
            type, 90,0,
            uri => {

              if(modal){
                setModal( modalOld => {
                  return {
                    ...modalOld,
                    image: {
                      uri: URL.createObjectURL(file),
                      base: uri
                    }
                  }
                })

              }
            },
            'base64',
            500,
            500,
            );
        }   catch(err) {

        }
    }
  }


  const removeStore = (store_id = 0) => {

    if(store_id > 0){

      setStores(storesOld => {
        return storesOld.filter((s, i) => s.id != store_id)
      });


      setLoading(true);
      axios.post( config.api.url + 'admin/stores/delete', {
        hash: token,
        store_id: store_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);

        if(success){

          fetchStores(page, false);

        }

      })
      .catch(function (error) {
        setLoading(false);
      });

    }
  }


  const storeSave = () => {

    document.activeElement.blur();
    if(modal && modal.id >= 0){
      const name = inputName.current?.value;
      const address = inputAddress.current?.value;
      const area = inputArea.current?.value;
      const phone = inputPhone.current?.value;

      const image = (modal.image) ? ((typeof modal.image == 'string') ? modal.image : (modal.image).base) : '';

      const opening_week = inputOpeningHoursWeek.current?.value;
      const opening_sat = inputOpeningHoursSat.current?.value;
      const opening_sun = inputOpeningHoursSunday.current?.value;

      const opening_hours = JSON.stringify([
        {
          days: 'Ma. tot Vrij.',
          time: opening_week
        },
        {
          days: 'Zaterdag',
          time: opening_sat
        },
        {
          days: 'Zondag',
          time: opening_sun
        }
      ]);

      const locationLat = inputLocationLat.current?.value;
      const locationLng = inputLocationLng.current?.value;

      const location = JSON.stringify({
        lat: locationLat,
        lng: locationLng
      });


      setLoading(true);
      axios.post( config.api.url + 'admin/stores/save', {
        hash: token,
        store_id: modal.id,
        image: image,
        name: name,
        address: address,
        area: area,
        phone: phone,
        opening_hours: opening_hours,
        location: location
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);


              console.log(json.data);
        if(success){

          setDetailsPre();
          fetchStores(page, false);

        }else{

          setModalPre({
            title: 'Oops.',
            subtitle: response
          })
        }

      })
      .catch(function (error) {
        setLoading(false);
      });

    }

  }

  const toggleStoreActive = (store_id = 0, store_index) => {

    if(store_id > 0){

      stores[store_index].active = !stores[store_index].active;

      if(modal){
        modal.active = stores[store_index].active;
      }

      // setLoading(true);
      axios.post( config.api.url + 'admin/stores/toggle', {
        hash: token,
        store_id: store_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        // setTimeout( () => {
        //   setLoading(false);
        // }, 1);



        if(success){

          fetchStores(page, false);
        }

      })
      .catch(function (error) {
        // setLoading(false);
      });

    }
  }

  const fetchStores = (pageFinal = page, shouldLoad = true) => {

    if(shouldLoad){
      setLoading(true);
    }
    axios.post( config.api.url + 'admin/stores', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(shouldLoad){
        setTimeout( () => {
          setLoading(false);
        }, 1);
      }

      if(success){
        const stores = data.stores;
        const stats = data.stats;

        if(stores){
          setStores(stores);
        }

        if(shouldLoad){
          scrollView.current?.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/winkels/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchStores(page);
  }

  const storesPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(stores.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/winkels/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/winkels/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/winkels/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }


  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setIsLeavingDropzone(false);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    const { relatedTarget } = e;

    // Check if the relatedTarget is null or outside the dropzone
    if (!relatedTarget || !e.currentTarget.contains(relatedTarget)) {
      setIsDragging(false);
      setIsLeavingDropzone(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    if (!isLeavingDropzone) {
      setIsDragging(true);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    setIsLeavingDropzone(false);

    const file = e.dataTransfer.files[0];

    if(file){
      selectImage(file)
    }
  };


  const modalView = () => {

    return (
        <div className={`ModalSide ${ (isDragging) ? 'Disable' : ''}`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}>
          <div className="ModalSideHeader">
            <div className="ModalSideHeaderTitle">Winkel { 'bewerken' }</div>

            { (modal && modal.id > 0) &&
            <a onClick={
              () => {

                if(modal && modal.id){
                  const modalIndex = (stores).findIndex( (c, i) => c.id == modal.id);
                  toggleStoreActive(modal.id, modalIndex);
                }

              }
            } className={`OrderItemOptionsButton ${ (modal.active) ? 'DeleteInverted' : 'AddInverted' }`}>
              { (modal.active)
              ?
              <RiToggleFill style={{ fontSize: 14 }} />
              :
              <RiToggleLine style={{ fontSize: 14 }} />
              }
              <span className="OrderItemOptionsButtonText">{ (modal.active) ? 'Deactiveren' : 'Activeren' }</span>
            </a>
            }

            <a onClick={
              () => {

                setModalPre({
                  title: 'Verwijderen.',
                  subtitle: 'Ben je zeker dat je deze winkel wilt verwijderen?',
                  content: (
                    <>
                      <div className="modalContentOptions NoBorder">
                        <a onClick={
                          () => {

                            removeStore(modal.id);
                            setDetailsPre();
                            setModalPre();
                          }
                        } className="modalContentOptionsButton Delete">Verwijderen</a>
                        <a onClick={
                          () => {

                            setModalPre();
                          }
                        } className="modalContentOptionsButton">Annuleren</a>
                      </div>
                    </>
                  )
                })

              }
            } className="OrderItemOptionsButton Delete">
              <RiDeleteBin5Line style={{ fontSize: 14 }} />
            </a>
            <a onClick={
              () => {
                setDetailsPre()
              }
            } className="ModalSideHeaderClose">
              <RiCloseLine />
            </a>
          </div>

          <div className="ModalSideContent">
            { (modal) &&
            <div
              className={`ModalSideContentImageWrapper ${ (isDragging) ? 'Enabled' : ''} `}
              onDrop={handleDrop}
            >
              {
              (isDragging)
              ?
              <div className="ModalSideContentImageDrop">
                <div className="ModalSideContentImageDropText">Laat de foto hier los om te selecteren.</div>
              </div>
              :
              <>
                <div className="ModalSideContentImage" style={{ backgroundImage: `url(${ (modal.image) ? ((typeof modal.image == 'string') ? (modal.image) : (modal.image.uri)) : '' })` }}>
                  <div className="ModalSideContentImageOverlay">
                    <div onClick={
                      () => {

                        inputImage.current?.click();

                      }
                    } className="ModalSideContentImageOverlayCircle">
                      <RiImage2Fill />
                      <input ref={ inputImage } type="file" accept=".jpg, .jpeg, .png, .gif" style={{ display: 'none' }} onChange={
                        (event) => {
                           const file = event.target?.files[0];

                           selectImage(file);
                        }
                      } />
                    </div>
                  </div>
                </div>
                <div className="ModalSideContentImageDetails">
                  <div className="ModalSideContentImageDetailsText">{ (modal.image != '') ? 'Foto actief.' : 'Geen foto geselecteerd.' }</div>
                  <div className="ModalSideContentImageDetailsHint">Sleep een foto of klik op 'selecteer foto'.</div>
                  <div className="ModalSideContentImageDetailsOptions">
                    { (modal && modal.image && modal.image != '') &&
                    <a onClick={
                      () => {

                        setModal( modalOld => {
                          return {
                            ...modalOld,
                            image: ''
                          }
                        })

                      }
                    } className="ModalSideContentImageDetailsOptionsButton Delete">Verwijderen</a>
                    }
                    <a onClick={
                      () => {

                        inputImage.current?.click();
                      }
                    } className="ModalSideContentImageDetailsOptionsButton">Selecteer foto</a>
                  </div>
                </div>
              </>
              }

            </div>
            }

            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Naam</div>
                <input
                  ref={inputName}
                  key={ JSON.stringify(modal) }
                  defaultValue={ (modal && modal.name) ? modal.name : '' }
                  placeholder={'DA Winkel'}
                  className={`ModalSideContentInputInput`}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            storeSave();
                          }
                        break;
                      }
                    }
                  }
                />
              </div>
            </div>

            <div className="ModalSideContentTwin">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Adres</div>
                <input
                  ref={inputAddress}
                  key={ JSON.stringify(modal) }
                  defaultValue={ (modal && modal.address) ? modal.address : '' }
                  placeholder={'Pharmacystraat 20'}
                  className={`ModalSideContentInputInput`}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            storeSave();
                          }
                        break;
                      }
                    }
                  }
                />
              </div>
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Buurt (Trefwoorden)</div>
                <input
                  ref={inputArea}
                  key={ JSON.stringify(modal) }
                  defaultValue={ (modal && modal.area) ? modal.area : '' }
                  placeholder={'paramaribo, zuid, noord'}
                  className={`ModalSideContentInputInput`}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            storeSave();
                          }
                        break;
                      }
                    }
                  }
                />
              </div>
            </div>

            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Telefoonnummer</div>
                <input
                  ref={inputPhone}
                  key={ JSON.stringify(modal) }
                  defaultValue={ (modal && modal.phone) ? modal.phone : '' }
                  placeholder={'456789'}
                  className={`ModalSideContentInputInput`}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            storeSave();
                          }
                        break;
                      }
                    }
                  }
                />
              </div>
            </div>

            <div className="modalCouponTitleDivider" />

            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Openingstijden</div>

                <div className="ModalSideContentInputTwin First">
                  <div className="ModalSideContentInputTwinPre">Maandag tot Vrijdag</div>
                  <input
                    ref={inputOpeningHoursWeek}
                    key={ JSON.stringify(modal) }
                    defaultValue={ (modal && modal.opening_hours && modal.opening_hours[0]?.time) ? modal.opening_hours[0]?.time : '' }
                    placeholder={'00:00 - 24:00'}
                    className={`ModalSideContentInputTwinInput`}
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                      }
                    }
                  />
                </div>
                <div className="ModalSideContentInputTwin First">
                  <div className="ModalSideContentInputTwinPre">Zaterdag</div>
                  <input
                    ref={inputOpeningHoursSat}
                    key={ JSON.stringify(modal) }
                    defaultValue={ (modal && modal.opening_hours && modal.opening_hours[1]?.time) ? modal.opening_hours[1]?.time : '' }
                    placeholder={'00:00 - 24:00'}
                    className={`ModalSideContentInputTwinInput`}
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                      }
                    }
                  />
                </div>
                <div className="ModalSideContentInputTwin">
                  <div className="ModalSideContentInputTwinPre">Zondag</div>
                  <input
                    ref={inputOpeningHoursSunday}
                    key={ JSON.stringify(modal) }
                    defaultValue={ (modal && modal.opening_hours &&  modal.opening_hours[2]?.time) ? modal.opening_hours[2]?.time : '' }
                    placeholder={'00:00 - 24:00'}
                    className={`ModalSideContentInputTwinInput`}
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                      }
                    }
                  />
                </div>

              </div>
            </div>


            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Locatie op de map</div>

                <div className="ModalSideContentInputTwin First">
                  <div className="ModalSideContentInputTwinPre">Latitude</div>
                  <input
                    ref={inputLocationLat}
                    key={ JSON.stringify(modal) }
                    defaultValue={ (modal && modal.location) ? modal.location.lat : '' }
                    placeholder={'5.0000'}
                    className={`ModalSideContentInputTwinInput`}
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                      }
                    }
                  />
                </div>
                <div className="ModalSideContentInputTwin">
                  <div className="ModalSideContentInputTwinPre">Longitude</div>
                  <input
                    ref={inputLocationLng}
                    key={ JSON.stringify(modal) }
                    defaultValue={ (modal && modal.location) ? modal.location.lng : '' }
                    placeholder={'-55.0000'}
                    className={`ModalSideContentInputTwinInput`}
                    onKeyUp={
                      (event) => {
                        const key = event.key;

                      }
                    }
                  />
                </div>

              </div>
            </div>


          </div>

          <div className="modalContentOptions LightBorder">
            <a onClick={
              () => {

                storeSave();
              }
            } className="modalContentOptionsButton C2a">Opslaan</a>
            <a onClick={
              () => {

                setDetailsPre();
              }
            } className="modalContentOptionsButton">Annuleren</a>
          </div>

        </div>
    )
  }



  useEffect( () => {

    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchStores(page_parsed);
    }else{

    }


    setSettingsHeaderPre(
      <div className="SettingsContentContentNavigation HideDesktop">
        <a onClick={
          () => {

            setDetailsPre({
              id: 0,
            });
          }
        } className="OrderItemOptionsButton AccentHover">
          <RiAddCircleLine style={{ fontSize: 14 }} />
          <span className="OrderItemOptionsButtonText">Aanmaken</span>
        </a>
      </div>
    )

  }, []);


  useEffect( () => {

    if(!loading){
      // controller.abort();
      fetchStores();
    }

  }, [searchQuery])


  const stores_items = (stores).map( (store_item, index) => {


    return (
      <div className="OrderItem" key={'store_item_' + store_item.id}>
        <div className="OrderItemDetailsText IDSmall">#{ store_item.id }</div>
        <div className="OrderItemDetailsText Active">
          <span className={`OrderItemDetailsTextOption Circle ${ (store_item.active) ? 'Success' : 'Error' }`}>
            { (store_item.active)
            ?
            <RiCheckFill style={{ fontSize: 12 }} />
            :
            <RiCloseFill style={{ fontSize: 12 }} />
            }
          </span>
        </div>
        <div className="OrderItemDetailsText Image">
          <div className="OrderItemDetailsTextImage" style={{ backgroundImage: `url(${store_item.image})` }} />
        </div>
        <div className="OrderItemDetailsText Title Bold">{ store_item.name }</div>
        <div className="OrderItemDetailsText Name">{ store_item.address }</div>

        <div className="OrderItemOptions">

          <a onClick={
            () => {

              toggleStoreActive(store_item.id, index);

            }
          } className={`OrderItemOptionsButton ${ (store_item.active) ? 'DeleteInverted' : 'AddInverted' }`}>
            { (store_item.active)
            ?
            <RiToggleFill style={{ fontSize: 14 }} />
            :
            <RiToggleLine style={{ fontSize: 14 }} />
            }
            <span className="OrderItemOptionsButtonText">{ (store_item.active) ? 'Deactiveren' : 'Activeren' }</span>
          </a>

          <a onClick={
            () => {

              setDetailsPre(store_item);

            }
          } className="OrderItemOptionsButton Edit">
            <RiFileShield2Line style={{ fontSize: 14 }} />
            <span className="OrderItemOptionsButtonText">{ 'Bewerken' }</span>
          </a>
        </div>

      </div>
    )
  })


  return (
    <div className="SettingsContentContent List">

      <div className="SettingsMessageText ShowMobile">Voorlopig alleen op desktop beschikbaar.</div>

      <div className={`settingsContentReal HideDesktop`}>
        <div className="SettingsContentNavigation">
          <div className="SettingsContentNavigationItem IDSmall">ID</div>
          <div className="SettingsContentNavigationItem Active">Actief</div>
          <div className="SettingsContentNavigationItem Image">Foto</div>
          <div className="SettingsContentNavigationItem Title">Naam</div>
          <div className="SettingsContentNavigationItem Name">Address</div>
          <div className="SettingsContentNavigationItem OptionsBig"></div>
        </div>

        <div className="GlobSettingsScroll" ref={scrollView}>

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }

          { (stores_items.length > 0)
          ?
          stores_items
          :
          <div className="SettingsMessageText">Geen winkels.</div>
          }

        </div>

        { storesPagination() }
      </div>


      <DetailView modal={modal} setDetailsPre={setDetailsPre}>
      { modalView() }
      </DetailView>

    </div>
  )
}

export default Stores;
