import '../../styles/DALogo.css';
import * as THREE from 'three'
import { a as three } from '@react-spring/three'
import { useSpring, animated } from '@react-spring/three'
import React, { Suspense, useMemo, useEffect, useRef, useState } from 'react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { Mesh } from "three";
import { useLoader, useFrame, useThree, Canvas } from '@react-three/fiber'
import config from '../../constants/config';
import { Hud, Stats, Environment, useGLTF, AccumulativeShadows, RandomizedLight, OrbitControls, OrthographicCamera, Loader, PerspectiveCamera, Cloud, Sky, Stars } from '@react-three/drei'

function ObjToPrimitive({ url, type = 'logo', scale = 1.5 }) {
  const [obj, setObj] = useState();
  useMemo(() => new OBJLoader().load(url, setObj), [url]);
  if (obj) {
    obj.traverse((child) => {
      if (child instanceof Mesh) {

          switch(type){
            case "earth":
              switch(child.name){

                case "URF-Height_watr_0":
                  child.material = new THREE.MeshStandardMaterial({
                    color: '#3892d4',
                    emissive: '#3892d4'
                  });
                break;

                case "URF-Height_Lampd_0":
                  child.material = new THREE.MeshStandardMaterial({
                    color: '#369246',
                    emissive: '#369246'
                  });
                break;

                case "URF-Height_Lampd_Ice_0":
                  child.material = new THREE.MeshStandardMaterial({
                    color: '#e2e5f1',
                    emissive: '#e2e5f1'
                  });
                break;
              }
            break;

            case "plane":

              switch(child.name){



                default:
                  child.material = new THREE.MeshStandardMaterial({
                    color: 'yellow'
                  });
                break;

              }
            break;

            case "balloon":


              switch(child.name){

                default:
                  child.material = new THREE.MeshStandardMaterial({
                    color: 'red'
                  });
                break;

              }
            break;

            case "logo":
            switch(child.name){

              case "Cube":
                child.material = new THREE.MeshStandardMaterial({
                  color: '#fff',
                  emissive: '#fff'
                });
              break;

              default:
                child.material = new THREE.MeshStandardMaterial({
                  color: '#e0197d',
                  emissive: '#8b2a5e'
                });
              break;
            }
            break;
          }



      }
    });
    return <primitive object={obj} dispose={null} scale={scale} position={[0, 0, 0]} />
  }
  return null;
}

function DALogo(props) {
  const controls = useRef();
  const camera = useRef();
  const groupAll = useRef();
  const group = useRef();
  const groupEarth = useRef();
  const groupFloater = useRef();

  const Model = () => {

      useFrame((state) => {

        camera.current?.updateMatrixWorld();

        if(group && group.current){
          const t = state.clock.getElapsedTime()

          if(t < 0.3){
            const scale = (t / 0.3);

            group.current.scale.x = scale;
            group.current.scale.y = scale;
            group.current.scale.z = scale;
          }

          group.current.rotation.y = Math.sin(t) * .4;
          group.current.position.y = Math.sin(t) * .5;
          group.current.rotation.x = Math.sin(t) * .15;

          groupEarth.current.rotation.x = t * .1;

          // groupFloater.current.position.y = (Math.sin(t * 0.5) * 0.5) - 2;
          // groupFloater.current.rotation.x = Math.sin(t * 0.5) * .05;
          // groupFloater.current.rotation.z = Math.sin(t * 0.5) * .15;
        }

      })

      const prod = true;
      const r_home = (prod) ? config.homepage : '';
      const obj = r_home + "3d/da_logo.obj";
      const obj_earth = r_home + "3d/earth.obj";
      const obj_floater = r_home + "3d/balloon.obj";

      return (
        <group ref={groupAll}>
          <mesh ref={group} position={[0, 0, 0]}>
            {ObjToPrimitive({ url: obj })}
          </mesh>
          <mesh ref={groupEarth} position={[0, -33, 0]} rotation={[0, 0, 0]}>
            {ObjToPrimitive({ url: obj_earth, type: 'earth', scale: 1 })}
          </mesh>
        </group>
      )
  }

  const updateCamera = () => {
    const camera = controls.current.object;
    const pos = new THREE.Vector3();

    // camera.lookAt(pos.set(0, 0, -30));
    // camera.position.lerp( pos.set(0, 0, -30), .01);
    // camera.updateProjectionMatrix();
    controls.current?.reset();

  }

  return (
    <div className="DALogoView" onMouseEnter={
      () => {

      }
    } onMouseLeave={
      () => {


      }
    }>

        <Canvas className="DALogoCanvas" dpr={[1, 2]}>
          <pointLight position={[0, 10, -100]} intensity={1} />

          <pointLight position={[0, 80, 80]} intensity={0.5} />


            <Suspense fallback={null}>
              <group rotation={[0, Math.PI, 0]}>
                <Model />
              </group>
            </Suspense>

          <PerspectiveCamera ref={camera} position={[0, 0, -30]} fov={25} makeDefault {...props} />
          <OrbitControls enableDamping={true} ref={controls} enableZoom={false} enableRotate={true} enablePan={false} minPolarAngle={0} maxPolarAngle={Math.PI / 2.25} makeDefault />

        </Canvas>
    </div>
  );
            // <Stats showPanel={2}/>
}

export default React.memo(DALogo);
