import '../styles/DropDown.css';
import config from '../constants/config';
import colors from '../constants/colors';
import React, { useState, useEffect, forwardRef, useRef } from 'react';
import {
  RiCheckFill
} from "react-icons/ri";


const DropDown = forwardRef((props, ref) => {
  const { selected, list, onSelect } = props;
  const [ open, setOpen ] = useState(false);
  const [ query, setQuery ] = useState()

  if(!list) return (
    <div className="DropDownSelected">NVT</div>
  );

  const hasSearch = (props.onSearch && typeof props.onSearch == 'function');


  console.log(selected);


  return (
      <div className={`DropDown ${ (open) ? 'Show' : '' }`}>

        <div className="DropDownSelected"  onClick={
          () => {
            setOpen(!open)
          }
        }>{ selected }</div>

        { (open) &&
        <div className={`DropDownDropdownWrapper`}>
          { (hasSearch) &&
          <div className="DropDownDropdownSearchWrapper">
            <input
              key={ 'drop_search' }
              value={query}
              placeholder={'Zoeken ...'}
              className={`DropDownDropdownSearch`}
              onChange={
                (event) => {

                  if(props.onSearch && typeof props.onSearch == 'function'){
                    props.onSearch(event.target?.value);
                  }
                }
              }
            />
          </div>
          }
          <div className={`DropDownDropdown ${ (hasSearch) ? 'Search' : '' }`}>
            { (list).map( (item, index) => {

              const isSelected = (item.value == selected);

              return (
                <div onClick={
                  () =>{
                    onSelect(item);
                    setOpen(false);
                  }
                } key={'dropdown_' + item.key} className={`DropDownDropdownItem ${ (item.key == 'CANCELED') ? 'Canceled' : ''}`}>
                <div className={`DropDownDropdownItemText`}>{ item.value }</div>
                { (isSelected) &&
                <span className="DropDownDropdownItemCircle">
                  <RiCheckFill style={{ fontSize: 12, color: '#fff' }} />
                </span>
                }
              </div>
              )
            })
            }
          </div>
        </div>
        }


    </div>
  );
});

export default (DropDown);
