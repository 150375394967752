import '../styles/BlogDetails.css';
import config from '../constants/config';
import {
  RiArrowRightSLine,
  RiArrowLeftLine,
  RiArrowRightLine,
  RiAddFill,
  RiSubtractFill,
  RiShoppingBagLine,
  RiHomeHeartLine,
  RiArrowGoBackFill,
  RiStore2Fill,

  RiCloseFill,
  RiStore3Fill,
  RiCustomerServiceFill,
  RiArrowGoForwardLine
} from "react-icons/ri";
import { useState, useEffect } from 'react';
import loadingAnimation from '../animations/loading_circle.json';
import Lottie from "lottie-react";
import axios from 'axios';


function BlogDetails(props) {
  const { subview,
    view, setViewPre,
    viewData,
    token
   } = props;
   const [ loading, setLoading ] = useState(false);
   const [ blogsRelated, setBlogsRelated ] = useState([]);
   const [ blog, setBlog ] = useState(null);

   // Get subview
   let id = 0;
   if(subview){
     const subviews = (subview + '').split('-');
     id = subviews[0];
   }


   const fetchBlog = () => {

     axios.post( config.api.url + 'blog/info', {
       blog_id: id,
     }, {
         crossdomain: true,
         headers: {
           'api': config.api.key,
           'session': props.sessionHash
         }
     })
     .then((json) => {
       const { success, response, data } = json.data;

       if(success){
         const blog = data.blog;

         if(blog){
          setBlog(blog);
         }

       }

       setLoading(false);
     })
     .catch(function (error) {

       setLoading(false);
     });

  }

   const fetchBlogsRelated = (type = 'related') => {

    axios.post( config.api.url + 'blogs/related', {
      blog_id: id,
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;


      if(success){
        const blogs = data.blogs;

        if(blogs){
          setBlogsRelated(blogs);
        }

      }

    })
    .catch(function (error) {


    });

   }


   useEffect( () => {

    fetchBlog();
    fetchBlogsRelated();

   }, [id]);

  return (
    <div className="BlogDetails">
      <div className="FixedWidth">
        <div className="AboutUsSide">
          <div className="AboutUsSideOptions">

            { (viewData && viewData.previous) &&
            <a href={ config.homepage + viewData.previous} onClick={
                (event) => {
                  setViewPre(viewData.previous);

                  event.preventDefault();
                }
              } className={`AboutUsSideOption Close`}>
              <div className="AboutUsSideOptionText">{ 'Terug naar vorige pagina' }</div>
              <RiArrowGoBackFill className="AboutUsSideOptionIcon" style={{ fontSize: 13 }} />
            </a>
            }

            <div className="AboutUsSideOptionsTitle" style={{ marginTop: 30 }}>Overige artikelen</div>

            <div className="AboutUsSideOptionOthers">

            { (blogsRelated.length == 0)
            ?
            <div className="LoginLoadingOverlay Inline">
              <div className="LoginLoading">
                <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
              </div>
            </div>
            :
            <div className="BlogDetailsSideItems">
              { blogsRelated.map( (news_item, news_index) => {

                 const b_page = 'blog/' + news_item.url;
                 const b_url = config.homepage + b_page;

                return (
                  <a href={b_url} onClick={
                    (event) => {

                      setViewPre(b_page);

                      event.preventDefault();
                    }
                  } key={'news_item_' + news_index} className="HomeContentItemNewsItem">
                    <div className="HomeContentItemNewsItemImage" style={{ backgroundImage: `url(${news_item.image})` }} />
                    <div className="HomeContentItemNewsItemDetails">
                      <div className="HomeContentItemNewsItemDetailsTitle">{ news_item.title }</div>
                      <div className="HomeContentItemNewsItemDetailsDate">{ news_item.timestamp.date }</div>
                      <div className="HomeContentItemNewsItemDetailsText">{ news_item.summ }</div>
                    </div>
                    <div className="HomeContentItemNewsItemArrow">

                    </div>
                  </a>
                  )
                })
              }
            </div>
            }

            </div>


          </div>

          <div className="AboutSideFooterView">
            <div className="AboutSideFooterText">© { new Date().getFullYear() } DA | Voel je gezond!</div>
          </div>
        </div>

        <div className="AboutUsContent">

          { (blog) &&
          <div className="AboutUsImage" style={{ backgroundImage: `url('${ blog.image }')` }} />
          }

          { (blog) &&
          <div className="BlogDetailsHeaderDetails">
            <div className="BlogDetailsHeaderDate">{ blog.timestamp.date }</div>
            <div className="BlogDetailsHeaderTitle">{ blog.title }</div>
          </div>
          }

          <div className={`LoginLoadingOverlay Animated ${ (loading) ? 'Loading' : '' }`}>
            { (loading) &&
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
            }
          </div>

          { (blog) &&
          <div className="BlogDetailsContentText" dangerouslySetInnerHTML={{__html: blog.content }}>

          </div>
          }

          
          { (blog?.product) &&
            <div className='BlogDetailsProduct' onClick={() => {
            }}>
              <div className='BlogDetailsProductImageWrapper'>
                <img src={blog.product.image} draggable={false} className='BlogDetailsProductImage' />
              </div>
              <div className='BlogDetailsProductInfo'>

                <div className='BlogDetailsProductInfoTitle'>
                  {blog.product.title}
                  <a className='BlogDetailsProductBtn' href={blog.product.url}>Naar Product <RiArrowGoForwardLine size={14} color={'#fff'} style={{marginLeft: '5px'}}/></a>
                </div>
                <div className='BlogDetailsProductInfoCat'>{blog.product.category.category}</div>

                <div className='BlogDetailsProductInfoPriceContent'>
                  <div className='BlogDetailsProductInfoPricePre'>SRD</div>
                  <div className='BlogDetailsProductInfoPrice'>{blog.product.price.toString().split('.')[0]}</div>
                  <div className='BlogDetailsProductInfoPriceSub'>{blog.product.price.toString().split('.')[1]}</div>
                </div>

                <div className='BlogDetailsProductDescrWrapper'>
                  <div className='BlogDetailsProductDescrTitle'>Productbeschrijving</div>
                  <div className='BlogDetailsProductDescr' dangerouslySetInnerHTML={{ __html: blog.product.descr }} />
                </div>

              </div>
            </div>
          }
          


        </div>

      </div>
    </div>
  );
}

export default BlogDetails;
