import '../styles/Header.css';
import config from '../constants/config';
import {
  RiCheckDoubleFill,
  RiCustomerServiceLine,
  RiCustomerServiceFill,
  RiUser3Line,
  RiUser3Fill,
  RiShoppingBag3Line,
  RiShoppingBag3Fill,
  RiArrowDownSLine,
  RiSearchLine,
  RiCloseLine,
  RiMenuFill,
  RiArticleFill,
  RiStore3Fill,
  RiPhoneFill,
  RiPagesFill,
  RiBriefcaseLine
} from "react-icons/ri";
import { Tooltip as DaTooltip } from "react-tooltip";
import React, { Animated, useRef, useState, useEffect } from 'react';
import loadingAnimation from '../animations/loading_circle.json';
import Lottie from "lottie-react";
import axios from 'axios';


function Header(props) {
  const {
    menu, setMenu,
    view, setViewPre,
    cartCount,
    accountNew,
    account,
    token,
    setAccountPre,
    setShowCookiesPopupPre
  } = props;

  let view_parsed = view;
  let show_search = true;
  const [ searchQuery, setSearchQuery ] = useState('');
  const [ searchResults, setSearchResults ] = useState([]);
  const [ marquee, setMarquee ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const searchQueryInput = useRef();

  let subview = '';
  if((view).includes('category')){
    view_parsed = 'producten';
  }else if((view).includes('product')){
    view_parsed = 'product';
  }else if((view).includes('blogs')){
    view_parsed = 'blogs';
  }else if((view).includes('account')){
    show_search = false;
  }

  const setSearchQueryPre = (query) => {

      setSearchQuery(query);
  }


  useEffect( () => {
    let cancelTokenSource;

    const fetchResults = async () => {

      cancelTokenSource = axios.CancelToken.source();
      axios.post( config.api.url + 'search/global', {
        query: searchQuery,
      }, {
          cancelToken: cancelTokenSource.token,
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setLoading(false);

        console.log(json.data)

        if(success){
          const results = data.results;

          setSearchResults(results);

        }else{
          setSearchResults([]);
        }


      })
      .catch(function (error) {
        setLoading(false);
        setSearchResults([]);

      });
    };

    if(searchQuery && searchQuery.length >= 2){
      fetchResults();
    }else if(searchResults.length > 0){
      setSearchResults([]);
    }

    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Request canceled due to component unmount');
      }
    };
  }, [searchQuery]);

  useEffect( () => {

    fetchMarquee();

  }, [])

  const searchView = () => {
    const searchItems = (searchResults).map( (result, index) => {

      switch(result.type){
        case "product":

          const page = 'product/' + result.id + '-' + (result.title.toLowerCase()).replaceAll(' ', '-');

          return (
            <a href={ config.homepage + page } onClick={
              (event) => {
                setViewPre(page);

                document.activeElement.blur();

                event.preventDefault();
              }
            } className={`searchItemWrapper`} key={'search_result_' + index}>
              <div className="searchItemProductImage" style={{ backgroundImage: `url('${ result.image }')` }} />
              <div className="searchItemDetails">
                <div className="searchItemDetailsTitle">{ result.title }</div>
                <div className="searchItemDetailsSubtitle">{ result.subtitle }</div>
              </div>
              <div className="searchItemInfo">
                <div className="searchItemInfoPre">Vanaf</div>
                <div className="searchItemInfoValue">SRD { result.price }</div>
              </div>
            </a>
          )
        break;
        case "blog":

          const b_page = 'blog/' + result.id + '-' + (result.title.toLowerCase()).replaceAll(' ', '-');

          return (
            <a href={ config.homepage + b_page } onClick={
              (event) => {
                setViewPre(b_page);

                document.activeElement.blur();

                event.preventDefault();
              }
            } className={`searchItemWrapper`} key={'search_result_blog_' + index}>
              <div className="searchItemBlogImage" style={{ backgroundImage: `url('${ result.image }')` }} />
              <div className="searchItemDetails">
                <div className="searchItemDetailsTitle">{ result.title }</div>
                <div className="searchItemDetailsSubtitle">{ result.subtitle }</div>
                <div className="searchItemDetailsDate">{ result.timestamp.date }</div>
              </div>
              <div className="searchItemIcon">
                <RiArticleFill />
              </div>
            </a>
          )
        break;
        case "store":

          const s_page = 'winkels/' + result.id + '-' + (result.name.toLowerCase()).replaceAll(' ', '-');

          return (
            <a href={ config.homepage + s_page } onClick={
              (event) => {
                setViewPre(s_page);

                document.activeElement.blur();

                event.preventDefault();
              }
            } className={`searchItemWrapper`} key={'search_result_store_' + index}>
              <div className="searchItemStoreImage" style={{ backgroundImage: `url('${ result.image }')` }} />
              <div className="searchItemDetails">
                <div className="searchItemDetailsTitle">{ result.name }</div>
                <div className="searchItemDetailsSubtitle">{ result.address }</div>
                <div className="searchItemDetailsPhoneView">
                  <div className="searchItemDetailsPhoneIcon">
                    <RiPhoneFill />
                  </div>
                  <div className="searchItemDetailsPhone">{ result.phone }</div>
                </div>
              </div>
              <div className="searchItemIcon">
                <RiStore3Fill />
              </div>
            </a>
          )
        break;
        case "page":

          const p_page = result.url;

          return (
            <a href={ config.homepage + p_page } onClick={
              (event) => {

                setViewPre(p_page);

                document.activeElement.blur();

                event.preventDefault();
              }
            } className={`searchItemWrapper`} key={'search_result_page_' + index}>
              <div className="searchItemDetails">
                <div className="searchItemDetailsTitle">{ result.title }</div>
              </div>
              <div className="searchItemIcon">
                <RiPagesFill />
              </div>
            </a>
          )
        break;
        case "vacancy":

          const v_page = 'vacatures/' + result.id + '-' + (result.title.toLowerCase()).replaceAll(' ', '-');

          return (
            <a href={ config.homepage + v_page } onClick={
              (event) => {
                setViewPre(v_page);

                document.activeElement.blur();

                event.preventDefault();
              }
            } className={`searchItemWrapper`} key={'search_result_page_' + index}>
              <div className="searchItemDetails">
                <div className="searchItemDetailsTitle">{ result.title }</div>
                <div className="searchItemDetailsSubtitle Upper">{ result.job_type }</div>
              </div>
              <div className="searchItemIcon">
                <RiPagesFill />
              </div>
            </a>
          )
        break;

        case "header":
          return (
            <div className="searchItemHeader" key={'result_header_' + index}>
              <div className="searchItemHeaderTitle">{ result.title }</div>
            </div>
          )
        break;
      }

      return false;
    })


    return (
      <div className={`searchItemsView`}>
          { (searchItems.length > 0)
          ?
          searchItems
          :
          (
            (loading)
            ?
            <div className="LoginLoadingOverlay Inline" style={{ height: 46 }}>
              <div className="LoginLoading">
                <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
              </div>
            </div>
            :
            <div className="searchItemWrapperText">{ (searchQuery.length >= 2) ? 'Geen resultaten gevonden.' : ( (searchQuery.length == 1) ? 'Typ minimaal nog 1 letter om te zoeken.' : '... een product, artikel, informatie, vacature of winkel.') }</div>
          )
         }
      </div>
    )

  }


 const fetchMarquee = () => {
   setLoading(true);
   axios.post( config.api.url + 'page', {
     page: 'marquee_top',
   }, {
       crossdomain: true,
       headers: {
         'api': config.api.key
       }
   })
   .then((json) => {
     const { success, response, data } = json.data;

     if(success){
       const blocksObjects = data.blocks;

       if(blocksObjects){
         setMarquee(blocksObjects);
       }

     }

     setLoading(false);

   })
   .catch(function (error) {

     setLoading(false);
   });
 }

  const HeaderMessages = () => {

    const marquees = (marquee).map( (mar, ind) => {

      return (
        <div className="HeaderMessageItem Good" key={'marquee_' + ind}>{ mar.data }</div>
      )
    })

    return (
      <div className="HeaderMessages">
        { marquees }
      </div>
    )
  }


  return (
    <div className="HeaderWrapper">

      <div className="HeaderSub">
        <div className="HeaderNavWidth">

          <div className="HeaderSubLeft">
            <HeaderMessages />
          </div>

          <div className="HeaderSubNav">
            <a href={ config.homepage + 'over-ons' } onClick={
              (event) => {
                setViewPre('over-ons');
                event.preventDefault();
              }
            } className="HeaderSubNavItem">Over ons</a>
            <a href={ config.homepage + 'over-ons' } onClick={
              (event) => {
                setViewPre('contact');
                event.preventDefault();
              }
            } className="HeaderSubNavItem">Contact</a>
            <a onClick={
              (event) => {
                setViewPre('vacatures');

                event.preventDefault();
              }
            } className="HeaderSubNavItem">Werken bij <b>DA</b></a>
          {/*
            <a href={ config.homepage + 'info/folder' } onClick={
              (event) => {
                setViewPre('info/folder');
                event.preventDefault();
              }
            } className="HeaderSubNavItem">Folder</a> */}
            <a href={ config.homepage + 'klantenservice' } onClick={
              (event) => {
                setViewPre('klantenservice');
                event.preventDefault();
              }
            } className="HeaderSubNavItem">Klantenservice</a>
          </div>

        </div>
      </div>

      <div className="HeaderNav">
        <div className="HeaderNavWidth">

          <a href={ config.homepage } id="menu-open" onClick={
            (event) => {

              if(view == 'home'){
                setMenu( (menu != 'menu') ? 'menu' : null);
              }else{
                setViewPre('home');
              }

              event.preventDefault();
            }
          } className="HeaderNavLogoWrapper">
            <div className="HeaderNavLogoImage">
              <img src={ config.homepage + 'data/logos/logo_0.png'} draggable={false} />
            </div>
            <div className="HeaderNavLogoDetails">
              <div className="HeaderNavLogoDetailsName">DA Drogisterij</div>
              <div className="HeaderNavLogoDetailsSubtitle">
                <img className="HeaderNavLogoDetailsSubtitleFlag" src="https://flagpedia.net/data/flags/w1160/sr.webp" id="location-suriname" />
                <div className="HeaderNavLogoDetailsSubtitleText">Suriname</div>
              </div>
            </div>
          </a>
          <DaTooltip
            anchorId="menu-open"
            place="bottom"
            content={ (view == 'home') ? 'Menu openen' : 'DA Home' }
          />

          <div className="HeaderNavOptions">

            <a href={ config.homepage + 'producten' }  onClick={
              (event) => {
                setMenu('producten')

                event.preventDefault();
              }
            } className={`HeaderNavOptionsMenu ${ (view_parsed == 'producten') ? 'Selected' : '' }`}>
              <span>Producten</span>
              <RiArrowDownSLine />
            </a>
            <a href={ config.homepage + 'categorie/aanbiedingen' }  onClick={
              (event) => {
                setViewPre('categorie/aanbiedingen')

                event.preventDefault();
              }
            } className={`HeaderNavOptionsMenu ${ (view_parsed == 'aanbiedingen') ? 'Selected' : '' }`}>
              <span>Aanbiedingen</span>
            </a>
            <a href={ config.homepage + 'winkels' } onClick={
              (event) => {
                setViewPre('winkels');

                event.preventDefault();
              }
            } className={`HeaderNavOptionsMenu ${ (view_parsed == 'winkels') ? 'Selected' : '' }`}>Winkels</a>
            <a href={ config.homepage + 'blogs' } onClick={
              (event) => {
                setViewPre('blogs');

                event.preventDefault();
              }

            } className={`HeaderNavOptionsMenu ${ (view_parsed == 'blogs') ? 'Selected' : '' }`}>Blogs</a>


          </div>

          { (show_search) &&
          <div className="HeaderNavSearchWrapper">

              <div className="HeaderNavSearch">
                <input
                  ref={searchQueryInput}
                  className="HeaderNavSearchInput"
                  placeholder={'Zoeken naar ...'}
                  value={searchQuery}
                  onChange={
                    (event) => {
                      setSearchQueryPre(event.target?.value)
                    }
                  }
                />
                <span onClick={
                  () => {
                    if(searchQuery.length > 0){
                      setSearchQueryPre('')
                    }else{
                      searchQueryInput.current?.focus();
                    }
                  }
                } className="HeaderNavSearchButton">
                  { (searchQuery.length > 0)
                  ?
                  <RiCloseLine />
                  :
                  <RiSearchLine />
                  }
                </span>
              </div>

              { searchView() }
          </div>
          }

          <div className="HeaderNavButtons">

            <a href={ config.homepage + 'klantenservice' } onClick={
              (event) => {

                setViewPre('klantenservice');
                event.preventDefault();
              }
            } id="quick-customer-service" className={`HeaderNavButton HideDesktop ${ (view == 'klantenservice') ? 'Selected' : '' }`}>
              { (view == 'klantenservice')
              ?
              <RiCustomerServiceFill />
              :
              <RiCustomerServiceLine />
              }
              <span className="HeaderNavButtonBadge" />
            </a>

            <a href={ config.homepage + 'winkelmandje' } onClick={
              (event) => {

                setViewPre('winkelmandje');
                event.preventDefault();
              }
            } id="quick-shopping-bag" className={`HeaderNavButton ${ (view == 'winkelmandje') ? 'Selected' : '' }`}>
              { (view == 'winkelmandje')
              ?
              <RiShoppingBag3Fill />
              :
              <RiShoppingBag3Line />
              }

              { (cartCount > 0) &&
              <span className="HeaderNavButtonCount">{ cartCount }</span>
              }
            </a>

            <a href={ config.homepage + 'account' } onClick={
              (event) => {

                if(account && view == 'account'){

                }else{
                  setViewPre('account');
                }

                event.preventDefault();
              }
            } id="quick-account" className={`HeaderNavButton ${ (view == 'account') ? 'Selected' : '' }`}>
              { ((account)
              ?
              (
                (account.avatar == '')
                ?
                <div className="HeaderNavAvatar" style={{ backgroundImage: `url('${ config.homepage + 'data/logos/logo_0.png' }')` }} referrerPolicy="no-referrer" />
                :
                <div className="HeaderNavAvatar" style={{ backgroundImage: `url('${ account.avatar }')` }} referrerPolicy="no-referrer" />
                )
              :
              (
              (view == 'account')
              ?
              <RiUser3Fill />
              :
              <RiUser3Line />
              ))
              }

              { (accountNew) &&
              <span className="HeaderNavButtonCount Small"></span>
              }
            </a>

            <a onClick={
              (event) => {

                setMenu( (menu != 'menu') ? 'menu' : null);
                event.preventDefault();
              }
            } id="quick-menu" className={`HeaderNavButton ShowMobile`}>
              <RiMenuFill />
            </a>

            <DaTooltip
              anchorId="quick-customer-service"
              place="bottom"
              content="Klantenservice"
            />
            <DaTooltip
              anchorId="quick-account"
              place="bottom"
              content={ (account) ? ( (view == 'account') ? 'Al bij account' : account.name) : 'Log in om uw bestelling te plaatsen'}
            />
            <DaTooltip
              anchorId="quick-shopping-bag"
              place="bottom"
              content="Winkelmandje"
            />
            <DaTooltip
              anchorId="quick-menu"
              place="bottom"
              content={ (menu != 'menu') ? 'Menu openen' : 'Menu sluiten' }
            />
          </div>

        </div>
      </div>

    </div>
  );
}

export default Header;
