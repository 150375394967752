import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleLine,

 RiBold,
 RiUnderline,
 RiItalic,
 RiImage2Fill,
 RiCloseFill,
 RiImageEditFill,
 RiAddCircleFill,

 RiAddFill
} from "react-icons/ri";
import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";
import { Editor, EditorState, RichUtils, ContentState, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import htmlToDraft from 'html-to-draftjs';
import DetailView from "../../../components/DetailView";
import Resizer from 'react-image-file-resizer';



function SettingsContent(props) {
  const {
    token, account,
    setModalPre,
    searchQuery,
    subview_data,
    setShouldUpdatePre
  } = props;
  const [ content, setContent ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ contentSelected, setContentSelected ] = useState(null);
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);
  const [ modal, setModal ] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [isLeavingDropzone, setIsLeavingDropzone] = useState(false);
  const inputImage = useRef();

  const [ contentImages, setContentImages ] = useState([]);
  const [ contentImageSelected, setContentImageSelected ] = useState(null);

  const [ editorState, setEditorState ] = React.useState(
    () => EditorState.createEmpty(),
  );
  const handleKeyCommand = (command) => {


    let nextState = null;
    if(command === 'bold'){

      nextState = RichUtils.toggleInlineStyle(editorState, "BOLD");
      setEditorState(nextState);

      return 'handled';
    }else if(command === 'underline'){

      nextState = RichUtils.toggleInlineStyle(editorState, "UNDERLINE");
      setEditorState(nextState);

      return 'handled';
    }else if(command === 'italic'){

      nextState = RichUtils.toggleInlineStyle(editorState, "ITALIC");
      setEditorState(nextState);

      return 'handled';
    }
    return 'not-handled';
  }

  const setDetailsPre = (mod) => {

    const timeout = (modal) ? 300 : 0;
    if(modal){
      setModal(modalOld=>{
        return {
          ...modalOld,
          hide: true
        }
      });
    }

    if(mod && mod.data.length > 0){
      setContentImages(mod.data);
    }else{
      setContentImages([]);
      setContentImageSelected();
    }

    setTimeout( () => {
        setModal(mod);
    }, timeout)


  }

  const htmlToDraftBlocks = (html) => {

   html = html.replaceAll(/\s+/g,' ');

   const blocksFromHtml = htmlToDraft(html);


   const { contentBlocks, entityMap } = blocksFromHtml;
   const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
   const editorState = EditorState.createWithContent(contentState);

   return editorState;
  }

  const setContentSelectedPre = (selected) => {

    if(selected){
      const { data } = selected;

      if(data && data != ''){
        setEditorState(htmlToDraftBlocks(data))
      }
    }

    setContentSelected(selected);
  }

  const fetchContent = (pageFinal = page) => {

    setLoading(true);
    axios.post( config.api.url + 'admin/content', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      setTimeout( () => {
        setLoading(false);
      }, 1);


      if(success){
        const content = data.content;
        const stats = data.stats;

        if(content){
          setContent(content);
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/content/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchContent(page);
  }

  const contentPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(content.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/content/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/content/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/content/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }

  useEffect( () => {


    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchContent(page_parsed);
    }else{

    }

  }, []);

  useEffect( () => {

    if(!loading){
      // controller.abort();
      fetchContent();
    }

  }, [searchQuery]);

  const insert = (main_string, ins_string, pos) => {
    if(typeof(pos) == "undefined"){
      pos = 0;
    }
    if(typeof(ins_string) == "undefined"){
      ins_string = '';
    }
    return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
   }

  const saveContentImages = (content_id = 0) => {

    if(content_id > 0){

      const contentImagesRaw = (contentImages).map( (im, ind) => {

        return (typeof im == 'string') ? im : im.base
      })

      setLoading(true);
      axios.post( config.api.url + 'admin/content/images/save', {
        hash: token,
        content_id: content_id,
        data: contentImagesRaw
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);
          console.log(json.data);

        if(success){

          // Set stuff
          fetchContent();
          setContentSelectedPre(null);

          // Save
          setShouldUpdatePre();

        }else{
          setModalPre({
            title: 'Oops.',
            subtitle: response
          })
        }

      })
      .catch(function (error) {
        setLoading(false);
      });
    }


  }

  const saveContentItem = () => {
    const contentState = editorState.getCurrentContent();
    const contentData = convertToRaw(contentState);

    // Go Through blocks
    let text = '';
    const blocks = contentData.blocks;
    for(let b = 0; b < blocks.length; b++){
      const block = blocks[b];

      let localText = block.text;
      const styleChanges = block.inlineStyleRanges;

      let realOffset = 0;
      for(let t = 0; t < styleChanges.length; t++){
        const styleChange = styleChanges[t];


        const offset = realOffset + styleChange.offset;
        const length = styleChange.length;

        switch(styleChange.style){

          case "BOLD":
            localText = insert(localText, '<strong>', offset);
            localText = insert(localText, '</strong>', offset + length + 8);

            realOffset = realOffset + (8 + 9);
          break;
          case "UNDERLINE":
            localText = insert(localText, '<u>', offset);
            localText = insert(localText, '</u>', offset + length + 3);

            realOffset = realOffset + (3 + 4);
          break;
          case "ITALIC":
            localText = insert(localText, '<i>', offset);
            localText = insert(localText, '</i>', offset + length + 3);

            realOffset = realOffset + (3 + 4);
          break;
        }
      }

      text += localText + '<br/>';
    }


    // Replace
    text = text.replace(/(?:\r\n|\r|\n)/g, '<br/>');
    text = text.replace(/(<br\/>\s*)+$/, "");

    if(text != ''){
      setLoading(true);
      axios.post( config.api.url + 'admin/content/save', {
        hash: token,
        content_id: contentSelected.id,
        data: text
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);


        if(success){

          const listIndex = (content).findIndex( (f, i) => contentSelected.id == f.id);
          if(listIndex >= 0){
            content[listIndex].data = text;
          }

          // Set stuff
          fetchContent();
          setContentSelectedPre(null);

          // Save
          setShouldUpdatePre();

        }else{
          setModalPre({
            title: 'Oops.',
            subtitle: response
          })
        }

      })
      .catch(function (error) {
        setLoading(false);
      });
    }else{
      setModalPre({
        title: 'Oops.',
        subtitle: 'Velden zijn leeg.'
      })
    }

  }

  const editorView = () => {

    const inlineStyle = editorState.getCurrentInlineStyle();
    const isBold = inlineStyle.has("BOLD");
    const isUnderline = inlineStyle.has("UNDERLINE");
    const isItalic = inlineStyle.has("ITALIC");


    return (
      <div className={`editorContent ${(contentSelected) ? 'Show' : ''}`}>
        <div className="editorHeader Right">
          <a onClick={
            () => {
              saveContentItem();
            }
          } className="editorHeaderButton">
            <div className="editorHeaderButtonText">Opslaan</div>
            <RiSave2Line />
          </a>
          <a onClick={
            () => {
              setContentSelectedPre(null)
            }
          } className="editorHeaderClose">
            <RiCloseLine />
          </a>
        </div>
        <div className="editorNavigation">
          <button onClick={ () => {
            const nextState = RichUtils.toggleInlineStyle(editorState, "BOLD");

            setEditorState(nextState);
          }} className={`editorNavigationButton ${(isBold) ? 'Selected' : ''}`}>
            <RiBold />
          </button>
          { /*
          <button onClick={ () => {
            const nextState = RichUtils.toggleInlineStyle(editorState, "UNDERLINE");

            setEditorState(nextState);
          }} className={`editorNavigationButton ${(isUnderline) ? 'Selected' : ''}`}>
            <RiUnderline />
          </button>
          <button onClick={ () => {
            const nextState = RichUtils.toggleInlineStyle(editorState, "ITALIC");

            setEditorState(nextState);
          }} className={`editorNavigationButton ${(isItalic) ? 'Selected' : ''}`}>
            <RiItalic />
          </button>
          */ }
        </div>
        <div className="editorEditor">
          <Editor
          editorState={editorState}
          onChange={setEditorState}
          handleKeyCommand={handleKeyCommand}
          />
        </div>
      </div>
    )
  }

  const content_items = (content).map( (content_item, index) => {


    const itemContent = (typeof content_item.data_clean == 'object') ? (
      (content_item.data_clean).map( (ite, inde) => {
        return (
          <div key={'content_item_' + index + '_img_' + inde} className="OrderItemDetailsImage" style={{ backgroundImage: `url(${ ite })` }}>
          </div>
        )
      })
    ) : content_item.data_clean;


    let c2a = null;
    let c2a_icon = (<RiFileShield2Line style={{ fontSize: 14 }} />);
    let c2a_text = 'Wijzigen';
    switch(content_item.type){
      case "text":
        c2a = () => {
          setContentSelectedPre(content_item)
        };
      break;
      case "image_normal":
        c2a_text = 'Wijzigen';
        c2a_icon = (<RiImageEditFill style={{ fontSize: 14 }} />);
        c2a = () => {
          setDetailsPre({
            id: content_item.id,
            type: 'images',
            data: content_item.data
          })
        };
      break;
      case "image_full":
        c2a_text = 'Wijzigen';
        c2a_icon = (<RiImageEditFill style={{ fontSize: 14 }} />);
        c2a = () => {
          setDetailsPre({
            id: content_item.id,
            type: 'images',
            data: content_item.data
          })
        };
      break;
    }

    return (
      <div className="OrderItem" key={'content_item_' + index}>
        <div className="OrderItemDetailsText IDSmall">#{ content_item.id }</div>
        <div className="OrderItemDetailsText Order">{ content_item.order }</div>
        <div className="OrderItemDetailsText NameMid Bold">{ content_item.page }</div>
        <div className="OrderItemDetailsText Content">{ itemContent }</div>
        <div className="OrderItemDetailsText Date">{ content_item.timestamp_last?.date }</div>

        <div className="OrderItemOptions">
          <a onClick={
            () => {

              if(c2a && typeof c2a == 'function'){

                c2a();
              }

            }
          } className="OrderItemOptionsButton Edit">
            { c2a_icon }
            <span className="OrderItemOptionsButtonText">{ c2a_text }</span>
          </a>
        </div>
      </div>
    )
  })

  const selectImage = (file) => {

    if(file) {
        try {

           let type = 'JPEG';
           switch(file.type){
             case "image/png":
              type = 'PNG';
             break;
           }

            Resizer.imageFileResizer(
            file,
            1000,
            1000,
            type, 90,0,
            uri => {

              if(modal){


                // UPDATE FILE
                let filteredImages = [];
                let image_index = -1;

                if(contentImageSelected){
                  // UPDATE
                  image_index = (contentImages).findIndex( (im, imind) => JSON.stringify(im) == JSON.stringify(contentImageSelected));
                }

                if(image_index >= 0){
                  filteredImages = (contentImages).map( (image, img_index) => {

                    if(image_index == img_index){
                      return {
                        uri: URL.createObjectURL(file),
                        base: uri
                      }
                    }else{
                      return image;
                    }
                  })
                }else{
                  // ADD
                  filteredImages = [...contentImages, {
                    uri: URL.createObjectURL(file),
                    base: uri
                  }];

                }
                setContentImages(filteredImages);
              }
            },
            'base64',
            500,
            500,
            );
        }   catch(err) {

        }
    }
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setIsLeavingDropzone(false);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    const { relatedTarget } = e;

    // Check if the relatedTarget is null or outside the dropzone
    if (!relatedTarget || !e.currentTarget.contains(relatedTarget)) {
      setIsDragging(false);
      setIsLeavingDropzone(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    if (!isLeavingDropzone) {
      setIsDragging(true);
    }
  };

  const handleDropFile = (e) => {
    e.preventDefault();
    setIsDragging(false);
    setIsLeavingDropzone(false);

    const file = e.dataTransfer.files[0];

    if(file){
      selectImage(file)
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    setIsLeavingDropzone(false);

    const file = e.dataTransfer.files[0];

    if(file){
      selectImage(file)
    }
  };

  const modalView = () => {

    const mType = (modal && modal.type) ? modal.type : '';

    switch(mType){
      case "images":

        return (
            <div className={`ModalSide Import ${ (isDragging) ? 'Disable' : ''}`}>
              <div className="ModalSideHeader">
                <div className="ModalSideHeaderTitle">Foto's wijzigen</div>
                <a onClick={
                  () => {
                    setDetailsPre()
                  }
                } className="ModalSideHeaderClose">
                  <RiCloseLine />
                </a>
              </div>

              <div className="ModalSideContent" style={{ margin: "0 -25px", padding: "25px 40px" }}>
                { (modal) &&
                <div
                  className={`ModalSideContentImageWrapper ${ (isDragging) ? 'Enabled' : ''} `}
                  onDrop={handleDropFile}
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                >
                  {
                  (isDragging)
                  ?
                  <div className="ModalSideContentImageDrop">
                    <div className="ModalSideContentImageDropText">Laat de foto hier los om te selecteren.</div>
                  </div>
                  :
                  <>
                    <div className="ModalSideContentImage Circle" style={{ backgroundImage: `url(${ (modal && contentImageSelected) ? ((typeof contentImageSelected == 'string') ? (contentImageSelected) : (contentImageSelected.uri)) : '' })` }}>

                      <div className="ModalSideContentImageOverlay">
                        <div onClick={
                          () => {

                            inputImage.current?.click();

                          }
                        } className="ModalSideContentImageOverlayCircle">
                          <RiImage2Fill />
                          <input ref={ inputImage } type="file" accept=".xls, .xlsx" style={{ display: 'none' }} onChange={
                            (event) => {
                               const file = event.target?.files[0];

                               selectImage(file);
                            }
                          } />
                        </div>
                      </div>
                    </div>
                    <div className="ModalSideContentImageDetails">
                      <div className="ModalSideContentImageDetailsText">{ (modal && contentImageSelected) ? 'Foto actief.' : 'Geen foto geselecteerd.' }</div>
                      <div className="ModalSideContentImageDetailsHint">Sleep een foto of klik op 'selecteer foto'.</div>

                      { (!isDragging) &&
                      <div className="ModalSideContentImageDetailsOptions">
                        { (contentImageSelected) &&
                        <a onClick={
                          () => {

                            setContentImageSelected();

                          }
                        } className="ModalSideContentImageDetailsOptionsButton Delete">Verwijderen</a>
                        }
                        <a onClick={
                          () => {

                            inputImage.current?.click();
                          }
                        } className="ModalSideContentImageDetailsOptionsButton">Selecteer foto</a>
                      </div>
                      }
                    </div>
                  </>
                  }

                </div>
                }

                <div className="ModalSideImageItems">

                  { (modal && contentImages) &&
                  (
                    (contentImages).map( (image, image_index) => {
                      return (
                        <div className="ModalSideImageItem" onClick={
                          () => {
                            setContentImageSelected(image);
                          }
                        } key={'content_image_' + image_index} style={{ backgroundImage: `url(${ (image) ? ((typeof image == 'string') ? (image) : (image.uri)) : '' })` }}>
                          <div onClick={
                            (event) => {

                              // Remove from list
                              if(contentImageSelected && JSON.stringify(contentImageSelected) == JSON.stringify(image)){
                                setContentImageSelected();
                              }
                              setContentImages((prevImages) =>
                                prevImages.filter((_, index) => index !== image_index)
                              );

                              event.stopPropagation();
                            }
                          } className="ModalSideImageItemClose">
                            <RiCloseFill />
                          </div>
                        </div>
                      )
                    })
                  )
                  }

                  <div className="ModalSideImageItem Add" onClick={
                    () => {
                      setContentImageSelected();
                      inputImage.current?.click();
                    }
                  }>
                    <RiAddCircleFill />
                  </div>

                </div>

                { (loading) &&
                <div className="LoginLoadingCenter">
                  <div className="LoginLoading">
                    <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
                  </div>
                </div>
                }

              </div>


              <div className="modalContentOptions LightBorder">
                <a onClick={
                  () => {

                    if(modal && modal.id > 0){
                      saveContentImages(modal.id);
                    }
                    setDetailsPre();
                  }
                } className={`modalContentOptionsButton C2a AccentHover`}>{ 'Opslaan' }</a>
                <a onClick={
                  () => {

                    setDetailsPre();
                  }
                } className="modalContentOptionsButton">Annuleren</a>
              </div>

            </div>
        )
      break;

      default:
        return (
          <div className={`ModalSide Import ${ (isDragging) ? 'Disable' : ''}`}>
            <div className="ModalSideHeader">
              <div className="ModalSideHeaderTitle">Foto's wijzigen</div>
              <a onClick={
                () => {
                  setDetailsPre()
                }
              } className="ModalSideHeaderClose">
                <RiCloseLine />
              </a>
            </div>

            <div className="ModalSideContent" style={{ margin: "0 -25px", padding: "25px 40px" }}>

            </div>
          </div>
        )
      break;
    }



    return false;
  }

  const modalViewMemo = useMemo(() => {

    return modalView;
  }, [modal, isDragging, loading, contentImages, contentImageSelected]);

  return (
    <div className="SettingsContentContent List">

      { editorView() }

      <div className="SettingsMessageText ShowMobile">Voorlopig alleen op desktop beschikbaar.</div>

      <div className={`settingsContentReal HideDesktop ${(contentSelected) ? 'Show' : ''}`}>
        <div className="SettingsContentNavigation Margin">
          <div className="SettingsContentNavigationItem IDSmall">ID</div>
          <div className="SettingsContentNavigationItem Order">Volg</div>
          <div className="SettingsContentNavigationItem NameMid">Type</div>
          <div className="SettingsContentNavigationItem Content">Content</div>
          <div className="SettingsContentNavigationItem Date">Aangepast</div>
          <div className="SettingsContentNavigationItem OptionsBig"></div>
        </div>

        <div className="GlobSettingsScroll">

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }


          { (content_items.length > 0)
          ?
          content_items
          :
          <div className="SettingsMessageText">Geen content items.</div>
          }
        </div>

        { contentPagination() }
      </div>

      <DetailView width={550} modal={modal} setDetailsPre={setDetailsPre}>
        { modalViewMemo() }
      </DetailView>

    </div>
  )
}

export default SettingsContent;
