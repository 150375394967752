import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiUserSmileLine,
 RiAddCircleLine,

 RiFileChartLine
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";
import Moment from 'react-moment';
import ScrollToBottom from 'react-scroll-to-bottom';

import DetailView from "../../../components/DetailView";


function CustomerService(props) {
  const {
    token, account,
    setModalPre,
    searchQuery,
    subview_data
  } = props;
  const [ chats, setChats ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);

  const [ modal, setModal ] = useState();


  const setDetailsPre = (mod) => {

    const timeout = (modal) ? 300 : 0;
    if(modal){
      setModal(modalOld=>{
        return {
          ...modalOld,
          hide: true
        }
      });
    }
    setTimeout( () => {
        setModal(mod);
    }, timeout)
  }

  const modalView = () => {


    return (
        <div className={`ModalSide`}>
          <div className="ModalSideHeader">
            <div className="ModalSideHeaderTitle">Chat { 'bekijken' }</div>

            <a onClick={
              () => {
                setDetailsPre()
              }
            } className="ModalSideHeaderClose">
              <RiCloseLine />
            </a>
          </div>

          <ScrollToBottom className="ModalSideContent ScrollBottom" scrollViewClassName="CustomerServiceChatContentItems" followButtonClassName="NoShowButton">
            <div key={ JSON.stringify(modal) }>

              { (modal && modal.items) &&
                (modal.items).map( (message, chat_index) => {

                  const nextMessage = ((chat_index + 1) < modal.items.length) ? ((modal.items[chat_index + 1].role == message.role) ? true : false) : false;

                  return (
                    <div className={`ChatMessageItemWrapper ${ (message.role) } ${ (nextMessage) ? 'Grouped' : '' }`} key={'chat_' + chat_index}>
                      <div className="ChatMessageItem">
                        <div className="ChatMessageItemAvatarWrapper">
                        { (message.role != 'user') &&
                        <div className="ChatMessageItemAvatar" style={{ backgroundImage: `url(${ config.homepage + 'data/logos/logo_flat.png'  })` }} />
                        }
                        <div className="ChatMessageItemText" dangerouslySetInnerHTML={{__html: message.content }}></div>
                        { (message.role == 'user' && !nextMessage && account) &&
                        <div className="ChatMessageItemAvatar" style={{ backgroundImage: `url('${ account.avatar }')` }}>
                          { (account.avatar == '') &&
                          <RiUserSmileLine />
                          }
                        </div>
                        }
                        </div>
                        { (!nextMessage) &&
                        <div className="ChatMessageItemDate"><Moment fromNow unix>{ message.date }</Moment></div>
                        }
                      </div>
                    </div>
                  )

                })
              }
            </div>

          </ScrollToBottom>

          <div className="modalContentOptions LightBorder">
            <a onClick={
              () => {

                setDetailsPre();
              }
            } className="modalContentOptionsButton">Sluiten</a>
          </div>

        </div>
    )
  }

  const fetchChats = (pageFinal = page) => {

    setLoading(true);
    axios.post( config.api.url + 'admin/customerservice/chats', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      setTimeout( () => {
        setLoading(false);
      }, 1);

      console.log(json.data)

      if(success){
        const chats = data.chats;
        const stats = data.stats;


        if(chats){
          setChats(chats);
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/customerservice_ai/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchChats(page);
  }

  const emailsPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(chats.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/customerservice_ai/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/customerservice_ai/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/customerservice_ai/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }

  useEffect( () => {


    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchChats(page_parsed);
    }else{

    }

  }, []);


  useEffect( () => {

    if(!loading){
      // controller.abort();
      fetchChats();
    }

  }, [searchQuery]);



  const chats_items = (chats).map( (chat, index) => {
    const { account, timestamp_last, timestamp, items } = chat;

    if(!account) return false;
    return (
      <div className="OrderItem" key={'chat_item_' + chat.id}>
        <div className="OrderItemDetailsText IDSmall">#{ chat.id }</div>
        <div className="OrderItemDetailsText Image">
          <div className="OrderItemDetailsTextImage Circle" style={{ backgroundImage: `url(${ (account) ? account.avatar : ''})` }} />
        </div>
        <div className="OrderItemDetailsText Name Bold">{ account.name }</div>
        <div className="OrderItemDetailsText Amount">{ (items) ? (items).length : 'NVT' }</div>
        <div className="OrderItemDetailsText Date">{ timestamp_last?.date }</div>
        <div className="OrderItemDetailsText Date">{ timestamp?.date }</div>

        <div className="OrderItemOptions Big">
          <a onClick={
            () => {

              setDetailsPre(chat);

            }
          } className="OrderItemOptionsButton Edit">
            <RiFileShield2Line style={{ fontSize: 14 }} />
            <span className="OrderItemOptionsButtonText">{ 'Bekijken' }</span>
          </a>
        </div>
      </div>
    )
  })


  return (
    <div className="SettingsContentContent List">

      <div className="SettingsMessageText ShowMobile">Voorlopig alleen op desktop beschikbaar.</div>

      <div className={`settingsContentReal HideDesktop`}>

        <div className="SettingsContentNavigation">
          <div className="SettingsContentNavigationItem IDSmall">ID</div>
          <div className="SettingsContentNavigationItem Image">Foto</div>
          <div className="SettingsContentNavigationItem Name">Naam</div>
          <div className="SettingsContentNavigationItem Amount">Berichten</div>
          <div className="SettingsContentNavigationItem Date">Aangepast</div>
          <div className="SettingsContentNavigationItem Date">Datum</div>
          <div className="SettingsContentNavigationItem OptionsBig"></div>
        </div>

        <div className="GlobSettingsScroll">

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }


          { (chats_items.length > 0)
          ?
          chats_items
          :
          <div className="SettingsMessageText">Geen chats.</div>
          }
        </div>

        { emailsPagination() }

      </div>

      <DetailView modal={modal} setDetailsPre={setDetailsPre}>
      { modalView() }
      </DetailView>

    </div>
  )
}

export default CustomerService;
