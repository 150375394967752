import '../styles/CustomerService.css';
import config from '../constants/config';
import React, { useState, useRef, useEffect } from 'react';
import {
  RiMessage3Fill,
  RiSendPlane2Fill,
  RiUserSmileLine,
  RiAddFill,
  RiArrowRightLine,
  RiSubtractFill,
  RiFileSearchFill,
  RiReplyAllFill
} from "react-icons/ri";
import loadingAnimation from '../animations/loading_circle.json';
import typingAnimation from '../animations/typing.json';
import helpdeskAnimation from '../animations/helpdesk.json';
import loginAnimation from '../animations/app_showcase.json';
import Lottie from "lottie-react";
import axios from "axios";
import Moment from 'react-moment';
import ScrollToBottom from 'react-scroll-to-bottom';


function CustomerService(props) {
  const { subview,
    view, setViewPre,
    viewData,
    account,
    token,
    setModalPre
   } = props;
  const inputChat  = useRef();
  const chatScrollView = useRef();
  const chatThinking = useRef();
  const [ aiID, setAiID ] = useState(0);
  const [ aiThinking, setAiThinking ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ faqSelected, setFaqSelected ] = useState(null);
  const faqRef = useRef([]);
  const [ messages, setMessages ] = useState([
    {
      role: 'assistant',
      content: 'Hi, waarmee kan ik je helpen?',
      content_raw: 'Hi, waarmee kan ik je helpen?',
      date: Math.floor(((new Date()).getTime()) / 1000)
    }
  ]);
  const [ faq, setFaq ] = useState([]);



   // First
   useEffect( () => {

     fetchFAQ();

   }, [token]);

  const fetchFAQ = () => {

    setLoading(true);
    axios.post( config.api.url + 'faq', {
      hash: token
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;


      if(success){
        const faq = data.faq;
        const chat = data.chat;
        const chats = data.chats;

        if(chats && chats.length > 0){
          setMessages(chats);
        }

        if(chat && chat.id > 0){
          setAiID(chat.id);
        }

        if(faq){
          setFaq(faq);
        }

      }
      setTimeout( () => {
        setLoading(false);
      }, 1);
    })
    .catch(function (error) {
      setLoading(false);
    });

  }

  const sendChat = () => {
    const message = inputChat.current?.value;

    if(message != ''){
      const now = Math.floor(((new Date()).getTime()) / 1000)

      const newMessage = {
        role: 'user',
        content: message,
        content: message,
        date: now
      };

      const newMessages = [...messages, newMessage];
      setMessages(newMessages);

      // Clear
      inputChat.current.value = '';

      const sendMessages = [...(messages).map( (msg, index) => {
        return {
          role: msg.role,
          content: msg.content_raw,
          date: msg.date
        }
      }), newMessage];


      setTimeout( () => {
        setAiThinking(true);

        setTimeout( () => {

          // chatThinking.current?.scrollIntoView({ behavior: "smooth", block: "end" });

          axios.post( config.api.url + 'helpdesk/chat', {
            hash: token,
            messages: sendMessages,
            chat_id: aiID
          }, {
              crossdomain: true,
              headers: {
                'api': config.api.key,
                'session': props.sessionHash
              }
          })
          .then((json) => {
            const { success, response, data } = json.data;


            console.log(json.data)
            if(response && response != ''){
              const newMessageAssistant = data.chat;

              setMessages(msgs => [...msgs, newMessageAssistant]);

              if(data && data.chat_id > 0){
                setAiID(data.chat_id);
              }

            }

            setAiThinking(false);
          })
          .catch(function (error) {


            setAiThinking(false);
          });

        }, 150);

      }, 150)


    }
  }

  const messagesContent = () => {

    const messagesObjects = (messages).map( (message, index) => {

      const nextMessage = ((index + 1) < messages.length) ? ((messages[index + 1].role == message.role) ? true : false) : false;
      const messageContentFinal = (message.content).replace(/\u([\dA-Fa-f]{4})/g, (match, hex) => {
        return String.fromCodePoint(parseInt(hex, 16));
      });

      let chat_data = null;
      if(message.has_links){
        const link_data = message.data;

        chat_data = (
          <div onClick={
            () => {
              setViewPre(link_data.url);
            }
          } className="ChatMessageItemLink">
            <RiFileSearchFill />
            <div className="ChatMessageItemLinkText">{ link_data.text }</div>
            <RiArrowRightLine />
          </div>
        )
      }

      return (
        <div className={`ChatMessageItemWrapper ${ (message.role) } ${ (nextMessage) ? 'Grouped' : '' }`} key={'chat_' + index}>
          <div className="ChatMessageItem">
            <div className="ChatMessageItemAvatarWrapper">
            { (message.role != 'user') &&
            <div className="ChatMessageItemAvatar" style={{ backgroundImage: `url(${ config.homepage + 'data/logos/logo_flat.png'  })` }} />
            }
            <div className="ChatMessageItemTextView">
              <div className="ChatMessageItemText" dangerouslySetInnerHTML={{__html: messageContentFinal }}></div>
              { chat_data }
            </div>
            { (message.role == 'user' && !nextMessage) &&
            <div className="ChatMessageItemAvatar" style={{ backgroundImage: `url('${ account.avatar }')` }}>
              { (account.avatar == '') &&
              <RiUserSmileLine />
              }
            </div>
            }
            </div>
            { (!nextMessage) &&
            <div className="ChatMessageItemDate"><Moment fromNow unix>{ message.date }</Moment></div>
            }
          </div>
        </div>
      )
    });

    const lastSent = (messages[messages.length - 1].role == 'user');

    if(aiThinking){
      messagesObjects.push(
        <div ref={chatThinking} className={`ChatMessageItemWrapper assistant`} key={'chat_loading'}>
          <div className="ChatMessageItem">
            <div className="ChatMessageItemAvatarWrapper">
            <div className="ChatMessageItemAvatar" style={{ backgroundImage: `url(${ config.homepage + 'data/logos/logo_flat.png'  })` }} />
            <div className="ChatMessageItemText Small">

              <div className="LoginLoading">
                <Lottie className="LoginLoadingAnimation" animationData={typingAnimation} loop={true} />
              </div>

            </div>
            </div>
          </div>
        </div>
      )
    }

    // RESET
    if(messages.length > 10){
      messagesObjects.push(
        <div className={`ChatResetView`} key={'chat_reset'}>
          <div className="ChatResetPre">Wijkt de conversatie af?</div>
          <div className={`ChatReset`} onClick={
            () => {
              setModalPre({
                title: 'Opnieuw beginnen.',
                subtitle: 'Start een verse converstatie als het teveel afwijkt.',
                content: (
                  <>
                    <div className="modalContentOptions">
                      <a onClick={
                        () => {
                          setMessages([
                            {
                              role: 'assistant',
                              content: 'Hi, waarmee kan ik je helpen?',
                              content_raw: 'Hi, waarmee kan ik je helpen?',
                              date: Math.floor(((new Date()).getTime()) / 1000)
                            }
                          ]);
                          setAiID(0);
                          setModalPre();
                        }
                      } className="modalContentOptionsButton C2a AccentHover">Opnieuw beginnen</a>
                      <a onClick={
                        () => {

                          setModalPre();
                        }
                      } className="modalContentOptionsButton">Annuleren</a>
                    </div>
                  </>
                )
              })
            }
          }>

            <div className="ChatResetText">CONVERSATIE RESETTEN</div>
            <RiReplyAllFill />

          </div>
        </div>
      )
    }


    return messagesObjects;
  }


  const faqItems = () => {

    const faqObjects = (faq).map( (faq_item, faq_index) => {

      const isSelected = (faqSelected && faqSelected == (faq_index + 1)) ? true : false;

      return (
        <div ref={el => faqRef.current[faq_index] = el}  className={`FaqItemWrapper ${ (isSelected) ? 'Show' : ( (faqSelected) ? 'Back' : '') }`} key={'faq_item_' + faq_index}>
          <a onClick={
            () => {
              if(isSelected){
                setFaqSelected(null);
              }else{
                setFaqSelected(faq_index + 1);
                const windowWidth = window.innerWidth;

                if(windowWidth > 1100){
                  setTimeout( () => {
                    if(faqRef.current[faq_index]){
                      faqRef.current[faq_index].scrollIntoView({ behavior: 'smooth' });
                    }
                  }, 50);
                }
              }
            }
          } className="FaqItemQuestionView">
            <div className="FaqItemQuestion">{ faq_item.question }</div>
            { (isSelected)
            ?
            <RiSubtractFill />
            :
            <RiAddFill />
            }
          </a>
          <div className="FaqItemAnswer" dangerouslySetInnerHTML={{__html: faq_item.answer }} />
        </div>
      )
    });

    return faqObjects;
  }

  return (
    <div className="CustomerService">
      <div className="FixedWidth">

        <div className="CustomerServiceWrapper">

        <div className="CustomerServiceSide">
            <div className="CustomerServiceHeader">
              <div className="CustomerServiceHeaderTitle">Hoe kunnen wij je helpen?</div>
            </div>

            <div className="CustomerServiceContent">

              { (loading)
              ?
              <div className="LoginLoadingOverlay Inline">
                <div className="LoginLoading">
                  <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
                </div>
              </div>
              :
              faqItems()
              }

            </div>
          </div>

          {/* (account || !account) &&
          <div className="CustomerServiceChat">
            <div className="CustomerServiceChatHeader">
              <RiMessage3Fill style={{ fontSize: 16 }} />
              <div className="CustomerServiceChatHeaderTitle">DA Instant hulp</div>
              <div className="CustomerServiceChatHeaderAI">AI POWERED</div>
            </div>
            <ScrollToBottom className="CustomerServiceChatContent" scrollViewClassName="CustomerServiceChatContentItems" followButtonClassName="NoShowButton">
              { messagesContent() }
            </ScrollToBottom>
            <div className="CustomerServiceChatMessageView">
              <input
                ref={inputChat}
                className="CustomerServiceChatMessageInput"
                placeholder={'Begin met typen ...'}
                onKeyUp={
                  (event) => {
                    const key = event.key;

                    switch(key){
                      case "Enter":
                        if(!aiThinking && account){
                          sendChat();
                        }

                        if(!account){
                          setModalPre({
                            title: 'Je bent niet ingelogd.',
                            subtitle: 'Maak eenmalig een account aan bij DA en shop met veel plezier!',
                            content: (
                              <>
                                <div className="modalContentContent Row">
                                  <div className="modalContentAnimationWrapper">
                                    <Lottie className="modalContentAnimation" animationData={loginAnimation} loop={true} />
                                  </div>
                                  <div className="LoginFormSignupView">
                                    <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/swirl.png'})` }} />
                                    <div className="LoginFormHeader">
                                      <div className="LoginFormHeaderTitle">Nog geen <b>DA</b> account?</div>
                                    </div>

                                    <a onClick={
                                      () => {
                                        setViewPre('account/registreren');
                                        setModalPre();
                                      }
                                    } className="LoginFormOptionsButton Signup">
                                      <div className="LoginFormOptionsButtonText">Registreren</div>
                                      <RiArrowRightLine />
                                    </a>

                                    <div className="LoginFormOrView">
                                      <div className="LoginFormOrDivider" />
                                      <div className="LoginFormOr">OF</div>
                                      <div className="LoginFormOrDivider" />
                                    </div>

                                    <a onClick={
                                      () => {
                                        setViewPre('account/login');
                                        setModalPre();
                                      }
                                    } className="LoginFormOptionsButton Margined">
                                      <div className="LoginFormOptionsButtonText">Inloggen</div>
                                      <RiArrowRightLine />
                                    </a>

                                  </div>
                                </div>
                                <div className="modalContentOptions NoBorder">
                                  <a onClick={
                                    () => {

                                      setModalPre();
                                    }
                                  } className="modalContentOptionsButton">Annuleren</a>
                                </div>
                              </>
                            )
                          })
                        }
                      break;
                    }
                  }
                }
              />
              <a href="#" onClick={
                (event) => {

                  if(!aiThinking && account){
                    sendChat();
                  }

                  if(!account){
                    setModalPre({
                      title: 'Je bent niet ingelogd.',
                      subtitle: 'Maak eenmalig een account aan bij DA en shop met veel plezier!',
                      content: (
                        <>
                          <div className="modalContentContent Row">
                            <div className="modalContentAnimationWrapper">
                              <Lottie className="modalContentAnimation" animationData={loginAnimation} loop={true} />
                            </div>
                            <div className="LoginFormSignupView">
                              <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/swirl.png'})` }} />
                              <div className="LoginFormHeader">
                                <div className="LoginFormHeaderTitle">Nog geen <b>DA</b> account?</div>
                              </div>

                              <a onClick={
                                () => {
                                  setViewPre('account/registreren');
                                  setModalPre();
                                }
                              } className="LoginFormOptionsButton Signup">
                                <div className="LoginFormOptionsButtonText">Registreren</div>
                                <RiArrowRightLine />
                              </a>

                              <div className="LoginFormOrView">
                                <div className="LoginFormOrDivider" />
                                <div className="LoginFormOr">OF</div>
                                <div className="LoginFormOrDivider" />
                              </div>

                              <a onClick={
                                () => {
                                  setViewPre('account/login');
                                  setModalPre();
                                }
                              } className="LoginFormOptionsButton Margined">
                                <div className="LoginFormOptionsButtonText">Inloggen</div>
                                <RiArrowRightLine />
                              </a>

                            </div>
                          </div>
                          <div className="modalContentOptions NoBorder">
                            <a onClick={
                              () => {

                                setModalPre();
                              }
                            } className="modalContentOptionsButton">Annuleren</a>
                          </div>
                        </>
                      )
                    })
                  }
                  event.preventDefault();
                }
              } className={`CustomerServiceChatMessageButton ${ (aiThinking) ? 'Disabled' : '' }`}>
                <RiSendPlane2Fill />
              </a>
             </div>
          </div>
            */}

        </div>
      </div>
    </div>
  );
}

export default CustomerService;
