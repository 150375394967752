import '../styles/Banners.css';
import config from '../constants/config';
import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiCalendarTodoFill,
  RiBookReadFill
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import loadingAnimation from '../animations/loading_circle.json';
import Lottie from "lottie-react";

const width = window.innerWidth;

function Banners(props) {
  const { subview,
    view, setViewPre,
    viewData,
    token,
    refreshBanners,
    bannerType
   } = props;
  const [ loading, setLoading ] = useState(false);
  const [ transitioning, setTransitioning ] = useState(false);
  const [ hovered, setHovered ] = useState(null);
  const [ index, setIndex ] = useState(0);
  const [ banners, setBanners ] = useState([]);

  const banner = (index < banners.length) ? banners[index] : null;

  const setIndexPre = (pre_index) => {

    let canSet = false;
    let realIndex = -1;


    if(pre_index < 0){
      realIndex = banners.length - 1;
    }else if(pre_index >= banners.length){
      realIndex = 0;
    }else if(pre_index < banners.length){
      realIndex = pre_index;
    }

    if(realIndex >= 0){

      setTransitioning(true);
      setTimeout( () => {
        setIndex(realIndex);
        setTimeout( () => {
          setTransitioning(false);
        }, 1);
      }, 250);
    }

  }



  // One tim
  useEffect(() => {
    let bannerScroll = null;

    if(!hovered){
      bannerScroll = setTimeout(() => {
        setIndexPre(((index + 1) < banners.length) ? (index + 1) : 0);
      }, 7 * 1000);
    }
    return () => {
      clearTimeout(bannerScroll)
    }
  }, [index, hovered]);


  useEffect(() => {

    fetchBanners();

  }, []);

  const fetchBanners = () => {

    setLoading(true);

    axios.post( config.api.url + 'banners', {
      hash: token,
      page: bannerType
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;


      if(success){
        const banners = data.banners;

        if(banners){
          setBanners(banners);
        }

      }
      setTimeout( () => {
        setLoading(false);
      }, 1);
    })
    .catch(function (error) {
      setLoading(false);
    });
  }

  return (
    <div className="HomeBanner">

      { (loading) &&
      <div className="LoginLoadingOverlay NoBackground">
        <div className="LoginLoading">
          <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
        </div>
      </div>
      }

      { (banners.length > 1) &&
      <a onClick={
        () => {
          setIndexPre(index - 1);
        }
      } className="HomeBannerArrow Left">
        <RiArrowLeftLine />
      </a>
      }
      { (banners.length > 1) &&
      <a onClick={
        () => {
          setIndexPre(index + 1);
        }
      } className="HomeBannerArrow Right">
        <RiArrowRightLine />
      </a>
      }

      <div className="HomeBannerPagination">

        { banners.map( (banner, b_index) => {

          return (
            <a onClick={
              () => {

                setIndexPre(b_index)
              }
            } key={'thumb_' + b_index} className={`${ (index == b_index) ? 'Selected' : 'Decoy'}`}></a>
          )

        })
        }

      </div>

      <div className={`HomeBannerImageView ${ (transitioning) ? 'Transitioning' : ''}`} onMouseEnter={
        () => {
          setHovered(true);
        }
      } onMouseLeave={
        () => {
          setHovered(false);
        }
      }>

        { (banner) &&
        <div className="HomeBannerImage" key={'banner_image_' + banner.image} style={{ height: (width < 1100) ? (width * 0.53) : 500, backgroundImage: `url(${ banner.image })` }}  />
        }

        { (banner && banner.sticker) &&
        <div className={`HomeBannerSale ${ (banner.sticker?.type == 'large') ? 'Large' : '' } `} key={'sticker_' + banner.sticker.text}>
          <div className="HomeBannerSaleText">{ banner.sticker.pre }</div>
          <div className="HomeBannerSalePrice">{ banner.sticker.text }</div>
          <div className="HomeBannerSaleText">{ banner.sticker.post }</div>
        </div>
        }

        { (banner && banner.title) &&
        <div className="HomeBannerModal">
          <div className="HomeBannerModalTitle">{ banner.title }</div>
          <div className="HomeBannerModalText">{ banner.subtitle }</div>

          {(banner.page == 'Home') &&
            <div className="HomeBannerModalOptions">
              <a href={ config.homepage + banner.c2a.url } onClick={
                (event) => {

                  setViewPre(banner.c2a.url);

                  event.preventDefault();
                }
              } className="HomeBannerModalOptionsButton">{ banner.c2a.text }</a>
           </div>

          }
          
        </div>
        }

      </div>

    </div>
  );
}

export default React.memo(Banners);
