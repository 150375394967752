import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleLine,

 RiShoppingBagLine,
 RiMapPinUserFill,

 RiFileChartLine
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";


function DeliveryAddresses(props) {
  const {
    token, account,
    setModalPre,
    setViewPre,
    searchQuery,
    fetchSettings,
    subview_data,
    setMenuSelectedPre
  } = props;
  const [ addresses, setAddresses ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ loaded, setLoaded ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);


  const fetchAddresses = (pageFinal = page) => {

    setLoading(true);
    axios.post( config.api.url + 'account/addresses', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      setTimeout( () => {
        setLoading(false);
      }, 1);

      if(success){
        const addresses = data.addresses;
        const stats = data.stats;


        if(!loaded){
          setLoaded(true);
        }

        if(addresses){
          setAddresses(addresses);

          fetchSettings();
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });

  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/notificaties/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchAddresses(page);
  }

  const addressesPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(addresses.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/notificaties/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/notificaties/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/leveringsaddressen/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }

  useEffect( () => {


    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){

      // Get main view
      setPage(page_parsed);
      fetchAddresses(page_parsed);
    }else{
      fetchAddresses();
    }

  }, []);



  useEffect( () => {

    if(!loading && loaded){
      // controller.abort();
      fetchAddresses();
    }


  }, [searchQuery]);


  const addresses_items = (addresses).map( (address, index) => {

    console.log(address)

    return (
      <div className="NotificationWrapper" key={'address_' + address.id} onClick={
        () => {


        }
      }>
        { (address.id == account.delivery_id) &&
        <div className="NotificationNew" />
        }
        <div className="NotificationDetails">
          <div className={`NotificationDetailsNotification ${ (address.id == account.delivery_id) ? 'Unseen' : '' }`}>{ address.address }</div>
          { (address.area) &&
          <div className="NotificationDetailsText" >{ address.area.area }</div>
          }
          <div className="NotificationDetailsDate">{ (address.timestamp_last > 0) ? ('Laatst gebruikt op ' + address.timestamp_last?.date + ' om ' + address.timestamp_last?.time) : 'Nooit eerder gebruikt' }</div>
        </div>
        <div className="NotificationIcon">
          <RiMapPinUserFill />
        </div>
      </div>
    );
  })


  return (
    <div className="SettingsContentContent List">

      <div className="settingsContentReal Flexed">
        <div className="GlobSettingsScroll">

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }

          { (addresses_items.length > 0)
          ?
          addresses_items
          :
          <div className="SettingsMessageText">Geen leveringsaddressen.</div>
          }
        </div>

        { addressesPagination() }
      </div>

    </div>
  )
}

export default DeliveryAddresses;
