import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleLine,
 RiGiftFill,

 RiFileChartLine,
 RiMapPinFill,
 RiStore3Fill,
 RiSwapLine,
 RiCloseFill
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";

import DetailView from "../../../components/DetailView";
import DropDown from "../../../components/DropDown";


function Orders(props) {
  const {
    token, account,
    setModalPre,
    searchQuery,
    fetchSettings,
    subview_data,
    setSettingsHeaderPre
  } = props;
  const [ orders, setOrders ] = useState([]);
  const [ ordersLoading, setOrdersLoading ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ resetDropdown, setResetDropdown ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);

  const [ modal, setModal ] = useState();
  const orderStatuses = [
    {
      key: 'SENT',
      value: 'Geplaatst'
    },
    {
      key: 'HANDLED',
      value: 'In behandeling'
    },
    {
      key: 'ONTHEWAY',
      value: 'Onderweg'
    },
    {
      key: 'COMPLETED',
      value: 'Voltooid'
    },
    {
      key: 'CANCELED',
      value: 'Geannuleerd'
    }
  ]
  const [ statusSelected, setStatusSelected ] = useState();


  const addItemLoading = (item_id) => {
     if (!ordersLoading.includes(item_id)) {
       setOrdersLoading(prevOrdersLoading => [...prevOrdersLoading, item_id]);
     }
   }

  const removeItemLoading = (item_id) => {
   setOrdersLoading(prevOrdersLoading => prevOrdersLoading.filter(id => id !== item_id));
  }

  const setDetailsPre = (mod) => {

    const timeout = (modal) ? 300 : 0;
    if(modal){
      setModal(modalOld=>{
        return {
          ...modalOld,
          hide: true
        }
      });
    }
    setTimeout( () => {
        setModal(mod);
    }, timeout)

    if(mod && mod.status){

      const statusObject = (orderStatuses).find( (stat, ind) => stat.key == mod.status_raw);

      if(statusObject){

        setStatusSelected({
          key: statusObject.key,
          value: statusObject.value
        })
      }


    }
  }

  const setStatusSelectedPre = (order, statusSel) => {

    setStatusSelected(statusSel);
    setDetailsPre();


    // Update
    addItemLoading(order.id);
    axios.post( config.api.url + 'admin/orders/update', {
      hash: token,
      order_id: order.id,
      status: statusSel.key
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      setTimeout( () => {
        setLoading(false);
      }, 1);
      removeItemLoading(order.id);

      if(success){

        fetchSettings();
        fetchOrders();

      }else{

        setModalPre({
          title: 'Oops.',
          subtitle: response
        })
      }

    })
    .catch(function (error) {

        removeItemLoading(order.id);
    });

  }


  const toFixedNoRound = (num, precision = 1) => {
    const factor = Math.pow(10, precision);
    return ((Math.floor(num * factor) / factor)).toFixed(2);
  }

  const modalTotals = () => {

    if(!modal) return false;

    let subtotals = (modal.totals_amount);
    let ob = toFixedNoRound((subtotals / 110) * 10, 2);
    ob = 0;
    let totals = toFixedNoRound(subtotals * 1, 2);

    if(modal.delivery_type == 'DELIVERY'){
      totals = toFixedNoRound(parseFloat(totals) + parseFloat(modal.delivery.fee));
    }
    subtotals = toFixedNoRound(subtotals - ob, 2);
    let totals_no_discount = toFixedNoRound(parseFloat(totals));

    if(modal.discount_amount > 0){
      totals -= parseFloat(modal.discount_amount);

      totals = toFixedNoRound(totals);
    }

    return (
      <div className="modalSideFinalWrapper">
        <div className="modalSideFinal">
          <div className="modalSideFinalPre">{ 'Subtotaal' }</div>
          <div className="modalSideFinalAmount">SRD { subtotals }</div>
        </div>
        {/* <div className={`modalSideFinal ${ (parseInt(modal.discount_amount) == 0) ? 'BorderBottom' : ''}`}>
          <div className="modalSideFinalPre">BTW (10%)</div>
          <div className="modalSideFinalAmount">SRD { ob }</div>
        </div> */}
        { (modal.delivery_type == 'DELIVERY') &&
        <div className="modalSideFinal">
          <div className="modalSideFinalPre">Levering</div>
          <div className="modalSideFinalAmount" style={ (modal.delivery.fee == 0) ? { color: '#47bb3c', fontSize: 16 } : { }}>{ (modal.delivery.fee == 0) ? (<b>GRATIS</b>) : (<>SRD <b> { toFixedNoRound(modal.delivery.fee) }</b></>) }</div>
        </div>
        }
        { (modal.discount_amount > 0) &&
        <div className="modalSideFinal BorderBottom">
          <div className="modalSideFinalPre">Subtotaal zonder korting</div>
          <div className="modalSideFinalAmount">SRD { totals_no_discount }</div>
        </div>
        }
        { (modal.discount_amount > 0) &&
        <div className="modalSideFinal">
          <div className={`modalSideFinalPre`}>Korting { (modal.discount) ? ('('+modal.discount.amount+')') : ''}
              <div className="OrderV2OptionsPreOptionsError" style={{
                backgroundColor: '#3a62c6',
                fontSize: 9,
                paddingBottom: 1,
                marginBottom: -1
              }}>
                <RiGiftFill />
              </div>
            </div>
          <div className="modalSideFinalAmount" style={{ color: '#d63636' }}>SRD { modal.discount_amount }</div>
        </div>
        }
        <div className="modalSideFinal">
          <div className="modalSideFinalPre">{ 'Totaal' }</div>
          <div className="modalSideFinalAmount">SRD { totals }</div>
        </div>
      </div>
    )
  }

  const modalView = () => {
    return (
        <div className={`ModalSide`}>
          <div className="ModalSideHeader">
            <div className="ModalSideHeaderTitle">Bestelling { 'bekijken' }</div>

            <a onClick={
              () => {
                setDetailsPre()
              }
            } className="ModalSideHeaderClose">
              <RiCloseLine />
            </a>
          </div>

          <div className="ModalSideContent" onClick={
            (event) => {

              const cl = event.target.className;

              if(cl != 'DropDownSelected'){
                setResetDropdown(!resetDropdown)
              }
            }
          }>

            { (modal && modal.account) &&
            <div className="modalAccountView">

              <div className="modalAccountDetailsWrapper">
                <div className="modalAccountAvatar" style={{ backgroundImage: `url(${ (modal.account?.avatar) })` }} />
                <div className="modalAccountDetails">
                  <div className="modalAccountDetailsName">{ (modal && modal.contact) && modal.contact.name }</div>
                  <div className="modalAccountDetailsPhone">{ (modal && modal.contact) && modal.contact.phone }</div>
                </div>
              </div>

              <div className="modalAccountDetailsOptions">
                  <div className="ModalSideContentTwin Single">
                    <div className="ModalSideContentInput">
                      <div className="ModalSideContentInputPre">Status</div>

                      <DropDown
                        key={JSON.stringify(statusSelected + resetDropdown)}
                        selected={(statusSelected) ? statusSelected.value : 'Selecteer status'}
                        list={(orderStatuses).map( (status, index) => { return { key: status.key, value: status.value }})}
                        onSelect={
                          (item) => {

                            switch(item.key){
                              case "CANCELED":
                                setModalPre({
                                  title: 'Bestelling annuleren.',
                                  subtitle: 'Annuleer deze bestelling. De klant wordt op de hoogte gesteld via e-mail.',
                                  content: (
                                    <>
                                      <div className="modalContentOptions NoBorder">
                                        <a onClick={
                                          () => {

                                            setStatusSelectedPre(modal, item)
                                            setModalPre();
                                          }
                                        } className="modalContentOptionsButton Delete">Aanvragen</a>
                                        <a onClick={
                                          () => {

                                            setModalPre();
                                          }
                                        } className="modalContentOptionsButton">Annuleren</a>
                                      </div>
                                    </>
                                  )
                                })
                              break;

                              default:
                                setStatusSelectedPre(modal, item)
                              break;
                            }
                          }
                        }
                      />
                    </div>
                  </div>
              </div>

            </div>
            }

            { (modal) &&
            <div className="ModalSideContentTwin">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Levering/Pick-up</div>
                <div className="ModalSideContentInputTextNormal Padded DeliveryType">{ modal.delivery_type }</div>
              </div>
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Betalingsmethode</div>
                <div className="ModalSideContentInputTextNormal Padded DeliveryType">{ modal.payment_method }</div>
              </div>
            </div>
            }

            <div className="modalDeliveryView">
            { (modal && modal.delivery) &&
            ( modal.delivery_type == 'DELIVERY'
            ?
              <div className="modalDelivery">
                <RiMapPinFill style={{ marginTop: 3 }} />
                <div className="modalDeliveryDetails">
                  <div className="modalDeliveryText">{ modal.delivery.address }</div>
                  <div className="modalDeliveryAddress">{ modal.delivery.area }</div>
                </div>
              </div>
            :
            <div className="modalDelivery">
              <RiStore3Fill style={{ marginTop: 3 }} />
              <div className="modalDeliveryDetails">
                <div className="modalDeliveryText">{ modal.delivery.name }</div>
                <div className="modalDeliveryAddress">{ modal.delivery.address }</div>
              </div>
            </div>
            )
            }

            </div>

            <div className="modalErrorView">
              { (modal && modal.cancel_request) &&
                <div className="modalError">
                  <div className="OrderV2OptionsPreOptionsError Text">!</div>
                  <div className="modalErrorText">{ (modal && modal.contact) && modal.contact.name } heeft aangevraagd om deze bestelling te annuleren.</div>
                </div>
              }

              { (modal && modal.transaction_id == '') &&
                <div className="modalError">
                  <div className="OrderV2OptionsPreOptionsError">
                    <RiCloseFill />
                  </div>
                  <div className="modalErrorText">Deze bestelling is niet doorgestuurd naar RetailNxt.</div>
                </div>
              }
            </div>

            <div key={ JSON.stringify(modal) }>
              <div className="modalCouponTitle">Item(s)</div>

              { (modal && modal.items) &&
                (modal.items).map( (cart_item, cart_index) => {

                  const { qty, product } = cart_item;
                  const finalPrice = (parseInt(qty) * parseFloat(product.price)).toFixed(2);

                  return (
                    <div className="modalCouponItem NoBackground End" key={'coup_item_' + cart_index}>
                      <div className="modalCouponItemImageWrapper">
                        <div className="modalCouponItemImage" style={{ backgroundImage: `url(${ (product.image) })` }}/>
                      </div>
                      <div className="modalCouponItemDetailsWrapper">
                        <div className="modalCouponItemDetails">
                          <div className="modalCouponItemDetailsName">{ product.title }</div>
                          <div className="modalCouponItemDetailsSubWrapper">
                            <div className="modalCouponItemDetailsSub" style={{ backgroundColor: (qty > 1) ? '#4bd63e' : 'rgba(255,255,255,.3)' }}>
                              <div className="modalCouponItemDetailsSubText">{ qty + 'x' }</div>
                            </div>
                            <div className="modalCouponItemDetailsSubItemText">{ 'SRD ' + (product.price) }</div>
                          </div>
                        </div>
                        <div className="modalCouponItemSub">
                          <div className="modalCouponItemSubPrice"></div>
                          <div className="modalCouponItemSubPriceNew">SRD { (finalPrice) }</div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              { (modal) && modalTotals() }

            </div>

          </div>

          <div className="modalContentOptions LightBorder">
            <a onClick={
              () => {

                setDetailsPre();
              }
            } className="modalContentOptionsButton">Sluiten</a>
          </div>

        </div>
    )
  }

  const fetchOrders = (pageFinal = page) => {

    setLoading(true);
    axios.post( config.api.url + 'admin/orders', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      setTimeout( () => {
        setLoading(false);
      }, 1);

      if(success){
        const orders = data.orders;
        const stats = data.stats;

              console.log(json.data)

        if(orders){
          setOrders(orders);

          if(subview_data.includes('order/')){
            const orderID = subview_data.replace('order/', '');

            if(orderID > 0){
              const orderFound = (orders).find((or, ind) => or.id == orderID);

              if(orderFound){

                setDetailsPre(orderFound);
              }

            }
          }
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);

    });

  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/bestellingen_all/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchOrders(page);
  }

  const ordersPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(orders.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/bestellingen_all/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/bestellingen_all/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/bestellingen_all/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }

  useEffect( () => {


    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchOrders(page_parsed);
    }else{

    }


  }, []);



  useEffect( () => {

    if(!loading){
      // controller.abort();
      fetchOrders();
    }

  }, [searchQuery]);


  const orders_items = (orders).map( (order, index) => {
    const { account, discount, cancel_request, timestamp, final_amount, status, status_color } = order;
    const isLoading = (ordersLoading).includes(order.id);
    return (
      <div className="OrderItem" key={'order_item_' + order.id}>
        <div className="OrderItemDetailsText ID">{ order.order_no }</div>
        <div className="OrderItemDetailsText Name Bold">{ account.name }</div>
        {/*<div className="OrderItemDetailsText Amount">{ order.items_count }</div> */}
        <div className={`OrderItemDetailsText DeliveryType Type ${ (order.delivery_type == 'DELIVERY') ? 'Delivery' : '' }`}>{ order.delivery_type }</div>
        <div className="OrderItemDetailsText Amount">SRD { final_amount }</div>
        <div className="OrderItemDetailsText Status Bold">
          { (isLoading)
          ?
          <div className="OrderV2OptionsPreOptionsStatus">
            <div className="LoginLoading" style={{ width: 20, height: 20, backgroundColor: 'transparent' }}>
              <Lottie className="LoginLoadingAnimation" style={{ position: 'absolute', width: 50, height: 50 }} animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          :
          <div className="OrderV2OptionsPreOptionsStatus" style={{ color: status_color }}>{ status }</div>
          }
          { (discount) &&
          <div className="OrderV2OptionsPreOptionsError" style={{
            backgroundColor: '#3a62c6',
            fontSize: 9,
            paddingBottom: 1
          }}>
            <RiGiftFill />
          </div>
          }


          { (order.transaction_id == '') &&
          <span className={`OrderV2OptionsPreOptionsError`}>
            <RiCloseFill />
          </span>
          }
          { (cancel_request) &&
          <div className="OrderV2OptionsPreOptionsError Text">!</div>
          }
        </div>
        <div className="OrderItemDetailsText Date">{ timestamp?.date }</div>

        <div className="OrderItemOptions Big">

          <a onClick={
            () => {

              setDetailsPre(order);

            }
          } className="OrderItemOptionsButton Edit">
            <RiFileShield2Line style={{ fontSize: 14 }} />
            <span className="OrderItemOptionsButtonText">{ 'Bekijken' }</span>
          </a>
        </div>
      </div>
    )
  })


  return (
    <div className="SettingsContentContent List">

      <div className="SettingsMessageText ShowMobile">Voorlopig alleen op desktop beschikbaar.</div>

      <div className={`settingsContentReal HideDesktop`}>

        <div className="SettingsContentNavigation">
          <div className="SettingsContentNavigationItem ID">ID</div>
          <div className="SettingsContentNavigationItem Name">Naam</div>
          <div className="SettingsContentNavigationItem Type">Type</div>
          <div className="SettingsContentNavigationItem Amount">Bedrag</div>
          <div className="SettingsContentNavigationItem Status">Status</div>
          <div className="SettingsContentNavigationItem Date">Datum</div>
          <div className="SettingsContentNavigationItem OptionsBig"></div>
        </div>

        <div className="GlobSettingsScroll">

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }

         { (orders_items.length > 0)
          ?
          orders_items
          :
          <div className="SettingsMessageText">Geen bestellingen.</div>
          }
        </div>

        { ordersPagination() }

      </div>

      <DetailView modal={modal} setDetailsPre={setDetailsPre}>
      { modalView() }
      </DetailView>

    </div>
  )
}

export default Orders;
